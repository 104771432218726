import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { useTranslation, initReactI18next } from "react-i18next";
import Skeleton from '@material-ui/lab/Skeleton';

function GlobalSkeleton({
    hasTitle = false,
    totalRowsStats = 1,
    totalTables = 1,
    heightTable = 450,
    margin = '0px',
    padding = '0px 10px',
    marginTopTable = "10px",

}) {
    const { t, i18n } = useTranslation('common');


    return (


        <div className="box loading noBg" style={{display:'flex', flexDirection:'column', margin: margin, marginRight:'14px', padding:padding}}>
            {
                (totalRowsStats > 0 || hasTitle) &&
                <div className="pageHeader">
                    {hasTitle && <h3><Skeleton variant="text" width="40%" height={22} /></h3>}
                    {
                        Array.from(Array(totalRowsStats).keys()).map((_, index) => (
                            <div key={`table-skeleton-${index}`} className="internalFilters">
                                <div className="filter">
                                    <div className="icon">
                                        <Skeleton variant="circle" width={16} height={16} />
                                    </div>
                                    <div className="info">
                                        <div className="name"><Skeleton variant="text" width="100%" height={17} /></div>
                                        <div className="total"><Skeleton variant="text" width="100%" height={17} /></div>
                                    </div>
                                    <div className="number">

                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="icon">
                                        <Skeleton variant="circle" width={16} height={16} />
                                    </div>
                                    <div className="info">
                                        <div className="name"><Skeleton variant="text" width="100%" height={17} /></div>
                                        <div className="total"><Skeleton variant="text" width="100%" height={17} /></div>
                                    </div>
                                    <div className="number">

                                    </div>
                                </div>

                                <div className="filter">
                                    <div className="icon">
                                        <Skeleton variant="circle" width={16} height={16} />
                                    </div>
                                    <div className="info">
                                        <div className="name"><Skeleton variant="text" width="100%" height={17} /></div>
                                        <div className="total"><Skeleton variant="text" width="100%" height={17} /></div>
                                    </div>
                                    <div className="number">

                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="icon">
                                        <Skeleton variant="circle" width={16} height={16} />
                                    </div>
                                    <div className="info">
                                        <div className="name"><Skeleton variant="text" width="100%" height={17} /></div>
                                        <div className="total"><Skeleton variant="text" width="100%" height={17} /></div>
                                    </div>
                                    <div className="number">

                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="icon">
                                        <Skeleton variant="circle" width={16} height={16} />
                                    </div>
                                    <div className="info">
                                        <div className="name"><Skeleton variant="text" width="100%" height={17} /></div>
                                        <div className="total"><Skeleton variant="text" width="100%" height={17} /></div>
                                    </div>
                                    <div className="number">

                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>
            }
            <div className="dataTable" style={{marginTop: marginTopTable}}>
                {
                    Array.from(Array(totalTables).keys()).map((_, index) => (
                        <Skeleton key={`skeleton-table-${index}`} variant="rect" width="100%" height={heightTable}  style={{marginBottom: totalTables > 1 ? "16px" : "0px"}}/>
                    ))
                }
            </div>
        </div>
    )
};

export default GlobalSkeleton;