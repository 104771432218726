import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Toolbar from '../../_components/_shared/Toolbar'
import Teams from '../../_components/settings/teams/Teams'
import TeamsSkeleton from '../../_components/settings/teams/TeamsSkeleton'
import DrawerCreate from '../../_components/settings/teams/DrawerCreate'
import DrawerInvite from '../../_components/settings/teams/DrawerInvite'
import DrawerEdit from '../../_components/settings/teams/DrawerEdit'


import { useTranslation } from "react-i18next";

import { alertActions, settingsActions, teamsActions } from '../../_actions';
import { teammatesActions } from '../../_actions';

import IconClose from '../../_components/_icons/IconClose';
import FilterbarTeams from '../../_components/_shared/FilterbarTeams';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';
import TeamsTable from '../../_components/teams_settings/TeamsTable';
import CompanyInfoHeader from '../../_components/teams_settings/CompanyInfoHeader';
import { Box } from '@mui/material';
import Header from '../../_components/switch_page/Header';


function TeamsSettingsPage(props) {
  const { t } = useTranslation('common');

  const { teamsActions, teammatesActions, userDetails, alertActions, settingsActions, filter, loadingTeams, teams } = props;

  const [openDrawerCreateTeam, setOpenDrawerCreateTeam] = useState(false)
  const [data, setData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([]);
  const [fetching, setFetching] = useState(true)
  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [filterMissingPeriod, setFilterMissingPeriod] = useState(false);

  function handleOpenDrawerCreateTeam() {
    setOpenDrawerCreateTeam(true)
  }
  function closeDrawerCreateTeam() {
    setOpenDrawerCreateTeam(false)
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t('settings.teams.teams'),
        accessor: 'hierarchy',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          return value == true ? t('settings.teams.status_active') : t('settings.teams.status_inactive');
        },
      },
      {
        Header: t('settings.teams.colabs'),
        accessor: 'total_colabs',
      },
    ];
  }, []);


  const getTeams = async ({ pageIndex = 0, pageSize = localStorage.getItem('pageSizeStorage') || 20, isLoading = false, teams = null, teammates = null , filterMissingPeriod = false}) => {
    try {
      if (isLoading) {
        setFetching(true)
      }
      const queryTeams = (teams && teams.length > 0) ? `&teams=${teams.map(t => t.id).join(',')}` : ''
      const queryTeammates = (teammates && teammates.length > 0) ? `&users=${teammates.map(t => t.id).join(',')}` : ''
      const queryFilterMissingPeriod  = filterMissingPeriod === true ? `&missing_period=true`: ''
      const res = await api.get(`/group?page=${pageIndex}&per_page=${pageSize}${queryFilterMissingPeriod}${queryTeams}${queryTeammates}`, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro get teams')
      }
      setData(res.data)
      setPageCount(Math.ceil(parseInt(res.data.countRows) / pageSize))
      setPageCountRows(parseInt(res.data.countRows))
      setSelectedRows([])
    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    teamsActions.getTeams()
    teammatesActions.getTeammates()
  }, [])


  useEffect(() => {
    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getTeams({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: false, teams: teams, teammates: teammates, filterMissingPeriod: filterMissingPeriod })

  }, [filter.teams, filter.teammates, filterMissingPeriod]);

  const toggleFilterMissingPeriod = () => {
    setFilterMissingPeriod(!filterMissingPeriod)
  }


  useEffect(() => {
    document.title = 'Befective | ' + t('menu.teams');
  }, []);


  return (
    <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
      <Header toBackUrl={"/global-settings"} />

      <section className="mainContent">
        
        {/* <Toolbar /> */}
        
        <FilterbarTeams
          showButtonNewTeam={true}
          openDrawer={handleOpenDrawerCreateTeam}
          getTeamsData={getTeams}
        />

        {/* PAGE STARTS */}
        <div className="mainPage profile">
          <div className="nocolumn" style={{ background: 'none' }}>
            <div className={'tab full active'}>
              {
                (fetching || loadingTeams)
                  ? (
                    <TeamsSkeleton />
                  ) : (
                    <>
                      <CompanyInfoHeader
                        organization={data.organization}
                        stats={data.stats}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        getTeamsData={getTeams}
                        alertActions={alertActions}
                        teams={teams}
                        toggleFilterMissingPeriod={toggleFilterMissingPeriod}
                        filterMissingPeriod={filterMissingPeriod}
                        userDetails={userDetails}
                      />
                      <TeamsTable
                        columns={columns}
                        data={data.teams}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        count={pageCount}
                        getTeamsData={getTeams}
                        pageCountRows={pageCountRows}
                        userDetails={userDetails}
                      />
                    </>
                  )
              }
            </div>
          </div>
        </div>
        {/* PAGE ENDS */}
      </section>

      <div
        className={'overlay' + (openDrawerCreateTeam ? ' open' : '')}
        onClick={() => { setOpenDrawerCreateTeam(false) }}>
      </div>

      <section className={'drawer drawer600' + (openDrawerCreateTeam ? ' open' : '')}>
        <button
          onClick={() => setOpenDrawerCreateTeam(false)}
          className="close">
          <IconClose />
        </button>

        {openDrawerCreateTeam &&
          <DrawerCreate closeDrawer={closeDrawerCreateTeam} getTeamsData={getTeams} />
        }
      </section>

    </Box>
  )
};

function mapDispatchToProps(dispatch) {

  return {
    teamsActions: bindActionCreators(teamsActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch)
  }
}

function mapStateToProps(state) {

  return {
    token: state.authentication.user.accessToken,
    loadingTeams: state.teams.loading,
    loadingTeammates: state.teammates.loading,
    filter: state.filter,
    permissions: state.authentication.permissions,
    userDetails: state.authentication.userDetails,
    companyInformation: state.settings.companyInformation,
    teams: state.teams.payload
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(TeamsSettingsPage)
