// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PF6UZaA0Wjr4WbD95ATLhA\\=\\= {\n  padding: 16px 0px !important;\n  display: flex;\n  justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/ProductivityPerformanceFormPage/ProductivityPerformanceFormPage.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,aAAA;EACA,uBAAA;AACF","sourcesContent":[".containerMain {\n  padding: 16px 0px !important;\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerMain": "PF6UZaA0Wjr4WbD95ATLhA=="
};
export default ___CSS_LOADER_EXPORT___;
