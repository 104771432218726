import React, { useEffect, useState } from 'react';
import styles from './CardManageScalesAnswers.module.scss';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { Typography } from '@material-ui/core';
import IconInfo from '../../_icons/IconInfo';
import IconThreePoints from '../../_icons/IconThreePoints';
import { useTranslation } from 'react-i18next';
import OptionEdit from './OptionEdit';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { v4 as uuidv4 } from 'uuid';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';

const CardManageScalesAnswers = ({
    scaleAnswer,
    index,
    handleClickCurrentScaleAnswer,
    handleCloseCurrentScaleAnswer,
    currentScaleAnswer,
    typeAction,
    getScalesAnswers,
    alertActions,
    lastScaleCreated
}) => {

    const { t, i18n } = useTranslation('common');
    const [addOption, setAddOption] = useState(false);

    const letters = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","U","V","W","X","Y","Z"]

    const [options, setOptions] = useState(scaleAnswer.options || []);
    const [saving, setSaving] = useState(false);

    const onDragEnd = async (result) => {
        if (!result.destination) return;
        
        const { destination, source, draggableId: optionID } = result;
        
        if(destination.index === source.index) return;

        const newOptions = Array.from(options);
        const [removed] = newOptions.splice(source.index, 1);
        newOptions.splice(destination.index, 0, removed);

        setOptions(newOptions.map((o, index) => ({...o, options_value: index + 1})));
        
    };

    const addNewOption = (newOption) => {
        if(options.find(o => o.options_label === newOption.options_label)) return;
        setOptions([...options, {...newOption, options_id: uuidv4(), is_new: true}]);
    }

    const removeOption = (optionId) => {
        setOptions(options.filter(o => o.options_id !== optionId));
    }

    const editOption = (option) => {
        setOptions(options.map(o => o.options_id === option.options_id ? option : o));
    }

    const validateOptions = (options) => {
        for (const option of options) {
            if (options.filter(q => q.options_label === option.options_label).length > 1) {
                return {
                    error: t('performance_assessment.messages.error_repeated_option')
                };
            }
            if (option.options_label === "" || option.options_value === "") {
                return {
                    error: t('performance_assessment.messages.error_empty_option')
                };
            }   
        }
        return {
            error: null
        }
    }

    const handleSaveOptions = async () => {
        try {

            const validateOptionsResult = validateOptions(options);

            if(validateOptionsResult.error !== null) return alertActions.error(validateOptionsResult.error);

            const optionsPrepared = options.map(q => {
                if(q.is_new){
                    return {
                        ...q,
                        options_id: undefined
                    }
                }
                return q                
            })

            setSaving(true);
            const res = await api.put(`/performance-assessment/scales/${scaleAnswer.id}/options`, {options: optionsPrepared}, {headers: authHeader()});

            if (res.status !== 200) {
                throw new Error('Erro to save options');
            }

            getScalesAnswers();            
            setAddOption(false);
            handleCloseCurrentScaleAnswer();
            setSaving(false);

            alertActions.success(t('performance_assessment.messages.success_save_scale'));
        } catch (error) {
            console.log(error);
            setSaving(false);  
            alertActions.error(t('performance_assessment.messages.error_save_scale'));      
        }
    }

    useEffect(() => {
        setOptions(scaleAnswer.options || []);
    }, [scaleAnswer.options]);

    useEffect(() => {
        if(options.length >= 6){
            setAddOption(false);
        }
    }, [options]);

    useEffect(() => {
        if(lastScaleCreated){
            setAddOption(true);
        }else{
            setAddOption(false);
        }
    }, [lastScaleCreated])

    return (
        <div className={styles.cardContainer}>
            <header className={styles.cardHeader}>
                <div className={styles.boxCardTitle}>
                    <h6 
                        style={{color: (currentScaleAnswer && currentScaleAnswer.id === scaleAnswer.id && typeAction === "edit_options") ? '#187DFF' : '#1E6B7F'}}>
                            {
                                scaleAnswer.is_objective
                                ?
                                `${index + 1}. ${scaleAnswer.name} ${(currentScaleAnswer && currentScaleAnswer.id === scaleAnswer.id && typeAction === "edit_options") ? `(${t('performance_assessment.page_scales_answers.edit')})` : ''}`
                                :
                                `${index + 1}. ${scaleAnswer.name} (${t('performance_assessment.page_scales_answers.discursive')})`
                            }
                    </h6>
                    {
                        scaleAnswer.description &&
                        <BefectiveTooltip
                            title={<Typography component={'div'} variant='caption'>{scaleAnswer.description}</Typography>}
                            placement="right-start"
                            arrow
                            interactive>
                            <span style={{ cursor: 'pointer' }}>&nbsp;&nbsp;<IconInfo /></span>
                        </BefectiveTooltip>
                    }
                </div>
                <div className={styles.boxButtons}>
                    {
                        currentScaleAnswer === null
                        ?
                        <button
                            className={"buttonThreePoints"}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClickCurrentScaleAnswer(e, scaleAnswer);
                            }}
                            >
                            <IconThreePoints />
                        </button>
                        :
                        (
                            (scaleAnswer.id === currentScaleAnswer.id && typeAction === "edit_options")
                            ?
                            <>
                                <button 
                                    className={styles.buttonCancel} 
                                    type="button" 
                                    onClick={() => handleCloseCurrentScaleAnswer(true)}
                                    disabled={saving}
                                >
                                    {t("performance_assessment.actions.cancel")}
                                </button>
                                <button 
                                    className={styles.buttonSave} 
                                    type="button" 
                                    onClick={() => {
                                        handleSaveOptions();
                                    }}
                                    disabled={saving}
                                >
                                    {saving ? t("performance_assessment.actions.saving") : t("performance_assessment.actions.save")}
                                </button>
                            </>
                            :
                            <button
                                className={"buttonThreePoints"}
                                style={{cursor: 'not-allowed'}}
                                disabled
                                >
                                <IconThreePoints />
                            </button>
                        )
                    }
                </div>
            </header>
            {
                scaleAnswer?.is_objective === true &&
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th style={{width: '0px', paddingRight: '4px'}}/>
                            <th style={{width: '20%'}}>{t("performance_assessment.page_scales_answers.table.col_options")}</th>
                            <th>{t("performance_assessment.page_scales_answers.table.col_text_help")}</th>
                            <th style={{width: '8%', textAlign: 'center'}}>{t("performance_assessment.page_scales_answers.table.col_value")}</th>
                            <th style={{width: '0px'}}/>
                        </tr>
                    </thead>

                    {
                        (currentScaleAnswer && scaleAnswer.id === currentScaleAnswer.id && typeAction === "edit_options")
                        ?
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                            {(provided) => (
                                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                
                                    {(options && options.length > 0) &&
                                        options.map((option, index) => {
                                            return (
                                                <Draggable
                                                    key={option.options_id}
                                                    draggableId={String(option.options_id)}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <OptionEdit
                                                            key={`option-${index}`}
                                                            provided={provided}
                                                            scaleAnswer={scaleAnswer}
                                                            option={option}
                                                            options={options}
                                                            getScalesAnswers={getScalesAnswers}
                                                            setAddOption={setAddOption}
                                                            addOption={addOption}
                                                            editOption={editOption}
                                                            removeOption={removeOption}
                                                            addNewOption={addNewOption}
                                                        />
                                                    )}
                                                </Draggable>
                                            )
                                        })
                                    }


                                    {provided.placeholder}

                                    {
                                        !addOption
                                        ?
                                        <tr>
                                            <td colSpan={5}>
                                                {
                                                    (Object.keys(options).length === 0 || options.length < 6) &&
                                                    <button className={styles.buttonNewOption} type="button" onClick={() => setAddOption(true)}>
                                                        {t("performance_assessment.page_scales_answers.new_item")}
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                        :

                                        <OptionEdit
                                            key={`option2-${index}`}
                                            provided={null}
                                            scaleAnswer={scaleAnswer}
                                            addOption={addOption}
                                            setAddOption={setAddOption}
                                            options={options}
                                            option={{
                                                options_id: null,
                                                options_label: "",
                                                options_help_text: "",
                                                options_value: ""
                                            }}
                                            getScalesAnswers={getScalesAnswers}                                        
                                            editOption={editOption}
                                            removeOption={removeOption}
                                            addNewOption={addNewOption}
                                        />
                                    }
                                
                                
                                </tbody>
                            )}
                            </Droppable>
                        </DragDropContext>
                    :
                    <tbody className={styles.tbodyGray}>
                        {
                            (options && options.length > 0) 
                            ?
                            options.map((option, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{width: '0px', paddingRight: '4px'}}>{`${letters[index]}.`}</td>
                                        <td style={{width: '20%'}}>{option.options_label}</td>
                                        <td>{option.options_help_text}</td>
                                        <td style={{width: '8%', textAlign: 'center'}}>{option.options_value}</td>
                                        <td style={{width: '0px'}}/>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={5}>{t("performance_assessment.page_scales_answers.no_items")}</td>
                            </tr>

                        }
                    </tbody>

                    }
                    
                </table>
            }
            

        </div>
    )
}

export default CardManageScalesAnswers;