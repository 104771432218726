import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { api } from "../../../_helpers/api";
import IconClose from "../../_icons/IconClose";
import { Skeleton } from "@material-ui/lab";
import './RepliesFeedbackDrawer.scss'
import { authHeader } from "../../../_helpers";

export default function RepliesFeedbackDrawer({
  openDrawer,
  closeDrawer,
  currentData
}) {
  const { t, i18n } = useTranslation("common");
  const [repliesFeedbacks, setRepliesFeedbacks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getFirstName = (name) => {
    if(!name) return null
    return name.split(" ")[0];
  };

  const getData = async () => {

    try {
      
      setIsLoading(true);

      const result = await api.get(`/performance-assessment/answers/shoulds?performance_assessment_id=${currentData.performance_assessment_id}&evaluated_id=${currentData.id}`, {
      	headers: authHeader()
      })
      
      setRepliesFeedbacks(result?.data);
      
      setIsLoading(false);

    } catch (error) {
      //alertActions.error(error)
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        className={"overlay" + (openDrawer ? " open" : "")}
        onClick={closeDrawer}
      ></div>
      <section
        className={
          "drawer drawer400" + (openDrawer ? " open" : "")
        }
      >
        <button onClick={closeDrawer} className="close">
          <IconClose />
        </button>
        {isLoading ? (
          <div className="containerDrawerRepliesFeedback">
            <header>
              <Skeleton width="70%" height={20} />
            </header>

            <div className="containerList">
              <div className="headerList">
                <h3>
                  <Skeleton width="120px" />
                </h3>
              </div>
              <div className="contentList">
                <div className="row">
                  <div className="col" style={{ width: "auto" }}>
                    <h6 style={{margin:'10px 0px'}}>
                      <Skeleton width="80%" />
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col" style={{ width: "auto" }}>
                    <h6 style={{margin:'10px 0px'}}>
                      <Skeleton width="80%" />
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col" style={{ width: "auto" }}>
                    <h6 style={{margin:'10px 0px'}}>
                      <Skeleton width="80%" />
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="containerDrawerRepliesFeedback">
            {
              (repliesFeedbacks && repliesFeedbacks.length > 0)
              ?
              repliesFeedbacks.map((answer, index) => (
                <React.Fragment key={`answer-${index}`}>
                  <header>
                    <h3>{answer?.evaluator_username}</h3>
                  </header>
                  <div className="containerList">
                    <div className="headerList">
                      <h3>{`${getFirstName(answer?.evaluated_username)} ${t('performance_assessment.page_assessment_details.replies.keep_doing')}`}</h3>
                    </div>
                    {
                      answer.keep_doing_arr.length > 0 
                      ? (
                          <div className="contentList">
                            {
                              answer.keep_doing_arr.map((keepDoing, index) => (
                                <p key={`keep_doing_${keepDoing.id}`} className="itemList">
                                  {keepDoing?.content}
                                </p>     
                              ))
                            }
                          </div>
                      )
                      :
                      <div className="boxNoneFeedback simple">{t('performance_assessment.page_assessment_details.replies.no_feedback')}</div>
                    }
                  </div>
                  
                  <div className="containerList">
                    <div className="headerList">
                      <h3>{`${getFirstName(answer?.evaluated_username)} ${t('performance_assessment.page_assessment_details.replies.start_todo')}`}</h3>
                    </div>
                    {
                      answer.should_do_arr.length > 0 
                      ? (
                        <div className="contentList">
                          {
                            answer.should_do_arr.map((startTodo, index) => (
                              <p key={`start_todo_${startTodo.id}`} className="itemList">
                                {startTodo?.content}
                              </p>     
                            ))
                          }
                        </div>
                      )
                      :
                      <div className="boxNoneFeedback simple">{t('performance_assessment.page_assessment_details.replies.no_feedback')}</div>
                    }
                  </div>
                </React.Fragment>
              ))
              :
              <div className="boxNoneFeedback">{t('performance_assessment.page_assessment_details.replies.no_feedback')}</div>
            }
          </div>
        )}
      </section>
    </>
  );
}
