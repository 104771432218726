import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import IconBack from "../../../_components/_icons/IconBack";
import { history } from "../../../_helpers/history";

export default function FeedbackTabsComponent({ currentActive, evalutedId }) {
  const { t, i18n } = useTranslation("common");

  return (
    <div className="mainFilterbar">
      <div className="filters">
        <NavLink
          to={`/performance-assessment/feedback/report/${evalutedId}`}
          className={currentActive === "report" ? "active" : ""}
        >
          <button className={"settingsNav"}>
            {t("performance_assessment.page_feedback.report_tab.title")}
          </button>
        </NavLink>
        <NavLink
          to={`/performance-assessment/feedback/action-plan/${evalutedId}`}
          className={currentActive === "action-plan" ? "active" : ""}
        >
          <button className={"settingsNav"}>
            {t("performance_assessment.page_feedback.action_plain_tab.title")}
          </button>
        </NavLink>
      </div>
      <button
        className={"back"}
        title={t("historical.back")}
        onClick={() => history.push("/performance-assessment/feedback")}
      >
        <IconBack />
      </button>
    </div>
  );
}
