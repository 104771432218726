import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import HeaderForm from "../personal/HeaderForm";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomReactSelect from "../../_shared/CustomReactSelect";
import { api } from "../../../_helpers/api";
import { authHeader, prepareOptionsData } from "../../../_helpers";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import ExperiencesManageList from "./ExperiencesManageList";


export default function FormProfessionalCreatePT({
  formData,
  getFormData,
  toogleShowForm,
  isEditing,
  lang,
  optionsData,
  alertActions,
  userId
}) {
  const { t, i18n } = useTranslation("common");
  const [isSubmitting, setIsSubmitting] = useState(false);

  let formSchema = yup.object({
    office: yup.string().required(t("spaces.required_field"))
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      office: formData?.office,
      department_name: formData?.department_name,
      admission: formData?.admission ? moment(formData?.admission).format("YYYY-MM-DD") : null,
      work_model: formData?.work_model ? { value: formData?.work_model.value, label: formData?.work_model[`label_${lang}`] } : null,
      work_classification: formData?.work_classification ? { value: formData?.work_classification.value, label: formData?.work_classification[`label_${lang}`] } : null,
      work_contract: formData?.work_contract,
      work_code: formData?.work_code,
      registration_code: formData?.registration_code,
      visa_documents: formData?.visa_documents,
      visa_residency_status: formData?.visa_residency_status,
      visa_expires_in: formData?.visa_expires_in ? moment(formData?.visa_expires_in).format("YYYY-MM-DD") : null,
      work_authorization: formData?.work_authorization,
      experiences: formData?.experiences || []
    },
    resolver: yupResolver(formSchema),
  });

  const {
    office,
    department_name,
    admission,
    work_model,
    work_classification,
    work_contract,
    work_code,
    registration_code,
    visa_documents,
    visa_residency_status,
    visa_expires_in,
    work_authorization,
    experiences
  } = watch();

  const onSubmit = async (data) => {

    try {

      setIsSubmitting(true)

      console.log("data => ", data)

      const payload = {
        ...data,
        machine_users_id: userId,
        work_model_id: work_model?.value,
        work_classification_id: work_classification?.value,
        work_contract_id: work_contract?.value,
        visa_documents_id: visa_documents?.value,
        visa_residency_status_id: visa_residency_status?.value,
        visa_expires_in: visa_expires_in ? moment(visa_expires_in?.$d).format("YYYY-MM-DD") : null,
        experiences: data.experiences.map((experience) => {
          if(experience.is_new){
            return {
              ...experience,
              id: undefined
            }
          }
          return experience
        })
      }
      
      const resCreate = await api.post('/users/professional-information/', payload,
        {
          headers: authHeader()
        })

      if (resCreate.status !== 200) {
        throw new Error("Error create professional information")
      }

      await getFormData(false)
      setIsSubmitting(false)
      toogleShowForm()
      alertActions.success(t('settings.users.success.info_save'))

    } catch (error) {
      setIsSubmitting(false)
      console.error(error)
      alertActions.error(error.response.data)
    }
  }


  console.log("values => ", getValues())
  return (
    <div>
      <div className={styles.cardContainer}>
        <HeaderForm
          formData={formData}
          toogleShowForm={toogleShowForm}
          isEditing={isEditing}
          titleCreate={t("settings.users.professional_form.title_create")}
          isSubmitting={isSubmitting}
          submitForm={handleSubmit(onSubmit)}
          userId={userId}
        />
        
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          paddingX="16px"
          paddingBottom="16px"
          borderRadius="4px"
          marginBottom="2px"
          flexGrow={1}
        >
         
          <Grid container spacing={2} marginBottom={'4px'} marginTop={0} >
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="office"
                >
                  {t("settings.users.professional_form.position")}
                </Typography>

                <TextField
                  id="office"
                  name="office"
                  placeholder={t("settings.users.professional_form.position_placeholder")}
                  value={office}
                  error={false}
                  helperText={""}
                  {...register("office")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                />

                {errors.office && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.office.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="admission"
                >
                  {t("settings.users.professional_form.admission")}
                </Typography>
                
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  locale={lang}
                >
                  <DatePicker
                    disabled
                    value={admission}
                    inputFormat={lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY"}
                    onChange={(value) => setValue("admission", value)}
                    renderInput={(params) => (
                      <TextField
                        id="admission"
                        variant="outlined"
                        fullWidth
                        {...params}
                        sx={{
                          width: "100%",
                          height: "43px",
                          color: "#4F7091",
                          backgroundColor: '#F5F6F9',
                          fieldset: {
                            border: "1px solid #DEE3E9CC",
                            height: "45px",
                          },
                          input: { color: "#4F7091", paddingY: "11.5px" },
                          label: { top: "-4px" },
                        }}
                        name="admission"
                        error={false}
                        helperText={""}
                      />
                    )}
                  />
                </LocalizationProvider>

                {errors.admission && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.admission.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="department_name"
                >
                  {t("settings.users.professional_form.department")}
                </Typography>

                <TextField
                  id="department_name"
                  name="department_name"
                  value={department_name}
                  placeholder={t("settings.users.professional_form.department_placeholder")}
                  error={false}
                  helperText={""}
                  {...register("department_name")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                ></TextField>

                {errors.department_name && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.department_name.message}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} marginBottom={'4px'} marginTop={0} >
            <Grid item xs={2}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="work_model"
                >
                  {t("settings.users.professional_form.model_work")}
                </Typography>

                <CustomReactSelect
                    id="work_model"
                    isMulti={false}
                    value={work_model}
                    options={prepareOptionsData(optionsData?.work_model, lang)}
                    onChange={(values) => setValue("work_model", values)}
                    closeMenuOnSelect={true}
                />
                {errors.work_model && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.work_model.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="work_contract"
                >
                  {t("settings.users.professional_form.contract")}
                </Typography>
                
                <CustomReactSelect
                    id="work_contract"
                    isMulti={false}
                    value={work_contract}                    
                    options={prepareOptionsData(optionsData?.work_contract, lang)}
                    onChange={(values) => setValue("work_contract", values)}
                    closeMenuOnSelect={true}
                />
                {errors.work_contract && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.work_contract.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="work_code"
                >
                  {t("settings.users.professional_form.pis_and_pasep")}
                </Typography>

                <TextField
                  id="work_code"
                  name="work_code"
                  value={work_code}
                  placeholder={t("settings.users.professional_form.pis_and_pasep_placeholder")}
                  error={false}
                  helperText={""}
                  {...register("work_code")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                ></TextField>

                {errors.work_code && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.work_code.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="work_classification"
                >
                  {t("settings.users.professional_form.classification_job")}
                </Typography>
                <CustomReactSelect
                  isMulti={false}
                  value={work_classification}                    
                  options={prepareOptionsData(optionsData?.work_classification, lang)}
                  onChange={(values) => setValue("work_classification", values)}
                  closeMenuOnSelect={true}
                />
                {errors.work_classification && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.work_classification.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="registration_code"
                >
                  {t("settings.users.professional_form.code_rigistration")}
                </Typography>

                <TextField
                  id="registration_code"
                  name="registration_code"
                  value={registration_code}
                  placeholder={t("settings.users.professional_form.code_rigistration_placeholder")}
                  error={false}
                  helperText={""}
                  {...register("registration_code")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                ></TextField>

                {errors.registration_code && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.registration_code.message}
                  </Typography>
                )}
              </Box>
            </Grid>

          </Grid>
        </Box>
      </div>

      <div className={styles.cardContainer}>
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          paddingX="16px"
          paddingBottom="16px"
          borderRadius="4px"
          marginBottom="2px"
          flexGrow={1}
        >
          <Grid container spacing={2} marginBottom={'4px'} marginTop={1}>
            <Grid item xs={12}>
                <Typography
                  component="h3"
                  sx={{ fontSize: "18px", color: "#1E6B7F", fontWeight: 500 }}
                >
                  {t("settings.users.professional_form.colab_outside_country")}
                </Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="visa_documents"
                >
                  {t("settings.users.professional_form.documents_visa")}
                </Typography>
                <CustomReactSelect
                  id="visa_documents"
                  isMulti={false}
                  value={visa_documents}                    
                  options={prepareOptionsData(optionsData?.visa_documents, lang)}
                  onChange={(values) => setValue("visa_documents", values)}
                  closeMenuOnSelect={true}
                />
                {errors.visa_documents && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.visa_documents.message}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="visa_residency_status"
                >
                  {t("settings.users.professional_form.status_residence")}
                </Typography>
                <CustomReactSelect
                  id="visa_residency_status"
                  isMulti={false}
                  value={visa_residency_status}
                  options={prepareOptionsData(optionsData?.visa_residency_status, lang)}
                  onChange={(values) => setValue("visa_residency_status", values)}
                  closeMenuOnSelect={true}
                />
                {errors.visa_residency_status && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.visa_residency_status.message}
                  </Typography>
                )}
              </Box>
            </Grid>            
            
            <Grid item xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="work_authorization"
                >
                  {t("settings.users.professional_form.authorize_work")}
                </Typography>
                <TextField
                    id="work_authorization"
                    name="work_authorization"
                    value={work_authorization}
                    placeholder={t("settings.users.professional_form.authorize_work_placeholder")}
                    error={false}
                    helperText={""}
                    {...register("work_authorization")}
                    sx={{
                      width: "100%",
                      height: "45px",
                      fieldset: {
                        border: "1px solid #DEE3E9CC",
                        height: "45px",
                      },
                      div: { color: "#4F7091", height: "45px" },
                      label: { top: "-4px" },
                    }}
                  ></TextField>

                  {errors.work_authorization && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.work_authorization.message}
                    </Typography>
                  )}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Typography
                component="label"
                sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                htmlFor="visa_expires_in"
              >
                {t("settings.users.professional_form.visa_expirated_at")}
              </Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                locale={lang}
              >
                <DatePicker
                  value={visa_expires_in}
                  inputFormat={lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY"}
                  onChange={(value) => setValue("visa_expires_in", value)}
                  renderInput={(params) => (
                    <TextField
                      id="visa_expires_in"
                      variant="outlined"
                      fullWidth
                      {...params}
                      sx={{
                        width: "100%",
                        height: "45px",
                        fieldset: {
                          border: "1px solid #DEE3E9CC",
                          height: "45px",
                        },
                        input: { color: "#4F7091", paddingY: "11.5px" },
                        label: { top: "-4px" },
                      }}
                      name="visa_expires_in"
                      error={false}
                      helperText={""}
                    />
                  )}
                />
              </LocalizationProvider>

              {errors.visa_expires_in && (
                <Typography fontSize={12} color="#FF7A90" component="span">
                  {errors.visa_expires_in.message}
                </Typography>
              )}
              
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className={styles.cardContainer}>
          <ExperiencesManageList
            experiences={experiences}
            setValue={setValue}
            lang={lang}
          />    
      </div>

    </div>
  );
}
