import React, { useEffect, useState } from 'react'
import Toolbar from '../../_components/_shared/Toolbar'
import { connect } from 'react-redux'
import { alertActions } from '../../_actions'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import { api } from '../../_helpers/api'
import { authHeader, history } from '../../_helpers'
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton'
import { Box, Popover } from "@material-ui/core";
import PopoverConfirm from '../../_components/teams_settings/PopoverConfirmDelete'
import ScalesTabComponent from '../../_components/performance_assessments/ScalesTabsComponent'
import CreateScaleAnswerModal from '../../_components/performance_assessments/PerformanceAssessmentsScalesAnswers/CreateScaleAnswerModal'
import CardManageScalesAnswers from '../../_components/performance_assessments/PerformanceAssessmentsScalesAnswers/CardManageScalesAnswers'
import Filterbar from '../../_components/_shared/Filterbar'
import NoData from './NoData'
import ImportScalesModal from '../../_components/performance_assessments/PerformanceAssessmentsScalesAnswers/ImportScalesModal'

function PerformanceAssessmentScalesAnswersPage({
  alertActions,
  userDetails,
  filter
}) {
  
  const { t, i18n } = useTranslation('common');
  const [scalesAnswers, setScalesAnswers] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [openModalCreateScaleAnswer, setOpenModalCreateScaleAnswer] = useState(false);
  const [openModalScalesImport, setOpenModalScalesImport] = useState(false);
  const [currentScaleAnswer, setCurrentScaleAnswer] = useState(null);
  const [lastScaleCreated, setLastScaleCreated] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "actions-popover" : undefined;

  const [typeAction, setTypeAction] = useState(null);

  const [scaleAnswerToEdit, setScaleAnswerToEdit] = useState(null);

  const [typeModal, setTypeModal] = useState('create_scale');

  const handleClickCurrentScaleAnswer = (event, scale) => {
    setCurrentScaleAnswer(scale);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCurrentScaleAnswer = (isFetching=false) => {
    setAnchorEl(null);
    setTypeAction(null);
    setCurrentScaleAnswer(null);
    setLastScaleCreated(null);
    if(isFetching===true) {
      getScalesAnswers(false, null)
    }
  };

    
  const [anchorElConfirmDelete, setAnchorElConfirmDelete] = useState(null);
  const openConfirmDelete = Boolean(anchorElConfirmDelete);
  const idConfirmDelete = openConfirmDelete ? 'popover-confirm-delete-scale' : undefined;

  const handleOpenConfirmDelete = (event) => {
    setAnchorElConfirmDelete(event.currentTarget);
  };

  const handleCloseConfirmDelete = () => {
    setAnchorElConfirmDelete(null)
  };  

  const handleConfirmDeleteScaleAnswer = async () => {
    try {
      if (!currentScaleAnswer.id) {
        throw new Error('Scale Answer to delete not found')
      }
      const res = await api.delete(`/performance-assessment/scales/${currentScaleAnswer.id}`, { headers: authHeader() })
      if (res.status !== 200) {
        throw new Error('Erro to delete scale answer')
      }
      handleCloseConfirmDelete()
      handleCloseCurrentScaleAnswer()
      getScalesAnswers()
      alertActions.success(t('performance_assessment.messages.success_delete_scale'))
    } catch (error) {
      alertActions.error(error.response.data)
      console.error(error)
    }
  }


  const [anchorElConfirmClone, setAnchorElConfirmClone] = useState(null);
  const openConfirmClone = Boolean(anchorElConfirmClone);
  const idConfirmClone = openConfirmClone ? 'popover-confirm-clone-scale' : undefined;

  const handleOpenConfirmClone = (event) => {
    setAnchorElConfirmClone(event.currentTarget);
  };

  const handleCloseConfirmClone = () => {
    setAnchorElConfirmClone(null)
  };

  const handleConfirmCloneScaleAnswer = async () => {
    try {
      if (!currentScaleAnswer.id) {
        throw new Error('Scale Answer off to clone not found')
      }

      const res = await api.post(`/performance-assessment/scales/${currentScaleAnswer.id}/clone`, {}, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro to clone scale answer')
      }
      handleCloseConfirmClone()
      handleCloseCurrentScaleAnswer()
      getScalesAnswers()
      alertActions.success(t('performance_assessment.messages.success_clone_scale'))
    } catch (error) {      
      alertActions.error(error.response.data)
      console.error(error)
    }
  }

  const getScalesAnswers = async (isLoading=true, lastScaleCreated=null) => {
    try {
      if(isLoading) setFetching(true);
      const response = await api.get('performance-assessment/scales', { headers: authHeader() });
      const { data } = response;
      setScalesAnswers(data);
      if(lastScaleCreated && lastScaleCreated.id){
        const scaleFiltered = data.find(scale => scale.id === lastScaleCreated.id);
        if(scaleFiltered) {
          setCurrentScaleAnswer(scaleFiltered);
          setTypeAction('edit_options');
          setLastScaleCreated(true);
        }
      }

      setFetching(false);
    } catch (error) {
      console.log(error);
      setFetching(false);
    }
  }

  const handleOpenModalCreateScaleAnswer = () => {
    setTypeModal('create_scale');
    setAnchorEl(null);
    setOpenModalCreateScaleAnswer(true);
  }

  const handleCloseModalCreateScaleAnswer = () => {
    setOpenModalCreateScaleAnswer(false);
  }

  const handleOpenModalEditScaleAnswer = () => {
    setTypeModal('edit_scale');
    if(currentScaleAnswer){
      setAnchorEl(null);
      setScaleAnswerToEdit(currentScaleAnswer);
    }
    setOpenModalCreateScaleAnswer(true);
  }

  const handleCloseModalEditScaleAnswer = () => {
    setOpenModalCreateScaleAnswer(false);
    if(currentScaleAnswer){
      handleCloseCurrentScaleAnswer(false)
      setScaleAnswerToEdit(null);
    }
  }

  const handleOpenModalScalesImport = () => {
    setOpenModalScalesImport(true);
  }

  const handleCloseModalModalScalesImport = () => {
    setOpenModalScalesImport(false);
  }

  useEffect(() => {    
    document.title = `Befective | ${t('menu.performance_assessment.pre_settings')} | ${t('menu.performance_assessment.scales')} `;  
    getScalesAnswers();
  }, [])


  return (
    <section className="mainContent">
      
      <Toolbar />

      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhat={'disabled'}    
        buttonCreate={{
          title: t('performance_assessment.page_scales_answers.button_create'),
          handleClick: handleOpenModalCreateScaleAnswer,
        }}
        buttonImport={{
          handleClick:handleOpenModalScalesImport,
        }}
      />


      <main className='mainContentPage'>
        {
          !fetching
          ?
          (
            scalesAnswers && scalesAnswers.length > 0
            ?
            scalesAnswers.map((scaleAnswer, index) => {
              return (
                <CardManageScalesAnswers 
                    key={`scale-answer-${index}`} 
                    index={index} 
                    scaleAnswer={scaleAnswer} 
                    currentScaleAnswer={currentScaleAnswer}
                    handleClickCurrentScaleAnswer={handleClickCurrentScaleAnswer}
                    handleCloseCurrentScaleAnswer={handleCloseCurrentScaleAnswer} 
                    getScalesAnswers={() => getScalesAnswers(false, null)}
                    typeAction={typeAction}
                    alertActions={alertActions}
                    lastScaleCreated={lastScaleCreated}
                />
              )
            })
            :
            <NoData />
          )
          :
          <GlobalSkeleton
            totalRowsStats={0}
            totalTables={1}
            heightTable={600}          
            margin={'-10px 0px 0px 0px'}
          />
        }
      </main>

      {/* Start Popover Actions */}
      <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => handleCloseCurrentScaleAnswer(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              backgroundColor: "#1E6B7F",
              marginTop: "10px",
            },
          }}
        >
          <Box className={"menuOptionsActions"}>
            <button
              className={"menuOptionsActionButton"}
              onClick={handleOpenModalEditScaleAnswer}
            >
              {t("performance_assessment.actions.edit_scale")}
            </button>
            <button
              className={"menuOptionsActionButton"}
              onClick={handleOpenConfirmDelete}
            >
              {t("performance_assessment.actions.delete_scale")}
            </button>
            <button
              className={"menuOptionsActionButton"}
              onClick={handleOpenConfirmClone}
            >
              {t("performance_assessment.actions.clone_scale")}
            </button>
            {
              currentScaleAnswer?.is_objective === true &&
              <button
                className={"menuOptionsActionButton"}
                onClick={() => {
                  setTypeAction("edit_options")
                  setAnchorEl(null);
                }}
              >
                {t("performance_assessment.actions.edit_options")}
              </button>
            }
          </Box>
      </Popover>
      {/* End Popover Actions */}


      {/* Start Confirm Dialog Delete Scale */}
      <PopoverConfirm
        idConfirm={idConfirmDelete}
        openConfirm={openConfirmDelete}
        anchorEl={anchorElConfirmDelete}
        handleClose={handleCloseConfirmDelete}
        handleConfirm={handleConfirmDeleteScaleAnswer}
        title={t('performance_assessment.messages.delete_scale')}
      />
      {/* End Confirm Dialog Delete Scale */}


      {/* Start Confirm Dialog Clone Scale */}
      <PopoverConfirm
        idConfirm={idConfirmClone}
        openConfirm={openConfirmClone}
        anchorEl={anchorElConfirmClone}
        handleClose={handleCloseConfirmClone}
        handleConfirm={handleConfirmCloneScaleAnswer}
        title={t('performance_assessment.messages.clone_scale')}
      />
      {/* End Confirm Dialog Clone Scale */}
      
      <CreateScaleAnswerModal
        openModal={openModalCreateScaleAnswer}
        closeModal={() => {
          if(typeModal === "edit_scale"){
            handleCloseModalEditScaleAnswer()
            return
          }
          handleCloseModalCreateScaleAnswer()
        }}
        getScalesAnswers={getScalesAnswers}
        scaleAnswerToEdit={scaleAnswerToEdit}
        alertActions={alertActions}
      />

      <ImportScalesModal
        openModal={openModalScalesImport}
        closeModal={handleCloseModalModalScalesImport}
        getScalesAnswers={getScalesAnswers}
        alertActions={alertActions}
      />

    </section>
  )
}



function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceAssessmentScalesAnswersPage)