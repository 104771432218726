import { Box, Grid, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./ExperiencesManageList.module.scss";
import { v4 as uuidv4 } from "uuid";
import { Delete, DragIndicator } from "@material-ui/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import BefectiveCheckbox from "../../_shared/BefectiveCheckbox";

export default function ExperiencesManageList({
  experiences = [],
  setValue = () => {},
  lang = "en",
}) {
  const { t, i18n } = useTranslation("common");

  const addNewItem = (newItem) => {
    if (
      experiences.find((item) => item.office === newItem.office) &&
      !newItem.is_new
    )
      return;
    setValue("experiences", [...experiences, { ...newItem, id: uuidv4(), index: experiences.length}]);
  };

  const removeItem = (itemId) => {
    setValue(
      "experiences",
      experiences.filter((item) => item.id !== itemId)
    );
  };

  const editItem = (item) => {
    setValue(
      "experiences",
      experiences.map((i) => (i.id === item.id ? item : i))
    );
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { destination, source } = result;

    if (destination.index === source.index) return;

    const newExperiences = Array.from(experiences);
    const [removed] = newExperiences.splice(source.index, 1);
    newExperiences.splice(destination.index, 0, removed);

    setValue("experiences", newExperiences.map((exp, index) => ({ ...exp, index })));
  };

  const handleExperiencesChange = (experienceId, event) => {
    const { name, value, checked, type } = event.target;
    const experiencesNew = experiences.map((experience) => {
      if (experience.id === experienceId) {
        if(type === "checkbox"){
          return { ...experience, [name] : checked, date_end: checked ? null : experience.date_end };
        }
        return { ...experience, [name] : value };
      }
      return experience;
    });
    setValue("experiences", experiencesNew);
  };

  return (
    <Box
      display="flex"
      bgcolor="#fff"
      flexDirection="column"
      paddingX="16px"
      paddingBottom="16px"
      borderRadius="4px"
      marginBottom="2px"
      flexGrow={1}
    >
      <Grid container spacing={2} marginBottom={"4px"} marginTop={1}>
        <Grid item xs={12}>
          <Typography
            component="h3"
            sx={{ fontSize: "18px", color: "#1E6B7F", fontWeight: 500 }}
          >
            {t("settings.users.professional_form.experience")}
          </Typography>
        </Grid>
      </Grid>

      {experiences && experiences.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {experiences?.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={String(item.id)}
                      index={index}
                    >
                      {(provided2) => (
                        <Box 
                          component={"div"}                          
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}
                          key={`experience-${index}`}
                          display={"flex"}
                          borderBottom={"1px solid #DEE3E9"}
                          paddingBottom={"16px"}
                          marginBottom={"16px"}
                          >
                            <Box marginTop={"32px"} marginRight={"8px"}>
                              <DragIndicator
                                style={{ cursor: "grab", color: "#1E6B7F" }}
                              />
                            </Box>
                            <Grid
                              component={"div"}
                              container
                              spacing={1}
                              marginBottom={"4px"}
                              marginTop={0}
                            >
                              <Grid item xs={4}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap="2px"
                                  width="100%"
                                >
                                  <Typography
                                    component="label"
                                    sx={{
                                      paddingLeft: "4px",
                                      fontSize: "12px",
                                      color: "#1E6B7F",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {t(
                                      "settings.users.professional_form.position"
                                    )}
                                  </Typography>
                                  <TextField
                                    name="office"
                                    value={item?.office}
                                    placeholder={t("settings.users.professional_form.position_placeholder")}
                                    error={false}
                                    helperText={""}
                                    onChange={(e) =>
                                      handleExperiencesChange(item.id, e)
                                    }
                                    sx={{
                                      width: "100%",
                                      height: "45px",
                                      fieldset: {
                                        border: "1px solid #DEE3E9CC",
                                        height: "45px",
                                      },
                                      div: { color: "#4F7091", height: "45px" },
                                      label: { top: "-4px" },
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={8}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap="2px"
                                  width="100%"
                                >
                                  <Typography
                                    component="label"
                                    sx={{
                                      paddingLeft: "4px",
                                      fontSize: "12px",
                                      color: "#1E6B7F",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {t(
                                      "settings.users.professional_form.position_description"
                                    )}
                                  </Typography>
                                  <TextField
                                    name="description"
                                    value={item?.description}
                                    placeholder={t("settings.users.professional_form.position_description_placeholder")}
                                    error={false}
                                    helperText={""}
                                    onChange={(e) =>
                                      handleExperiencesChange(item.id, e)
                                    }
                                    sx={{
                                      width: "100%",
                                      height: "45px",
                                      fieldset: {
                                        border: "1px solid #DEE3E9CC",
                                        height: "45px",
                                      },
                                      div: { color: "#4F7091", height: "45px" },
                                      label: { top: "-4px" },
                                    }}
                                  />
                                </Box>
                              </Grid>

                              <Grid item xs={8}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap="2px"
                                  width="100%"
                                >
                                  <Typography
                                    component="label"
                                    sx={{
                                      paddingLeft: "4px",
                                      fontSize: "12px",
                                      color: "#1E6B7F",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {t("settings.users.professional_form.company")}
                                  </Typography>
                                  <TextField
                                    name="company"
                                    value={item?.company}
                                    placeholder={t("settings.users.professional_form.company_placeholder")}
                                    error={false}
                                    helperText={""}
                                    onChange={(e) =>
                                      handleExperiencesChange(item.id, e)
                                    }
                                    sx={{
                                      width: "100%",
                                      height: "45px",
                                      fieldset: {
                                        border: "1px solid #DEE3E9CC",
                                        height: "45px",
                                      },
                                      div: { color: "#4F7091", height: "45px" },
                                      label: { top: "-4px" },
                                    }}
                                  />
                                </Box>
                              </Grid>

                              <Grid item xs={2}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap="2px"
                                  width="100%"
                                >
                                  <Typography
                                    component="label"
                                    sx={{
                                      paddingLeft: "4px",
                                      fontSize: "12px",
                                      color: "#1E6B7F",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {t("settings.users.professional_form.of")}
                                  </Typography>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    locale={lang}
                                  >
                                    <DatePicker
                                      value={item?.date_start}
                                      inputFormat={
                                        lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY"
                                      }
                                      onChange={(value) =>
                                        handleExperiencesChange(item.id, {
                                          target: { name: "date_start", value },
                                        })
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          variant="outlined"
                                          fullWidth
                                          {...params}
                                          sx={{
                                            width: "100%",
                                            height: "45px",
                                            fieldset: {
                                              border: "1px solid #DEE3E9CC",
                                              height: "45px",
                                            },
                                            input: {
                                              color: "#4F7091",
                                              paddingY: "11.5px",
                                            },
                                            label: { top: "-4px" },
                                          }}
                                          name="date_start"
                                          error={false}
                                          helperText={""}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={2}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap="2px"
                                  width="100%"
                                >
                                  <Typography
                                    component="label"
                                    sx={{
                                      paddingLeft: "4px",
                                      fontSize: "12px",
                                      color: "#1E6B7F",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {t("settings.users.professional_form.to")}
                                  </Typography>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    locale={lang}
                                  >
                                    <DatePicker
                                      disabled={item?.actual_job}
                                      value={item?.date_end}
                                      inputFormat={
                                        lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY"
                                      }
                                      onChange={(value) =>
                                        handleExperiencesChange(item.id, {
                                          target: { name: "date_end", value },
                                        })
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          variant="outlined"
                                          fullWidth
                                          {...params}
                                          sx={{
                                            width: "100%",
                                            height: "45px",
                                            fieldset: {
                                              border: "1px solid #DEE3E9CC",
                                              height: "45px",
                                            },
                                            input: {
                                              color: "#4F7091",
                                              paddingY: "11.5px",
                                            },
                                            label: { top: "-4px" },
                                          }}
                                          name="date_end"
                                          error={false}
                                          helperText={""}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box
                                  display="flex"
                                  width="100%"
                                >
                                  <label htmlFor={`actual_job_${item.id}`} className={styles.boxItemCheck}>
                                    <BefectiveCheckbox 
                                        id={`actual_job_${item.id}`}
                                        name='actual_job'
                                        checked={item?.actual_job} 
                                        onChange={(e) =>handleExperiencesChange(item.id, e)}
                                        style={{padding: 0, paddingRight: "6px"}}

                                    />
                                    <p style={{color: item?.actual_job ? "#22BEAD" : "#1E6B7F", margin: 0}}>{t("settings.users.professional_form.actual_job")}</p>
                                </label>
                                  
                                </Box>
                              </Grid>
                            </Grid>
                            <Box marginTop={"30px"} marginLeft={"8px"}>
                              <button
                                  className={styles.buttonRemove}
                                  onClick={() => removeItem(item.id)}
                              >
                                  <Delete style={{color:"#FC6662"}} fontSize='small' />
                              </button>
                            </Box>  
                        </Box>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginTop={"14px"}
          marginBottom={"8px"}
        >
          <Typography
            component="label"
            sx={{
              paddingLeft: "4px",
              fontSize: "12px",
              color: "#1E6B7F",
              fontWeight: 400,
            }}
          >
            {t("settings.users.professional_form.no_experiences")}
          </Typography>
        </Box>
      )}

      <Grid container spacing={2} marginBottom={"4px"} marginTop={0}>
        <Grid item xs={12}>
          <button
            className={styles.buttonAddItem}
            onClick={() =>
              addNewItem({
                office: "",
                date_start: null,
                date_end: null,
                company: "",
                description: "",
                actual_job: false,
                is_new: true
              })
            }
          >
            {t("settings.users.professional_form.new_item")}
          </button>
        </Grid>
      </Grid>
    </Box>
  );
}
