import React, { useEffect, useState } from 'react';
import { Card, CardContent, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './ReportAssessmentsStatsCard.module.scss';
import { ClockCounterClockwise, ShieldWarning, Smiley,  WarningCircle, XCircle } from 'phosphor-react';
import { Typography } from '@material-ui/core';

export function ReportAssessmentsStatsCard({ alertActions, userDetails, stats }) {
  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #4F7091' }}>
          <WarningCircle size={18} color={'#4F7091'} weight="bold" />
          <Typography className={style.textStats} style={{color: "#4F7091"}}>
            {stats?.total_assessments || 0}
          </Typography>
          <Typography className={style.textInfo} component={'span'} style={{color: "#4F7091"}}>
            {t("performance_assessment.page_reports.card_stats.total_assessments")}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
          <XCircle size={18} color={'#22BEAD'} weight="bold" />
          <Typography className={style.textStats} style={{color: "#22BEAD"}}>
            {stats?.total_assessments_done || 0}
          </Typography>
          <Typography className={style.textInfo} component={'span'} style={{color: "#22BEAD"}}>
            {t("performance_assessment.page_reports.card_stats.total_assessments_done")}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #FC6662' }}>
          <ShieldWarning size={18} color="#FC6662" weight="bold" />
          <Typography className={style.textStats} style={{color: "#FC6662"}}>
            {stats?.total_assessments_undone || 0}
          </Typography>
          <Typography className={style.textInfo} component="span" style={{color: "#FC6662"}}>
            {t("performance_assessment.page_reports.card_stats.total_assessments_not_done")}
          </Typography>
        </CardContent>
      </Card>

    </Stack>
  );
}
