import React from 'react'
import { Backdrop, Modal, Slide, makeStyles } from '@material-ui/core';
import styles from './ApresentationDetailsModal.module.scss';
import { useTranslation } from 'react-i18next';
import IconLogo from '../../_icons/IconLogo';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export default function ApresentationDetailsModal({
  openModal,
  closeModal,
  title,
  subtitle,
  description,
  handleClickStart
}) {

  const classes = useStyles();  
  const { t, i18n } = useTranslation("common");

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${styles.modal}`}
      open={openModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <div className={styles.containerModal}>
          <header>
            <IconLogo />
            <h4>{title}</h4>
            <h6>{subtitle}</h6>
          </header>
          <section>
            <div className='ql-editor' dangerouslySetInnerHTML={{ __html: description}} />
          </section>
          <footer>
            <button onClick={handleClickStart}>{t("performance_assessment.actions.start_assessment")}</button>
          </footer>
        </div>
      </Slide>
    </Modal>
  )
}
