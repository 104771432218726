import React, { useState } from 'react'
import styles from './NewAssessmentForm.module.scss'
import { useTranslation } from 'react-i18next';
import { CaretLeft, CaretRight, Eye } from 'phosphor-react';
import IconThreePoints from '../../_icons/IconThreePoints';
import { Box, Popover } from '@material-ui/core';
import PopoverConfirm from '../../teams_settings/PopoverConfirmDelete';

export default function FormHeader({
  currentTab,
  isEdit = false,
  isPreview=false,
  handleOpenModalPreview,
  handleCancel,
  handleNext = null,
  handleSubmit=null,
  isSubmitting = false,
  title,
  handleClearForm = () => {},
  handleCancelEdit = () => {}
}) {

  
  const { t, i18n } = useTranslation("common");
  const [disableActions, setDisableActions] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "actions-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElConfirmCancelEdit, setAnchorElConfirmCancelEdit] = useState(null);
  const openConfirmCancelEdit = Boolean(anchorElConfirmCancelEdit);
  const idConfirmCancelEdit = openConfirmCancelEdit ? 'popover-confirm-cancel-edit' : undefined;

  const handleClickConfirmCancelEdit = (event) => {
    setAnchorElConfirmCancelEdit(event.currentTarget);
  };

  const handleCloseConfirmCancelEdit = () => {
    setAnchorElConfirmCancelEdit(null)
    handleClose()
  };


  return (
    <header className={styles.headerForm}>
      <h6 className={styles.titleHeader}>{title}</h6>
      <div className={styles.boxButtons}>
        {
          isPreview && (
            <button className={`${styles.buttonHeader} ${styles.buttonPreview}`} onClick={handleOpenModalPreview}>
              <Eye size={18} color='#98ACC1' weight='bold'/>
            </button>
          )
        }
        {
          !["apresentation"].includes(currentTab) && (
            <button className={`${styles.buttonHeader} ${styles.buttonCancel}`} onClick={handleCancel}>
              <CaretLeft size={16} />
              {t("performance_assessment.actions.back")}
            </button>            
          )
        }
        {
          handleNext && (
            <button className={`${styles.buttonHeader} ${styles.buttonNext}`} onClick={handleNext}>
              {t("performance_assessment.actions.next")}
              <CaretRight size={16} />
            </button>
          )
        }
        {
          handleSubmit && (
            <button style={{justifyContent: 'center'}}   className={`${styles.buttonHeader} ${isEdit ? styles.buttonNext : styles.buttonSend}`} disabled={isSubmitting} onClick={handleSubmit}>
              {
                isEdit 
                ? 
                  (
                    isSubmitting
                    ?
                      t("performance_assessment.actions.saving") 
                    :
                      t("performance_assessment.actions.save") 
                  )
                  
                : 
                  t("performance_assessment.actions.send_assessment")}
            </button>
          )
        }

        <button
            className={"buttonThreePoints"}
            onClick={handleClick}
            >
            <IconThreePoints />
        </button>
      </div>
      
      {/* End Popover Options Three Points */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              backgroundColor: "#1E6B7F",
              marginTop: "10px",
            },
          }}
        >
          <Box className={"menuOptionsActions"}>
            <button
              className={
                disableActions
                  ? "menuOptionsActionButtonDisabled"
                  : "menuOptionsActionButton"
              }
              disabled={disableActions}
              onClick={() => {
                handleClearForm(currentTab);
                handleClose();
              }}
            >
              {t("performance_assessment.actions.clear_form")}
            </button>
            <button
              className={
                disableActions
                  ? "menuOptionsActionButtonDisabled"
                  : "menuOptionsActionButton"
              }
              disabled={disableActions}
              onClick={handleClickConfirmCancelEdit}
            >
              {t("performance_assessment.actions.cancel_edit")}
            </button>
          </Box>
        </Popover>
        {/* End Popover Options Three Points */}
        {/* Start Confirm Dialog Cancel Edit */}
          <PopoverConfirm
            idConfirm={idConfirmCancelEdit}
            openConfirm={openConfirmCancelEdit}
            anchorEl={anchorElConfirmCancelEdit}
            handleClose={handleCloseConfirmCancelEdit}
            handleConfirm={handleCancelEdit}
            title={t('performance_assessment.messages.cancel_edit_info')}
          />
        {/* End Confirm Dialog Cancel Edit */}
    </header>
  )
}
