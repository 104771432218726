import React from 'react'
import styles from './styles.module.scss'
import HeaderForm from "../personal/HeaderForm";
import { useTranslation } from 'react-i18next'
import { befectiveFormatDate } from '../../../_helpers';


export default function FormProfessionalPreviewPT({
  formData,
  toogleShowForm,
  isEditing,
  isExistUserId,
  userId
}) {

  const {t, i18n} = useTranslation('common')

  return (
    <div className={styles.containerPreview}>
      <div className={styles.card}>
        <HeaderForm formData={formData} toogleShowForm={toogleShowForm} isEditing={isEditing} titleCreate={t('settings.users.professional_form.title_create')} isExistUserId={isExistUserId} userId={userId}/>
        <div className={styles.cardContent}>
            <div className={styles.col50}>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.professional_form.position')}</span>
                <h6 className={styles.value}>{formData?.office || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.professional_form.department')}</span>
                <h6 className={styles.value}>{formData?.department_name || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.professional_form.model_work')}</span>
                <h6 className={styles.value}>{formData?.work_model && formData?.work_model?.label_pt ? formData?.work_model[`label_${i18n.language}`] : '-----'}</h6>
              </div>

               <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.professional_form.admission')}</span>
                <h6 className={styles.value}>{formData?.admission ? befectiveFormatDate(formData?.admission, i18n.language) : '-----'}</h6>
              </div>
              
            </div>

            <div className={styles.col50}>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.professional_form.classification_job')}</span>
                <h6 className={styles.value}>{formData?.work_classification && formData?.work_classification?.label_pt ? formData?.work_classification[`label_${i18n.language}`] : '-----'}</h6>
              </div>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.professional_form.contract')}</span>
                <h6 className={styles.value}>{formData?.work_contract?.label || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.professional_form.pis_and_pasep')}</span>
                <h6 className={styles.value}>{formData?.work_code || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.professional_form.code_rigistration')}</span>
                <h6 className={styles.value}>{formData?.registration_code || '-----'}</h6>
              </div>
            </div>
        </div>
      </div>
      
      <div className={styles.card}>        
        <header className={styles.cardHeader}>
          <h6>{t('settings.users.professional_form.colab_outside_country')}</h6>
        </header>
        <div className={styles.cardContent}>
          <div className={styles.col50}>
            <div className={styles.field}>
              <span className={styles.label}>{t('settings.users.professional_form.documents_visa')}</span>
              <h6 className={styles.value}>{ formData?.visa_documents?.label || '-----'}</h6>
            </div>

            <div className={styles.field}>
              <span className={styles.label}>{t('settings.users.professional_form.status_residence')}</span>
              <h6 className={styles.value}>{formData?.visa_residency_status?.label || '-----'}</h6>
            </div>
          </div>
          <div className={styles.col50}>
            <div className={styles.field}>
              <span className={styles.label}>{t('settings.users.professional_form.visa_expirated_at')}</span>
              <h6 className={styles.value}>{formData?.visa_expires_in ? befectiveFormatDate(formData?.visa_expires_in, i18n.language) : '-----'}</h6>
            </div>

            <div className={styles.field}>
              <span className={styles.label}>{t('settings.users.professional_form.authorize_work')}</span>
              <h6 className={styles.value}>{formData?.work_authorization || '-----'}</h6>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.card}>
        <header className={styles.cardHeader}>
          <h6>{`${t('settings.users.professional_form.experience')} (${formData?.experiences?.length})`}</h6>
        </header>
        <div className={styles.cardContent} style={{padding:'20px', display:'flex', flexDirection:'column'}}>
          {
            formData?.experiences?.map((experience, index) => (
            <div className={styles.rowExperience} key={`experience-${index}`}>
              <div className={styles.boxTimeline}>
                <div className={styles.circle}></div>
                {index !== formData?.experiences?.length - 1 && <div className={styles.line}></div>}
              </div>
              <div className={styles.boxInfoExperience}>
                <h6>{experience?.office || "-----"}</h6>
                <span>{experience?.date_start ? befectiveFormatDate(experience?.date_start, i18n.language) : '-----'} {t('settings.users.professional_form.to_2')} { !experience?.actual_job ? (experience?.date_end ? befectiveFormatDate(experience?.date_end, i18n.language) : '-----') : t('settings.users.professional_form.the_moment')} - {experience?.company || '-----'}</span>
              </div>
            </div>
            ))
          }          
        </div>
      </div>
      {/* <div className={styles.card}>
        <div className={styles.cardContent}>
            <div className={styles.col50}>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.professional_form.next_vacations')}</span>
                <h6 className={styles.value}>{formData?.next_vacation || '-----'}</h6>
              </div>
            </div>
            <div className={styles.col50}>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.professional_form.last_period_vacations')}</span>
                <h6 className={styles.value}>{formData?.last_period_vacations || '-----'}</h6>
              </div>
            </div>
        </div>
      </div> */}
    </div>
  )
}
