import React from "react";
import { useTranslation } from "react-i18next";
import styles from './Global.module.scss'
import { befectiveFormatDate, truncateNumber } from "../../_helpers";
import StatusAssessment from "../performance_assessments/PerformanceAssessmentsCreated/StatusAssessment";

const ResultLastAssessmentTable = ({ data }) => {

    const { t, i18n } = useTranslation('common');

    return(
        <div className={styles.cardSecondary}>
            <header className={styles.headerCard}>
                <h6>{data?.date_ref ? t('colab_page.performance_assessment.result_last_assessment.title')+" "+befectiveFormatDate(data?.date_ref, i18n.language) : t('colab_page.performance_assessment.result_last_assessment.title2')}</h6>
            </header>
            <div className={styles.containerLastTable}>
                <table className={styles.tableResult}>
                    <thead>
                        <tr>
                            <th>{t('colab_page.performance_assessment.result_last_assessment.col_skills')}</th>
                            <th>{t('colab_page.performance_assessment.result_last_assessment.col_self_assessment')}</th>
                            <th>{t('colab_page.performance_assessment.result_last_assessment.col_manager')}</th>
                            <th>{t('colab_page.performance_assessment.result_last_assessment.col_pairs')}</th>
                            <th>{t('colab_page.performance_assessment.result_last_assessment.col_score_avg')}</th>
                            <th>{t('colab_page.performance_assessment.result_last_assessment.col_company_avg')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.skills && data?.skills.length > 0
                            ?
                            data?.skills.map((skill, index) => (
                                <tr key={`skill-${index}`}>
                                    <td>{`${index+1}. ${skill?.name || '-----'}`}</td>
                                    <td>
                                        <StatusAssessment
                                            title={truncateNumber(skill?.self_assessment?.avg)}
                                            textColor={skill?.self_assessment && skill?.self_assessment?.avg > 0 ? "#ffffff" : "#98ACC1"}
                                            bgColor={skill?.self_assessment?.classification?.bg_color}
                                            height='48px'            
                                            borderTop='1px solid #FFFFFF'
                                            borderBottom='1px solid #FFFFFF'
                                            fontWeight='400'
                                        />
                                    </td>
                                    <td>
                                        <StatusAssessment
                                            title={truncateNumber(skill?.manager_assessment?.avg)}
                                            textColor={skill?.manager_assessment && skill?.manager_assessment?.avg > 0 ? "#ffffff" : "#98ACC1"}
                                            bgColor={skill?.manager_assessment?.classification?.bg_color}
                                            height='48px'            
                                            borderTop='1px solid #FFFFFF'
                                            borderBottom='1px solid #FFFFFF'
                                            fontWeight='400'
                                        />
                                    </td>
                                    <td>
                                        <StatusAssessment
                                            title={truncateNumber(skill?.pairs_assessment?.avg)}
                                            textColor={skill?.pairs_assessment && skill?.pairs_assessment?.avg > 0 ? "#ffffff" : "#98ACC1"}
                                            bgColor={skill?.pairs_assessment?.classification?.bg_color}
                                            height='48px'            
                                            borderTop='1px solid #FFFFFF'
                                            borderBottom='1px solid #FFFFFF'
                                            fontWeight='400'
                                        />
                                    </td>
                                    <td>
                                        <StatusAssessment
                                            title={truncateNumber(skill?.score?.avg)}
                                            textColor={skill?.score && skill?.score?.avg > 0 ? "#ffffff" : "#98ACC1"}
                                            bgColor={skill?.score?.classification?.bg_color}
                                            height='48px'            
                                            borderTop='1px solid #FFFFFF'
                                            borderBottom='1px solid #FFFFFF'
                                            fontWeight='400'
                                        />
                                    </td>
                                    <td>
                                        <StatusAssessment
                                            title={truncateNumber(skill?.company_avg.avg)}
                                            textColor={skill?.company_avg && skill?.company_avg?.avg > 0 ? "#ffffff" : "#98ACC1"}
                                            bgColor={skill?.company_avg?.classification?.bg_color}
                                            height='48px'            
                                            borderTop='1px solid #FFFFFF'
                                            borderBottom='1px solid #FFFFFF'
                                            fontWeight='400'
                                        />

                                    </td>
                                </tr>
                            ))
                            :
                            <tr>
                                <td colSpan={6} className={styles.cellNoData}>{t('colab_page.performance_assessment.result_last_assessment.none')}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ResultLastAssessmentTable;