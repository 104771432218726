// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dM0nP--owmNNcNdci8oCUA\\=\\= {\n  display: flex;\n  flex: 1;\n  min-width: 165px;\n}\n\n.L3NJLnl8-pG9cgE-j4WJXQ\\=\\= {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n}\n\n._174sdZIiUxJd88PxCv-i5w\\=\\= {\n  font-size: 18px !important;\n  font-weight: 400;\n  margin-top: 6px !important;\n  margin-bottom: 4px !important;\n}\n\n.tJQklduoyy7Hd0hyCJKkzg\\=\\= {\n  font-size: 12px !important;\n}", "",{"version":3,"sources":["webpack://./src/_components/performance_assessments/PerformanceAssessmentReports/ReportAssessmentsStatsCard.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,OAAA;EACA,gBAAA;AACJ;;AAEE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AACJ;;AAEE;EACE,0BAAA;EACA,gBAAA;EACA,0BAAA;EACA,6BAAA;AACJ;;AAEE;EACE,0BAAA;AACJ","sourcesContent":[".cardContainer {\n    display: flex;\n    flex: 1;\n    min-width: 165px;\n  }\n  \n  .cardHeader {\n    display: flex;\n    flex-direction: column;\n    padding: 1rem;\n  }\n  \n  .textStats {\n    font-size: 18px !important;\n    font-weight: 400;\n    margin-top: 6px !important;\n    margin-bottom: 4px !important;\n  }\n  \n  .textInfo {\n    font-size: 12px !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": "dM0nP--owmNNcNdci8oCUA==",
	"cardHeader": "L3NJLnl8-pG9cgE-j4WJXQ==",
	"textStats": "_174sdZIiUxJd88PxCv-i5w==",
	"textInfo": "tJQklduoyy7Hd0hyCJKkzg=="
};
export default ___CSS_LOADER_EXPORT___;
