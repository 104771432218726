import React from "react";
import styles from "./Global.module.scss";
import { useTranslation } from "react-i18next";
import { PlusCircle, MinusCircle } from "phosphor-react";
import { truncateNumber } from "../../_helpers";

const StrongAndWeakPointCard = ({ 
    type="strong",
    data
 }) => {
    const { t, i18n } = useTranslation('common');   

    return (
        <div className={styles.card}>
            {
                type === "strong"
                ?
                <div className={styles.strongPointBox}>
                    <PlusCircle size={26} color="#22BEAD" weight="bold"/>
                    <h6>{data?.name || "-----"}</h6>
                    <span>{t('colab_page.performance_assessment.strong_point')}: {truncateNumber(data?.general_skill_avg)}</span>
                </div>
                :
                <div className={styles.weakPointBox}>
                    <MinusCircle size={26} color="#FF7D25" weight="bold"/>
                    <h6>{data?.name || "-----"}</h6>                    
                    <span>{t('colab_page.performance_assessment.weak_point')}: {truncateNumber(data?.general_skill_avg)}</span>
                </div>
            }
        </div>
    )
}

export default StrongAndWeakPointCard;