import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, Select, OutlinedInput} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      width: "250px",
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      backgroundColor: "#1E6B7F",
      marginTop: '6px'
    },
  },

};

const AssessmentsFilter = ({
    getData = () => {},
    assessments = []
}) => {
    const { t, i18n } = useTranslation('common');
    const [assessmentSelected, setAssessmentSelected] = useState("");

    const handleFilter = (value) => {
        setAssessmentSelected(value);
        getData({assessment_id: value});
    }

    useEffect(() => {
        if (assessments.length > 0) {
            setAssessmentSelected(assessments[0].id);
        }
    }, [assessments]);

    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                    displayEmpty
                    className={'customSelectMulti'}
                    style={{minWidth:'250px', backgroundColor:'#106276', height:'45px', color: '#CEDEE3', fieldset: { border: 'transparent', height: '45px' }, div: { color: '#4F7091' }, label: { top: '-4px' } }}
                    value={assessmentSelected}
                    input={
                        <OutlinedInput
                            style={{ width: '100%', height: '45px', fieldset: { border: 'transparent', height: '45px' }, div: { color: '#4F7091' }, label: { top: '-4px' } }}
                        />
                        }
                    onChange={(event) => handleFilter(event.target.value)}
                    MenuProps={MenuProps}
                    IconComponent={(props) => <ArrowDropDown {...props} style={{ fill: '#4D8A99' }} />}
                >
                    {assessments.map((assessment) => (
                        <MenuItem style={{color: "#fefefe" }} key={assessment.id} value={assessment.id}>{assessment.title}</MenuItem>
                    ))}
                </Select>
            </FormControl>               
        </div>
    )
}

export default AssessmentsFilter;