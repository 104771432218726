import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import BefectiveTooltip from "./BefectiveTooltip";
import { Info } from "phosphor-react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "130px",
    minHeight: "42px",
    backgroundColor: "#FDBE2B",
    borderRadius: "2px",
    cursor: "pointer",
    fontWeight: "600",
    border: "none",
    color: "#106276",
    textTransform: "none",
    boxShadow: "none",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: "#FDBE2B",
      boxShadow: "0px 0px 4px 0px #2B2B2B55",
    },
    "& > span": {
      fontSize: "13px",
      display: "flex",
      flexWrap: "nowrap",
    },
  },
}));

export default function ButtonCreate({
  title = "+ create",
  handleClick = () => {},
  showButton = true,
  hasAssessmentFormInStorage = false,
  minWidth = 160
}) {
  const classes = useStyles();
  const { t } = useTranslation('common');
  return (    
      showButton &&
      <div>
        {
          hasAssessmentFormInStorage
            ? 
            <BefectiveTooltip title={<Typography component={'div'} variant='caption' marginY={'4px'} style={{fontSize:'12px', lineHeight:'16px'}}>{t('performance_assessment.page_assessment_created.assessment_not_completed')}</Typography>} placement="bottom-center" arrow interactive>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={handleClick}
                style={{ minWidth: minWidth }}
              >
                {title} <Info size={18} weight="bold" color="#106276" style={{marginLeft:'6px'}} />
              </Button>
						</BefectiveTooltip>	
            : <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={handleClick}
              style={{ minWidth: minWidth }}
            >
              {title}
            </Button>
        }
      </div>
  );
}
