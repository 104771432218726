import React, { useEffect, useState } from 'react';
import styles from './CardManageSkill.module.scss';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { Typography } from '@material-ui/core';
import IconInfo from '../../_icons/IconInfo';
import IconThreePoints from '../../_icons/IconThreePoints';
import { useTranslation } from 'react-i18next';
import QuestionEdit from './QuestionEdit';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { v4 as uuidv4 } from 'uuid';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

const CardManageSkill = ({
    skill,
    index,
    handleClickCurrentSkill,
    handleCloseCurrentSkill,
    currentSkill,
    typeAction,
    getSkills,
    scales=[],
    alertActions,
    lastSkillCreated
}) => {

    const { t, i18n } = useTranslation('common');
    const [addQuestion, setAddQuestion] = useState(false);
    const [questions, setQuestions] = useState(skill.questions || []);
    const [saving, setSaving] = useState(false);
    
    const onDragEnd = async (result) => {

        if (!result.destination) return;
        
        const { destination, source, draggableId: questionID } = result;
        
        if(destination.index === source.index) return;

        const newQuestions = Array.from(questions);
        const [removed] = newQuestions.splice(source.index, 1);
        newQuestions.splice(destination.index, 0, removed);

        setQuestions(newQuestions);     

    };


    const addNewQuestion = (newQuestion) => {
        if(questions.find(q => q.questions_content === newQuestion.questions_content)) return;
        setQuestions([...questions, {...newQuestion, questions_id: uuidv4(), is_new: true}]);
    }

    const removeQuestion = (questionId) => {
        setQuestions(questions.filter(q => q.questions_id !== questionId));
    }

    const editQuestion = (question) => {
        setQuestions(questions.map(q => q.questions_id === question.questions_id ? question : q));
    }

    const validateQuestions = (questions) => {

        for (const question of questions) {
            if (questions.filter(q => q.questions_content === question.questions_content).length > 1) {
                return {
                    error: t('performance_assessment.messages.error_repeated_question')
                };
            }
            if (question.questions_content === "" || question.scales_id === "") {
                return {
                    error: t('performance_assessment.messages.error_empty_question')
                };
            }   
        }
        return {
            error: null
        }
    }

    const handleSaveQuestions = async () => {
        try {
        
            const validateQuestionsResult = validateQuestions(questions);

            if(validateQuestionsResult.error !== null) return alertActions.error(validateQuestionsResult.error);

            const questionsPrepared = questions.map(q => {
                if(q.is_new || (typeof q.questions_id === 'string')){
                    return {
                        ...q,
                        questions_id: undefined
                    }
                }
                return q                
            })

            setSaving(true);
            const res = await api.put(`/performance-assessment/skills/${skill.id}/questions`, {questions: questionsPrepared}, {headers: authHeader()});

            if (res.status !== 200) {
                throw new Error('Erro to save questions');
            }

            getSkills();            
            setAddQuestion(false);
            handleCloseCurrentSkill();
            setSaving(false);

            alertActions.success(t('performance_assessment.messages.success_save_skill'));
        } catch (error) {
            console.log(error);
            setSaving(false);  
            alertActions.error(t('performance_assessment.messages.error_save_skill'));      
        }
    }

    useEffect(() => {
        setQuestions(skill.questions || []);
    }, [skill.questions]);

    useEffect(() => {
        if(lastSkillCreated){
            setAddQuestion(true);
        }else{
            setAddQuestion(false);
        }
    }, [lastSkillCreated])

    return (
        <div className={styles.cardContainer}>
            <header className={styles.cardHeader} style={{borderLeft: `4px solid ${skill.type_color}`}}>
                <div className={styles.boxCardTitle}>
                    <h6 style={{color: (currentSkill && currentSkill.id === skill.id && typeAction === "edit_questions") ? '#187DFF' : '#1E6B7F'}}>{`${index + 1}. ${skill.name} ${(currentSkill && currentSkill.id === skill.id && typeAction === "edit_questions") ? `(${t('performance_assessment.page_skills.edit')})` : ''}`}</h6>
                    {
                        skill.description &&
                        <BefectiveTooltip
                        title={<Typography component={'div'} variant='caption'>{skill.description}</Typography>}
                        placement="right-start"
                        arrow
                        interactive>
                            <span style={{ cursor: 'pointer' }}>&nbsp;&nbsp;<IconInfo /></span>
                        </BefectiveTooltip>
                    }
                </div>
                <div className={styles.boxButtons}>
                    {
                        currentSkill === null
                        ?
                        <button
                            className={"buttonThreePoints"}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClickCurrentSkill(e, skill);
                            }}
                            >
                            <IconThreePoints />
                        </button>
                        :
                        (
                            (skill.id === currentSkill.id && typeAction === "edit_questions")
                            ?
                            <>
                                <button 
                                    className={styles.buttonCancel} 
                                    type="button" 
                                    onClick={() => handleCloseCurrentSkill(true)}
                                    disabled={saving}
                                >
                                    {t("performance_assessment.actions.cancel")}
                                </button>
                                <button 
                                    className={styles.buttonSave} 
                                    type="button" 
                                    onClick={handleSaveQuestions}
                                    disabled={saving}
                                >
                                    {saving ? t("performance_assessment.actions.saving") : t("performance_assessment.actions.save")}
                                </button>
                            </>
                            :
                            <button
                                className={"buttonThreePoints"}
                                style={{cursor: 'not-allowed'}}
                                disabled
                                >
                                <IconThreePoints />
                            </button>
                        )
                    }
                </div>
            </header>
            
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th style={{width: '0px', paddingRight: '4px'}}/>
                        <th>{t("performance_assessment.page_skills.table.col_questions")}</th>
                        <th style={{width: '20%'}}>{t("performance_assessment.page_skills.table.col_type_reply")}</th>
                        <th style={{width: '0px'}}/>
                    </tr>
                </thead>

                {
                    (currentSkill && skill.id === currentSkill.id && typeAction === "edit_questions")
                    ?
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                            
                                {(questions && questions.length > 0) 
                                &&
                                questions.map((question, index) => {
                                    return (
                                        <Draggable
                                            key={question.questions_id}
                                            draggableId={String(question.questions_id)}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <QuestionEdit
                                                    key={`question-${index}`}
                                                    provided={provided}
                                                    skill={skill}
                                                    scales={scales}
                                                    question={question}
                                                    getSkills={getSkills}
                                                    setAddQuestion={setAddQuestion}
                                                    editQuestion={editQuestion}
                                                    removeQuestion={removeQuestion}
                                                    addNewQuestion={addNewQuestion}
                                                />
                                            )}
                                        </Draggable>
                                    )
                                    })}


                                {provided.placeholder}

                                {
                                    !addQuestion
                                    ?
                                    <tr>
                                        <td colSpan={4}>
                                            <button className={styles.buttonNewQuestion} type="button" onClick={() => setAddQuestion(true)}>
                                                {t("performance_assessment.page_skills.table.add_question")}
                                            </button>
                                        </td>
                                    </tr>
                                    :

                                    <QuestionEdit
                                        key={`question2-${index}`}
                                        provided={null}
                                        skill={skill}
                                        scales={scales}
                                        setAddQuestion={setAddQuestion}
                                        question={{
                                            questions_id: null,
                                            questions_content: "",
                                            scales_id: ""
                                        }}
                                        getSkills={getSkills}
                                        addNewQuestion={addNewQuestion}
                                    />
                                }
                            
                            
                            </tbody>
                          )}
                        </Droppable>
                    </DragDropContext>
                :
                <tbody className={styles.tbodyGray}>
                    {
                        (questions && questions.length > 0) 
                        ?
                        questions.map((question, index) => {
                            return (
                                <tr key={index}>
                                    <td style={{width: '0px', paddingRight: '4px'}}>{`${letters[index]}.`}</td>
                                    <td>{question.questions_content}</td>
                                    <td style={{width: '20%'}}>{question.scales_name}</td>
                                    <td style={{width: '0px'}}/>
                                </tr>
                            )
                        })
                        :
                        <tr>
                            <td colSpan={4}>{t("performance_assessment.page_skills.table.no_questions")}</td>
                        </tr>

                    }
                </tbody>

                }
                
            </table>

        </div>
    )
}

export default CardManageSkill;