import React from 'react'
import { useTranslation } from 'react-i18next';
import styles from './ParticipantsTable.module.scss'
import StatusAssessment from '../PerformanceAssessmentsCreated/StatusAssessment';
import { befectiveFormatDate, getStructureOrganizationalLabel, history } from '../../../_helpers';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#1E6B7F',
    maxWidth: '200px'
  },
  arrow: {
    color: '#1E6B7F'
  },
}))(Tooltip);

export default function ParticipantsTable({
  data=[],
  assessmentId
}) {
  
  const { t, i18n } = useTranslation("common");

  return (
    <table>
      <thead className={styles.tableHead}>
        <tr>
          <th className={styles.tableColumn}>{t('performance_assessment.page_assessment_details.participants.table.col_avaluated')}</th>
          <th className={styles.tableColumn}>{t('performance_assessment.page_assessment_details.participants.table.col_avaluators')}</th>
          <th className={styles.tableColumn}>{t('performance_assessment.page_assessment_details.participants.table.col_structure_organizational')}</th>
          <th className={styles.tableColumn}>{t('performance_assessment.page_assessment_details.participants.table.col_position')}</th>
          <th className={`${styles.tableColumn} ${styles.alignCenter}`}>{t('performance_assessment.page_assessment_details.participants.table.col_status')}</th>
          <th className={`${styles.tableColumn} ${styles.alignCenter}`}>{t('performance_assessment.page_assessment_details.participants.table.col_replied_at')}</th>
          <th className={`${styles.tableColumn} ${styles.alignCenter} ${styles.widthMinimal}`}>{t('performance_assessment.page_assessment_details.participants.table.col_assessment_avg')}</th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (
          data.map((item, index) => (
            <React.Fragment key={`evaluated-${index}`}>
              <tr className={`${styles.tableRow} ${styles.tableRowAvaleated}`} style={{backgroundColor: item?.status?.bg_color || '#ffffff'}}>
                <td style={{ color: `${item?.status?.text_color || '#4F7091'}`}}>{item?.username}</td>
                <td style={{ color: `${item?.status?.text_color || '#4F7091'}`}}>{item?.total_evaluator || ''}</td>
                <td />
                <td style={{ color: `${item?.status?.text_color || '#4F7091'}`}}>{item?.office || '-----'}</td>
                <td className={styles.alignCenter} style={{ color: `${item?.status?.text_color || '#4F7091'}`}}>{item?.progress}</td>
                <td />
                <td className={styles.alignCenter} style={{ color: `${item?.status?.text_color || '#4F7091'}`}}>
                  {
                    item?.avg_assessment_label
                    ?
                    <CustomTooltip
                      title={<Typography component={'div'} variant='caption'>{item?.avg_assessment_label}</Typography>}
                      placement="left-start"
                      arrow
                      interactive>
                      <span className={styles.avgAssessment}>{item?.general_avg}</span>
                    </CustomTooltip>
                    :
                    item?.general_avg
                  }
                </td>
              </tr>
              {item.evaluators.length > 0 ? (
                item.evaluators.map((evaluator, subIndex) => (
                  <tr className={`${styles.tableRow}`} key={`evaluator-${index}-${subIndex}`}>
                    <td/>
                    <td>{evaluator?.username}</td>
                    <td className={`${evaluator?.is_self_assessment ? styles.autoAssessment : ''}`}>
                      {getStructureOrganizationalLabel(evaluator?.is_manager, evaluator?.is_self_assessment)}
                    </td>
                    <td>{evaluator?.office || "-----"}</td>
                    <td style={{padding: '0px 12px'}}>
                      {
                        evaluator?.status
                        ?
                          <StatusAssessment
                            title={evaluator?.status["title_" + i18n.language]}
                            textColor={evaluator?.status?.text_color}
                            bgColor={evaluator?.status?.bg_color}
                            height='52px'
                          />
                        :
                        '-----'
                      }
                    </td>
                    <td className={styles.alignCenter}>{evaluator?.answered_at ? befectiveFormatDate(evaluator?.answered_at, i18n.language) : '-----'}</td>
                    <td className={styles.alignCenter}>
                      {
                        evaluator?.avg_assessment_label
                        ?
                        <CustomTooltip
                          title={<Typography component={'div'} variant='caption' >{evaluator?.avg_assessment_label}</Typography>}
                          placement="left-start"
                          arrow
                          interactive>
                          <span className={styles.avgAssessment}>{evaluator?.avg}</span>
                        </CustomTooltip>
                        :
                        evaluator?.avg
                      }
                    </td>
                  </tr>
                ))
              ) : (
                <tr key={`${index}-no-evaluators`}>
                  <td colSpan={7} style={{backgroundColor: '#ffffff'}}>
                    {t('performance_assessment.page_assessment_details.participants.table.no_data')}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan={7} style={{backgroundColor: '#ffffff'}}>
              {t('performance_assessment.page_assessment_details.participants.table.no_data')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
