import { Box, Container, Grid,Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { alertActions, usersActions } from '../../_actions'
import style from './ProductivityDetailsPage.module.scss'
import { authHeader, befectiveFormatDate, secondsToHm } from '../../_helpers'
import { api } from '../../_helpers/api'
import AlertBox from '../../_components/_shared/AlertBox'
import Header from '../../_components/productivity/Header'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import moment from 'moment'
import SkeletonProductivityDetailsPage from './SkeletonProductivityDetailsPage'
import HeaderProductivityColab from '../../_components/_shared/HeaderProductivityColab'

function ProductivityDetailsPage({ userDetails, usersActions, alertActions, filter }) {
  const { t, i18n } = useTranslation('common');
  const [fetching, setFetching] = useState(true)
  const current_day = moment().format('YYYY-MM-DD HH:mm')
  const [data, setData] = useState([])
  const lang = userDetails.language || i18n.language

  const getData = async (isFetching = true, filterMonth = moment(new Date()).format('YYYY-MM')) => {

    if (isFetching) {
      setFetching(true)
    }

    try {

      const res = await api.get(`/users/new-productivity/details?current_day=${current_day}&mu_id=${userDetails.id}&year_month=${filterMonth}`, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error("Falha ao carregar os dados")
      }

      setData(res.data)
    } catch (error) {
      console.log(error)
    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    const date = filter.singleDate ? moment(filter.singleDate).format('YYYY-MM') : moment(new Date()).format('YYYY-MM');
    getData(true, date)
  }, [filter])

  useEffect(() => {

    document.title = 'Befective | Producivity | Details';

  }, [])


  return (
    <Box
      bgcolor="#F2F5FA"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <AlertBox />

      <HeaderProductivityColab 
        activeMenu="productivity"
        titleDetails={true} 
        urlToBack={'/productive/productivity'}
      />

      {
        !fetching
          ?
          <Container component={'main'} maxWidth="xl" className={style.containerMain} sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} mt={'-80px'} mb={'16px'} paddingX={'32px'}>
              <Grid item xs={12}>
                <div className={style.rulerContainer}>
                  <table className={style.table}>
                    <thead>
                      <th style={{width:'80px'}}>{t('colab_page.ruler.date')}</th>
                      <th>{t('colab_page.ruler.start')}</th>
                      <th>{t('colab_page.ruler.total_worked_time')}</th>
                      <th>{t('colab_page.ruler.productive_time')}</th>
                      <th>{t('colab_page.ruler.closed_time')}</th>
                      <th>{t('colab_page.ruler.period_greatest_productivity')}</th>
                      <th>{t('colab_page.ruler.online')}</th>
                      <th>{t('colab_page.ruler.offline')}</th>
                      <th style={{ textAlign: 'center' }}>{t('colab_page.ruler.mood')}</th>
                    </thead>
                    <tbody>
                      {
                        data.length > 0
                          ?
                          data.map((item, index) => (

                            <tr key={item.day_ref} style={{backgroundColor: index % 2 == 0 ? '#EEEEEE' : '#FFFFFF'}}>
                              <td>
                                {befectiveFormatDate(item.day_ref, lang)}
                              </td>
                              <td style={{ color: item.stats.is_late ? '#FF7A90' : '#4F7091' }}>
                                {item.stats.start_time_value}
                              </td>
                              <td>
                                <div className={style.boxCircularProgress}>
                                  <div className={`${style.circularProgress} ${style.borderPrimary}`} >
                                    <CircularProgressbar
                                      value={item.stats.worked_time_percent}
                                      strokeWidth={50}
                                      styles={buildStyles({
                                        strokeLinecap: "butt",
                                        pathColor: '#187DFF',
                                        trailColor: '#E4EAEF',
                                      })}
                                    />
                                  </div>
                                  <div>
                                    {secondsToHm(item.stats.worked_time)}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className={style.boxCircularProgress}>
                                  <div className={`${style.circularProgress} ${style.borderCompleted}`} >
                                    <CircularProgressbar
                                      value={item.stats.productive_time_percent}
                                      strokeWidth={50}
                                      styles={buildStyles({
                                        strokeLinecap: "butt",
                                        pathColor: '#22BEAD',
                                        trailColor: '#E4F7F5',
                                      })}
                                    />
                                  </div>
                                  <div>
                                    {secondsToHm(item.stats.productive_time)}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className={style.boxCircularProgress}>
                                  <div className={`${style.circularProgress} ${style.borderPlanned}`} >
                                    <CircularProgressbar
                                      value={item.stats.closed_time_percent}
                                      strokeWidth={50}
                                      styles={buildStyles({
                                        strokeLinecap: "butt",
                                        pathColor: '#98ACC1',
                                        trailColor: '#E4EAEF',
                                      })}
                                    />
                                  </div>
                                  <div>
                                    {secondsToHm(item.stats.closed_time)}
                                  </div>
                                </div>
                              </td>
                              <td>
                                {item.stats.period_greatest_productivity}
                              </td>
                              <td>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                  <div>{secondsToHm(item.stats.online_time)}</div>
                                  <div className={style.barchart}>
                                    <div className={style.bar} style={{ width: `${item.stats.online_time_productive_percent}%` }}></div>
                                    <div className={`${style.bar} ${style.neutral}`} style={{ width: `${item.stats.online_time_neutral_percent}%` }}></div>
                                    <div className={`${style.bar} ${style.unrelated}`} style={{ width: `${item.stats.online_time_unproductive_percent}%` }}></div>
                                  </div>
                                </div>
                              </td>
                              <td>

                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                  <div>{secondsToHm(item.stats.offline_time)}</div>
                                  <div className={style.barchart}>
                                    <div className={style.bar} style={{ width: `${item.stats.offline_time_productive_percent}%` }}></div>
                                    <div className={`${style.bar} ${style.neutral}`} style={{ width: `${item.stats.offline_time_neutral_percent}%` }}></div>
                                    <div className={`${style.bar} ${style.unrelated}`} style={{ width: `${item.stats.offline_time_unproductive_percent}%` }}></div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  {
                                    item.stats.mood_today.url_image
                                      ?
                                      <Tooltip arrow title={item.stats.mood_today[`title_${userDetails.language || i18n.language}`]}>
                                        <img className={style.moodImg} src={item.stats.mood_today.url_image} loading='lazy' />
                                      </Tooltip>
                                      :
                                      '--'
                                  }
                                </div>
                              </td>
                            </tr>
                          ))
                          :
                          <tr>
                            <td colSpan={9}>{t('colab_page.ruler.none_data')}</td>
                          </tr>

                      }
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>
          </Container>
          :
          <SkeletonProductivityDetailsPage />
      }
    </Box >
  )
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter
  }
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductivityDetailsPage));