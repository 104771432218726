import React, { useEffect, useState } from 'react';
import styles from './CardManageResultIndicators.module.scss';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { Typography } from '@material-ui/core';
import IconInfo from '../../_icons/IconInfo';
import IconThreePoints from '../../_icons/IconThreePoints';
import IconRemove from '../../_icons/IconRemove';
import { useTranslation } from 'react-i18next';
import { Add, Close, Delete, Remove, Save } from '@material-ui/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { api } from '../../../_helpers/api';
import { authHeader, calculateScoreRatings } from '../../../_helpers';
import RatingEdit from './RatingEdit';
import { v4 as uuidv4 } from 'uuid';


const CardManageResultIndicators = ({
    resultIndicator,
    index,
    handleClickCurrentResultIndicator,
    handleCloseCurrentResultIndicator,
    currentResultIndicator,
    typeAction,
    getResultIndicators,
    alertActions,
    lastResultIndicatorCreated
}) => {

    const { t, i18n } = useTranslation('common');
    const [addRating, setAddRating] = useState(false);

    const letters = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","U","V","W","X","Y","Z"]

    const [ratings, setRatings] = useState(resultIndicator.ratings || []);
    const [saving, setSaving] = useState(false);

    const onDragEnd = async (result) => {
        if (!result.destination) return;
        
        const { destination, source, draggableId: ratingID } = result;
        
        if(destination.index === source.index) return;

        const newRatings = Array.from(ratings);
        const [removed] = newRatings.splice(source.index, 1);
        newRatings.splice(destination.index, 0, removed);
        const processedRatings = calculateScoreRatings(newRatings);
        setRatings(processedRatings);
        
    };

    const addNewRating = (newRating) => {
        if(ratings.find(q => q.ratings_label === newRating.ratings_label)) return;
        const newRatings = [...ratings, {...newRating, ratings_id: uuidv4(), is_new: true}];
        const processedRatings = calculateScoreRatings(newRatings);
        setRatings(processedRatings);
    }

    const removeRating = (ratingId) => {
        setRatings(ratings.filter(q => q.ratings_id !== ratingId));
    }

    const editRating = (rating) => {
        setRatings(ratings.map(q => q.ratings_id === rating.ratings_id ? rating : q));
    }

    const validateRatings = (ratings) => {
        for (const option of ratings) {
            if (ratings.filter(q => q.ratings_label === option.ratings_label).length > 1) {
                return {
                    error: t('performance_assessment.messages.error_repeated_option')
                };
            }
            if (option.ratings_label === "" || option.ratings_value === "") {
                return {
                    error: t('performance_assessment.messages.error_empty_option')
                };
            }   
        }
        return {
            error: null
        }
    }

    const handleSaveRatings = async () => {
        try {

            const validateRatingsResult = validateRatings(ratings);

            if(validateRatingsResult.error !== null) return alertActions.error(validateRatingsResult.error);

            const ratingsPrepared = ratings.map(q => {
                if(q.is_new){
                    return {
                        ...q,
                        ratings_id: undefined
                    }
                }
                return q                
            })

            setSaving(true);
            const res = await api.put(`/performance-assessment/result-indicators/${resultIndicator.id}/ratings`, {ratings: ratingsPrepared}, {headers: authHeader()});

            if (res.status !== 200) {
                throw new Error('Erro to save ratings');
            }

            getResultIndicators();            
            setAddRating(false);
            handleCloseCurrentResultIndicator();
            setSaving(false);

            alertActions.success(t('performance_assessment.messages.success_save_result_indicator'));
        } catch (error) {
            console.log(error);
            setSaving(false);  
            alertActions.error(t('performance_assessment.messages.error_save_result_indicator'));      
        }
    }

    useEffect(() => {
        setRatings(resultIndicator.ratings || []);
    }, [resultIndicator.ratings]);

    useEffect(() => {
        if(lastResultIndicatorCreated){
            setAddRating(true);
        }else{
            setAddRating(false);
        }
    }, [lastResultIndicatorCreated])

    return (
        <div className={styles.cardContainer}>
            <header className={styles.cardHeader}>
                <div className={styles.boxCardTitle}>
                    <h6 style={{color: (currentResultIndicator && currentResultIndicator.id === resultIndicator.id && typeAction === "edit_options") ? '#187DFF' : '#1E6B7F'}}>{`${index + 1}. ${resultIndicator.name} ${(currentResultIndicator && currentResultIndicator.id === resultIndicator.id && typeAction === "edit_options") ? `(${t('performance_assessment.page_scales_result_indicator.edit')})` : ''}`}</h6>
                    {
                        resultIndicator.description &&
                        <BefectiveTooltip
                            title={<Typography component={'div'} variant='caption'>{resultIndicator.description}</Typography>}
                            placement="right-start"
                            arrow
                            interactive>
                            <span style={{ cursor: 'pointer' }}>&nbsp;&nbsp;<IconInfo /></span>
                        </BefectiveTooltip>
                    }
                </div>
                <div className={styles.boxButtons}>
                    {
                        currentResultIndicator === null
                        ?
                        <button
                            className={"buttonThreePoints"}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClickCurrentResultIndicator(e, resultIndicator);
                            }}
                            >
                            <IconThreePoints />
                        </button>
                        :
                        (
                            (resultIndicator.id === currentResultIndicator.id && typeAction === "edit_options")
                            ?
                            <>
                                <button 
                                    className={styles.buttonCancel} 
                                    type="button" 
                                    onClick={() => handleCloseCurrentResultIndicator(true)}
                                    disabled={saving}
                                >
                                    {t("performance_assessment.actions.cancel")}
                                </button>

                                <button 
                                    className={styles.buttonSave} 
                                    type="button" 
                                    onClick={() => handleSaveRatings()}
                                    disabled={saving}
                                >
                                    {saving ? t("performance_assessment.actions.saving") : t("performance_assessment.actions.save")}
                                </button>

                            
                            </>
                            :
                            <button
                                className={"buttonThreePoints"}
                                style={{cursor: 'not-allowed'}}
                                disabled
                                >
                                <IconThreePoints />
                            </button>
                        )
                    }
                </div>
            </header>
            
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th style={{width: '0px', paddingRight: '4px'}}/>
                        <th style={{width: '25%'}}>{t("performance_assessment.page_scales_result_indicator.table.col_classification")}</th>
                        <th>{t("performance_assessment.page_scales_result_indicator.table.col_text_help")}</th>
                        <th style={{width: '18%', textAlign: 'center'}}>{t("performance_assessment.page_scales_result_indicator.table.col_performance_range")}</th>
                        <th style={{width: '0px'}}/>
                    </tr>
                </thead>

                {
                    (currentResultIndicator && resultIndicator.id === currentResultIndicator.id && typeAction === "edit_options")
                    ?
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                            
                                {(ratings && ratings.length > 0) 
                                &&
                                ratings.map((rating, index) => {
                                    return (
                                        <Draggable
                                            key={rating.ratings_id}
                                            draggableId={String(rating.ratings_id)}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <RatingEdit
                                                    key={`rating-${index}`}
                                                    provided={provided}
                                                    resultIndicator={resultIndicator}
                                                    rating={rating}
                                                    ratings={ratings}
                                                    getResultIndicators={getResultIndicators}
                                                    setAddRating={setAddRating}
                                                    editRating={editRating}
                                                    removeRating={removeRating}
                                                    addNewRating={addNewRating}
                                                />
                                            )}
                                        </Draggable>
                                    )
                                })}


                                {provided.placeholder}

                                {
                                    !addRating
                                    ?
                                    <tr>
                                        <td colSpan={5}>
                                            <button className={styles.buttonNewOption} type="button" onClick={() => setAddRating(true)}>
                                                {t("performance_assessment.page_scales_result_indicator.new_item")}
                                            </button>
                                        </td>
                                    </tr>
                                    :

                                    <RatingEdit
                                        key={`rating2-${index}`}
                                        provided={null}
                                        resultIndicator={resultIndicator}
                                        addRating={addRating}
                                        setAddRating={setAddRating}
                                        ratings={ratings}
                                        rating={{
                                            ratings_id: null,
                                            ratings_label: "",
                                            ratings_help_text: "",
                                            ratings_score: ""
                                        }}
                                        getResultIndicators={getResultIndicators}
                                        editRating={editRating}
                                        removeRating={removeRating}
                                        addNewRating={addNewRating}
                                    />
                                }
                            
                            
                            </tbody>
                          )}
                        </Droppable>
                    </DragDropContext>
                :
                <tbody className={styles.tbodyGray}>
                    {
                        (ratings && ratings.length > 0) 
                        ?
                        ratings.map((rating, index) => {
                            return (
                                <tr key={index}>
                                    <td style={{width: '0px', paddingRight: '4px'}}>{`${letters[index]}.`}</td>
                                    <td style={{width: '25%'}}>{rating.ratings_label}</td>
                                    <td>{rating.ratings_help_text}</td>
                                    <td style={{width: '18%', textAlign: 'center'}}>
                                        {rating.ratings_score ? `${rating.ratings_score.split("/")[0]}% ${t('performance_assessment.page_scales_result_indicator.to')} ${rating.ratings_score.split("/")[1]}%`: "-----"}
                                    </td>                                    
                                    <td style={{width: '0px'}}/>
                                </tr>
                            )
                        })
                        :
                        <tr>
                            <td colSpan={5}>{t("performance_assessment.page_scales_result_indicator.no_items")}</td>
                        </tr>

                    }
                </tbody>

                }
                
            </table>

        </div>
    )
}

export default CardManageResultIndicators;