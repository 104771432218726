import { history } from "./history";

const convertionKeyProfileAccessToFrontPageEndPoints = (key) => {
  switch (key) {
    case 'executive':
      return 'executive'

    case 'activities':
      return 'activities'

    case 'history':
      return 'historical'
    
    default:
      return 'executive'

  }
}

const redictBasedOnAccessProfileKey = (key) => {
  switch (key) {
    case 'executive':
      history.push('/productive')
      break;

    case 'activities':
      history.push('/productive/activities')
      break;
      
    case 'historical':
      history.push('/productive/historical')
      break;

    case 'reports':
      history.push('/productive/reports')
      break;
    default:
      break;

  }
}

export function redirectToModule(moduleName, initialScreen = '',allowed_menu=[]) {
  switch (moduleName) {
    case ('productivity_management'):
      if(allowed_menu.length > 0 && !allowed_menu.includes(convertionKeyProfileAccessToFrontPageEndPoints(initialScreen))){
        return redictBasedOnAccessProfileKey(allowed_menu[0])
      }else{
        switch (initialScreen) {
          case 'activities':
            history.push('/productive/activities')
            break;
          case 'history':
            history.push('/productive/historical')
            break;
          case 'teams':
            history.push('/settings/teams')
            break;
          case 'realtime':
            history.push('/productive/reports/realtime')
            break;
          case 'time_goal':
            history.push('/productive/reports/timegoal')
            break;
          case 'work_goal':
            history.push('/productive/reports/workgoal')
            break;
          case 'mood':
            history.push('/productive/reports/mood')
            break;
          case 'report-teams':
            history.push('/productive/reports/teams')
            break;
          case 'teammates':
            history.push('/productive/reports/teammates')
            break;
          case 'inactivity':
            history.push('/productive/reports/inactivity')
            break;
          default:
            history.push('/productive')
            break;
        }
      }
      
      break;
    case ('goals_management'):
      switch (initialScreen) {
        case 'running':
          history.push('/goals')
          break;
        case 'completed':
          history.push('/goals/completed')
          break;
        default:
          history.push('/goals')
          break;
      }
      break;
    case ('check_in_remote'):
      switch (initialScreen) {
        case '':
          history.push('/virtual-point')
          break;
        default:
          history.push('/virtual-point')
          break;
      }
      break;
    case ('check_in_remote_mobile'):
      switch (initialScreen) {
        case '':
          history.push('/checkin-remote')
          break;
        default:
          history.push('/checkin-remote')
          break;
      }
      break;
    case ('rooms_management'):
      switch (initialScreen) {
        case '':
          history.push('/spaces')
          break;
        default:
          history.push('/spaces')
          break;
      }
      break;
    case ('project_management'):
      switch (initialScreen) {
        case '':
          history.push('/project_management/home')
          break;
        default:
          history.push('/project_management/home')
          break;
      }
      break;
    case ('inventory'):
      switch (initialScreen) {
        case '':
          history.push('/inventory')
          break;
        default:
          history.push('/inventory')
          break;
      }
      break;
    case ('performance_assessment'):
      switch (initialScreen) {
        case '':
          history.push('/performance-assessment')
          break;
        default:
          history.push('/performance-assessment')
          break;
      }
        break;
    default:
      history.push('/')
      break;

    
    
  }
}