import React, { useEffect, useState } from 'react'
import style from './styles.module.scss';
import { authHeader, secondsToHm } from '../../../../_helpers';
import IconInfo from '../../../_icons/IconInfo';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Tooltip } from '@mui/material';
import Widget35Skeleton from './Widget35Skeleton';
import ReactApexChart from 'react-apexcharts';
import BefectiveTooltip from '../../../_shared/BefectiveTooltip';
import { api } from '../../../../_helpers/api';
import { ThumbsUp, ThumbsDown } from 'phosphor-react';
import { VictoryPie } from 'victory';
export default function Widget35({
  alertActions,
  currentDate,
  width,
  filters
}) {
  const { t, i18n } = useTranslation('common');
  const [fetching, setFetching] = useState(true);
  const [filterSelected, setFilterSelected] = useState("best");
  
  const [data, setData] = useState(null)


  const getData = async ({filterTeams, filterTeammates}) => {
    try {
      setFetching(true)
      const response = await api.get(`/performance-assessment/widgets/last-pa?current_day=${currentDate.format('YYYY-MM-DD')}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget35!!!')
      }
      const { data } = response
      setData(data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData({...filters})
  }, [])
  console.log()

  return (
    !fetching
      ?

      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>
                <h6 className={style.title}>{t('widgets.widget35.title')}</h6>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('widgets.widget35.tooltip')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo/></div>
                </BefectiveTooltip>
              </div>
            </div>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div className={style.rowStrongAndWeak}>
                  <div className={`${style.boxIcon} ${style.strongPointBgColor}`}>
                    <ThumbsUp size={16} color='#22BEAD'/>
                  </div>
                  <div className={style.boxInfo}>
                    <h6 className={style.strongPointColor}>{t('widgets.widget35.strong_point')}</h6>
                    <span>{data?.strong_point?.name || '-----'}: {Number(data?.strong_point?.avg || 0).toFixed(2)}</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={style.rowStrongAndWeak}>
                  <div className={`${style.boxIcon} ${style.weakPointBgColor}`}>
                    <ThumbsDown size={16} color='#FF7A90'/>
                  </div>
                  <div className={style.boxInfo}>
                    <h6 className={style.weakPointColor}>{t('widgets.widget35.weak_point')}</h6>
                    <span>{data?.weak_point?.name || '-----'}: {Number(data?.weak_point?.avg || 0).toFixed(2)}</span>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={'0px'}>
              <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center'}}>
                <div className={style.mainChart}>
                  <div className={style.stats}>
                    <span className={style.primary}>
                      {Number(data?.evaluated_percent || 0).toFixed(1) + '%'}
                    </span>
                    <span className={style.secondary}>{t('widgets.widget35.evaluated')}</span>
                    <span className={style.secondary2}>{`${data?.total_evaluated_answered || 0}/${data?.total_evaluated || 0} ${t('widgets.widget35.responded')}`}</span>
                  </div>
                  <svg width={172} height={172}>
                    <VictoryPie
                      padAngle={0}
                      standalone={false}
                      labelComponent={<span />}
                      innerRadius={72}
                      width={172} height={172}
                      data={[
                              {'key': "", 'y': Number(data?.evaluated_percent || 0) }, 
                              {'key': "", 'y': 100 - Number(data?.evaluated_percent || 0)}
                            ]}
                      colorScale={["", "#D2D7E1"]}
                    />
                    <circle cx={86} cy={86} r={66} />
                  </svg>
                </div>
              </Grid>
              <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center'}}>
                <div className={style.mainChart}>
                  <div className={style.stats}>
                    <span className={style.primary}>
                      {Number(data?.evaluator_percent || 0).toFixed(1) + '%'}
                    </span>
                    <span className={style.secondary}>{t('widgets.widget35.evaluators')}</span>
                    <span className={style.secondary2}>{`${data?.total_evaluator_answered || 0}/${data?.total_evaluator || 0} ${t('widgets.widget35.responded')}`}</span>
                  </div>
                  <svg width={172} height={172}>
                    <VictoryPie
                      padAngle={0}
                      standalone={false}
                      labelComponent={<span />}
                      innerRadius={72}
                      width={172} height={172}
                      data={[
                            { 'key': "", 'y': Number(data?.evaluator_percent || 0) }, 
                            {'key': "", 'y': 100 - Number(data?.evaluator_percent || 0) }
                          ]}
                      colorScale={["", "#D2D7E1"]}
                    />
                    <circle cx={86} cy={86} r={66} />
                  </svg>
                </div>
              </Grid>
              <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center'}}>
                <div className={`${style.mainChart} ${style.work}`}>
                  <div className={style.stats}>
                    <span className={style.primary}>
                      {Number(data?.percent_day_past || 0).toFixed(1) + '%'}
                    </span>
                    <span className={style.secondary}>{t('widgets.widget35.missing')} {data?.remaining_days || 0} {t('widgets.widget35.days')}</span>
                    <span className={style.secondary2}>{t('widgets.widget35.to_reply')}</span>
                  </div>
                  <svg width={172} height={172}>
                    <VictoryPie
                      padAngle={0}
                      standalone={false}
                      labelComponent={<span />}
                      innerRadius={72}
                      width={172} height={172}
                      data={[{ 'key': "", 'y': Number(data?.percent_day_past || 0)}, {
                        'key': "",
                        'y': (100 - Number(data?.percent_day_past || 0))
                      }]}
                      colorScale={["", "#D2D7E1"]}
                    />
                    <circle cx={86} cy={86} r={66} />
                  </svg>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget35Skeleton />
      </Grid>

  )
}
