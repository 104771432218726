import React, { useEffect, useState } from 'react';
import styles from './CardManageSkill.module.scss';
import { useTranslation } from 'react-i18next';
import { Add, Check, Close, Delete, DragIndicator, Save } from '@material-ui/icons';

const QuestionEdit = ({
    provided,
    skill,
    scales,
    question,
    getSkills = () => {},
    setAddQuestion = () => {},
    editQuestion = () => {},
    removeQuestion = () => {},
    addNewQuestion = () => {}
}) => {

    const { t, i18n } = useTranslation('common');

    const [formQuestion, setFormQuestion] = useState({
        skills_id: skill.id,
        questions_id: question.questions_id || null,
        questions_content: question.questions_content || "",
        scales_id: question.scales_id || ""
    })

    const [formErrors, setFormErrors] = useState({
        questions_content: "",
        scales_id: ""
    });

    const onChangeFormQuestion = (event) => {

        const { name, value } = event.target
        
        const newFormQuestion = {
            ...formQuestion,
            [name]: value
        }

        setFormQuestion(newFormQuestion)

        if(formQuestion.questions_id) {
            editQuestion(newFormQuestion)
        }

        setFormErrors({
            ...formErrors,
            [event.target.name]: ""
        })
    }

    const validateForm = () => {

        const newFormErrors = {}

        if(!formQuestion.questions_content) {
            newFormErrors.questions_content = t('validation.required')
        }

        if(!formQuestion.scales_id) {
            newFormErrors.scales_id = t('validation.required')
        }

        setFormErrors(newFormErrors)

        if(Object.keys(newFormErrors).length > 0) {
            return false
        }

        return true
    }

    const resetForm = () => {
        setFormQuestion({
            skills_id: skill.id,
            questions_id: null,
            questions_content: "",
            scales_id: ""
        })

        setFormErrors({
            questions_content: "",
            scales_id: ""
        })
    }
    

    return (
        <tr
        ref={provided && provided?.innerRef}
        {...provided?.draggableProps}
        className={styles.trCustomPadding}
        >
            {
                (formQuestion.questions_id !== null)
                ?
                    <td style={{ cursor: 'grab', width: '0px' }} {...provided?.dragHandleProps}>
                        <DragIndicator style={{ cursor: 'grab' }} />
                    </td>
                :
                    <td></td>
            }
            
            <td>
                <div className={styles.formGroup}>
                    <input required autoComplete='off' autoFocus={formQuestion.questions_id === null} className={`${styles.inputEditable} ${formErrors.questions_content && styles.inputError}`} placeholder={t('performance_assessment.page_skills.table.placeholder_question')} type="text" value={formQuestion?.questions_content || ""} onChange={onChangeFormQuestion} name="questions_content"/>
                </div>
            </td>
            <td>
                <div className={styles.formGroup}>
                    <select required className={`${styles.customSelect} ${formErrors.scales_id && styles.inputError}`} value={formQuestion?.scales_id || ""} onChange={onChangeFormQuestion} name="scales_id">
                        <option value="" disabled>{t('global_options.select')}</option>
                        {
                            (scales && scales.length > 0) &&
                            scales.map((scale, index) => (
                                <option key={`scale-${index}`} value={scale.id}>{scale.name}</option>
                            ))
                        }
                    </select>
                </div>
            </td>
            {
                (formQuestion.questions_id !== null)
                ?
                <td style={{width: '0px'}}>
                    <div className={styles.boxButtonsQuestions}>
                        <button
                            className={styles.buttonRemoveQuestion}
                            onClick={() => removeQuestion(formQuestion.questions_id)}
                        >
                            <Delete style={{color:"#FC6662"}} fontSize='small' />
                        </button>
                    </div>
                </td>
                :
                <td style={{width: '0px'}}>
                    <div className={styles.boxButtonsQuestions}>
                        <button
                            className={styles.buttonRemoveQuestion}
                            onClick={() => setAddQuestion(false)}
                        >
                            <Close style={{color:"#FC6662"}} fontSize='small' />
                        </button>
                        <button
                            className={styles.buttonAddQuestion}
                            onClick={() => {
                                if(validateForm()) {
                                    addNewQuestion(formQuestion)
                                    resetForm()
                                }
                            }}
                        >
                            <Check style={{color:"#187DFF"}} fontSize='small' />
                        </button>
                    </div>
                </td>
            }
        </tr>
    )
}   


export default QuestionEdit;