import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { NavLink, Link } from 'react-router-dom';

import Toolbar from '../_components/_shared/Toolbar'
import CompanyInformation from '../_components/settings/company/CompanyInformation'
import CompanyInformationSkeleton from '../_components/settings/company/CompanyInformationSkeleton'

import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation, initReactI18next } from "react-i18next";

import { teamsActions } from '../_actions';
import { settingsActions } from '../_actions';

import { VictoryPie, VictoryTheme } from 'victory';

import IconAvatar from '../_components/_icons/IconAvatar';
import { authHeader, calcLicensesAvailable } from '../_helpers';
import { api } from '../_helpers/api';
import { Box } from '@mui/material';
import Header from '../_components/switch_page/Header';


function CompanyPageInformation(props) {
    const { t, i18n } = useTranslation('common');

    const { settingsActions } = props;


    let permissions = props.permissions || [];
    let companyInformation = props.companyInformation || {};
    let currentUser = props.userDetails || {}
    let token = props.token || {};
    let loading = props.loading;

    const [minimumTime, setMinimumTime] = useState(500)
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true)
    const [currencies, setCurrencies] = useState([])
    let perLicenses = 0;

    if (companyInformation.usedLicenses > 0) {

        perLicenses = Math.round((companyInformation.usedLicenses / companyInformation.totalLicenses) * 100);

    }

    const getCurrencies = async () => {   
        try {
          const { data } = await api.get(`/settings/currencies`, {
            headers: authHeader()
          });
          setCurrencies(data)
        } catch (error) {
          console.error('Erro get currencies. Erro: ', error);
        }
    }

    useEffect(() => {
        getCurrencies()
        settingsActions.getCompanyInformation()

    }, []);


    useEffect(() => {
        document.title = 'Befective | ' + t('menu.settings') + ' | ' + t('menu.company') + ' | ' + t('settings.information');
    });


    return (
        <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
            <Header toBackUrl={"/global-settings"} />
            <section className="mainContent">
                {/* <Toolbar /> */}

                <div className="mainFilterbar">
                    {/* {(permissions.ViewCompanyInformation) &&
                        <NavLink exact to="/settings/company" activeClassName="active">
                            <button className={'settingsNav'}>{t('settings.information')}</button>
                        </NavLink>
                    }

                    {(permissions.ViewCompanyAdministrators) &&
                        <NavLink to="/settings/company/administrators" activeClassName="active">
                            <button className={'settingsNav'}>{t('settings.administrators')}</button>
                        </NavLink>
                    }

                    {(permissions.ViewCompanyFreeDays) &&
                        <NavLink to="/settings/company/daysoff" activeClassName="active">
                            <button className={'settingsNav'}>{t('settings.daysoff')}</button>
                        </NavLink>
                    } */}

                    {/* {(permissions.ViewCompanyInventory) &&
                        <NavLink to="/settings/company/inventory-teammates" activeClassName="active">
                            <button className={'settingsNav'}>{t('settings.inventory')}</button>
                        </NavLink>
                    } */}

                </div>

                {/* PAGE STARTS */}

                <div className="mainPage profile">
                    <div className="nocolumn">
                        <div className={'tab2 active'}>
                            {
                                !minimumTimeElapsed || loading
                                    ? <div className="profile extra">
                                        <div className='boxAvatar'>
                                            <div className="avatar">
                                                <Skeleton variant="circle" width={80} height={80} />
                                            </div>
                                            <div className="info">
                                                <div className="name" style={{width:'200px'}}><Skeleton variant="text" width="100%" height={18} /></div>
                                                <div className="company" style={{width:'200px'}}><Skeleton variant="text" width="100%" height={12} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <div className="profile extra">
                                        <div className='boxAvatar'>
                                            <div className="avatar">
                                                <div className="img">
                                                    <img
                                                        src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&rounded=true&size=80&name=' + currentUser.organizationName} />
                                                </div>
                                                <button className="change" style={{ display: 'none' }}>
                                                    <IconAvatar></IconAvatar>
                                                </button>
                                            </div>
                                            <div className="info">
                                                <div className="name">{companyInformation.name}</div>
                                                <div
                                                    className="company">{companyInformation.website != "null" ? companyInformation.website : ""}</div>
                                            </div>
                                        </div>
                                        <div className="plan">
                                            <div className="chart">
                                                <div className="label">{t('settings.company.plan')}</div>
                                                <svg width={80} height={80}>
                                                    <VictoryPie
                                                        padAngle={0}
                                                        standalone={false}
                                                        // used to hide labels
                                                        labelComponent={<span />}
                                                        innerRadius={40}
                                                        width={80} height={80}
                                                        data={[{ 'key': "", 'y': perLicenses }, {
                                                            'key': "",
                                                            'y': (100 - perLicenses)
                                                        }]}
                                                        colorScale={["", "#D2D7E1"]}
                                                    />
                                                    <circle cx={40} cy={40} r={32} fill="#fff" />
                                                </svg>
                                            </div>
                                            <div className="details">
                                                <span>{companyInformation.usedLicenses} {t('settings.company.users')}</span>
                                                <span>{calcLicensesAvailable(companyInformation) + ' / ' + companyInformation.totalLicenses} {t('settings.licenses_available')}</span>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>


                        <div className={'tab active'}>
                            {
                                !minimumTimeElapsed || loading
                                    ? <CompanyInformationSkeleton />
                                    : <CompanyInformation  currencies={currencies}/>
                            }
                        </div>

                    </div>
                </div>
                {/* PAGE ENDS */}
            </section>

        </Box>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        teamsActions: bindActionCreators(teamsActions, dispatch)
    }
}


function mapStateToProps(state) {

    return {
        token: state.authentication.user.accessToken,
        companyInformation: state.settings.companyInformation,
        loading: state.settings.loading,
        filter: state.filter,
        permissions: state.authentication.permissions,
        userDetails: state.authentication.userDetails
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPageInformation)
