import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import HeaderForm from "../personal/HeaderForm";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { authHeader } from "../../../_helpers";
import { api } from "../../../_helpers/api";

export default function FormAnnotationsCreatePT({
  formData,
  getFormData,
  toogleShowForm,
  isEditing,
  userId,
  alertActions,
}) {
  const { t, i18n } = useTranslation("common");
  const [isSubmitting, setIsSubmitting] = useState(false);
  let formSchema = yup.object({
    // emergency_contact: yup.string().required(t("spaces.required_field"))
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      text_body: formData?.text_body || "",
    },
    resolver: yupResolver(formSchema),
  });

  const { text_body } = watch();

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      const payload = {
        machine_users_id: userId,
        text_body: data.text_body
      }
      const resCreate = await api.post("/users/general-notes", payload, {
        headers: authHeader(),
      });

      if (resCreate.status !== 200) {
        throw new Error("Error create annotations information");
      }

      await getFormData(false);
      setIsSubmitting(false);
      toogleShowForm();
      alertActions.success(t("settings.users.success.info_save"));
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
      alertActions.error(error.response);
    }
  };

  console.log(getValues());

  return (
    <div className={styles.cardContainer}>
      <HeaderForm
        formData={formData}
        toogleShowForm={toogleShowForm}
        isEditing={isEditing}
        titleCreate={t("settings.users.annotations_form.title_create")}
        isSubmitting={isSubmitting}
        submitForm={handleSubmit(onSubmit)}
        userId={userId}
      />
      <Box
        display="flex"
        bgcolor="#fff"
        flexDirection="column"
        paddingX="16px"
        paddingBottom="16px"
        borderRadius="4px"
        marginBottom="2px"
        flexGrow={1}
      >
        <Grid container spacing={2} marginBottom={"4px"} marginTop={0}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" gap="2px" width="100%">
              <Typography
                component="label"
                sx={{
                  paddingLeft: "4px",
                  fontSize: "12px",
                  color: "#1E6B7F",
                  fontWeight: 400,
                }}
                htmlFor="text_body"
              >
                {t("settings.users.annotations_form.annotations")}
              </Typography>

              <TextField
                multiline
                rows={16}
                id="text_body"
                name="text_body"
                value={text_body}
                placeholder={t("settings.users.annotations_form.placeholder")}
                error={false}
                helperText={""}
                {...register("text_body")}
                sx={{
                  width: "100%",
                  minHeight: "45px",
                  fieldset: {
                    border: "1px solid #DEE3E9CC",
                    minHeight: "45px",
                  },
                  div: { color: "#4F7091", minHeight: "45px" },
                  label: { top: "-4px" },
                }}
              />

              {errors.text_body && (
                <Typography fontSize={12} color="#FF7A90" component="span">
                  {errors.text_body.message}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
