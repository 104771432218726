import React from 'react'
import { useTranslation } from 'react-i18next';
import { ChartBar, Question } from 'phosphor-react';
import { Grid } from '@mui/material';
import ImageBR from '../../assets/images/dash_flat_img_br.png';
import ImageES from '../../assets/images/dash_flat_img_es.png';
import ImageEN from '../../assets/images/dash_flat_img_en.png';
import { history } from '../../_helpers';
export default function NoData({
  lang="en"
}) {
  const { t } = useTranslation('common');
  return (
    <Grid container>
      <Grid item xs={6} xl={7}>
        <div className="containerNoData">
          {/* <div className="containerImageNoData">
            <img src={ImageBee} alt="bee" className="imageBeeNoData" />
          </div> */}
          <h6 className="titleNoData">{t("messages_no_data.dashboard.title")}</h6>
          <p className="simpleTextNoData lineHeight">{t("messages_no_data.dashboard.description")}</p>
          <div className='containerListNoData'>
            <p className="simpleTextNoData w500">{t("messages_no_data.dashboard.title_list")}</p>
            <ul className="listNoData">
              <li>
                <p className="simpleTextNoData lineHeight"><strong>{t("messages_no_data.dashboard.item1_title")}</strong> {t("messages_no_data.dashboard.item1_text")}</p>
              </li>
              <li>
                <p className="simpleTextNoData lineHeight"><strong>{t("messages_no_data.dashboard.item2_title")}</strong> {t("messages_no_data.dashboard.item2_text")}</p>
              </li>
              <li>
                <p className="simpleTextNoData lineHeight"><strong>{t("messages_no_data.dashboard.item3_title")}</strong> {t("messages_no_data.dashboard.item3_text_part1")} <button className="buttonIconLinkNoData" onClick={() => history.push("/performance-assessment/widgets")}> <ChartBar style={{marginBottom:'-6px'}} size={24} color="#98ACC1" /></button> {t("messages_no_data.dashboard.item3_text_part2")}</p>
              </li>
              <li>
                <p className="simpleTextNoData lineHeight"><strong>{t("messages_no_data.dashboard.item4_title")}</strong> {t("messages_no_data.dashboard.item4_text_part1")} <button className="buttonIconLinkNoData" onClick={() => history.push("/calls-page/?last_url=performance-assessment")}> <Question style={{marginBottom:'-6px'}} size={24} color="#98ACC1" /></button> {t("messages_no_data.dashboard.item4_text_part2")}</p>
              </li>
            </ul>
          </div>
          <p className="simpleTextNoData w500">{t("messages_no_data.dashboard.finish_text")}</p>
        </div>
      </Grid>
      <Grid item xs={6} xl={5}>
         <div className="containerImageNoData">
          {
            lang === "en" && <img src={ImageEN} alt="image info en" className="imageFlatDash" />
          }
          {
            lang === "es" && <img src={ImageES} alt="image info es" className="imageFlatDash" />
          }
          {
            lang === "pt" && <img src={ImageBR} alt="image info br" className="imageFlatDash" />
          }
          </div>
      </Grid>
    </Grid>
  )
}
