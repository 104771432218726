import React from 'react'
import { useTranslation } from 'react-i18next';
import { history } from '../../_helpers';

export default function NoData() {
  const { t } = useTranslation('common');
  return (
    <div className="containerNoData" >
      <h6 className="titleNoData">{t("messages_no_data.feedbacks.title")}</h6>
      <p className="simpleTextNoData lineHeight">{t("messages_no_data.feedbacks.subtitle")}</p>
      <p className="simpleTextNoData">{t("messages_no_data.feedbacks.step1")}</p>
      <p className="simpleTextNoData">{t("messages_no_data.feedbacks.step2")}</p>
      <p className="simpleTextNoData">{t("messages_no_data.feedbacks.step3")}</p>
      <p className="simpleTextNoData">{t("messages_no_data.feedbacks.step4")} <a href="" onClick={(e) => {
        e.preventDefault();
        history.push("/performance-assessment/action-plan")
      }}>{t("messages_no_data.feedbacks.action_plan")}</a>.</p>
    </div>
  )
}
