import { Box, Container, Divider, Grid, Popover, Stack, Tooltip, Typography } from '@mui/material'
import { Globe, Lock, UserCircle, Users } from 'phosphor-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { alertActions, usersActions } from '../../_actions'
import style from './ChecklistPage.module.scss'
import { authHeader } from '../../_helpers'
import { api } from '../../_helpers/api'
import AlertBox from '../../_components/_shared/AlertBox'
import Header from '../../_components/checklist/Header'
import { Checkbox, Chip, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, TextField, withStyles } from '@material-ui/core'
import ModalCreateNote from '../../_components/checklist/ModalCreateNote'
import PopoverConfirm from '../../_components/teams_settings/PopoverConfirmDelete'
import { Masonry } from '@mui/lab'
import Note from '../../_components/checklist/Note'
import SkeletonChecklistPage from './SkeletonProductivityPage'
import HeaderProductivityColab from '../../_components/_shared/HeaderProductivityColab'

function ChecklistPage({ userDetails, usersActions, alertActions }) {
  const { t, i18n } = useTranslation('common');
  const [fetching, setFetching] = useState(true)
  const [openModalCreateNote, setOpenModalCreateNote] = useState(false)
  const [data, setData] = useState({
    my_todo: [],
    shared_todo: []
  })
  const [currentNote, setCurrentNote] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSharing, setAnchorElSharing] = useState(null);
  const [anchorElDeleteNote, setAnchorElDeleteNote] = useState(null);
  const [checkedSharing, setCheckedSharing] = useState(null)
  const open = Boolean(anchorEl);
  const openSharing = Boolean(anchorElSharing);
  const openDeleteNote = Boolean(anchorElDeleteNote);
  const id = openDeleteNote ? 'popover-delete-note' : undefined;

  const handleClick = (event, note) => {
    setCurrentNote(note)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentNote(null)
  };


  const handleClickSharing = (event, note) => {
    setCheckedSharing(note.access_level)
    setCurrentNote(note)
    setAnchorElSharing(event.currentTarget)
  };

  const handleCloseSharing = () => {
    setAnchorElSharing(null)
    setCheckedSharing(null)
    setCurrentNote(null)
  };

  const handleClickDeleteNote = (event) => {
    setAnchorElDeleteNote(event.currentTarget);
  };

  const handleCloseDeleteNote = () => {
    setAnchorElDeleteNote(null);
  };

  const handleConfirmDelete = async () => {
    try {
      if (!currentNote) {
        throw new Error('Note to delete not found')
      }

      const res = await api.delete(`/users/todo/${currentNote.id}`, { headers: authHeader() })
      if (res.status !== 200) {
        throw new Error('Erro to delete note')
      }
      handleCloseDeleteNote()
      await getData(false)
      handleClose()
      alertActions.success(t('colab_page.checklist.delete_note_success'))
    } catch (error) {
      console.error(error)
    }
  }


  const getData = async (isFetching = true) => {

    if (isFetching) {
      setFetching(true)
    }

    try {

      const res = await api.get("/users/todo", { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error("Erro ao carregar as notas")
      }

      setData(res.data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      setFetching(false)
    }
  }

  const handleUpdateItem = async ({ id, title, done, is_fetching = false }) => {
    try {
      const res = await api.put(`/users/todo/items/${id}`, { title: title, done: done }, { headers: authHeader() })
      if (res.status !== 200) {
        throw new Error("Erro ao atualizar o item")
      }
      if (is_fetching)
        await getData(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteItem = async ({ id }) => {
    try {
      const res = await api.delete(`/users/todo/items/${id}`, { headers: authHeader() })
      if (res.status !== 200) {
        throw new Error("Erro ao deletar o item")
      }
      await getData(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateNote = async ({ id, title, card_color, tags, sharing, is_fetching = false }) => {
    try {
      const res = await api.put(`/users/todo/${id}`, { title: title, card_color: card_color, tags: tags, sharing: sharing }, { headers: authHeader() })
      if (res.status !== 200) {
        throw new Error("Erro ao atualizar a nota")
      }
      if (is_fetching)
        await getData(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeSharing = async (e) => {
    const { value } = e.target
    setCheckedSharing(value)
    handleCloseSharing()
    await handleUpdateNote({ ...currentNote, sharing: value, is_fetching: true })

  }

  useEffect(() => {

    getData()

    document.title = 'Befective | Checklist ';

  }, [])


  return (
    <Box
      bgcolor="#F2F5FA"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <AlertBox />

      <HeaderProductivityColab
        activeMenu="checklist"
        setOpenModalCreateNote={setOpenModalCreateNote}
      />


      {
        (!fetching && data !== null)
          ?
          <Container component={'main'} maxWidth="xl" className={style.containerMain} sx={{ flexGrow: 1, marginTop:'-80px' }}>
            <Grid container spacing={2} mb={'16px'} paddingX={'32px'}>
              {
                data.my_todo.length > 0
                  ?
                  data.my_todo.map(note => (
                    <Grid item xs={4} key={`note-${note.id}`}>
                      <Note
                        isOwner={true}
                        note={note}
                        handleClickMore={handleClick}
                        handleClickSharing={handleClickSharing}
                        handleUpdateNote={handleUpdateNote}
                        handleUpdateItem={handleUpdateItem}
                        handleDeleteItem={handleDeleteItem}
                        getData={getData}
                      />
                    </Grid>
                  ))
                  :
                  <Grid item xs={4}>
                    <div
                      className={style.boxCreateNewChecklist}
                      onClick={() => setOpenModalCreateNote(true)}
                    >
                      <span>+ {t('colab_page.checklist.create_new_checklist')}</span>
                    </div>
                  </Grid>
              }
            </Grid>

            {
              data.shared_todo.length > 0
              &&
              <Grid container spacing={2} mb={'16px'} paddingX={'32px'}>
                <Grid item xs={12}>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop:'24px', marginBottom:'10px', justifyContent:'space-between'}}>
                    <Typography variant='h6' color={'#1E6B7F'}>
                    {t('colab_page.checklist.shared_me')}
                    </Typography>
                    <Divider sx={{ width: '84%'}} />
                  </div>  
                </Grid>
                {
                  data.shared_todo.map(note => (
                    <Grid item xs={4} key={`note-${note.id}`}>
                      <Note
                        isOwner={false}
                        note={note}
                        getData={getData}
                      />
                    </Grid>
                  ))}
              </Grid>
            }

            <Popover
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              disableEnforceFocus
            >
              <Box className={style.menuOptionsBoard}>
                <button className={style.menuOptionsBoardButton} aria-describedby={id} onClick={handleClickDeleteNote}>{t('colab_page.checklist.delete')}</button>
              </Box>
            </Popover>

            <Popover
              id="sharing-popover"
              aria-labelledby="sharing-popover-button"
              anchorEl={anchorElSharing}
              open={openSharing}
              onClose={handleCloseSharing}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              disableEnforceFocus
            >
              <Box className={style.boxSharingUpdate}>
                <h6>{t(`colab_page.checklist.modal_note.field_sharing`)}</h6>
                <List dense>
                  <ListItem selected={checkedSharing === 'private'} button>
                    <ListItemText
                      id={'private'}
                      primary={
                        <Box display={`flex`} alignItems={'center'} gap={'10px'} color='#1E6B7F'>
                          <Lock size={16} weight='bold' color='#1E6B7F' /> {t('colab_page.checklist.modal_note.option_private')}
                        </Box>
                      }
                      onClick={() => handleChangeSharing({ target: { value: 'private' } })}
                    />
                  </ListItem>
                  <ListItem selected={checkedSharing === 'manager'} button>
                    <ListItemText
                      id={'manager'}
                      primary={
                        <Box display={`flex`} alignItems={'center'} gap={'10px'} color='#1E6B7F'>
                          <UserCircle size={16} weight='bold' color='#1E6B7F' /> {t('colab_page.checklist.modal_note.option_manager')}
                        </Box>
                      }
                      onClick={() => handleChangeSharing({ target: { value: 'manager' } })}
                    />
                  </ListItem>
                  <ListItem selected={checkedSharing === 'peers'} button>
                    <ListItemText
                      id={'peers'}
                      primary={
                        <Box display={`flex`} alignItems={'center'} gap={'10px'} color='#1E6B7F'>
                          <Users size={16} weight='bold' color='#1E6B7F' /> {t('colab_page.checklist.modal_note.option_peers')}
                        </Box>
                      }
                      onClick={() => handleChangeSharing({ target: { value: 'peers' } })}
                    />
                  </ListItem>
                  <ListItem selected={checkedSharing === 'all'} button>
                    <ListItemText
                      id={'all'}
                      primary={
                        <Box display={`flex`} alignItems={'center'} gap={'10px'} color='#1E6B7F'>
                          <Globe size={16} weight='bold' color='#1E6B7F' /> {t('colab_page.checklist.modal_note.option_all')}
                        </Box>
                      }
                      onClick={() => handleChangeSharing({ target: { value: 'all' } })}
                    />
                  </ListItem>
                </List>
              </Box>
            </Popover>

            <PopoverConfirm
              idConfirm={id}
              openConfirm={openDeleteNote}
              anchorEl={anchorElDeleteNote}
              handleClose={handleCloseDeleteNote}
              handleConfirm={handleConfirmDelete}
              title={t('colab_page.checklist.confirm_delete_note')}
            />
          </Container>
          :
          <SkeletonChecklistPage />
      }

      <ModalCreateNote
        openModalCreateNote={openModalCreateNote}
        setOpenModalCreateNote={setOpenModalCreateNote}
        userDetails={userDetails}
        alertActions={alertActions}
        updateInfo={getData}
      />
    </Box >
  )
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistPage));