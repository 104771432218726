import React from 'react'
import styles from './styles.module.scss'
import HeaderForm from "../personal/HeaderForm";
import { useTranslation } from 'react-i18next'
import { IconButton } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';


export default function FormDocumentsPreviewPT({
  formData,
  toogleShowForm,
  isEditing,
  isExistUserId,
  userId
}) {

  const {t, i18n} = useTranslation('common')

  const formatList = (list) => {
    if(list.length === 0) return '-----'

    if(list.length === 1) return list[0]

    const listLessLast = list.slice(0, -1)

    const listLast = list[list.length - 1]

    return `${listLessLast.join(', ')} ${t('settings.users.personal_form.and')} ${listLast}`
  }

  const downloadFile = (file) => {
    const url = file?.url
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'file')
    document.body.appendChild(link)
    link.click()
  }
  
  return (
    <div className={styles.containerPreview}>
      <div className={styles.card}>
        <HeaderForm formData={formData} toogleShowForm={toogleShowForm} isEditing={isEditing}  titleCreate={t('settings.users.documents_form.title_create')} isExistUserId={isExistUserId} userId={userId}/>
        <div className={styles.card}>
          <header className={styles.cardHeader}>
            <h6>{`${t('settings.users.documents_form.documents')} (${formData?.documents?.length})`}</h6>
          </header>
          <div className={styles.cardContent} style={{padding:'0px 20px 20px', display:'flex', flexDirection:'column'}}>
            {
              formData?.documents.length > 0 
              ?
              formData?.documents.map((attachment, index) => (
                <div key={index} className={styles.filePreview}>
                  <div>{attachment.original_name || attachment.name}</div>
                  <div>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => downloadFile(attachment) }
                    >
                      <GetApp fontSize='small' />
                    </IconButton>
                  </div>
                </div>
              ))
              :
              <div>
                <p className={styles.noneDocuments}>{t('settings.users.documents_form.none_docs_available')}</p>
              </div>
            }
          </div>
        </div>
      </div>      

    </div>
  )
}
