export const menuConstants = {
    SET_MENU_REPORTS: '[MENU] SET_REPORTS',
    SET_MENU_SETTINGS: '[MENU] SET_SETTINGS',
    SET_MENU_GOALS: '[MENU] SET_GOALS',
    SET_MENU_INTEGRATIONS: '[MENU] SET_INTEGRATIONS',
    SET_MENU_CHECKIN: '[MENU] SET_MENU_CHECKIN',
    SET_MENU_CHECKIN_CONTROL: '[MENU] SET_MENU_CHECKIN_CONTROL',
    SET_MENU_PROJECT_MANAGEMENT_CONTROL: '[MENU] SET_MENU_PROJECT_MANAGEMENT_CONTROL',
    SET_MENU_PRE_SETTINGS: '[MENU] SET_MENU_PRE_SETTINGS',
    SET_MENU_PERFORMANCE_ASSESSMENTS: '[MENU] SET_MENU_PERFORMANCE_ASSESSMENTS'
};