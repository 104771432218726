import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Modal,
  Slide,
  Typography,
  makeStyles,
} from "@material-ui/core";
import styles from "./FormPreviewModal.module.scss";
import { useTranslation } from "react-i18next";
import BefectiveTooltip from "../../_shared/BefectiveTooltip";
import IconInfo from "../../_icons/IconInfo";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function FormPreviewModal({ openModal, closeModal, skills, updateOptionQuestions }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const [currentSkill, setCurrentSkill] = useState(0);

  const allowNext = (questions) => {
    
    if(!questions || !questions.length) return false;

    let allowedNext = true;

    questions.forEach((question) => {
      if(!question?.scales_options?.find(option => option.selected)){
        allowedNext = false;
      }
    });

    return allowedNext
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${styles.modal}`}
      open={openModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: "#205B6F", opacity: 0.8 },
      }}
      sx={{ border: "none" }}
      style={{ outline: 0 }}
    >
      <Slide
        direction="down"
        in={openModal}
        style={{ outline: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.containerModal}>
          <header>
            <h4>
              {`${currentSkill + 1}. ${skills[currentSkill]?.name || '------'}`}
              {skills[currentSkill]?.description && (
                <BefectiveTooltip
                  title={
                    <Typography component={"div"} variant="caption">
                      {skills[currentSkill]?.description}
                    </Typography>
                  }
                  placement="right-start"
                  arrow
                  interactive
                >
                  <span style={{ cursor: "pointer" }}>
                    &nbsp;&nbsp;
                    <IconInfo />
                  </span>
                </BefectiveTooltip>
              )}
            </h4>
          </header>
          <section>
            {skills[currentSkill]?.questions &&
            skills[currentSkill]?.questions?.length > 0 ? (
              <ol type="A">
                {skills[currentSkill]?.questions?.map((question, index) => (
                  <li key={`question-${index}`}>
                    <p>{question?.questions_content}</p>
                    {
                      question?.is_objective === true 
                      ?                             
                        question?.scales_options &&
                        question?.scales_options?.length > 0 ? (
                          <div>
                            {question?.scales_options?.map((option, index2) => (
                              <button
                                key={`option-button-${index2}`}
                                onClick={() => updateOptionQuestions(skills[currentSkill], question, option)}
                                className={`${option?.selected ? styles.optionActive : ""}`}
                              >
                                {option?.title}
                              </button>
                            ))}
                          </div>
                        ) : (
                          <p>{t("performance_assessment.messages.none_options")}</p>
                        )
                      : 
                      <input type="text" className={styles.inputDiscursive} placeholder={t("colab_page.performance_assessment.form.placeholder_discursive")} value={question?.discursive_content} onChange={(e) => updateOptionQuestions(skills[currentSkill], question, e.target.value)}/>
                    }
                  </li>
                ))}
              </ol>
            ) : (
              <div className={styles.noneBox}>{t("performance_assessment.messages.none_questions")}</div>
            )}
          </section>
          <footer>
            
            <button className={styles.buttonPrevious} onClick={() => currentSkill > 0 && setCurrentSkill(currentSkill - 1)}>
              {t("performance_assessment.actions.previous")}
            </button>
            <button onClick={() => currentSkill < skills.length - 1 && setCurrentSkill(currentSkill + 1)} >
              {t("performance_assessment.actions.next")}
            </button>
          </footer>
        </div>
      </Slide>
    </Modal>
  );
}
