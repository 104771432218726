import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Toolbar from '../_components/_shared/Toolbar'

import SettingsProfile from '../_components/settings/profile/SettingsProfile'
import SettingsProfileSkeleton from '../_components/settings/profile/SettingsProfileSkeleton'
import SettingsPassword from '../_components/settings/profile/SettingsPassword'
import SettingsPasswordSkeleton from '../_components/settings/profile/SettingsPasswordSkeleton'
import Moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import utf8 from 'utf8'
import { useTranslation, initReactI18next } from "react-i18next";

import { statsActions } from '../_actions';

import IconAvatar from '../_components/_icons/IconAvatar';
import Header from '../_components/switch_page/Header';
import { Box } from '@mui/material';

function HomePage(props) {
    const { t, i18n } = useTranslation('common');

    const { statsActions } = props;

    let currentUser = props.userDetails || {}
    let filter = props.filter || {};
    let token = props.token || {};
    let loading = false;

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const user = JSON.parse(window.atob(base64));

    const [isActiveProfile, setActiveProfile] = useState(true);
    const [isActivePassword, setActivePassword] = useState(false);

    const [minimumTime, setMinimumTime] = useState(500);
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);

    function toggleClass(type = null) {

        if (type === "profile") {
            setActiveProfile(true);
            setActivePassword(false);
        }
        if (type === "password") {
            setActivePassword(true);
            setActiveProfile(false);
        }

    };

    useEffect(() => {
        let date = new Date();
        let startDate = Moment(new Date()).format('YYYY-MM-DD');
        let endDate = Moment(new Date()).format('YYYY-MM-DD');

        if (filter.date) {
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }

        statsActions.getStats(startDate, endDate);

    }, [])

    useEffect(() => {
        if (filter.date) {
            statsActions.getStats(filter.date.startDate, filter.date.endDate);
        }

    }, [filter]);

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.settings') + ' | ' + t('menu.profile');
    });

    return (
        <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
            <Header toBackUrl={"/global-settings"} />
            <section className="mainContent">
                {/* <Toolbar /> */}
                <div className="mainFilterbar alt">
                    <button className={isActiveProfile ? 'settingsNav active' : 'settingsNav'} onClick={() => {
                        toggleClass("profile")
                    }}>{t('settings.profile')}</button>
                    <button className={isActivePassword ? 'settingsNav active' : 'settingsNav'} onClick={() => {
                        toggleClass("password")
                    }}>{t('settings.password')}</button>
                </div>
                {/* PAGE STARTS */}
                <div className="mainPage profile">
                    <div className="nocolumn" style={{ overflow: 'hidden' }}>
                        {
                            !minimumTimeElapsed || loading
                                ? <div className="profile alt">
                                    <div className='boxAvatar'>
                                        <div className="avatar">
                                            <Skeleton variant="circle" width={80} height={80} />
                                        </div>
                                        <div className="info">
                                            <div className="name" style={{width:'200px'}}><Skeleton variant="text" width="100%" height={18} /></div>
                                            <div className="company" style={{width:'200px'}}><Skeleton variant="text" width="100%" height={12} /></div>
                                        </div>
                                    </div>
                                </div>
                                : <div className="profile alt">
                                    <div className='boxAvatar'>
                                        <div className="avatar">
                                            <div className="img">
                                                <img
                                                    src={'https://ui-avatars.com/api/?background=24677E&color=ffffff&rounded=true&size=80&name=' + currentUser.name} />
                                            </div>
                                            <button className="change" style={{ display: 'none' }}>
                                                <IconAvatar></IconAvatar>
                                            </button>
                                        </div>
                                        <div className="info">
                                            <div className="name">{utf8.decode(user.name)}</div>
                                            <div className="company">{t(user.profile_name)}</div>

                                        </div>
                                    </div>
                                </div>
                        }

                        <div className="wrapper" style={{ maxHeight: 'initial' }}>
                            <div className={isActiveProfile ? 'tab active' : 'tab'}>
                                {
                                    !minimumTimeElapsed || loading
                                        ? <SettingsProfileSkeleton />
                                        : <SettingsProfile />
                                }
                            </div>
                            <div className={isActivePassword ? 'tab active' : 'tab'}>
                                {
                                    !minimumTimeElapsed || loading
                                        ? <SettingsPasswordSkeleton />
                                        : <SettingsPassword />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* PAGE ENDS */}
            </section>
        </Box>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        statsActions: bindActionCreators(statsActions, dispatch)
    }
}


function mapStateToProps(state) {

    return {
        token: state.authentication.user.accessToken,
        filter: state.filter,
        userDetails: state.authentication.userDetails
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
