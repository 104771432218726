import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export default function UsersTabComponent({ currentActive, userId=undefined }) {

  const { t, i18n } = useTranslation("common");

  return (
    <div className="mainFilterbar">
        
        <div className="filters">
            <NavLink
                to={userId !== undefined ? `/global-settings/users/edit/${userId}` : `/global-settings/users/create`}
                className={currentActive === "user" ? "active" : ""}
            >
                <button className={"settingsNav"}>
                    {t("settings.users.tabs.user")}
                </button>
            </NavLink>
            <NavLink
                to={userId !== undefined && `/global-settings/users/personal/${userId}`}
                className={currentActive === "personal" ? "active" : ""}
            >
                <button className={`settingsNav ${userId === undefined && 'disableTab'}`}>
                    {t("settings.users.tabs.personal")}
                </button>
            </NavLink>
            <NavLink
                to={userId !== undefined && `/global-settings/users/professional/${userId}`}
                className={currentActive === "professional" ? "active" : ""}
            >
                <button className={`settingsNav ${userId === undefined && 'disableTab'}`}>
                    {t("settings.users.tabs.professional")}
                </button>
            </NavLink>
            <NavLink
                to={userId !== undefined && `/global-settings/users/education/${userId}`}
                className={currentActive === "education" ? "active" : ""}
            >
                <button className={`settingsNav ${userId === undefined && 'disableTab'}`}>
                    {t("settings.users.tabs.education")}
                </button>
            </NavLink>
            <NavLink
                to={userId !== undefined && `/global-settings/users/finance/${userId}`}
                className={currentActive === "finance" ? "active" : ""}
            >
                <button className={`settingsNav ${userId === undefined && 'disableTab'}`}>
                    {t("settings.users.tabs.finance")}
                </button>
            </NavLink>
            <NavLink
                to={userId !== undefined && `/global-settings/users/documents/${userId}`}
                className={currentActive === "documents" ? "active" : ""}
            >
                <button className={`settingsNav ${userId === undefined && 'disableTab'}`}>
                    {t("settings.users.tabs.documents")}
                </button>
            </NavLink>
            <NavLink
                to={userId !== undefined && `/global-settings/users/annotations/${userId}`}
                className={currentActive === "annotations" ? "active" : ""}
            >
                <button className={`settingsNav ${userId === undefined && 'disableTab'}`}>
                    {t("settings.users.tabs.annotations")}
                </button>
            </NavLink>
        </div>
    </div>
  );
}
