import React, { useEffect, useMemo, useState } from "react";
import Toolbar from "../../_components/_shared/Toolbar";
import Filterbar from "../../_components/_shared/Filterbar";
import { connect } from "react-redux";
import { alertActions } from "../../_actions";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { authHeader, befectiveFormatDate, getAvatarUrl, history } from "../../_helpers";
import GlobalSkeleton from "../../_components/_shared/GlobalSkeleton";
import EnhancedTable from "../../_components/_shared/EnhancedTable";
import moment from "moment";
import StatusAssessment from "../../_components/performance_assessments/PerformanceAssessmentsCreated/StatusAssessment";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { AvatarGroup } from "@material-ui/lab";
import { Avatar, Box, Popover, Tooltip, IconButton} from "@material-ui/core";
import { api } from "../../_helpers/api";
import { Search, Visibility } from "@material-ui/icons";
import { ReportAssessmentsStatsCard } from "../../_components/performance_assessments/PerformanceAssessmentReports/ReportAssessmentsStatsCard";

function PerformanceAssessmentReportsAssessmentsPage({
  alertActions,
  userDetails,
  filter,
}) {
  const { t, i18n } = useTranslation("common");

  const [fetching, setFetching] = useState(true);

  const [data, setData] = useState({
    stats: null,
    data: []
  });

  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [dateRef, setDateRef] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [currentRow, setCurrentRow] = useState(null);

  async function getData(
    date_ref,
    pageIndex,
    pageSize,
    isLoading,
    teams = null,
    teammates = null
  ) {

    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&teams=${teams.map(t=>t.id).join(',')}` : ''
      const queryTeammates = teammates ? `&users=${teammates.map(t=>t.id).join(',')}` : ''
      const queryFilterYear = date_ref ? `&year=${moment(date_ref).year()}` : ''

      const response = await api.get(`/performance-assessment/reports/assessments?page=${pageIndex}&per_page=${pageSize}${queryFilterYear}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      // const stats = {
      //   total_assessments: response.data.lenght,
      //   total_assessments_done: response.data.reduce((total, current) => total + (current.status.id === 2 ? 1 : 0), 0),
      //   total_assessments_not_done: response.data.reduce((total, current) => total + (current.status.id !== 2 ? 1 : 0), 0)
      // }

      setData(response.data);

      setFetching(false);
      
    } catch (error) {
      console.log(error);
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t(
          "performance_assessment.page_assessment_created.table.col_assessment"
        ),
        accessor: "title",
      },
      {
        Header: t(
          "performance_assessment.page_assessment_created.table.col_start"
        ),
        accessor: "date_start",
        Cell: ({ value }) => {
          return befectiveFormatDate(value, i18n.language);
        },
      },
      {
        Header: t(
          "performance_assessment.page_assessment_created.table.col_completed_at"
        ),
        accessor: "date_end",
        Cell: ({ value }) => {
          return befectiveFormatDate(value, i18n.language);
        },
      },
      {
        Header: t(
          "performance_assessment.page_assessment_created.table.col_users_evaluated"
        ),
        accessor: "total_evaluated",
      },
      // {
      //   Header: t(
      //     "performance_assessment.page_assessment_created.table.col_users_evaluator"
      //   ),
      //   accessor: "evaluators",
      //   Cell: ({ value }) => {
      //     return value && value.length > 0 ? (
      //       <AvatarGroup max={5}>
      //         {value.map((teammate, index) => (
      //           <Tooltip key={`avatar-${index}`} title={teammate?.username}>
      //             <Avatar
      //               alt={teammate?.username}
      //               src={getAvatarUrl(teammate)}
      //               style={{ width: 28, height: 28 }}
      //             />
      //           </Tooltip>
      //         ))}
      //       </AvatarGroup>
      //     ) : (
      //       "----"
      //     );
      //   },
      // },
      {
        Header: t(
          "performance_assessment.page_assessment_created.table.col_progress"
        ),
        accessor: "progress_percent",
        Cell: ({ value, ...rest }) => {
          const currentRow = rest.row.original;
          return (
            <div className="boxCellCircularProgress">
              <div className={`containerCircularProgress`}>
                <CircularProgressbar
                  value={Number(value)}
                  strokeWidth={50}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: currentRow.status.text_color,
                    trailColor: "#E4EAEF",
                  })}
                />
              </div>
              <div>{currentRow.progress}</div>
            </div>
          );
        },
      },
      {
        Header: t(
          "performance_assessment.page_assessment_created.table.col_status"
        ),
        accessor: "status",
        style: { padding: "0px 12px" },
        Cell: ({ value }) => {
          return (
            <StatusAssessment
              title={value["title_" + i18n.language]}
              textColor={value.text_color}
              bgColor={value.bg_color}
            />
          );
        },
      },
    ];
  }, []);

  const getDataAssessments = ({pageIndex, pageSize, filter}) => {
    
    let date_ref = dateRef;

    if (filter.singleDate) {
      date_ref = filter.singleDate;
    }

    setDateRef(date_ref);

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getData(date_ref, pageIndex, pageSize, true, teams, teammates);
  };

  const handleRowClick = (row) => {
    return history.push(`/performance-assessment/reports/assessments/${row.id}`);
  };

  useEffect(() => {
    getData(
      dateRef,
      0,
      localStorage.getItem("pageSizeStorage") || 20,
      false,
      null,
      null
    );
    document.title = `Befective | ${t('menu.performance_assessment.reports')} | ${t('menu.performance_assessment.assessments')} `;
  }, []);

  return (
    <section className="mainContent">
      <Toolbar />

      <Filterbar
        filterYears={"single"}
        filterWho={"multi"}
        filterWhen={"disabled"}
        filterWhat={"disabled"}
      />

      <main className="mainContentPage">
        {!fetching ? (
          <div>
            <ReportAssessmentsStatsCard stats={data.stats}/>
            <EnhancedTable
              columns={columns}
              data={data.data}
              pageCountRows={pageCountRows}
              count={pageCount}
              filter={filter}
              fetchData={getDataAssessments}
              actionColumn={(row) => (
                <div>

                  <IconButton
                    className={`button-options`}
                    aria-label="expand row"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(row);
                    }}
                  >
                    <Search style={{color: "#4F7091"}} fontSize="small" />
                  </IconButton>
                </div>
              )}
              onRowClick={handleRowClick}
            />
          </div>
        ) : (
          <GlobalSkeleton
            totalRowsStats={1}
            totalTables={1}
            heightTable={600}
          />
        )}
      </main>
    </section>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceAssessmentReportsAssessmentsPage);
