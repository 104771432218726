import { CheckSquare, Clock, Link, TextAlignLeft } from 'phosphor-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Badge, Typography } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import { AvatarGroup } from '@material-ui/lab';
import { Box, Tooltip } from '@mui/material';
import { befectiveFormatDate, getAvatarUrl } from '../../../../_helpers';
import './styles.scss';

export function KanbanCard({ card, list, showModalCard, provided, innerRef }) {

  const { t, i18n } = useTranslation('common');

  return (
    <div
      className='kanban-card'
      onClick={() => showModalCard(card, list)}
      ref={innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {
        card.tags.length > 0 &&
        <Box display={'flex'} gap={'4px'} marginBottom={'8px'} flexWrap={'wrap'}>
          {
            card.tags.map((tag) => (
              <Tooltip
                title={`${t('project_management.kanban.cardDetails.label_title')}: ${tag.title}`}
                style={{ cursor: 'pointer' }}
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -5],
                      },
                    },
                  ],
                }}
              >
                <Box key={`tag-${tag.id}`} sx={{ minWidth: '50px', height: '10px', borderRadius: '6px', backgroundColor: tag.color, cursor: 'pointer' }}></Box>
              </Tooltip>
            ))
          }
        </Box>
      }
      <span style={{ margin: '4px 0px'}}>
        {card?.title}
      </span>
      <Box display={'flex'} marginTop={'6px'}>
        <Box marginTop={'4px'} display={'flex'} justifyContent={'flex-start'} gap={'6px'} flexWrap={'wrap'} width={'70%'}>
          {
            card.end &&
            <Box className={'badgeCard'} display={'flex'} alignItems={'center'} component={'div'} gap={'2px'} title={t('project_management.kanban.tooltip_title.date_delivery')}>
              <Clock size={20} color="#1E6B7F" weight="regular" />
              <Typography style={{ fontSize: '12px', paddingTop: '2px' }} variant='caption' component={'span'}>{befectiveFormatDate(card.end, i18n.language, 'H')}</Typography>
            </Box>
          }
          {
            card.checklists.length > 0 &&
            <Box component={'div'} title={t('project_management.kanban.tooltip_title.checklist')} className={'badgeCard'} display={'flex'} alignItems={'center'}>
              <CheckSquare size={20} color="#1E6B7F" weight="regular" />
              <Typography style={{ fontSize: '12px' }} variant='caption' component={'span'}>{`${card.total_checklist_done}/${card.total_checklists}`}</Typography>
            </Box>
          }
          {
            card.task_id &&
            <Box className={'badgeCard'} component={'div'} title={t('project_management.kanban.tooltip_title.association')}>
              <Link size={20} color="#1E6B7F" weight="regular" />
            </Box>
          }
          {
            card.description !== '' &&
            <Box className={'badgeCard'} component={'div'} title={t('project_management.kanban.tooltip_title.description')}>
              <TextAlignLeft size={20} color="#1E6B7F" weight="regular" />
            </Box>
          }
          {
            card.total_attachments > 0 &&
            <Box className={'badgeCard'} component={'div'} title={t('project_management.kanban.tooltip_title.attachments')}>
              <Badge badgeContent={card.total_attachments} color="default" style={{ fontSize: '12px' }}>
                <AttachFile />
              </Badge>
            </Box>
          }
        </Box>
        {
          card.members.length > 0 &&
          <Box display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'} flexWrap={'wrap'} width={'30%'}>
            <AvatarGroup>
              {card.members.map((member, index) => (
                <Tooltip
                  key={index}
                  title={member?.username}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  }}
                >
                  <Avatar alt={member?.username} src={getAvatarUrl(member)} style={{ width: 24, height: 24 }} />
                </Tooltip>
              ))
              }
            </AvatarGroup>
          </Box>
        }
      </Box>
    </div>
  );
}
