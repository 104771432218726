import React, { useEffect, useState } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from 'react-table';
import styles from './EnhancedTable.module.scss';
import { Pagination } from '@material-ui/lab';
import { ArrowDown, ArrowUp } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

const EnhancedTable = ({
  columns,
  data,
  fetchData,
  count,
  pageCountRows,
  filter,
  actionColumn,
  title,
  enablePagination = false,
  onRowClick = null,
  enableRowSelection = false, // New prop to control row selection
  onSelectedRowsChange = null, // Callback for selected rows
  newItemButton = {
    label: '',
    onClick: () => {},
    enable: false
  }
}) => {
  const { t } = useTranslation('common');

  const [firstLoading, setFirstLoading] = useState(true);

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: enablePagination,
      pageCount: enablePagination ? count : data.length,
      autoResetPage: false,
      initialState: {
        pageIndex: 0,
        pageSize: localStorage.getItem('pageSizeStorage') || 20,
      },
      pageOptions: Array.from({ length: enablePagination ? count : 1 }, (_, index) => index),
    },
    useGlobalFilter,
    useSortBy,
    enablePagination && usePagination,
    useRowSelect,
    hooks => {
      if (enableRowSelection) {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <label className={styles.customCheckbox}>
                  <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
                  <span className={`${styles.checkmark} ${styles.checkmarkGreen}`}></span>
                </label>
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <label className={styles.customCheckbox}>
                  <input type="checkbox" {...row.getToggleRowSelectedProps()} />
                  <span className={`${styles.checkmark} ${styles.checkmarkGreen}`}></span>
                </label>
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  const pageOptions = [
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 300, label: '300' },
    { value: 400, label: '400' },
    { value: 500, label: '500' },
    { value: pageCountRows, label: 'all' },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, sortBy, globalFilter, selectedRowIds },
  } = tableInstance;

  useEffect(() => {
    if (firstLoading) {
      setFirstLoading(false);
      return;
    }
    fetchData({ pageIndex, pageSize, filter });
  }, [pageIndex, pageSize, filter]);

  useEffect(() => {
    if (onSelectedRowsChange) {
      onSelectedRowsChange(selectedFlatRows.map(row => row.original));
    }
  }, [selectedRowIds]);

  const rowsToRender = enablePagination ? page : rows;

  return (
    <div className={styles.tableContainer}>
      {
        title &&
        <div className={styles.titleTable}>
          <h3 className={styles.title}>{title}</h3>
        </div>
      }
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  title={t('title_sort')}
                  className={styles.headColTable}
                  style={{ ...column.style, textAlign: column.style?.textAlign || 'left' }}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <ArrowDown size={10} color="#1E6B7F" weight="bold" />
                    ) : (
                      <ArrowUp size={10} color="#1E6B7F" weight="bold" />
                    )
                  ) : null}
                </th>
              ))}
              {actionColumn && (
                <td style={{ textAlign: 'center', width:'10px' }} className={styles.headColTable}/>
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(rowsToRender.length > 0 || newItemButton?.enable) ? (
            <>
              {
              rowsToRender.map((row) => {
                prepareRow(row);
                return (
                    <tr 
                      {...row.getRowProps()}
                      onClick={onRowClick ? () => onRowClick(row.original) : null}
                      style={{ cursor: onRowClick ? 'pointer' : 'default' }}
                      className={`${onRowClick ? styles.rowHover : ''}`} 
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            ...cell.column.style,
                            textAlign: cell.column.style?.textAlign || 'left',
                            fontSize:  cell.column.style?.fontSize || '14px',
                            color: cell.column.style?.color || '#4F7091',
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                      {actionColumn && (
                        <td style={{ textAlign: 'center' }}>
                          {actionColumn(row.original)}
                        </td>
                      )}
                    </tr>
                );
              })
              }
              {
                newItemButton?.enable && (
                  <tr>
                    <td colSpan={columns.length + (actionColumn ? 1 : 0)}>
                      <button className={styles.buttonNewItem} onClick={newItemButton.onClick}>
                        {newItemButton?.label}
                      </button> 
                    </td>
                  </tr>                      
                )
              }
            </>
          ) : (
            <tr>
              <td colSpan={columns.length + (actionColumn ? 1 : 0)}>
                  {t('generic_messages.none_data')}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {enablePagination && (
        <div className={styles.containerPagination}>
          <Pagination
            count={tableInstance.pageCount}
            page={pageIndex + 1}
            onChange={(event, page) => {
              gotoPage(page - 1);
            }}
            variant="outlined"
            shape="rounded"
            siblingCount={1}
            boundaryCount={1}
          />
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              localStorage.setItem('pageSizeStorage', Number(e.target.value));
            }}
            className={styles.selectOptions}
          >
            {pageOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label === 'all'
                  ? t('settings.teams.show_all')
                  : `${t('settings.teams.show')} ${option.label}`}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default EnhancedTable;
