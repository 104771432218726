import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';
import { FormControl, MenuItem, Select, OutlinedInput} from '@material-ui/core';
import SkillTypesFilterSkeleton from './SkillTypesFilterSkeleton';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      width: "250px",
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      backgroundColor: "#1E6B7F",
      marginTop: '6px'
    },
  },

};

const SkillTypesFilter = ({
    filterSkills = () => {},
    skillTypes = [],
}) => {
    const { t, i18n } = useTranslation('common');
    const [skillTypeSelected, setSkillTypeSelected] = useState("all");

    const filterTypeSkill = (value) => {
        setSkillTypeSelected(value);
        filterSkills(true, value);
    }

    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                    displayEmpty
                    className={'customSelectMulti'}
                    style={{minWidth:'250px', backgroundColor:'#DCE4EA', height:'45px', color: '#4F7091', fieldset: { border: 'transparent', height: '45px' }, div: { color: '#4F7091' }, label: { top: '-4px' } }}
                    value={skillTypeSelected}
                    input={
                        <OutlinedInput
                            style={{ width: '100%', height: '45px', fieldset: { border: 'transparent', height: '45px' }, div: { color: '#4F7091' }, label: { top: '-4px' } }}
                        />
                        }
                    onChange={(event) => filterTypeSkill(event.target.value)}
                    MenuProps={MenuProps}
                >
                    <MenuItem value="all" style={{color: "#fefefe" }}>  
                        {t('global_options.all')}
                    </MenuItem>
                    {skillTypes.map((skillType) => (
                        <MenuItem style={{color: "#fefefe" }} key={skillType.id} value={skillType.id}>{skillType[`label_${i18n.language}`]}</MenuItem>
                    ))}
                </Select>
            </FormControl>               
        </div>
    )
}

export default SkillTypesFilter;