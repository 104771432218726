import React, { useEffect, useState } from "react";
import Toolbar from "../../_components/_shared/Toolbar";
import { connect } from "react-redux";
import { alertActions } from "../../_actions";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import TabsFormComponent from "../../_components/performance_assessments/PerformanceAssessmentsNew/TabsFormComponent";
import { authHeader, getIntroExample, history, updateQueryParams, useQuery } from "../../_helpers";
import NewAssessmentForm from "../../_components/performance_assessments/PerformanceAssessmentsNew/NewAssessmentForm";
import GlobalSkeleton from "../../_components/_shared/GlobalSkeleton";
import { api } from "../../_helpers/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

function PerformanceAssessmentsNewPage({
  alertActions,
  userDetails,
  filter,
}) {
  const { t, i18n } = useTranslation("common");
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState("apresentation");
  const [assessmentData, setAssessmentData] = useState(null);
  const [fetchingAssessmentData, setFetchingAssessmentData] = useState(false);
  const [globalData, setGlobalData] = useState(null);
  const [fetchingGlobal, setFetchingGlobal] = useState(true);
  const [teams, setTeams] = useState([]);
  const [fetchingTeams, setFetchingTeams] = useState(true);  
  const lang = userDetails && userDetails.language ? userDetails.language : i18n.language;

  const [formAssessment, setFormAssessment] = useState({
    id: null,
    title: "",
    subtitle: "",
    introduction: getIntroExample(lang),
    performance_assessment_notification_id: "",
    date_start: moment().format("YYYY-MM-DD"),
    date_end: moment().endOf('month').format("YYYY-MM-DD"),
    allow_responses_after_deadline: false,
    performance_assessment_periodization_id: "",
    performance_assessment_scope_id: 1,
    self_assessment: true,
    participants: [],
    result_indicators_id: '',
    show_result_table: true,
    show_avg_score_result_table: true,
    show_radar: true,
    show_general_stats: true,
    allowed_todo_keep_doing: true,
    allow_todo_should_do: true
  });
  
  
  const [skillsAssessment, setSkillsAssessment] = useState([]);

  const updateTabValue = (tab) => {
    setCurrentTab(tab)
  }

  const getAssessmentData = async () => {

    try {
      setFetchingAssessmentData(true);
      const res = await api.get(`/performance-assessment/retrieve/${id}`, {
        headers: authHeader()
      })
      const skillsFiltered = res.data?.skills && res?.data?.skills?.length > 0 ? res?.data?.skills?.filter(skill => skill?.active) : []
      setSkillsAssessment(skillsFiltered)
      setAssessmentData(res.data)
      localStorage.removeItem("formAssessment")
    } catch (error) {
      console.log(error)
      alertActions.error(error.response.data)
    }finally{
      setFetchingAssessmentData(false)
    }
  }

  const getGlobalData = async () => {

    try {
      setFetchingGlobal(true);
      const res = await api.get('/performance-assessment/select-data', {
        headers: authHeader()
      })
      setGlobalData(res.data)
      if(!id){
        const skillsLocalStorage = JSON.parse(localStorage.getItem("skillsAssessment"))
        if(skillsLocalStorage && skillsLocalStorage?.length > 0){
          const matchingSkills = res.data?.skills?.map((skill) => {
            const matchingSkill = skillsLocalStorage?.find(localSkill => localSkill?.id === skill?.id)
            if(matchingSkill){
              return {
                ...matchingSkill,
                questions: skill?.questions
              }
            }
            return skill
          }).sort((a, b) => a?.id - b?.id)

          setSkillsAssessment(matchingSkills)
        }else{
          setSkillsAssessment(res.data?.skills || [])
        }
      }
    } catch (error) {
      console.log(error)
      alertActions.error(error.response.data)
    }finally{
      setFetchingGlobal(false)
    }
  }

  const getTeams = async () => {

    try {
      setFetchingTeams(true);
      const res = await api.get('/teams', {
        headers: authHeader()
      })
      setTeams(res.data)
    } catch (error) {
      console.log(error)
      alertActions.error(error.response.data)
    }finally{
      setFetchingTeams(false)
    }
  }

  useEffect(() => {
    document.title = `Befective | ${t("menu.performance_assessment.assessments")}`;
    getGlobalData()
    getTeams()
    if(id){
      getAssessmentData()
    }else{
      const formLocalStorage = JSON.parse(localStorage.getItem("formAssessment"))
      if(formLocalStorage){
        setFormAssessment(formLocalStorage)
      }else{
        setFormAssessment((prevState) => ({...prevState}))
      }
    }
  }, [id]);

  useEffect(() => {
    if(!id){
      if(formAssessment.title !== ''){
        localStorage.setItem("formAssessment", JSON.stringify(formAssessment));
      }
      if(!formAssessment.title){
        localStorage.removeItem("formAssessment");
      }
    }else{
      localStorage.removeItem("formAssessment");
    }
  }, [formAssessment]);

  useEffect(() => {

    if(assessmentData){
      setFormAssessment({
        id: assessmentData?.id || null,
        title: assessmentData?.title || "",
        subtitle: assessmentData?.subtitle || "",
        introduction: assessmentData?.introduction || getIntroExample(lang),
        performance_assessment_notification_id: assessmentData?.performance_assessment_notification_id || "",
        date_start: assessmentData?.date_start || moment().format("YYYY-MM-DD"),
        date_end:  assessmentData?.date_end ||  moment().endOf('month').format("YYYY-MM-DD"),
        allow_responses_after_deadline: assessmentData?.allow_responses_after_deadline || false,
        performance_assessment_periodization_id: assessmentData?.performance_assessment_periodization_id || "",
        performance_assessment_scope_id: assessmentData?.performance_assessment_scope_id || 1,
        self_assessment: assessmentData ? assessmentData?.self_assessment : true,
        participants: assessmentData?.evaluated || [],
        result_indicators_id: assessmentData?.result_indicators_id || '',
        show_result_table: assessmentData ? assessmentData?.show_result_table : true,
        show_avg_score_result_table: assessmentData ? assessmentData?.show_avg_score_result_table : true,
        show_radar: assessmentData ? assessmentData?.show_radar : true,
        show_general_stats: assessmentData ? assessmentData?.show_general_stats : true,
        allowed_todo_keep_doing: assessmentData ? assessmentData?.allowed_todo_keep_doing : true,
        allow_todo_should_do: assessmentData ? assessmentData?.allow_todo_should_do : true
      })
    }
  }, [assessmentData]);

  return (
    <section className="mainContent">

      <Toolbar />

      <TabsFormComponent 
        hasEdit={id ? true : false}
        currentTab={currentTab} 
        updateTabValue={updateTabValue}
        formAssessment={formAssessment}
        skillsAssessment={skillsAssessment}
      />

      {
        (!fetchingGlobal && !fetchingTeams && !fetchingAssessmentData)
        ?
          <NewAssessmentForm
            assessmentData={assessmentData}
            alertActions={alertActions}
            userDetails={userDetails}
            currentTab={currentTab}
            updateTabValue={updateTabValue}
            filter={filter}
            globalData={globalData}
            teams={teams}
            formAssessment={formAssessment}
            setFormAssessment={setFormAssessment}
            skillsAssessment={skillsAssessment}
            setSkillsAssessment={setSkillsAssessment}
          />
        :
        <GlobalSkeleton
          totalRowsStats={0}
          totalTables={1}
          heightTable={600}          
          margin={'0px 16px 16px'}
        />
      }


    </section>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceAssessmentsNewPage);
