import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import style from './styles.module.scss';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { FormProfiles } from '../../_components/profiles_settings/FormProfiles';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import { Box } from '@mui/material';
import Header from '../../_components/switch_page/Header';


function ProfilesSettingsFormPage(props) {
  const { t, i18n } = useTranslation('common');

  const { userDetails, teammates, teammatesActions, alertActions } = props
  const [profileToEdit, setProfileToEdit] = useState(null)
  const [loading, setLoading] = useState(false);
  const [optionsPermissions, setOptionsPermissions] = useState([])
  const [optionsGroups, setOptionsGroups] = useState([])
  const { id } = useParams();

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const getProfileById = async (withLoading = true, id = null) => {
    try {
      if (withLoading) {
        setLoading(true)
      }
      const filterId = id ? `?id=${id}` : ''
      const { data } = await api.get(`/settings/access-profile${filterId}`, { headers: authHeader() })
      setProfileToEdit(data)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getGroups = async () => {
    try {
      const resResponsibles = await api.get(`/goals/get-groups?organization_id=${userDetails.organizationId}`, {
        headers: authHeader()
      })
      setOptionsGroups(resResponsibles?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getOptionsPermissions = async () => {
    try {
      setLoading(true)
      const { data } = await api.get(`/settings/permission-options`, {
        headers: authHeader()
      })
      setOptionsPermissions(data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    getGroups()
    getOptionsPermissions()
  }, [])

  return (
    <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
      <Header toBackUrl={"/global-settings/profiles"} />
      <section className="mainContent productivity">

        {/* <Toolbar /> */}

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
        />

        {/* PAGE STARTS */}
        <div className={style.containerRoomsPage}>
          {
            (loading==false && optionsPermissions.length > 0)
              ?
              <FormProfiles alertActions={alertActions} userDetails={userDetails} profileToEdit={profileToEdit} optionsGroups={optionsGroups} optionsPermissions={optionsPermissions} />
              :
              <GlobalSkeleton totalRowsStats={0} totalTables={1} />
          }
        </div>
        {/* PAGE ENDS */}

      </section>
    </Box>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilesSettingsFormPage)