import React, { useEffect, useState } from 'react'
import styles from './ActionPlanForm.module.scss'
import { useTranslation } from 'react-i18next';
import ButtonCreate from '../../_shared/ButtonCreate';
import { Avatar, Box, CircularProgress, Grid, TextField } from '@mui/material';
import { authHeader, befectiveFormatDate, getAvatarUrl, history } from '../../../_helpers';
import SelectResponsibles from '../../../pages/CheckinSettings/SelectResponsibles';
import { formatCurrencyByEnd } from '../../../_helpers/formattValue';
import { NumberFormatBase } from 'react-number-format';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import RowNewItem from './RowNewItem';
import { api } from '../../../_helpers/api';
import { DotsThreeOutlineVertical } from 'phosphor-react';
import { Popover } from '@material-ui/core';
import PopoverConfirm from '../../teams_settings/PopoverConfirmDelete';

export default function ActionPlanForm({
  selectData = null,
  actionPlanData = null,
  teammates = [],
  evaluatedId = null,
  actionPlanId=null,
  getDataForm=null,
  new_action_plan=null,
  alertActions,
  assessments = [],
  evaluateds = [],
  getActionPlanCreateSelectData = async () => {},
}) {
  
  const { t, i18n } = useTranslation("common");
  const [disableFields, setDisableFields] = useState(actionPlanData?.id !== undefined ? true : false);
  const [disableSelectStatus, setDisableSelectStatus] = useState(false);
  const optionsResponsibles = teammates.map((item) => {
    return {
      value: item.id,
      label: item.name
    }
  })

  const [enableAddTodo, setEnableAddTodo] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [form, setForm] = useState({
    id: actionPlanData?.id || null,
    performance_assessment_evaluated_id: actionPlanData?.performance_assessment_evaluated_id || evaluatedId,
    performance_assessment_action_plan_status_id: actionPlanData?.performance_assessment_action_plan_status_id || "",
    performance_assessment_action_plan_categories_id: actionPlanData?.performance_assessment_action_plan_categories_id || "",
    responsible_id: actionPlanData?.responsible_id || "",
    name: actionPlanData?.name || "",
    objective: actionPlanData?.objective || "",
    expected_outcome: actionPlanData?.expected_outcome || "",
    date_start: actionPlanData?.date_start || null,
    date_end: actionPlanData?.date_end || null,
    budget: actionPlanData?.budget || 0,
    todo_list: actionPlanData?.todo_list || [],
    assessment: actionPlanData?.assessment || "",
    evaluated: actionPlanData?.evaluated || "",
  });

  const [formError, setFormError] = useState({
    performance_assessment_action_plan_status_id: "",
    performance_assessment_action_plan_categories_id: "",
    responsible_id: "",
    name: "",
    objective: "",
    expected_outcome: "",
    date_start: "",
    date_end: "",
    budget: "",
    assessment: "",
    evaluated: ""
  });
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "actions-popover" : undefined;

  const handleClickActions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const [anchorElConfirmCancelPlan, setAnchorElConfirmCancelPlan] = useState(null);
  const openConfirmCancelPlan = Boolean(anchorElConfirmCancelPlan);
  const idConfirmCancelPlan = openConfirmCancelPlan ? 'popover-confirm-cancel-plan' : undefined;

  const handleClickConfirmCancelPlan = (event) => {
    setAnchorElConfirmCancelPlan(event.currentTarget);
  };

  const handleCloseConfirmCancelPlan = () => {
    setAnchorElConfirmCancelPlan(null)
  };
  
  const handleConfirmCancelPlan = async () => {
    try {
      if (!actionPlanData.id) {
        throw new Error('Action plan to delete not found')
      }

      const res = await api.delete(`/performance-assessment/action-plan/${actionPlanData.id}`, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro to delete assessment')
      }
      getDataForm()
      handleCloseConfirmCancelPlan()
      handleCloseActions()
      
      alertActions.success(t('performance_assessment.messages.success_cancel_plan'))
    } catch (error) {
      alertActions.error(error.response.data)
      console.error(error)
    }
  }
  
  function getDataFormat(lang) {
    if (lang === 'en') {
      return "MM/DD/YYYY"
    } else {
      return "DD/MM/YYYY"
    }
  }  

  const updateStatus = async (form) => {
    try {
      if(!form.id){
        throw new Error('Action plan not found')
      }
      setDisableSelectStatus(true)
      
      const response = await api.post("/performance-assessment/action-plan", form, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error('Erro to update assessment')
      }

      alertActions.success(t('generic_messages.success.update'))

      setDisableSelectStatus(false)

    }catch (error) {
      alertActions.error(error.response.data)
      console.error(error)
      setDisableSelectStatus(false)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value
    })

    setFormError({
      ...formError,
      [name]: ""
    })

    if(name === "performance_assessment_action_plan_status_id"){
      updateStatus({
        ...form,
        performance_assessment_action_plan_status_id: value
      })
    }
  }

  const handleChangeAssessment = async (value) => {
    setForm({
      ...form,
      assessment: value,
      evaluated: ""
    })
    setFormError({
      ...formError,
      assessment: ""
    })
    await getActionPlanCreateSelectData(value)
  }

  const validateForm = (form) => {
    const errors = {};

    if(form.performance_assessment_action_plan_categories_id === "") {
      errors.performance_assessment_action_plan_categories_id = t("validation.required")
    }

    if(form.responsible_id === "") {
      errors.responsible_id = t("validation.required")
    }

    if(form.name === "") {
      errors.name = t("validation.required")
    }

    if(form.objective === "") {
      errors.objective = t("validation.required")
    }

    if(form.expected_outcome === "") {
      errors.expected_outcome = t("validation.required")
    }

    if(!form.date_start) {
      errors.date_start = t("validation.required")
    }

    if(!form.date_end) {
      errors.date_end = t("validation.required")
    }

    if(form.budget === 0) {
      errors.budget = t("validation.required")
    }    

    if(new_action_plan && new_action_plan === "new_action_plan" && form.assessment === "") {
      errors.assessment = t("validation.required")
    }

    if(new_action_plan && new_action_plan === "new_action_plan" && form.evaluated === "") {
      errors.evaluated = t("validation.required")
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const saveActionPlan = async () => {
    try{
      setIsSubmitting(true)

      const evaluated_id = new_action_plan && new_action_plan === "new_action_plan" ? form.evaluated : form.performance_assessment_evaluated_id

      const payload = {
        ...form,
        performance_assessment_evaluated_id: evaluated_id
      }
      const response = await api.post("/performance-assessment/action-plan", payload, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error('Erro to create action plan')
      }

      if(new_action_plan && new_action_plan === "new_action_plan") {
        history.push('/performance-assessment/action-plan')
        alertActions.success(t('performance_assessment.messages.success_create_action_plan'))
        setIsSubmitting(false)
        return
      }

      if(actionPlanData?.id === undefined) {
        getDataForm()
        alertActions.success(t('performance_assessment.messages.success_create_action_plan'))
      }
      alertActions.success(t('performance_assessment.messages.success_update_action_plan'))
      setIsSubmitting(false)
    }catch(error) {
      console.log(error)
      setIsSubmitting(false)
      alertActions.error(error.response.data)
    }
  }

  useEffect(() => {
    if (Object.keys(formError).length === 0 && submitted) {
      saveActionPlan()
    } else {
      setSubmitted(false)
    }
  }, [formError, submitted])


  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.cardContainer}>
        <header className={styles.cardHeader}>
          {
            (new_action_plan && new_action_plan === "new_action_plan") 
            ? 
            <div className={styles.infoUser}>
              <h4 className={styles.title}>{t("performance_assessment.page_action_plan.header.create_new_action_plan")}</h4> 
            </div>
            : 
            <div className={styles.infoUser}>
              <div className={styles.avatarContainer}>
                <Avatar
                  alt={actionPlanData?.evaluated_name}
                  src={
                    getAvatarUrl({
                      name: actionPlanData?.evaluated_name || "-----",
                    },
                      "187DFF"
                    )
                  }
                  style={{ width: 72, height: 72 }}
                  title={actionPlanData?.evaluated_name || "-----"}
                />
              </div>
              <div className={styles.infoUserContent}>
                <h6 className={styles.title}>{actionPlanData?.evaluated_name}</h6>
                <div className={styles.listInfo}>
                  <span>{t("performance_assessment.page_action_plan.header.position")}: {actionPlanData?.office || "-----"}</span>
                  <span>{t("performance_assessment.page_action_plan.header.created_at")}: {actionPlanData?.created_at ? befectiveFormatDate(actionPlanData?.created_at, i18n.language) : "-----"}</span>
                </div>
              </div>
            </div>
          }
          <div className={styles.boxButtonHeader}>
            {
              actionPlanData?.id == undefined
              ?
              <button className={styles.buttonCreate} type="submit" disabled={isSubmitting}>
                {
                  isSubmitting
                    ? <CircularProgress size={18} />
                    : <span>{t("performance_assessment.page_action_plan.header.create_action_plan")}</span>
                }
              </button>
              :
              <>
                <div className={styles.boxSelectStatus}>
                  <label htmlFor="performance_assessment_action_plan_status_id">Staus:</label>
                  <select
                    className={styles.customSelect} 
                    id="performance_assessment_action_plan_status_id"
                    name="performance_assessment_action_plan_status_id"
                    onChange={handleChange}
                    value={form?.performance_assessment_action_plan_status_id}
                    disabled={disableSelectStatus}
                  >
                    {
                      (selectData && selectData.status.length > 0) &&
                      selectData.status?.map((status, index) => (
                        <option key={`status-${index}`} value={status.id}>{status[`label_${i18n.language}`]}</option>
                      ))
                    }
                  </select>
                </div>
                {
                  disableFields
                  ?                 
                  <button className={styles.buttonThreePoints} type="button" onClick={handleClickActions}>
                    <DotsThreeOutlineVertical size={18} weight="fill"/>
                  </button>
                  :
                  <>
                    <button className={`${styles.button} ${styles.buttonCancel}`} type="button" disabled={isSubmitting} onClick={() => setDisableFields(true)}>
                      <span>{t("performance_assessment.actions.cancel")}</span>
                    </button>
                    <button className={`${styles.button} ${styles.buttonSave}`} type="submit" disabled={isSubmitting}>
                      {
                        isSubmitting
                          ? <CircularProgress size={18} />
                          : <span>{t("performance_assessment.actions.save")}</span>
                      }
                    </button>
                  </>
                }
              </>
            }
          </div>
        </header>
        
        <Box component={"main"} sx={{ flexGrow: 1, padding: '24px' }}>
          {
            (new_action_plan && new_action_plan === "new_action_plan") && (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Box className={styles.field}>
                    <label htmlFor='assessment'>{t("performance_assessment.page_action_plan.form.assessment_field")}*</label>
                    
                    <select  
                      className={styles.customSelect} 
                      name="assessment" 
                      id="assessment" 
                      onChange={(e) => handleChangeAssessment(e.target.value)} 
                      value={form?.assessment}
                      disabled={disableFields}                  
                      >

                      <option value="" disabled>{t("performance_assessment.page_action_plan.form.select")}</option>

                      {assessments?.map((item) => (
                        <option key={`assesment_${item.value}`} value={item.value}>{item.label}</option>
                      ))}

                    </select>

                    {formError?.assessment && <span className={styles.error}>{formError.assessment}</span>}
                    
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className={styles.field}>
                    <label htmlFor='evaluated'>{t("performance_assessment.page_action_plan.form.evaluated_field")}*</label>
                    
                    <select  
                      className={styles.customSelect} 
                      name="evaluated" 
                      id="evaluated" 
                      onChange={handleChange} 
                      value={form?.evaluated}
                      disabled={disableFields}                  
                      >

                      <option value="" disabled>{t("performance_assessment.page_action_plan.form.select")}</option>

                      {evaluateds?.map((item) => (
                        <option key={`evaluated_${item.value}`} value={item.value}>{item.label}</option>
                      ))}

                    </select>

                    {formError?.evaluated && <span className={styles.error}>{formError.evaluated}</span>}
                    
                  </Box>
                </Grid>
              </Grid>
            )
          }
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box className={styles.field}>
                <label htmlFor='name'>{t("performance_assessment.page_action_plan.form.title_field")}*</label>
                <input
                  id='name'
                  name='name'
                  type='text'
                  placeholder={t("performance_assessment.page_action_plan.form.title_placeholder")}
                  onChange={handleChange}
                  value={form?.name}
                  disabled={disableFields}
                />
                {formError?.name && <span className={styles.error}>{formError.name}</span>} 
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box className={styles.field}>
                <label htmlFor='objective'>{t("performance_assessment.page_action_plan.form.target_plan_field")}*</label>
                <input
                  id='objective'
                  name='objective'
                  type='text'
                  placeholder={t("performance_assessment.page_action_plan.form.target_plan_placeholder")}
                  onChange={handleChange}
                  value={form?.objective}
                  disabled={disableFields}
                />                
                {formError?.objective && <span className={styles.error}>{formError.objective}</span>} 
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={styles.field}>
                <label htmlFor='responsible_id'>{t("performance_assessment.page_action_plan.form.responsible_field")}*</label>
                
                <select  
                  className={styles.customSelect} 
                  name="responsible_id" 
                  id="responsible_id" 
                  onChange={handleChange} 
                  value={form?.responsible_id}
                  disabled={disableFields}                  
                  >

                  <option value="" disabled>{t("performance_assessment.page_action_plan.form.responsible_placeholder")}</option>

                  {optionsResponsibles?.map((item) => (
                    <option key={`responsible_${item.value}`} value={item.value}>{item.label}</option>
                  ))}

                </select>

                {formError?.responsible_id && <span className={styles.error}>{formError.responsible_id}</span>}
                
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Box className={styles.field}>
                <label htmlFor='performance_assessment_action_plan_categories_id'>{t("performance_assessment.page_action_plan.form.category_field")}*</label>
                <select 
                  className={styles.customSelect} 
                  name="performance_assessment_action_plan_categories_id" 
                  id="performance_assessment_action_plan_categories_id" 
                  onChange={handleChange} 
                  value={form?.performance_assessment_action_plan_categories_id}
                  disabled={disableFields}
                  >

                  <option value="" disabled>{t("performance_assessment.page_action_plan.form.select")}</option>

                  {
                    (selectData && selectData.categories.length > 0) &&
                    selectData.categories?.map((category, index) => (
                      <option key={`category-${index}`} value={category.id}>{category[`label_${i18n.language}`]}</option>
                    ))
                  }

                </select>
                
                {formError?.performance_assessment_action_plan_categories_id && <span className={styles.error}>{formError.performance_assessment_action_plan_categories_id}</span>}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={styles.field}>
                <label htmlFor='budget'>{t("performance_assessment.page_action_plan.form.budget_field")}*</label>
                <NumberFormatBase
                  id="budget"
                  format={formatCurrencyByEnd}
                  placeholder={"1000.00"}
                  inputMode="numeric"
                  displayType="input"
                  disabled={disableFields}
                  value={
                    !form.budget || !Number(form.budget) ? "" : Number(form.budget) * 100
                  }
                  isAllowed={values => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue / 100 <= 10000000000;
                  }}
                  onValueChange={values => {
                    setFormError({...formError, budget: ''})
                    if (values.value) {
                      setForm({
                        ...form,
                        budget: (parseFloat(values.value) / 100).toFixed(2),
                      })

                    } else {

                      setForm({
                        ...form,
                        budget: (0 / 100).toFixed(2),
                      })
                    }
                  }}
                />

                {formError?.budget && <span className={styles.error}>{formError.budget}</span>}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={styles.field}>
                <label htmlFor='date_start'>{t("performance_assessment.page_action_plan.form.start_date_field")}*</label>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale={i18n.language}>
                  <DatePicker
                    label={""}
                    value={form?.date_start}
                    disabled={disableFields}
                    inputFormat={getDataFormat(i18n.language)}
                    onChange={(value) => {
                      setForm({...form, date_start: moment(value.$d).format().split("T")[0]})
                      setFormError({...formError, date_start: ''})
                    }}
                    renderInput={(params) => (
                      <TextField
                        id='date_start'
                        variant='outlined'
                        fullWidth
                        disabled={disableFields}
                        {...params}
                        sx={{ height: '48px', fieldset: { border: '1px solid #D6DEEB', borderRadius:'2px', outline: 'none' }, input: { color: '#4F7091', padding: '14px',  borderRadius:'2px', outline: 'none' } }}
                      />
                    )}
                  />
                </LocalizationProvider>
                {formError?.date_start && <span className={styles.error}>{formError.date_start}</span>}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={styles.field}>
                <label htmlFor='date_end'>{t("performance_assessment.page_action_plan.form.end_date_field")}*</label>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale={i18n.language}>
                  <DatePicker
                    label={""}
                    value={form?.date_end}
                    disabled={disableFields}
                    inputFormat={getDataFormat(i18n.language)}
                    onChange={(value) => {
                      setForm({...form, date_end: moment(value.$d).format().split("T")[0]})
                      setFormError({...formError, date_end: ''})
                    }}
                    renderInput={(params) => (
                      <TextField
                        id='date_end'
                        variant='outlined'
                        fullWidth
                        disabled={disableFields}
                        {...params}
                        sx={{ height: '48px', fieldset: { border: '1px solid #D6DEEB', borderRadius:'2px', outline: 'none' }, input: { color: '#4F7091', padding: '14px',  borderRadius:'2px', outline: 'none' } }}
                      />
                    )}
                  />
                </LocalizationProvider>
                {formError?.date_end && <span className={styles.error}>{formError.date_end}</span>}
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box className={styles.field}>
                <label htmlFor='expected_outcome'>{t("performance_assessment.page_action_plan.form.result_expected_field")}*</label>
                <input
                  id='expected_outcome'
                  name='expected_outcome'
                  type='text'
                  placeholder={t("performance_assessment.page_action_plan.form.result_expected_placeholder")}
                  onChange={handleChange}
                  value={form?.expected_outcome}
                  disabled={disableFields}
                />

                {formError?.expected_outcome && <span className={styles.error}>{formError.expected_outcome}</span>}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className={styles.cardContainer}>
        <header className={styles.cardHeader}>
          <h6 className={styles.title}>{t("performance_assessment.page_action_plan.checklists.title")}</h6>
        </header>
        
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t("performance_assessment.page_action_plan.checklists.step")}</th>
              <th style={{width: '160px'}}>{t("performance_assessment.page_action_plan.checklists.expiration")}</th>
              <th style={{ width: '10px'}}/>
            </tr>
          </thead>
          <tbody>
            {
              form.todo_list.length > 0
                && form.todo_list.map((item, index) => (
                  <RowNewItem
                    key={`todo-${item.id}`}
                    currentItem={item}
                    setForm={setForm}
                    form={form}
                    setEnableAddTodo={setEnableAddTodo}
                    inputFormat={getDataFormat(i18n.language)}
                    disableFields={disableFields}
                  />  
                ))
            }
            {
              enableAddTodo
              ?
                <RowNewItem
                  currentItem={{
                    id: null,
                    active: false,
                    content: "",
                    deadline: null
                  }}
                  setForm={setForm}
                  form={form}
                  setEnableAddTodo={setEnableAddTodo}
                  inputFormat={getDataFormat(i18n.language)}
                  disableFields={disableFields}
                />                
              :
              <tr>
                <td colSpan={3}>
                  {
                    !disableFields &&
                    <button
                      type="button"
                      className={styles.buttonNewOption}
                      onClick={() => setEnableAddTodo(true)}
                    >
                      {t("performance_assessment.page_action_plan.checklists.add_item_list")}
                    </button>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      {/* Start Popover Actions */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseActions}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "#1E6B7F",
            marginTop: "10px",
          },
        }}
      >
        <Box className={"menuOptionsActions"}>
          <button
            className={"menuOptionsActionButton"}
            onClick={() => {
              handleCloseActions();
              setDisableFields(false);
            }}
          >
            {t("performance_assessment.actions.edit")}
          </button>
          <button
            className={"menuOptionsActionButton"}
            onClick={handleClickConfirmCancelPlan}
          >
            {t("performance_assessment.actions.cancel_plan")}
          </button>
        </Box>
      </Popover>
      {/* End Popover Actions */}

      {/* Start Confirm Dialog Delete Plan */}
      <PopoverConfirm
        idConfirm={idConfirmCancelPlan}
        openConfirm={openConfirmCancelPlan}
        anchorEl={anchorElConfirmCancelPlan}
        handleClose={handleCloseConfirmCancelPlan}
        handleConfirm={handleConfirmCancelPlan}
        title={t('performance_assessment.messages.cancel_plan')}
      />
      {/* End Confirm Dialog Delete Plan */}
    </form>
  )
}
