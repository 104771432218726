import React from 'react'
import styles from './RepliesCardList.module.scss'
import { Chat, Hexagon } from 'phosphor-react'
import BefectiveTooltip from '../../_shared/BefectiveTooltip'
import { Typography } from '@material-ui/core'
import IconInfo from '../../_icons/IconInfo'
import RepliesSkillsCollapsibleTable from './RepliesSkillsCollapsibleTable'

export default function RepliesCardList({
  data,
  openDrawerFeedbacks,
  openDrawerDetails
}) {
  return (
    <div className={styles.cardContainer}>
      <header className={styles.cardHeader} style={{borderLeft: `5px solid ${data?.classification?.text_color || '#86B2B3'}`}}>
        <div className={styles.infoUser}>
          <span className={styles.title}>{data?.username}: {data?.evaluated_avg} {data?.classification?.label && `(${data?.classification?.label})`}</span>
          {
            data?.avg_info &&
              <BefectiveTooltip
                title={<Typography component={'div'} variant='caption'>{data?.avg_info}</Typography>}
                placement="right-start"
                arrow
                interactive>
                <span style={{ cursor: 'pointer' }}>&nbsp;&nbsp;<IconInfo /></span>
              </BefectiveTooltip>
          }
        </div>
        <div className={styles.boxButtonHeader}>
          {
            openDrawerFeedbacks &&
            <button type='button' onClick={openDrawerFeedbacks}>
              <Chat size={16} color='#98ACC1' weight='bold'/>
            </button>
          }
          {
            openDrawerDetails && 
            <button type='button' onClick={openDrawerDetails}>
              <Hexagon size={16} color='#98ACC1' weight='bold'/>
            </button>
          }
        </div>
      </header>

      <RepliesSkillsCollapsibleTable skills={data?.skills} evaluators={data?.evaluators}/>
    </div>
  )
}
