import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import { getAvatarUrl } from '../../_helpers';
import './CustomAvatarGroup.scss';


const CustomAvatarGroup = ({ avatars, max }) => {

  const visibleAvatars = avatars.slice(0, max);
  const remainingAvatars = avatars.length - max;

  const listRemainingAvatars = () => {
    const remainingAvatars = avatars.slice(max, avatars.length);
    return (<ul className={"listRemainingAvatars"}>
      {remainingAvatars.map((item, index) => (
        <li key={`remaining-${index}`} className={"listRemainingAvatarsItem"}>
          <p className={"tooltipText"}>{item?.username}</p>
        </li>
      ))}
    </ul>)
  };
  return (
    <Box display="flex" className='customAvatarGroup'>
      {visibleAvatars.map((item, index) => (
        <Tooltip key={index} title={<p className={"tooltipText"}>{item?.username}</p>} arrow interactive>
          <Avatar
            alt={item?.username}
            src={getAvatarUrl(item)}
            style={{ width: 28, height: 28, marginLeft: index > 0 ? -6 : 0, border: '2px solid #ffffff' }}
          />
        </Tooltip>
      ))}
      {remainingAvatars > 0 && (
        <Tooltip 
          title={listRemainingAvatars()} 
          arrow
          interactive
          >
          <Avatar style={{ width: 28, height: 28, marginLeft: visibleAvatars.length > 0 ? -6 : 0, border: '2px solid #ffffff' }}>
            {`+${remainingAvatars}`}
          </Avatar>
        </Tooltip>
      )}
    </Box>
  );
};

export default CustomAvatarGroup;