import React, { useState } from 'react'
import styles from './FeedbacksTable.module.scss'
import { useTranslation } from 'react-i18next';
import { befectiveFormatDate } from '../../../_helpers';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';


export default function FeedbacksTable({
  title="",
  data=[],
  lang="en",
  isUpdating=false,
  handleChangeDateFeedback=() => {},
  actionColumn=() => {},
  getDataFormat = () => {}
}) {

  const { t, i18n } = useTranslation("common");

  return (
    <div className={styles.tableContainer}>
      {
        title && (<div className={styles.titleTable}>
          <h3 className={styles.title}>{title}</h3>
        </div>
        )
      }
      <table>
        <thead>
          <tr>
            <th className={styles.headColTable}>{t("performance_assessment.page_feedback.table.col_assessment")}</th>
            <th className={styles.headColTable}>{t("performance_assessment.page_feedback.table.col_completed_at")}</th>
            <th className={styles.headColTable}>{t("performance_assessment.page_feedback.table.col_colab")}</th>
            <th className={styles.headColTable}>{t("performance_assessment.page_feedback.table.col_responsible")}</th>
            <th className={styles.headColTable}>{t("performance_assessment.page_feedback.table.col_score")}</th>
            <th className={styles.headColTable}>{t("performance_assessment.page_feedback.table.col_date")}</th>
            <th className={styles.headColTable}>{t("performance_assessment.page_feedback.table.col_action_plan")}</th>
            <th className={styles.headColTable}/>
          </tr>
        </thead>
        <tbody>
          {
            data && data.length > 0
            ?          
            data.map((item, index) => {

              return item?.evaluated?.map((evaluated, index2) => {
                const [date, setDate] = useState(evaluated?.feedback_date)
                return (
                  <tr key={`evaluated-${index2}`}>
                    {
                      index2 === 0 && (
                        <>
                          <td className={`${styles.tableColumn} ${styles.tableRowSpan}`} rowSpan={item.evaluated.length}>
                            {item?.title || '-----'}
                          </td>
                          <td className={`${styles.tableColumn} ${styles.tableRowSpan}`} rowSpan={item.evaluated.length}>
                            {item?.date_end ? befectiveFormatDate(item?.date_end, lang) : '-----'}
                          </td>
                        </>
                      )
                    }
                    <td className={styles.tableColumn}>{evaluated?.evaluated_name || '-----'}</td>
                    <td className={styles.tableColumn}>{evaluated?.manager?.username || '-----'}</td>
                    <td className={styles.tableColumn}>
                      <BefectiveTooltip
                        title={`${t("performance_assessment.page_feedback.table.col_classification")}: ${evaluated?.classification?.label || "-----"}`}
                        placement="bottom-center" 
                        arrow 
                        interactive
                      >
                        <span style={{color: evaluated?.classification?.text_color || '#4F7091', fontWeight: 500}}>{evaluated?.general_avg || "-----"}</span> 
                      </BefectiveTooltip>
                    </td>
                    <td className={styles.tableColumn}>
                      <div
                        style={{width: '150px'}}
                        onClick={(e) => e.stopPropagation()}
                      >
                          <LocalizationProvider dateAdapter={AdapterDayjs} locale={lang}>
                            <DatePicker
                              value={date}
                              inputFormat={getDataFormat(lang)}
                              disabled={isUpdating}
                              onChange={(newValue) => {
                                setDate(newValue)
                                handleChangeDateFeedback(newValue, evaluated)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  variant='outlined'
                                  fullWidth
                                  {...params}
                                  sx={{ height: '40px', fieldset: { border: '1px solid #DEE3E9CC' }, input: { color: '#4F7091', padding: '10px' } }}
                                  size="small"
                                />
                              )}
                            />
                          </LocalizationProvider>
                      </div>
                    </td>
                    <td className={styles.tableColumn}>
                      {
                        evaluated?.has_action_plan 
                        ? 
                        <span style={{color: '#22BEAD'}}>{t("performance_assessment.page_feedback.table.has_action_plan")}</span> 
                        : 
                        <span style={{color: '#FC6662'}}>{t("performance_assessment.page_feedback.table.none_action_plan")}</span>
                      }
                    </td>
                    <td className={styles.tableColumn}>
                      {
                        actionColumn(evaluated)
                      }
                    </td>
                  </tr>
                )
              })
            })
            :
            <tr>
              <td colSpan={8} className={styles.noData}>{t('generic_messages.none_data')}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  )
}
