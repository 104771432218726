import React from 'react';
import {connect} from 'react-redux';
import Skeleton from "@material-ui/lab/Skeleton";

function SkillTypesFilterSkeleton(props) {


    return (

        <div className="filter">
            <div className="holder">
                <Skeleton variant="text" width="100%" height={50}/>
            </div>
        </div>

    )
};


function mapDispatchToProps(dispatch) {

    return {}
}


function mapStateToProps(state) {

    return {}

}

export default connect(mapStateToProps, mapDispatchToProps)(SkillTypesFilterSkeleton)
