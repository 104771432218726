import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import HeaderForm from "../personal/HeaderForm";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomReactSelect from "../../_shared/CustomReactSelect";
import { authHeader, prepareOptionsData } from "../../../_helpers";
import { api } from "../../../_helpers/api";
import CoursesManageList from "./CoursesManageList";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

export default function FormEducationCreatePT({
  formData,
  getFormData,
  toogleShowForm,
  isEditing,
  lang,
  userId,
  optionsData,
  alertActions
}) {
  const { t, i18n } = useTranslation("common");
  const [isSubmitting, setIsSubmitting] = useState(false);
  let formSchema = yup.object({
    // emergency_contact: yup.string().required(t("spaces.required_field"))
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      level_education: formData?.level_education || null,
      educational_institution: formData?.educational_institution,
      academic_training: formData?.academic_training,
      conclusion_year: formData?.conclusion_year || null,
      languages: formData?.languages ? formData?.languages?.map((item) => ({ value: item.value, label: item[`label_${lang}`] })) : [],
      courses: formData?.courses || []
    },
    resolver: yupResolver(formSchema),
  });

  const {
    level_education,
    educational_institution,
    academic_training,
    conclusion_year,
    languages,
    courses
  } = watch();
  
  const onSubmit = async (data) => {

    try {

      setIsSubmitting(true)

      const dataForm = new FormData();

      dataForm.append('machine_users_id', userId)
      dataForm.append('level_education_id', data.level_education ? data.level_education.value : null)
      dataForm.append('educational_institution', data.educational_institution)
      dataForm.append('academic_training', data.academic_training)
      dataForm.append('conclusion_year', data.conclusion_year || null)
      dataForm.append('languages', JSON.stringify(data.languages?.map((item) => item.value)))
      dataForm.append('courses', data.courses.length > 0 ? JSON.stringify(data.courses.map((c) => ({...c, conclusion_year: c.conclusion_year || null}))) : JSON.stringify([]))
      const filesCourses = data.courses.filter(c => c.attachment).map((item) => ({attachment : item.attachment, index: item.index}))

      for(let item of filesCourses) {
        dataForm.append('files', item.attachment)
      }

      const files_index_new = filesCourses.map(item => item.index);

      dataForm.append('files_index_new', JSON.stringify(files_index_new))
      
      const resCreate = await api.post('/users/educational-information', dataForm,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': authHeader().Authorization
          },
        })

      if (resCreate.status !== 200) {
        throw new Error("Error create educational information")
      }

      await getFormData(false)
      setIsSubmitting(false)
      toogleShowForm()
      alertActions.success(t('settings.users.success.info_save'))
      
    } catch (error) {
      setIsSubmitting(false)
      console.error(error)
      alertActions.error(error.response.data)
    }
  }

  console.log(getValues())

  return (
    <div>
      <div className={styles.cardContainer}>

        <HeaderForm
          formData={formData}
          toogleShowForm={toogleShowForm}
          isEditing={isEditing}
          titleCreate={t("settings.users.education_form.title_create")}
          isSubmitting={isSubmitting}
          submitForm={handleSubmit(onSubmit)}
          userId={userId}
        />

        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          paddingX="16px"
          paddingBottom="16px"
          borderRadius="4px"
          marginBottom="2px"
          flexGrow={1}
        >
        
          <Grid container spacing={2} marginBottom={'4px'} marginTop={0} >
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="level_education"
                >
                  {t("settings.users.education_form.teaching_degree")}
                </Typography>
                <CustomReactSelect
                  id="level_education"
                  isMulti={false}
                  value={level_education}
                  options={prepareOptionsData(optionsData?.level_education, lang)}
                  onChange={(values) => setValue("level_education", values)}
                  closeMenuOnSelect={true}
                />
                {errors.level_education && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.level_education.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="educational_institution"
                >
                  {t("settings.users.education_form.teaching_institution")}
                </Typography>
                <TextField
                  id="educational_institution"
                  name="educational_institution"
                  value={educational_institution}
                  placeholder={t("settings.users.education_form.teaching_institution_placeholder")}
                  error={false}
                  helperText={""}
                  {...register("educational_institution")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                />
                {errors.educational_institution && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.educational_institution.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="academic_training"
                >
                  {t("settings.users.education_form.formation")}
                </Typography>
                <TextField
                  id="academic_training"
                  name="academic_training"
                  value={academic_training}
                  placeholder={t("settings.users.education_form.formation_placeholder")}
                  error={false}
                  helperText={""}
                  {...register("academic_training")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                />
                {errors.academic_training && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.academic_training.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="conclusion_year"
                >
                  {t("settings.users.education_form.year_formation")}
                </Typography>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    locale={lang}
                  >
                  <DatePicker
                    value={conclusion_year ? `${conclusion_year}-01-01` : null}
                    inputFormat={"YYYY"}
                    onChange={(value) => setValue("conclusion_year", value.$y)}
                    views={["year"]}
                    renderInput={(params) => (
                      <TextField
                        id="conclusion_year"
                        variant="outlined"
                        fullWidth
                        {...params}
                        sx={{
                          width: "100%",
                          height: "45px",
                          fieldset: {
                            border: "1px solid #DEE3E9CC",
                            height: "45px",
                          },
                          input: { color: "#4F7091", paddingY: "11.5px" },
                          label: { top: "-4px" },
                        }}
                        name="conclusion_year"
                        error={false}
                        helperText={""}
                      />
                    )}
                  />
                </LocalizationProvider>
                {errors.conclusion_year && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.conclusion_year.message}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>  

      
      <div className={styles.cardContainer}>
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          paddingX="16px"
          paddingBottom="16px"
          borderRadius="4px"
          marginBottom="2px"
          flexGrow={1}
        >        
          <Grid container spacing={2} marginBottom={'4px'} marginTop={0}>
            <Grid item xs={12}>
              <Typography
                component="h3"
                sx={{ fontSize: "18px", color: "#1E6B7F", fontWeight: 500 }}
              >
                {t("settings.users.education_form.languages_title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="languages"
                >
                  {t("settings.users.education_form.languages")}
                </Typography>
                <CustomReactSelect
                  id="languages"
                  value={languages}
                  options={prepareOptionsData(optionsData?.languages, lang)}
                  onChange={(values) => setValue("languages", values)}
                />
                {errors.languages && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.languages.message}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>    
      <div className={styles.cardContainer}>
          <CoursesManageList
            courses={courses}
            setValue={setValue}
            lang={lang}
          />    
      </div>
    </div>
  );
}
