import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import HeaderForm from "../personal/HeaderForm";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomReactSelect from "../../_shared/CustomReactSelect";
import { authHeader, prepareOptionsData } from "../../../_helpers";
import { api } from "../../../_helpers/api";
import { NumberFormatBase } from "react-number-format";
import { formatCurrencyByEnd } from "../../../_helpers/formattValue";
import BenefitsManageList from "./BenefitsManageList";

export default function FormFinanceCreatePT({
  formData,
  getFormData,
  toogleShowForm,
  isEditing,
  lang,
  optionsData,
  alertActions,
  userId,
}) {
  const { t, i18n } = useTranslation("common");
  const [isSubmitting, setIsSubmitting] = useState(false);

  let formSchema = yup.object({
    // bank: yup.string().required(t("spaces.required_field"))
  });
  const [inputDecimal, setInputDecimal] = useState({
    focus: false,
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      bank: formData?.bank
        ? { value: formData?.bank.value, label: formData?.bank?.label }
        : null,
      current_account: formData?.current_account,
      agency: formData?.agency,
      account_type: formData?.account_type
        ? {
            value: formData?.account_type.value,
            label: formData?.account_type[`label_${lang}`],
          }
        : null,
      base_salary: formData?.base_salary,
      payroll_deductions: formData?.payroll_deductions
        ? 
          formData?.payroll_deductions?.map((item) => ({
            value: item.value,
            label: item[`label_${lang}`]
          }))
        : [],
      payment_form: formData?.payment_form
        ? {
            value: formData?.payment_form.value,
            label: formData?.payment_form[`label_${lang}`],
          }
        : null,
      pix_key: formData?.pix_key,
      benefits: formData?.benefits || [],
    },
    resolver: yupResolver(formSchema),
  });

  const {
    bank,
    current_account,
    agency,
    account_type,
    base_salary,
    payroll_deductions,
    payment_form,
    pix_key,
    benefits,
  } = watch();

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      const payload = {
        ...data,
        machine_users_id: userId,
        account_type_id: account_type?.value,
        bank_id: bank?.value,
        payroll_deductions: data.payroll_deductions?.map((item) => item.value),
        payment_form_id: payment_form?.value,
      };
      const resCreate = await api.post(
        "/users/financial-information",
        payload,
        {
          headers: authHeader(),
        }
      );

      if (resCreate.status !== 200) {
        throw new Error("Error create finance information");
      }

      await getFormData(false);
      toogleShowForm();
      setIsSubmitting(false);
      alertActions.success(t("settings.users.success.info_save"));
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
      alertActions.error(error.response.data);
    }
  };

  return (
    <div>
      <div className={styles.cardContainer}>
        <HeaderForm
          formData={formData}
          toogleShowForm={toogleShowForm}
          isEditing={isEditing}
          titleCreate={t("settings.users.finance_form.title_create")}
          isSubmitting={isSubmitting}
          submitForm={handleSubmit(onSubmit)}
          userId={userId}
        />
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          paddingX="16px"
          paddingBottom="16px"
          borderRadius="4px"
          marginBottom="2px"
          flexGrow={1}
        >
          <Grid container spacing={2} marginBottom={"4px"} marginTop={0}>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column" gap="2px" width="100%">
                <Typography
                  component="label"
                  sx={{
                    paddingLeft: "4px",
                    fontSize: "12px",
                    color: "#1E6B7F",
                    fontWeight: 400,
                  }}
                  htmlFor="bank"
                >
                  {t("settings.users.finance_form.bank")}
                </Typography>
                <CustomReactSelect
                  id="bank"
                  isMulti={false}
                  value={bank}
                  options={prepareOptionsData(optionsData?.banks, lang)}
                  onChange={(values) => setValue("bank", values)}
                  closeMenuOnSelect={true}
                />
                {errors.bank && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.bank.message}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap="2px" width="100%">
                <Typography
                  component="label"
                  sx={{
                    paddingLeft: "4px",
                    fontSize: "12px",
                    color: "#1E6B7F",
                    fontWeight: 400,
                  }}
                  htmlFor="account_corrent"
                >
                  {t("settings.users.finance_form.account_corrent")}
                </Typography>
                <TextField
                  id="account_corrent"
                  name="current_account"
                  value={current_account}
                  placeholder=""
                  error={false}
                  helperText={""}
                  {...register("current_account")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                ></TextField>

                {errors.current_account && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.current_account.message}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap="2px" width="100%">
                <Typography
                  component="label"
                  sx={{
                    paddingLeft: "4px",
                    fontSize: "12px",
                    color: "#1E6B7F",
                    fontWeight: 400,
                  }}
                  htmlFor="agency"
                >
                  {t("settings.users.finance_form.agency")}
                </Typography>
                <TextField
                  name="agency"
                  value={agency}
                  placeholder=""
                  error={false}
                  helperText={""}
                  {...register("agency")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                ></TextField>

                {errors.agency && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.agency.message}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} marginBottom={"4px"} marginTop={0}>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap="2px" width="100%">
                <Typography
                  component="label"
                  sx={{
                    paddingLeft: "4px",
                    fontSize: "12px",
                    color: "#1E6B7F",
                    fontWeight: 400,
                  }}
                >
                  {t("settings.users.finance_form.account_type")}
                </Typography>
                <CustomReactSelect
                  isMulti={false}
                  value={account_type}
                  options={prepareOptionsData(optionsData?.account_type, lang)}
                  closeMenuOnSelect={true}
                  onChange={(values) => setValue("account_type", values)}
                />
                {errors.account_type && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.account_type.message}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" flexDirection="column" gap="2px" width="100%">
                <Typography
                  component="label"
                  sx={{
                    paddingLeft: "4px",
                    fontSize: "12px",
                    color: "#1E6B7F",
                    fontWeight: 400,
                  }}
                  htmlFor="base_salary"
                >
                  {t("settings.users.finance_form.base_salary")}
                </Typography>
                <NumberFormatBase
                  id="base_salary"
                  style={{ border: inputDecimal.focus && "1px solid #3788d8" }}
                  className={styles.inputNumberCustom}
                  onFocus={() =>
                    setInputDecimal({ ...inputDecimal, focus: true })
                  }
                  onBlur={() =>
                    setInputDecimal({ ...inputDecimal, focus: false })
                  }
                  format={formatCurrencyByEnd}
                  placeholder={"1000.00"}
                  inputMode="numeric"
                  displayType="input"
                  value={
                    !base_salary || !Number(base_salary)
                      ? ""
                      : Number(base_salary) * 100
                  }
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return (
                      formattedValue === "" || floatValue / 100 <= 10000000000
                    );
                  }}
                  onValueChange={(values) => {
                    setError("base_salary", { message: "" });
                    if (values.value) {
                      setValue(
                        "base_salary",
                        (parseFloat(values.value) / 100).toFixed(2)
                      );
                    } else {
                      setValue("base_salary", (0 / 100).toFixed(2));
                    }
                  }}
                />

                {errors.base_salary && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.base_salary.message}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" flexDirection="column" gap="2px" width="100%">
                <Typography
                  component="label"
                  sx={{
                    paddingLeft: "4px",
                    fontSize: "12px",
                    color: "#1E6B7F",
                    fontWeight: 400,
                  }}
                  htmlFor="payment_form"
                >
                  {t("settings.users.finance_form.form_payment")}
                </Typography>
                <CustomReactSelect
                  isMulti={false}
                  value={payment_form}
                  options={prepareOptionsData(optionsData?.payment_form, lang)}
                  closeMenuOnSelect={true}
                  onChange={(values) => setValue("payment_form", values)}
                />

                {errors.payment_form && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.payment_form.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            {payment_form?.value === 1 && (
              <Grid item xs={4}>
                <Box display="flex" flexDirection="column" gap="2px" width="100%">
                  <Typography
                    component="label"
                    sx={{
                      paddingLeft: "4px",
                      fontSize: "12px",
                      color: "#1E6B7F",
                      fontWeight: 400,
                    }}
                    htmlFor="pix_key"
                  >
                    {t("settings.users.finance_form.pix_key")}
                  </Typography>
                  <TextField
                    id="pix_key"
                    name="pix_key"
                    placeholder=""
                    value={pix_key}
                    error={false}
                    helperText={""}
                    {...register("pix_key")}
                    sx={{
                      width: "100%",
                      height: "45px",
                      fieldset: {
                        border: "1px solid #DEE3E9CC",
                        height: "45px",
                      },
                      div: { color: "#4F7091", height: "45px" },
                      label: { top: "-4px" },
                    }}
                  ></TextField>

                  {errors.pix_key && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.pix_key.message}
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
            
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" gap="2px" width="100%">
                <Typography
                  component="label"
                  sx={{
                    paddingLeft: "4px",
                    fontSize: "12px",
                    color: "#1E6B7F",
                    fontWeight: 400,
                  }}
                  htmlFor="payroll_deduction"
                >
                  {t("settings.users.finance_form.payroll_deduction")}
                </Typography>
                <CustomReactSelect
                  value={payroll_deductions}
                  options={prepareOptionsData(
                    optionsData?.payroll_deduction,
                    lang
                  )}
                  onChange={(values) => setValue("payroll_deductions", values)}
                />

                {errors.payroll_deductions && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.payroll_deductions.message}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className={styles.cardContainer}>
          <BenefitsManageList
            benefits={benefits}
            setValue={setValue}
            lang={lang}
            optionsData={optionsData}
          />    
      </div>
    </div>
  );
}
