import React from "react";
import styles from "./NewAssessmentCard.module.scss";
import Avatar from 'react-avatar';
import { useTranslation } from "react-i18next";
import { befectiveFormatDate } from "../../_helpers";
const NewAssessmentCard = ({
    assessment,
    onClick
}) => {

    const { t, i18n } = useTranslation('common');

    return (
        <div className={styles.cardNew} onClick={onClick}>
            <header>
                <div className={styles.badgeNew}>
                    {t('colab_page.performance_assessment.new')}
                </div>
                <div className={styles.boxAvatar}>
                    <Avatar
                        name={assessment?.evaluated_username}
                        size={40}
                        round
                        color={'#24677E'}
                        style={{
                            fontSize: '18px'
                        }}
                    />
                </div>
                <div className={styles.boxInfo}>
                    <h6>{assessment?.evaluated_username}</h6>
                    <span>{assessment?.office || "-----"}</span>
                </div>
            </header>
            <main>
                <span>{t('colab_page.performance_assessment.assessment')} {assessment[`label_${i18n.language}`]}</span>
                <span>{t('colab_page.performance_assessment.assess_to')}: {befectiveFormatDate(assessment?.date_end, i18n.language)}</span>
            </main>
        </div>
    )
}

export default NewAssessmentCard;