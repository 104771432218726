import React, { useEffect, useState } from "react";
import Toolbar from "../../_components/_shared/Toolbar";
import { connect } from "react-redux";
import { alertActions } from "../../_actions";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { authHeader, history} from "../../_helpers";
import GlobalSkeleton from "../../_components/_shared/GlobalSkeleton";
import { api } from "../../_helpers/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PerformanceAssessmentsDetailsDrawer from "../../_components/performance_assessments/PerformanceAssessmentsDetails/PerformanceAssessmentsDetailsDrawer";
import FeedbackTabsComponent from "../../_components/performance_assessments/PerformanceAssessmentFeedback/FeedbackTabsComponent";
import ReportSkillsCardList from "../../_components/performance_assessments/PerformanceAssessmentFeedback/ReportSkillsCardList";
import ReportTodoList from "../../_components/performance_assessments/PerformanceAssessmentFeedback/ReportTodoList";
import styles from "./styles.module.scss";
import { ArrowRight } from "phosphor-react";


function PerformanceAssessmentFeedbackReportPage({
  alertActions,
  userDetails,
  filter,
}) {
  const { evaluated_id } = useParams();
  const { t, i18n } = useTranslation("common");
  const [fetching, setFetching] = useState(true);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);
  const [currentData, setCurrentData] = useState(true);
  const [data, setData] = useState(null);
  
  const handleOpenDrawerDetails = (currentData) => {
    setCurrentData(currentData);
    setOpenDrawerDetails(true);
  };

  const handleCloseDrawerDetails = () => {
    setOpenDrawerDetails(false);
    setCurrentData(null);
  };

  async function getData(isLoading=true) {
    try {
      if (isLoading) {
        setFetching(true)
      }

      const response = await api.get(`/performance-assessment/feedback/report?evaluated_id=${evaluated_id}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setData(response.data)
      setFetching(false);

    } catch (error) {
      console.log(error);
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }
  }


  useEffect(() => {
    document.title = `Befective | ${t("menu.performance_assessment.feedback")} | ${t("performance_assessment.page_feedback.report_tab.title")}`;
    getData();
  }, []);

  return (
    <section className="mainContent">
      <Toolbar />

      <FeedbackTabsComponent 
        currentActive="report" 
        evalutedId={evaluated_id}
      />

      <main className="mainContentPage">
        {!fetching ? (
         <>
            <ReportSkillsCardList 
              data={data}
              openDrawerDetails={() => handleOpenDrawerDetails(data)}
            />
            <ReportTodoList
              type={"list_keep_doing"}
              title={t("performance_assessment.page_feedback.report_tab.keep_doing")}
              data={data.keep_doing_arr}
              alertActions={alertActions}
            />
             <ReportTodoList
              type={"list_should_do"}
              title={t("performance_assessment.page_feedback.report_tab.should_do")}
              data={data.should_do_arr}
              alertActions={alertActions}
            />
            <div className={styles.containerButton}>
              <button className={styles.buttonCreateActionPlan} onClick={() => history.push(`/performance-assessment/feedback/action-plan/${evaluated_id}`)}>
                {t("performance_assessment.page_feedback.report_tab.create_action_plan")} <ArrowRight size={18} color="#106276"/>
              </button>
            </div>
         </>         
        ) : (
          <GlobalSkeleton
            totalRowsStats={0}
            totalTables={1}
            heightTable={600}
          />
        )}
      </main>

      {
        (openDrawerDetails && currentData) && (
          <PerformanceAssessmentsDetailsDrawer 
            openDrawer={openDrawerDetails} 
            closeDrawer={handleCloseDrawerDetails} 
            currentData={currentData}
          />
        )
      }
    </section>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceAssessmentFeedbackReportPage);
