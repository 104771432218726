import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Modal,
  Slide,
  Typography,
  makeStyles,
} from "@material-ui/core";
import styles from "../PerformanceAssessmentsScalesAnswers/ImportScalesModal.module.scss";
import { useTranslation } from "react-i18next";
import { api } from "../../../_helpers/api";
import { authHeader } from "../../../_helpers";
import IconClose from "../../_icons/IconClose";
import BefectiveTooltip from "../../_shared/BefectiveTooltip";
import IconInfo from "../../_icons/IconInfo";
import { Add, Remove } from "@material-ui/icons";
import BefectiveCheckbox from "../../_shared/BefectiveCheckbox";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function ImportSkillsModal({ openModal, closeModal, getSkills, alertActions}) {

  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const [loadingSkillsModels, setLoadingSkillsModels] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [skillsModels, setSkillsModels] = useState([]);

  const letters = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","U","V","W","X","Y","Z"]

  const getSkillsModels = async () => {
    try {
      setLoadingSkillsModels(true);
      const response = await api.get("/performance-assessment/skills/import-models", {
        headers: authHeader(),
      });
      const { data } = response;
      if(data.length > 0){
        setSkillsModels(data.map((skill, index) => ({...skill, selected: false, show: true})));
      }
      setLoadingSkillsModels(false);
    } catch (error) {
      console.log(error);
      setLoadingSkillsModels(false);
    }
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const selectedSkillsIds = skillsModels.filter(s => s.selected).map(s => s.id);

      if(selectedSkillsIds.length === 0){
        alertActions.error(t("performance_assessment.page_skills.modal_import.error_no_skill_selected"));
        return;
      }

      const response = await api.post("/performance-assessment/skills/import-models", { skills_id: selectedSkillsIds }, {
        headers: authHeader(),
      });

      closeModal();
      await getSkills();
      alertActions.success(t("performance_assessment.page_skills.modal_import.success_import"));
      setIsSubmitting(false);
      setSkillsModels([]);
    } catch (error) {
      console.log(error);
      alertActions.error(t("performance_assessment.page_skills.modal_import.error_import"));
      setIsSubmitting(false);
    }
  }
  

  const onChangeCheckedSkill = (skill) => {
    setSkillsModels(skillsModels.map(s => s.id === skill.id ? {...s, selected: !s.selected} : s));
  }

  useEffect(() => {
    if(openModal){
      getSkillsModels();
    }
  }, [openModal]);


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${styles.modal}`}
      open={openModal}
      onClose={() => {
        if(!isSubmitting){
          closeModal();
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: "#205B6F", opacity: 0.8 },
      }}
      sx={{ border: "none" }}
      style={{ outline: 0 }}
    >
      <Slide
        direction="down"
        in={openModal}
        style={{ outline: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.containerModal}>

          <button
              onClick={() => {
                if(!isSubmitting){
                  closeModal();
                }
              }}
              className={styles.close}
          >
            <IconClose />
          </button>


          <header>
            <h4>
              {t("performance_assessment.page_skills.modal_import.title")}
            </h4>
            <p>{t("performance_assessment.page_skills.modal_import.subtitle")}</p>
          </header>

          <main>
            {
              !loadingSkillsModels
              ?
              (
                skillsModels.length > 0
                ?
                skillsModels.map((skillModel, index) => {

                  return (
                    <div className={styles.scaleContainer} key={skillModel.id}>
                      <header className={styles.scaleHeader} onClick={() => onChangeCheckedSkill(skillModel)}>
                        <div className={styles.scaleBoxTitle}>
                          {
                              skillModel.show
                              ?
                              <IconButton aria-label="minus" size="small" onClick={(e) => {
                                e.stopPropagation();
                                setSkillsModels(skillsModels.map(s => s.id === skillModel.id ? {...s, show: false} : s))
                              }}>
                                <Remove fontSize="small" />
                              </IconButton>
                              :
                              <IconButton aria-label="plus" size="small" onClick={(e) => {
                                e.stopPropagation();
                                setSkillsModels(skillsModels.map(s => s.id === skillModel.id ? {...s, show: true} : s))
                              }}>
                                <Add fontSize="small" />
                              </IconButton>
                          }
                          <h6 style={{color: skillModel.selected ? '#22BEAD' : '#1E6B7F'}}>{skillModel[`name_${i18n.language}`]}</h6>
                          {
                            skillModel[`description_${i18n.language}`] &&
                            <BefectiveTooltip
                                title={<Typography component={'div'} variant='caption'>{skillModel[`description_${i18n.language}`]}</Typography>}
                                placement="right-start"
                                arrow
                                interactive>
                                <span style={{ cursor: 'pointer' }}>&nbsp;&nbsp;<IconInfo /></span>
                            </BefectiveTooltip>
                          }
                        </div>
                        <div className={styles.scaleBoxCheckboxSelect}>
                           <BefectiveCheckbox 
                            checked={skillModel.selected} 
                            onChange={(e) => {
                              e.stopPropagation(); 
                              onChangeCheckedSkill(skillModel)
                            }}/>
                        </div>
                      </header>

                      {
                        skillModel.show && (
                          <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th style={{width: '0px', paddingRight: '4px'}}/>
                                    <th>{t("performance_assessment.page_skills.table.col_questions")}</th>
                                    <th style={{width: '30%'}}>{t("performance_assessment.page_skills.table.col_type_reply")}</th>
                                </tr>
                            </thead>
                            <tbody className={styles.tbodyGray}>
                              {
                              (skillModel.questions && skillModel.questions.length > 0) 
                                ?
                                skillModel.questions.map((question, index) => {
                                    return (
                                      <tr key={`question-${index}`}>
                                          <td style={{width: '0px', paddingRight: '4px'}}>{`${letters[index]}.`}</td>
                                          <td>
                                            {
                                              question[`content_${i18n.language}`]
                                              ?
                                              ( 
                                                question[`content_${i18n.language}`].length > 60
                                                ?
                                                <BefectiveTooltip
                                                  title={<Typography component={'div'} variant='caption'>{question[`content_${i18n.language}`]}</Typography>}
                                                  placement="right-start"
                                                  arrow
                                                  interactive>
                                                  <span>{`${question[`content_${i18n.language}`].substring(0, 60)}...`}</span>
                                                </BefectiveTooltip>                                                
                                                :
                                                question[`content_${i18n.language}`]
                                              )
                                              :
                                              '------'
                                            }
                                          </td>
                                          <td style={{width: '30%'}}>{question[`scale_name_${i18n.language}`]}</td>
                                      </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={5}>{t("performance_assessment.page_skills.table.no_questions")}</td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        )
                      }
                    </div>
                  )
                })
                :
                <p>{t("performance_assessment.page_skills.modal_import.no_models_found")}</p>
              )
              :
              <div className={styles.boxLoading}>
                <CircularProgress />
              </div>

            }
          </main>

          <footer>            
            <button className={styles.buttonCancel} type="button" disabled={isSubmitting} onClick={closeModal}>
              {t("performance_assessment.actions.close")}
            </button>
            <button className={styles.buttonSave} style={{cursor: isSubmitting && 'progress'}} disabled={isSubmitting || !skillsModels.some(s => s.selected)} type="button" onClick={handleSubmit}>
              {isSubmitting ? <CircularProgress size={20} /> : t("performance_assessment.actions.import")}
            </button>
          </footer> 
        </div>
      </Slide>
    </Modal>
  );
}
