import React, { useState } from 'react'
import { CaretDown, CaretUp } from 'phosphor-react';
import styles from './RepliesSkillsCollapsibleTable.module.scss'
import { useTranslation } from 'react-i18next';
import StatusAssessment from '../PerformanceAssessmentsCreated/StatusAssessment';

const CollapsibleRow = ({ skill, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <tr className={styles.tableRow}>
        <td className={`${styles.skillNameStyle}`} style={{width: '30%'}}>{`${index + 1}. ${skill.name}`}</td>
        {
          skill.evaluators.length > 0 && (
            skill.evaluators.map((evaluator, index) => (
              <td key={`evaluator-${evaluator?.mu_id}`} className={styles.alignCenter} style={{width: '10%', color: evaluator?.classification?.text_color || ''}}>{evaluator?.evaluator_skill_avg || "---"}</td>
            ))
          )
        }
        <td className={styles.alignCenter} style={{width:'10%', padding: '0px 12px'}}>
          <StatusAssessment
              title={skill?.skill_avg}
              textColor={skill?.skill_avg && skill?.skill_avg !== 0 ? "#FFFFFF" : "#98ACC1"}
              bgColor={skill?.classification?.bg_color}
              height='48px'
              borderTop='1px solid #FFFFFF'
              borderBottom='1px solid #FFFFFF'
              fontWeight='500'
            />
        </td>
        <td className={styles.alignCenter} style={{width:'10%', padding: '0px 12px'}}>
          <StatusAssessment
            title={skill.classification?.label || "----"}
            textColor={skill.classification?.label ? "#FFFFFF" : "#98ACC1"}
            bgColor={skill?.classification?.bg_color}
            height='48px'            
            borderTop='1px solid #FFFFFF'
            borderBottom='1px solid #FFFFFF'
            fontWeight='500'
          />
        </td>
        <td style={{width:'1%'}}>
          <button className={styles.buttonExpand} onClick={toggleCollapse}>
            {
              isOpen
              ?
              <CaretUp size={16} weight="bold" color='#98ACC1' />
              :
              <CaretDown size={16} weight="bold" color='#98ACC1' />
            }
          </button>
        </td>
      </tr>
      {isOpen && (
        Object.keys(skill.questions).length > 0 && skill.questions.map((question, index) => (
          <tr key={`question-${index}`} className={styles.tableRowInternal}>
            <td style={{width: '30%'}}>{question.questions_content}</td>
            {
              question.evaluators.length > 0 && (
                question.evaluators.map((evaluator, index) => (
                  <td key={`evaluator-question-${index}`} className={styles.alignCenter} style={{width: '10%', color: evaluator?.classification?.text_color || ''}}>{evaluator?.question_value}</td>
                ))
              )
            }
            <td className={styles.alignCenter} style={{width:'10%', color: `${question?.classification?.text_color|| '#1E6B7F'}`}}>{question.question_avg}</td>
            <td className={styles.alignCenter} style={{width:'10%', color: `${question?.classification?.text_color|| '#1E6B7F'}`}}>{question.classification?.label || "----"}</td>
            <td style={{width:'1%'}}/>
          </tr>
        ))
      )}
    </>
  );
};

export default function RepliesSkillsCollapsibleTable ({
  skills,
  evaluators
}) {
  
  const { t, i18n } = useTranslation("common");

  return (
    <table>
      <thead className={styles.tableHead}>
        <tr>
          <th className={styles.tableColumn} style={{width: '30%'}}>{t('performance_assessment.page_assessment_details.replies.table.col_skills')}</th>
          {
            evaluators.length > 0 && (
              evaluators.map((evaluator, index) => (
                <th key={index} className={`${styles.tableColumn} ${styles.alignCenter}`} style={{width: '10%'}}>{evaluator?.self_assessment === true ? t('performance_assessment.page_assessment_details.replies.table.col_auto_evaluated') : evaluator?.username}</th>
              ))
            )
          }
          <th className={`${styles.tableColumn} ${styles.alignCenter}`} style={{width:'10%'}}>{t('performance_assessment.page_assessment_details.replies.table.col_average')}</th>
          <th className={`${styles.tableColumn} ${styles.alignCenter}`} style={{width:'10%'}}>{t('performance_assessment.page_assessment_details.replies.table.col_classification')}</th>
          <th className={styles.tableColumn} style={{width:'1%'}}/>
        </tr>
      </thead>
      <tbody>
        {skills.map((skill, index) => (
          <CollapsibleRow key={index} skill={skill} index={index}/>
        ))}
      </tbody>
    </table>
  )
}
