import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SkillForm.module.scss";
import BefectiveTooltip from "../_shared/BefectiveTooltip";
import { Typography, CircularProgress } from "@material-ui/core";
import IconInfo from "../_icons/IconInfo";
import { Warning } from "phosphor-react"
import FeedbackTodoList from "./FeedbackTodoList";
import { api } from "../../_helpers/api";
import { authHeader, history } from "../../_helpers";
import { Skeleton } from "@material-ui/lab";

export default function SkillForm({
    alertActions,
    evaluatedId,
    data,
    currentTab,
    setCurrentTab,
    skills,
    updateOptionQuestions,
    setOpenModalAssessmentAnswered,
    isManager
}) {
    const { t, i18n } = useTranslation('common');
    const [keepDoingArray, setKeepDoingArray] = useState([]);
    const [shouldDoArray, setShouldDoArray] = useState([]);
    const [loading, setLoading] = useState(false);

    const allowNext = (questions) => {
    
        if(!questions || !questions?.length) return false;
    
        let allowedNext = true;
    
        questions?.forEach((question) => {
          if(question?.is_objective === true && !question?.options?.find(option => option.selected)){
            allowedNext = false;
          }

          if(question?.is_objective === false && !question?.discursive_content){
            allowedNext = false;
          }
        });
    
        return allowedNext
    };

    const nextTab = (skill) => {
        if(allowNext(skill?.questions)){
            setCurrentTab(currentTab + 1)
        }
    }

    const handleConcludeAssessment = async () => {

        try {

            setLoading(true)

            const payload = {
                evaluated_id: evaluatedId,
                skills: skills.filter(skill => !skill.is_conclusion),
                keep_doing: keepDoingArray.map((keepDoing) => ({content: keepDoing})),
                should_do: shouldDoArray.map((shouldDo) => ({content: shouldDo})),
            }   

            const response = await api.post('/performance-assessment/user/register-answer', payload, {headers: authHeader()})

            if (response.status !== 200) {
                throw new Error('Erro get data ProductivityPerformancePage!!!')
            }

            setLoading(false)

            setOpenModalAssessmentAnswered(true)

            setTimeout(() => {
                setOpenModalAssessmentAnswered(false)
                history.replace(isManager ? '/performance-assessment/assess' : '/productive/productivity/performance-assessment')
            }, 3000)
        } catch (error) {
            console.log(error)
            alertActions.error(t('generic_messages.errors.send'))
            setLoading(false)
        }

    }
    const showButtonNext = (questions = []) => {

        if(!questions || !questions?.length) return false;

        const allNotIsObjective = questions?.filter(question => question?.is_objective === false)
        const allIsObjective = questions?.filter(question => question?.is_objective === true)
        const totalQuestions = allNotIsObjective?.length + allIsObjective?.length

        let allowNext = false

        if(allIsObjective?.length === totalQuestions && allNotIsObjective?.length === 0){
            allowNext = questions[questions.length - 1]?.options?.find(option => option.selected)
        }else if(allNotIsObjective?.length === totalQuestions && allIsObjective?.length === 0){
            allowNext = questions[questions.length - 1]?.discursive_content !== ""
        }else{
            allowNext = questions[questions.length - 1]?.options?.find(option => option.selected) || questions[questions.length - 1]?.discursive_content !== ""
        }

        return allowNext
     
    }

    return (
        (skills && skills?.length > 0) &&
        (
            currentTab < skills?.length - 1
            ?
            <div className={styles.containerForm}>
                <header>
                    <h4>
                    {`${currentTab+1}. ${skills[currentTab]?.name || '------'}`}
                    {skills[currentTab]?.description && (
                        <BefectiveTooltip
                        title={
                            <Typography component={"div"} variant="caption">
                            {skills[currentTab]?.description}
                            </Typography>
                        }
                        placement="right-start"
                        arrow
                        interactive
                        >
                            <span style={{ cursor: "pointer" }}>
                                &nbsp;&nbsp;
                                <IconInfo width={16} hoverColor="#FED852"/>
                            </span>
                        </BefectiveTooltip>
                    )}
                    </h4>
                </header>
                <section>
                    {skills[currentTab]?.questions &&
                        skills[currentTab]?.questions?.length > 0 ? (
                        <ol type="A">
                            {skills[currentTab]?.questions?.map((question, index) => (
                            (index === 0 || ( index > 0 && skills[currentTab]?.questions[index - 1]?.options?.find(option => option.selected)) || ( index > 0 && skills[currentTab]?.questions[index - 1]?.is_objective === false && skills[currentTab]?.questions[index - 1]?.discursive_content)) 
                            ?
                                <li key={`question-${index}`}>
                                    <p>{question?.questions_content}</p>
                                    {question?.options &&
                                    question?.options?.length > 0 ? (
                                    <div>
                                        {
                                        question?.is_objective === true
                                        ?                                        
                                            question?.options?.map((option, index2) => (
                                                <BefectiveTooltip
                                                    title={
                                                        <Typography component={"div"} variant="caption">
                                                        {option?.help_text}
                                                        </Typography>
                                                    }
                                                    placement="bottom-start"
                                                    enterDelay={1500}
                                                    leaveDelay={200}
                                                    arrow
                                                >
                                                    <button
                                                        key={`option-button-${index2}`}
                                                        onClick={() => updateOptionQuestions(skills[currentTab], question, option)}
                                                        className={`${option?.selected ? styles.optionActive : ""}`}
                                                    >
                                                        {option?.label}
                                                    </button>
                                                </BefectiveTooltip>
                                            ))
                                        :
                                        <input type="text" className={styles.inputDiscursive} placeholder={t("colab_page.performance_assessment.form.placeholder_discursive")} value={question?.discursive_content} onChange={(e) => updateOptionQuestions(skills[currentTab], question, e.target.value)}/>
                                    }
                                    </div>
                                    ) : (
                                        question?.is_objective === false
                                        ?
                                        <input type="text" className={styles.inputDiscursive} placeholder={t("colab_page.performance_assessment.form.placeholder_discursive")}  value={question?.discursive_content} onChange={(e) => updateOptionQuestions(skills[currentTab], question, e.target.value)}/>
                                        :
                                        <p>{t("performance_assessment.messages.none_options")}</p>
                                    )}
                                </li>
                            :
                                <li key={`question-${index}`}>
                                    <p className={styles.opacityTextSkeleton}>
                                        {question?.questions_content}
                                        {/* <Skeleton animation={false}/> */}
                                    </p>
                                    <div>
                                        {
                                            question?.is_objective === true
                                            ?
                                            question?.options?.map((option, index2) => (
                                                // <Skeleton key={`option-skeleton-${index2}`} height={"45px"} width={"80px"} sx={{marginRight: "8px"}} animation={false}/>
                                                <button
                                                    disabled
                                                    key={`option-button-${index2}`}
                                                    className={`${styles.opacityButtonSkeleton}`}
                                                >
                                                    {option?.label}
                                                </button>

                                            ))
                                            :
                                            // <Skeleton height={"75px"} width={"100%"} sx={{marginRight: "8px"}} animation={false}/>
                                            <input type="text" className={`${styles.inputDiscursive} ${styles.opacityButtonSkeleton}`} placeholder={t("colab_page.performance_assessment.form.placeholder_discursive")} disabled/>
                                        }
                                    </div>
                                </li>
                            ))}
                        </ol>
                        ) : (
                            <div className={styles.noneBox}>{t("performance_assessment.messages.none_questions")}</div>
                        )
                    }
                </section>

                {
                    showButtonNext(skills[currentTab]?.questions) &&
                    <footer>       
                        {
                            currentTab > 0 &&
                            <button className={styles.buttonPrevious} onClick={() => currentTab > 0 && setCurrentTab(currentTab - 1)}>
                            {t("performance_assessment.actions.previous")}
                            </button>
                        }         
                        <button onClick={() => nextTab(skills[currentTab])} disabled={!allowNext(skills[currentTab]?.questions)}>
                        {t("performance_assessment.actions.next")}
                        </button>
                    </footer>
                }
            </div>
            :
            <div className={styles.containerForm} style={{width: "620px"}}>
                <header>
                    <h4>
                        {skills[currentTab]?.name}
                    </h4>
                    {
                        (data?.allowed_todo_keep_doing || data?.allow_todo_should_do) &&
                        <p>{t('colab_page.performance_assessment.form.info_conclusion')}</p>
                    }
                </header>
                <section>
                    {
                        data?.allowed_todo_keep_doing &&
                        <FeedbackTodoList
                            title={`${t('colab_page.performance_assessment.form.what')} ${data?.self_assessment ? t('colab_page.performance_assessment.form.you') : data?.evaluated_username} ${t('colab_page.performance_assessment.form.keep_doing')}`}
                            items={keepDoingArray}
                            setItems={setKeepDoingArray}
                        />
                    }

                    {
                        data?.allow_todo_should_do &&
                        <FeedbackTodoList
                            title={`${t('colab_page.performance_assessment.form.what')} ${data?.self_assessment ? t('colab_page.performance_assessment.form.you') : data?.evaluated_username} ${t('colab_page.performance_assessment.form.should_do')}`}
                            items={shouldDoArray}
                            setItems={setShouldDoArray}
                        />                    
                    }
                </section>
                <footer>                
                    <button className={styles.buttonPrevious} onClick={() => currentTab > 0 && setCurrentTab(currentTab - 1)} disabled={loading}>
                    {t("performance_assessment.actions.previous")}
                    </button>
                    <button onClick={handleConcludeAssessment} disabled={loading} style={{cursor: loading && "progress"}}>
                        {
                            loading
                            ?
                                <CircularProgress size={14} color="inherit"/>
                            :
                            t("performance_assessment.actions.conclude")
                        }
                    </button>
                </footer>
                <div className={styles.warningEdit}>
                    <Warning size={24} weight="bold" color="#A9C6D0" />
                    <p>{t('colab_page.performance_assessment.form.warning_edit')}</p>
                </div>
            </div>

        )
    )
}


