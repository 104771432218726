import React, { useState } from 'react'
import style from './WorkspaceBoards.module.scss';
import { AvatarGroup, Box, Button, CardActionArea, CardActions, Divider, Grid, Menu, MenuItem, Popover, Tooltip } from '@mui/material';
import { authHeader, befectiveFormatDate, history, limitedText } from '../../_helpers';
import { Edit, ExpandMore, Favorite, MoreVert, PeopleOutline, Share, Tune } from '@material-ui/icons';
import { Link, NavLink } from 'react-router-dom/cjs/react-router-dom';
import ModalEditBoard from './ModalEditBoard';
import { useEffect } from 'react';
import { api } from '../../_helpers/api';
import { useTranslation } from 'react-i18next';
import { Clock, Heart, HeartStraight, Kanban, Pencil, PencilSimple, Star, UsersThree } from 'phosphor-react';
import ModalEditWorkspace from './ModalEditWorkspace';
import { IconButton, Typography } from '@material-ui/core';
import ModalCreateBoard from './ModalCreateBoard';
import MembersWorkspaceModal from './MembersWorkspaceModal';


export default function WorkspaceBoards({
  data,
  userDetails,
  alertActions,
  colabs,
  getData,
  workspaces = [],
  setOpenModalCreateBoard,
  openModalCreateBoard
}) {

  const { t, i18n } = useTranslation('common');

  const [currentWorkspace, setCurrentWorkspace] = useState(null)
  const [currentBoard, setCurrentBoard] = useState(null)
  const [openModalEditBoard, setOpenModalEditBoard] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleting, setDeleting] = useState(false)
  const [anchorElDeleteBoard, setAnchorElDeleteBoard] = useState(null);
  const [openModalEditWorkspace, setOpenModalEditWorkspace] = useState(false)
  const [openModalMembers, setOpenModalMembers] = useState(false)

  const handleClickDeleteBoard = (event) => {
    setAnchorElDeleteBoard(event.currentTarget);
  };
  const handleCloseDeleteBoard = () => {
    setAnchorElDeleteBoard(null);
  };

  const open = Boolean(anchorEl);
  const openDeleteBoard = Boolean(anchorElDeleteBoard);
  const id = openDeleteBoard ? 'simple-popover-delete-goal' : undefined;

  const handleClick = (event, board) => {
    setCurrentBoard(board)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentBoard(null)
  };

  const handleFavoriteBoard = async (board_id) => {
    try {
      const result = await api.post(`/project-management/boards/favorite`,
        {
          mu_id: userDetails.id,
          board_id: board_id
        },
        {
          headers: authHeader()
        }
      )
      getData(false)
    } catch (error) {
      setDeleting(false)
    }
  }

  const handleDeleteBoard = async () => {
    try {
      setDeleting(true)
      const result = await api.delete(`/project-management/boards/${currentBoard?.id}`,
        {
          headers: authHeader()
        }
      )
      handleCloseDeleteBoard()
      handleClose()
      getData()
      alertActions.success(t('project_management.success.deleted_board'))
      setDeleting(false)
    } catch (error) {
      alertActions.success(t('project_management.errors.deleted_board'))
      setDeleting(false)
    }
  }

  const handleArchiveBoard = async () => {
    try {
      setDeleting(true)
      const result = await api.put(`/project-management/boards/${currentBoard?.id}`,
        {
          ...currentBoard,
          archive: true
        },
        {
          headers: authHeader()
        }
      )
      handleCloseDeleteBoard()
      handleClose()
      getData()
      alertActions.success(t('project_management.success.unarchived_board'))
      setDeleting(false)
    } catch (error) {
      //alertActions.error(t('project_management.errors.unarchived_board'))
      handleCloseDeleteBoard()
      handleClose()
      setDeleting(false)
    }
  }


  useEffect(() => {
    if (openModalEditBoard === false) {
      handleClose()
    }
  }, [openModalEditBoard])

  return (
    <Box display={`flex`} flexDirection={'column'} gap={'26px'}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <Typography variant='h6' style={{ color: '#1E6B7F' }}>{data?.title}</Typography>
          <IconButton aria-label="edit-workspace" size="small" style={{ marginLeft: '2px' }} onClick={() => {
            setOpenModalEditWorkspace(true)
          }}>
            <Edit fontSize="inherit" style={{ fill: '#1E6B7F' }} />
          </IconButton>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={'6px'}>
          <Button
            variant="outlined"
            size="small"
            className={style.buttonActions}
            startIcon={<PeopleOutline />}
            onClick={() => {
              setOpenModalMembers(true)
            }}
          >
            {t('project_management.workspaces.members') + ` (${data.members.length})`}
          </Button>
        </Box>
      </Box>
      <hr style={{ width: '100%', borderColor: '#DCDFE446', margin: 0 }} />
      {/* Section Boards */}
      <Box className="container">
        <Box display={"flex"} flexDirection={"column"} gap={'10px'}>
          <Box display={'flex'} alignItems={'center'} marginBottom={'0px'} gap={'4px'}>
            <Kanban size={20} color="#1E6B7F" />
            <Typography variant='h6' style={{ color: '#1E6B7F', fontSize: '16px' }}>{t('project_management.workspaces.boards')}</Typography>
          </Box>
          <Box>
            {
              data.boards.length > 0
                ?
                <Grid container spacing={2}>
                  {
                    data.boards.map((board, index) => (
                      <Grid className={style.gridBoard} key={index} item xs={12} sm={6} md={4} lg={3} xl={2} position={'relative'} height={'100px'}>
                        <Link
                          className={style.linkBoard}
                          to={`/project_management/home/boards/${board.id}`}
                        >
                          <Box
                            style={{ borderRadius: '8px', backgroundColor: !board.color || board.color === '#ffffff' ? '#ffffff' : board.color, height: '100%' }}
                            className={style.boardCard}
                          >
                            <Box style={{ padding: '10px 30px 0px 10px' }} zIndex={1}>
                              {
                                board.title.length > 40
                                  ?
                                  <Tooltip title={board.title} placement="bottom" style={{ maxWidth: '300px' }}
                                    PopperProps={{
                                      modifiers: [
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [0, -10],
                                          },
                                        },
                                      ],
                                    }}
                                  >
                                    <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F', lineHeight:'18px' }}>{limitedText(board.title, 40)}</Typography>
                                  </Tooltip>
                                  :
                                  <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F', lineHeight:'18px' }}>{board.title}</Typography>
                              }
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'} padding={'0px 30px 10px 10px'} zIndex={1}>
                              <Box>
                                <Typography variant='subtitle2' style={{ fontSize: '11px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F'}}>
                                  {befectiveFormatDate(board.date_start, i18n.language) + " - " + befectiveFormatDate(board.date_end, i18n.language)}&nbsp;&nbsp;{`(${board.cards_stats?.total_done}/${board.cards_stats?.total_cards})`}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>                          
                          {board.color !== '#ffffff' && <Box className={style.boxBright}></Box>}
                        </Link>
                        <Box position={'absolute'} top={'20px'} right={'4px'} zIndex={2}>
                          <IconButton aria-label="settings" size='small' onClick={(event) => handleClick(event, board)}>
                            <MoreVert fontSize='small' style={{ fill: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F' }} />
                          </IconButton>
                        </Box>
                        <Box className={`${!board.is_favorite && style.favorite}`} position={'absolute'} bottom={'4px'} right={'4px'} zIndex={2}>
                          <IconButton aria-label="settings" size='small' onClick={() => handleFavoriteBoard(board.id)}>
                            {
                              board.is_favorite
                                ?
                                <Star size={20} color="#FDBE2B" weight="fill" />
                                :
                                <Star size={20} color={board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F'} />
                            }
                          </IconButton>
                        </Box>
                      </Grid>
                    ))
                  }
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2} position={'relative'} height={'100px'}>
                    <Box
                      style={{ borderRadius: '8px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      className={style.cardNew}
                      elevation={0}
                      onClick={() => {
                        setCurrentWorkspace(data)
                        setOpenModalCreateBoard(true)
                      }}
                    >
                      <Box style={{ padding: '8px 12px' }}>
                        <Typography variant='subtitle2' style={{ fontSize: '12px', fontWeight: 400, color: '#1E6B7F' }}>{t('project_management.workspaces.new_board')}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                :
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2} position={'relative'} height={'100px'}>
                    <Box
                      style={{ borderRadius: '8px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      className={style.cardNew}
                      elevation={0}
                      onClick={() => {
                        setCurrentWorkspace(data)
                        setOpenModalCreateBoard(true)
                      }}
                    >
                      <Box style={{ padding: '8px 12px' }}>
                        <Typography variant='subtitle2' style={{ fontSize: '12px', fontWeight: 400, color: '#1E6B7F' }}>{t('project_management.workspaces.new_board')}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
            }
          </Box>

          <Popover
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            disableEnforceFocus
          >
            <Box className={style.menuOptionsBoard}>
              <button className={style.menuOptionsBoardButton} onClick={() => setOpenModalEditBoard(true)}>{t('project_management.btn_edit')}</button>
              <button className={style.menuOptionsBoardButton} aria-describedby={id} onClick={handleClickDeleteBoard}>{t('project_management.btn_archive')}</button>
            </Box>
          </Popover>

          <Popover
            id={id}
            open={openDeleteBoard}
            anchorEl={anchorElDeleteBoard}
            onClose={handleCloseDeleteBoard}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            disableEnforceFocus
          >
            <Box
              sx={{
                backgroundColor: '#FFE0DF'
              }}
            >
              <Typography variant="subtitle2" style={{ padding: '12px', color: '#FC6662', fontSize: '12px', fontWeight: '600' }}>{t('project_management.boards.title_dialog_archive')}</Typography>
              <Box paddingBottom={2} paddingRight={2} display={'flex'} style={{ gap: '6px' }} justifyContent={'flex-end'}>
                <Button
                  onClick={handleCloseDeleteBoard}
                  disabled={deleting}
                  style={{
                    width: '42px !important',
                    height: '25px',
                    backgroundColor: '#fff',
                    color: '#FC6662',
                    fontSize: '10px',
                    textTransform: 'capitalize'
                  }}
                >
                  {t('confirmDialog.no')}
                </Button>
                <Button
                  onClick={handleArchiveBoard}
                  disabled={deleting}
                  style={{
                    width: '42px !important',
                    height: '25px',
                    backgroundColor: '#FC6662',
                    color: '#fff',
                    fontSize: '10px',
                    textTransform: 'capitalize'
                  }}
                >
                  {t('confirmDialog.yes')}
                </Button>
              </Box>
            </Box>
          </Popover>

          <MembersWorkspaceModal
            openModalMembers={openModalMembers}
            setOpenModalMembers={setOpenModalMembers}
            userDetails={userDetails}
            colabsOptions={colabs}
            alertActions={alertActions}
            updateInfo={getData}
            currentWorkspace={data}
          />

          <ModalEditBoard
            openModalEditBoard={openModalEditBoard}
            setOpenModalEditBoard={setOpenModalEditBoard}
            userDetails={userDetails}
            colabsOptions={colabs}
            alertActions={alertActions}
            updateInfo={getData}
            currentBoard={currentBoard}
            closeMenu={handleClose}
            workspaces={workspaces}
          />

          {
            currentWorkspace &&
            <ModalCreateBoard
              openModalCreateBoard={openModalCreateBoard}
              setOpenModalCreateBoard={setOpenModalCreateBoard}
              userDetails={userDetails}
              colabsOptions={colabs}
              alertActions={alertActions}
              updateInfo={getData}
              workspaces={workspaces}
              currentWorkspace={currentWorkspace}
              setCurrentWorkspace={setCurrentWorkspace}
            />
          }

          {openModalEditWorkspace &&
            <ModalEditWorkspace
              openModalEditWorkspace={openModalEditWorkspace}
              setOpenModalEditWorkspace={setOpenModalEditWorkspace}
              userDetails={userDetails}
              colabsOptions={colabs}
              alertActions={alertActions}
              updateInfo={getData}
              currentWorkspace={data}
            />
          }
        </Box>
      </Box>

    </Box>
  )
}
