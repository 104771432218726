import React from "react";
import styles from "./Global.module.scss";
import GaugeComponent from "react-gauge-component";
import { useTranslation } from "react-i18next";
import { truncateNumber } from "../../_helpers";

const GaugeChartCard = ({
    data
}) => {
    const { t, i18n } = useTranslation('common');

    return (
        <div className={styles.card}>
            <div className={styles.gaugeChartBox}>
                <GaugeComponent
                    type="semicircle"
                    arc={{
                        width: 0.1,
                        padding: 0.005,
                        cornerRadius: 1,
                        subArcs: [
                        {
                            limit: 2,
                            color: '#FF7D25'
                        },
                        {
                            limit: 3,
                            color: '#FDBE2B'
                        },
                        {
                            limit: 4,
                            color: '#86B2B3'
                        },
                        {
                            limit: 5,
                            color: '#22BEAD'
                        },
                        {
                            limit: 6,
                            color: '#1E6B7F'
                        }
                        ]
                    }}
                    pointer={{type: "arrow", elastic: true, color:data?.general_classification?.text_color || "#4F7091", width: 15}}
                    value={Number(data?.general_avg)}
                    labels={{
                        valueLabel: { 
                        style: {
                            fill: data?.general_classification?.text_color || "#4F7091", // Custom color for the main value
                            fontSize: '24px', // You can adjust the font size as needed
                            fontWeight: 'bold', // You can also set the font weight
                            textShadow: 'none'
                        },
                        formatTextValue: value => String(data?.general_avg),
                        hide: true
                        }
                        
                    }}
                    minValue={1}
                    maxValue={6}
                />
                <div className={styles.labelScoreGeneral}>
                    <h4 style={{color:data?.general_classification?.text_color || "#4F7091"}}>{truncateNumber(data?.general_avg)}</h4>
                    <p style={{color:'#1E6B7F'}}>{t('colab_page.performance_assessment.general_score')}</p>
                </div>
            </div>

            <div className={styles.gaugeStatsBox}>
                <div>
                    <h6 style={{color:data?.general_classification?.text_color || "#4F7091"}}>{truncateNumber(data?.last_avg_evaluated)}</h6>
                    <span>{t('colab_page.performance_assessment.previous')}</span>
                </div>
                <div>
                    <h6 style={{color:data?.score_change > 0 ? "#22BEAD" : (data?.score_change < 0 ? "#FF7D25" : "#4F7091")}}>
                        {data?.score_change > 0 ? "+" : ""}{truncateNumber(data?.score_change)}
                    </h6>
                    <span>{t('colab_page.performance_assessment.change')}</span>
                </div>
            </div>
        </div>
    )
}

export default GaugeChartCard;
