import React from 'react'
import styles from './styles.module.scss'
import HeaderForm from './HeaderForm'
import { useTranslation } from 'react-i18next'
import { befectiveFormatDate } from '../../../_helpers'


export default function FormPersonalPreviewPT({
  formData,
  toogleShowForm,
  isEditing,
  isExistUserId,
  userId
}) {

  const {t, i18n} = useTranslation('common')

  return (
    <div className={styles.containerPreview}>
      <div className={styles.card}>
        <HeaderForm formData={formData} toogleShowForm={toogleShowForm} isEditing={isEditing} titleCreate={t('settings.users.personal_form.title_create')} isExistUserId={isExistUserId} userId={userId}/>
        <div className={styles.cardContent}>
            <div className={styles.col50}>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.full_name')}</span>
                <h6 className={styles.value}>{formData?.full_name || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.birth_date')}</span>
                <h6 className={styles.value}>{formData?.birth_date ? befectiveFormatDate(formData?.birth_date, i18n.language) : '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.birth_location')}</span>
                <h6 className={styles.value}>{formData?.birth_location || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.gender')}</span>
                <h6 className={styles.value}>{formData?.sex?.label || '-----'}</h6>
              </div>
              
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.marital_status')}</span>
                <h6 className={styles.value}>{formData?.marital_status && formData?.marital_status?.label_pt ? formData?.marital_status[`label_${i18n.language}`] : '-----'}</h6>
              </div>
            </div>

            <div className={styles.col50}>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.document_1')}</span>
                <h6 className={styles.value}>{formData?.document_1 || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.document_2')}</span>
                <h6 className={styles.value}>{formData?.document_2 || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.blood_type')}</span>
                <h6 className={styles.value}>{formData?.blood_type && formData?.blood_type?.label_pt ?  formData?.blood_type[`label_${i18n.language}`] : '-----'}</h6>
              </div>
              
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.nationality')}</span>
                <h6 className={styles.value}>{formData?.nationality && formData?.nationality?.label_pt ? formData?.nationality[`label_${i18n.language}`] : '-----'}</h6>
              </div>
            </div>
        </div>
      </div>
      <div className={styles.card}>
        <header className={styles.cardHeader}>
          <h6>
            {t('settings.users.personal_form.address')}
          </h6>
        </header>
        <div className={styles.cardContent}>
           
            <div className={styles.col50}>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.cep')}</span>
                <h6 className={styles.value}>{formData?.CEP || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.street')}</span>
                <h6 className={styles.value}>{formData?.street || '-----'}</h6>
              </div>
              
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.neighborhood')}</span>
                <h6 className={styles.value}>{formData?.neighborhood || '-----'}</h6>
              </div>
            </div>
            <div className={styles.col50}>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.number')}</span>
                <h6 className={styles.value}>{formData?.address_number || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{`${t('settings.users.personal_form.city')} / ${t('settings.users.personal_form.state')} / ${t('settings.users.personal_form.country')}`}</span>
                <h6 className={styles.value}>{`${formData?.city || '-----'} / ${formData?.state?.label || '-----'} / ${formData?.country?.label || '-----'}`}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.obs')}</span>
                <h6 className={styles.value}>{formData?.observation || '-----'}</h6>
              </div>
            </div>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.cardContent}>           
            <div className={styles.col50}>
              <div className={styles.field}>
                <h4>
                  {t('settings.users.personal_form.contact')}
                </h4>
              </div>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.email')}</span>
                <h6 className={styles.value}>{formData?.email || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.cell_phone')}</span>
                <h6 className={styles.value}>{formData?.phone_number || '-----'}</h6>
              </div>
              
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.telephone')}</span>
                <h6 className={styles.value}>{formData?.telephone_number || '-----'}</h6>
              </div>
            </div>
            <div className={styles.col50}>
              <div className={styles.field}>
                <h4>
                  {t('settings.users.personal_form.contact_emergency')}
                </h4>
              </div>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.full_name')}</span>
                <h6 className={styles.value}>{formData?.emergency_contact_full_name || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.attribuition')}</span>
                <h6 className={styles.value}>{formData?.emergency_contact_assignment && formData?.emergency_contact_assignment?.label_pt ? formData?.emergency_contact_assignment[`label_${i18n.language}`] : '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.personal_form.cell_phone')}</span>
                <h6 className={styles.value}>{formData?.emergency_contact_phone_number || '-----'}</h6>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}
