import React, { useEffect, useState } from 'react'
import styles from './ActionPlanForm.module.scss'
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import moment from 'moment';
import BefectiveCheckbox from '../../_shared/BefectiveCheckbox';
import { Add, Close, Delete, Edit, Save } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import { authHeader, befectiveFormatDate } from '../../../_helpers';
import { api } from '../../../_helpers/api';


export default function RowNewItem({
  currentItem,
  form, 
  setForm,
  setEnableAddTodo,
  inputFormat,
  disableFields
}) {
  const { t, i18n } = useTranslation("common");
  const [newItem, setNewItem] = useState({
    id: currentItem.id,
    done: currentItem.done,
    content: currentItem.content,
    deadline: currentItem.deadline
  })
  const [disableActions, setDisableActions] = useState(false);
  const [enableButtonEdit, setEnableButtonEdit] = useState(true);
  const [updatingActive, setUpdatingActive] = useState(false);

  const onChange = (e) => {
    const { name, value, type, checked } = e.target

    if(type === "checkbox"){
      setNewItem({
        ...newItem,
        [name]: checked
      })
      return
    }
    setNewItem({
      ...newItem,
      [name]: value
    })
  }

  const addNewItem = () => {
    setForm({
      ...form,
      todo_list: [...form.todo_list, {...newItem, id: uuidv4()}]
    })
    setNewItem({
      id: null,
      done: false,
      content: "",
      deadline: null
    })
  }

  const deleteItem = () => {
    setForm({
      ...form,
      todo_list: form.todo_list.filter(item => item.id !== newItem.id)
    })
    setEnableButtonEdit(true)
  }

  const updateItem = () => {
    setForm({
      ...form,
      todo_list: form.todo_list.map(item => {
        if(item.id === newItem.id){
          return newItem
        }
        return item
      })
    })
    setEnableButtonEdit(true)
  }

  const closeNewItem = () => {
    setNewItem({
      id: null,
      done: false,
      content: "",
      deadline: null
    })
    setEnableAddTodo(false)
  }

  const updateActive = async (item) => {
    try{
      if(!item.id){
        throw new Error("Item not found")
      }
      setUpdatingActive(true)
      await api.put(`/performance-assessment/action-plan/item`, item, {headers: authHeader()})
      setUpdatingActive(false)
    }catch(error){
      console.log(error)
      setUpdatingActive(false)
    }
  }

  const onChangeUpdateActive = (e) => {
    const { name,  checked } = e.target;

    const item = {
      ...newItem,
      [name]: checked
    }
    setNewItem(item)

    setForm({
      ...form,
      todo_list: form.todo_list.map(item => {
        if(item.id === newItem.id){
          return {
            ...item,
            [name]: checked
          }
        }
        return item
      })
    })

    if(disableFields){
      updateActive(item)
    }
  }

  return (
    <tr>
      <td>
        {
          newItem?.id === null
            ?
              <div className={`${styles.boxItemCheck} ${styles.field}`}>                
                {/* <BefectiveCheckbox 
                name='done'
                checked={newItem?.done} 
                onChange={onChange}/>
                 */}
                <input 
                type="text" 
                name="content" 
                value={newItem?.content} 
                onChange={onChange} 
                placeholder={t('performance_assessment.page_action_plan.checklists.placeholder_step')}              
                />
              </div>
            :            
            <div className={`${styles.boxItemCheck} ${styles.field}`}>
               <BefectiveCheckbox 
                  name='done'
                  checked={newItem?.done} 
                  onChange={onChangeUpdateActive}
                  disabled={updatingActive}
                />
                {
                  !enableButtonEdit
                  ?
                    <input 
                      type="text" 
                      name="content" 
                      value={newItem?.content} 
                      onChange={onChange} 
                      placeholder={t('performance_assessment.page_action_plan.checklists.placeholder_step')}              
                    />
                  :
                    <span style={{textDecoration: newItem?.done ? 'line-through' : 'none'}}>{newItem?.content}</span>
                }
            </div>
        }
      </td>
      <td style={{width: '160px'}}>
          {
            newItem?.id === null
            ?
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={i18n.language}>
              <DatePicker
                label={""}
                value={newItem?.deadline}
                inputFormat={inputFormat}
                onChange={(value) => setNewItem({...newItem, deadline: moment(value.$d).format().split("T")[0]})}
                renderInput={(params) => (
                  <TextField
                    id='deadline'
                    variant='outlined'
                    {...params}
                    sx={{ 
                        width: '160px', 
                        height: '48px', 
                        '& .MuiOutlinedInput-root': {
                        fieldset: {
                          border: '1px solid #D6DEEB',
                          borderRadius: '2px',
                        },
                        input: {
                          color: '#4F7091',
                          padding: '14px',
                          borderRadius: '2px',
                        },
                      }
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            :
            (              
              !enableButtonEdit
              ?
                <LocalizationProvider dateAdapter={AdapterDayjs} locale={i18n.language}>
                  <DatePicker
                    label={""}
                    value={newItem?.deadline}
                    inputFormat={inputFormat}
                    onChange={(value) => setNewItem({...newItem, deadline: moment(value.$d).format().split("T")[0]})}
                    renderInput={(params) => (
                      <TextField
                        id='deadline'
                        variant='outlined'
                        {...params}
                        sx={{ 
                            width: '160px', 
                            height: '48px', 
                            '& .MuiOutlinedInput-root': {
                            fieldset: {
                              border: '1px solid #D6DEEB',
                              borderRadius: '2px',
                            },
                            input: {
                              color: '#4F7091',
                              padding: '14px',
                              borderRadius: '2px',
                            },
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              :
                <span>{befectiveFormatDate(newItem?.deadline, i18n.language)}</span>
            )
          }
      </td>
      <td>
        {
          newItem.id !== null 
          ?
            <div className={styles.boxButtonsOptions}>
              {
                enableButtonEdit
                ?
                ( !disableFields &&
                  <button
                      className={styles.buttonAddOption}
                      onClick={() => setEnableButtonEdit(false)}
                      disabled={disableActions}
                      type='button'
                    >
                      <Edit style={{color:"#187DFF"}} fontSize='small'/>
                    </button>
                )
                :
                <>
                 <button
                    className={styles.buttonCloseOption}
                    onClick={() => setEnableButtonEdit(true)}
                    disabled={disableActions}
                    type='button'
                  >
                    <Close style={{color:"#106276"}} fontSize='small' />
                  </button>
                  <button
                      className={styles.buttonRemoveOption}
                      onClick={deleteItem}
                      disabled={disableActions}
                      type='button'
                  >
                      <Delete style={{color:"#FC6662"}} fontSize='small' />
                  </button>
                  <button
                      className={styles.buttonAddOption}
                      onClick={updateItem}
                      disabled={disableActions}
                      type='button'
                  >
                      <Save style={{color:"#187DFF"}} fontSize='small'/>
                  </button>
                </>

              }
            </div>
          :
            <div className={styles.boxButtonsOptions}>
                <button
                    className={styles.buttonRemoveOption}
                    onClick={closeNewItem}
                    disabled={disableActions}
                    type='button'
                >
                  <Close style={{color:"#FC6662"}} fontSize='small' />
                </button>
                <button
                    className={styles.buttonAddOption}
                    onClick={addNewItem}
                    disabled={disableActions}
                    type='button'
                >
                  <Add style={{color:"#187DFF"}} fontSize='small' />
                </button>
            </div>

        }

      </td>
    </tr>
  )
}
