import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from './Global.module.scss'
import ReactApexChart from "react-apexcharts";
import { truncateNumber } from "../../_helpers";

const MyPerformanceCard = ({
    data
}) => {

    const { t, i18n } = useTranslation('common');

    const [chartRadarData, setChartRadarData] = useState({
        series: [{
            name: '',
            data: [0, 0, 0, 0, 0],
          }],
        options: {
          colors: [],
          chart: {
            height: 250,
            type: 'radar',
            toolbar: {
              show: false // Disable the export options
            }
          },
          yaxis: {
            stepSize: 1,
            labels: {
              style: {
                width: '80px',
                fontSize: '8px',
              }
            }
          },
          xaxis: {
            categories: [' ', '  ', '   ', '    ', '      '],
            labels: {
              style: {
                width: '80px',
                fontSize: '8px'
              }
            }
          },
          legend: {
            show: false // Disable the legend
          },
          plotOptions: {
            radar: {
              size: 80,
            }
          }
        }
    })

    useEffect(() => {
        if(!data) return
        setChartRadarData({
            ...chartRadarData,
            series: data?.series?.length > 0 ? data?.series?.map((serie) => ({name: t(`colab_page.performance_assessment.${serie?.name}`), data: serie?.data})) : chartRadarData?.series,
            options: {
                ...chartRadarData.options,
                colors: data?.colors || [],
                xaxis: {
                    ...chartRadarData.options.xaxis,
                    categories: data?.categories || []
                }
            }
        })
    }, [data])

    return (
        <div className={styles.cardSecondary}>
            <header className={styles.headerCard}>
                <h6>{t('colab_page.performance_assessment.my_performance')}</h6>
            </header>
            <div id="chartRadarMyPerformance" className={styles.boxChartRadar}>
                <ReactApexChart 
                    options={chartRadarData?.options} 
                    series={chartRadarData?.series} 
                    type="radar" 
                    height={250}                     
                />
            </div>
            <ul className={styles.listTypeAssessment}>
                <li className={styles.listItemTypeAssessment}>
                    <div className={styles.itemTitleBox}>
                        <div className={styles.itemCircle} style={{backgroundColor: data?.colors.length > 0 ? data?.colors[0] : '#ccc'}}></div>
                        <span>{t('colab_page.performance_assessment.self_assessment')}</span>
                    </div>
                    <div className={styles.itemValueBox}>
                        <span>{truncateNumber(data?.self_assessment?.avg)}</span>
                    </div>
                </li>
                <li className={styles.listItemTypeAssessment}>
                    <div className={styles.itemTitleBox}>
                        <div className={styles.itemCircle} style={{backgroundColor: data?.colors.length > 0 ? data?.colors[1] : '#ccc'}}></div>
                        <span>{t('colab_page.performance_assessment.assessment_pairs')}</span>
                    </div>
                    <div className={styles.itemValueBox}>
                        <span>{truncateNumber(data?.peer_assessment?.avg)}</span>
                    </div>
                </li>
                <li className={styles.listItemTypeAssessment}>
                    <div className={styles.itemTitleBox}>
                        <div className={styles.itemCircle} style={{backgroundColor: data?.colors.length > 0 ? data?.colors[2] : '#ccc'}}></div>
                        <span>{t('colab_page.performance_assessment.assessment_manager')}</span>
                    </div>
                    <div className={styles.itemValueBox}>
                        <span>{truncateNumber(data?.manager_assessment?.avg)}</span>
                    </div>
                </li>
                <li className={styles.listItemTypeAssessment}>
                    <div className={styles.itemTitleBox}>
                        <div className={styles.itemCircle} style={{backgroundColor: data?.colors.length > 0 ? data?.colors[3] : '#ccc'}}></div>
                        <span>{t('colab_page.performance_assessment.avg_general_company')}</span>
                    </div>
                    <div className={styles.itemValueBox}>
                        <span>{truncateNumber(data?.general_company?.avg)}</span>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default MyPerformanceCard;