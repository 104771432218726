// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RcY54DZJfmpNe1RzFXev5g\\=\\= {\n  display: flex;\n  flex: 1;\n  min-width: 165px;\n}\n\n.siEFVe8og3U0PJ3w5daKWA\\=\\= {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n}\n\n.UH\\+HwYME7lMCbVDPsmS0ug\\=\\= {\n  font-size: 18px !important;\n  font-weight: 400;\n  margin-top: 6px !important;\n  margin-bottom: 4px !important;\n}\n\n.mxOv8r\\+bLC85hcwSc1AcMw\\=\\= {\n  color: #1E6B7F;\n  font-size: 12px !important;\n}", "",{"version":3,"sources":["webpack://./src/_components/performance_assessments/PerformanceAssessmentsDetails/ResultsStatsCards.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,OAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AACF;;AAEA;EACE,0BAAA;EACA,gBAAA;EACA,0BAAA;EACA,6BAAA;AACF;;AAEA;EACE,cAAA;EACA,0BAAA;AACF","sourcesContent":[".cardContainer {\n  display: flex;\n  flex: 1;\n  min-width: 165px;\n}\n\n.cardHeader {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n}\n\n.textStats {\n  font-size: 18px !important;\n  font-weight: 400;\n  margin-top: 6px !important;\n  margin-bottom: 4px !important;\n}\n\n.textInfo {\n  color: #1E6B7F;\n  font-size: 12px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": "RcY54DZJfmpNe1RzFXev5g==",
	"cardHeader": "siEFVe8og3U0PJ3w5daKWA==",
	"textStats": "UH+HwYME7lMCbVDPsmS0ug==",
	"textInfo": "mxOv8r+bLC85hcwSc1AcMw=="
};
export default ___CSS_LOADER_EXPORT___;
