import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Modal,
  Slide,
  makeStyles,
} from "@material-ui/core";
import styles from "./EvaluatorsEditModal.module.scss";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { api } from "../../../_helpers/api";
import { authHeader } from "../../../_helpers";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function EvaluatorsEditModal({ openModal, closeModal, currentEvaluated, evaluators = [], updateEvaluators}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const [evaluatorsOptions, setEvaluatorsOptions] = useState([]);
  const [evaluatorsSelected, setEvaluatorsSelected] = useState(evaluators);

  const getUserByTeam = async() => {
    try {
      const queryTeam = evaluatorsSelected.length > 4 ? `?team_id=${currentEvaluated.group_id}` : ''
      const {data} = await api.get(`/performance-assessment/get-users${queryTeam}`, {
        headers: authHeader()
      })
      setEvaluatorsOptions(data)
    } catch (error) {
      console.log(error)
    }
  }

  const onChangeEvaluators = (newEvaluator, oldEvaluator) => {
    
    if(!oldEvaluator){
      setEvaluatorsSelected([...evaluatorsSelected, {...newEvaluator, machine_users_id: newEvaluator.value, username: newEvaluator.label}])
      return
    }

    if(newEvaluator) { 
      //replace the old evaluator with the new one
      setEvaluatorsSelected(evaluatorsSelected.map(evaluator => (evaluator.id === oldEvaluator.id) ? {...newEvaluator, machine_users_id: newEvaluator.value, username: newEvaluator.label} : evaluator))
    } else {
      //remove the evaluator
      setEvaluatorsSelected(evaluatorsSelected.filter(evaluator => (evaluator.id !== oldEvaluator.id)))
    }
    
  }

  const saveEvaluators = () => {
    updateEvaluators(currentEvaluated, evaluatorsSelected)
    closeModal()
  }
  
  useEffect(() => {
    if(currentEvaluated) {
      getUserByTeam()
    }
  }, [currentEvaluated]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${styles.modal}`}
      open={openModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: "#205B6F", opacity: 0.8 },
      }}
      sx={{ border: "none" }}
      style={{ outline: 0 }}
    >
      <Slide
        direction="down"
        in={openModal}
        style={{ outline: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.containerModal}>
          <header>
            <h4>
              {`${t("performance_assessment.tab_participants_form.evaluators")} (${evaluatorsSelected.length})`}
            </h4>
          </header>
          <section>
            {evaluatorsSelected && evaluatorsSelected.length > 0 && (
              evaluatorsSelected.map((evaluator, index) => (
                <div
                  className={styles.boxEvaluatorsSelect}
                  key={`evaluator-select-${index}`}
                >
                  <Select
                      isDisabled={evaluator.is_manager}
                      value={{
                        value: evaluator.machine_users_id,
                        label: evaluator.username
                      }}
                      options={[
                        {
                          label: t('global_options.none'),
                          value: null
                        },
                        ...evaluatorsOptions.filter(e => !evaluatorsSelected.some(ee => (ee.machine_users_id === e.id || ee.id === e.id))).map(e2 => ({...e2, value: e2.id, label: e2.username}))
                      ]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(value) => onChangeEvaluators(value, evaluator)}
                      placeholder={t('performance_assessment.tab_participants_form.select')}
                    />
                </div>
              ))
            )}
            {
              (4-evaluatorsSelected.length > 0) && 
              Array(4-evaluatorsSelected.length).fill(0).map((_, index) => (
                <div
                  className={styles.boxEvaluatorsSelect}
                  key={`evaluator-othe-select-${index}`}
                >
                  <Select
                    value={{
                      value: null,
                      label: t('global_options.none')
                    }}
                    options={[
                      {
                        label: t('global_options.none'),
                        value: null
                      },
                      ...evaluatorsOptions.filter(e => !evaluatorsSelected.some(ee => ee.machine_users_id === e.id ||  ee.id === e.id)).map(e2 => ({...e2, value: e2.id, label: e2.username}))
                    ]}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(value) => onChangeEvaluators(value, null)}
                    placeholder={t('performance_assessment.tab_participants_form.select')}
                  />
                </div>
              ))
            }

              {/* // <div className={styles.noneBox}>{t("performance_assessment.messages.none_questions")}</div> */}
          </section>
          <footer>            
            <button className={styles.buttonCancel} onClick={closeModal}>
              {t("performance_assessment.actions.cancel")}
            </button>
            <button className={styles.buttonSave} onClick={saveEvaluators}>
              {t("performance_assessment.actions.save")}
            </button>
          </footer>
        </div>
      </Slide>
    </Modal>
  );
}
