import { Box, Grid, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./CoursesManageList.module.scss";
import { v4 as uuidv4 } from "uuid";
import { AttachFile, Delete, DragIndicator } from "@material-ui/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export default function CoursesManageList({
  courses = [],
  setValue = () => {},
  lang = "en",
}) {
  const { t, i18n } = useTranslation("common");

  const addNewItem = (newItem) => {
    if (
      courses.find((item) => item.name === newItem.name) &&
      !newItem.is_new
    )
      return;
    setValue("courses", [...courses, { ...newItem, id: uuidv4(), index: courses.length }]);
  };

  const removeItem = (itemId) => {
    setValue(
      "courses",
      courses.filter((item) => item.id !== itemId)
    );
  };

  const editItem = (item) => {
    setValue(
      "courses",
      courses.map((i) => (i.id === item.id ? item : i))
    );
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { destination, source } = result;

    if (destination.index === source.index) return;

    const newCourses = Array.from(courses);
    const [removed] = newCourses.splice(source.index, 1);
    newCourses.splice(destination.index, 0, removed);

    setValue("courses", newCourses.map((exp, index) => ({ ...exp, index })));
  };

  const handleCoursesChange = (courseId, event) => {
    const { name, value, type } = event.target;
    const coursesNew = courses.map((course) => {
      if (course.id === courseId) {
        if(type === "file") return { ...course, [name]: event.target.files[0] };
        return { ...course, [name]: value };
      }
      return course;
    });
    setValue("courses", coursesNew);
  };


  const truncateAttachmentName = (name) => {
    return name.substring(0, 4) + "..." + name.substring(name.length - 6);
  };

  const getAttachmentName = ({attachment, file_original_name}) => {
    if (attachment) {
      return <div title={attachment.name}>{truncateAttachmentName(attachment.name)}</div>
    }
    if (file_original_name) {
      return <div title={file_original_name}>{truncateAttachmentName(file_original_name)}</div>
    }
    return <div>{t("settings.users.actions.add")}</div>
  };

  return (
    <Box
      display="flex"
      bgcolor="#fff"
      flexDirection="column"
      paddingX="16px"
      paddingBottom="16px"
      borderRadius="4px"
      marginBottom="2px"
      flexGrow={1}
    >
      <Grid container spacing={2} marginBottom={"4px"} marginTop={0}>
        <Grid item xs={12}>
          <Typography
            component="h3"
            sx={{ fontSize: "18px", color: "#1E6B7F", fontWeight: 500 }}
          >
            {t("settings.users.education_form.title_courses_and_certificates")}
          </Typography>
        </Grid>
      </Grid>

      {courses && courses.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {courses?.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={String(item.id)}
                      index={index}
                    >
                      {(provided2) => (
                        <Grid
                          component={"div"}
                          container
                          spacing={1}
                          marginTop={0}
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}
                          key={`experience-${index}`}
                          borderBottom={"1px solid #DEE3E9"}
                          paddingBottom={"16px"}
                          marginBottom={"10px"}
                        >
                          <Grid item xs={4}>
                            <Box display="flex" gap="6px">
                              <Box sx={{ marginTop: "32px" }}>
                                <DragIndicator
                                  style={{ cursor: "grab", color: "#1E6B7F" }}
                                />
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                gap="2px"
                                width="100%"
                              >
                                <Typography
                                  component="label"
                                  sx={{
                                    paddingLeft: "4px",
                                    fontSize: "12px",
                                    color: "#1E6B7F",
                                    fontWeight: 400,
                                  }}
                                >
                                  {t(
                                    "settings.users.education_form.courses_or_certificates"
                                  )}
                                </Typography>
                                <TextField
                                  name="name"
                                  value={item?.name}
                                  placeholder={t("settings.users.education_form.courses_or_certificates_placeholder")}
                                  error={false}
                                  helperText={""}
                                  onChange={(e) =>
                                    handleCoursesChange(item.id, e)
                                  }
                                  sx={{
                                    width: "100%",
                                    height: "45px",
                                    fieldset: {
                                      border: "1px solid #DEE3E9CC",
                                      height: "45px",
                                    },
                                    div: { color: "#4F7091", height: "45px" },
                                    label: { top: "-4px" },
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="2px"
                              width="100%"
                            >
                              <Typography
                                component="label"
                                sx={{
                                  paddingLeft: "4px",
                                  fontSize: "12px",
                                  color: "#1E6B7F",
                                  fontWeight: 400,
                                }}
                              >
                                {t("settings.users.education_form.realized")}
                              </Typography>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                locale={lang}
                              >
                                <DatePicker
                                  value={item?.conclusion_year ?`${item?.conclusion_year}-01-01` : null}
                                  inputFormat={"YYYY"}
                                  views={["year"]}
                                  onChange={(value) =>
                                    handleCoursesChange(item.id, {
                                      target: { name: "conclusion_year", value: value?.$y },
                                    })
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      variant="outlined"
                                      fullWidth
                                      {...params}
                                      sx={{
                                        width: "100%",
                                        height: "45px",
                                        fieldset: {
                                          border: "1px solid #DEE3E9CC",
                                          height: "45px",
                                        },
                                        input: {
                                          color: "#4F7091",
                                          paddingY: "11.5px",
                                        },
                                        label: { top: "-4px" },
                                      }}
                                      name="conclusion_year"
                                      error={false}
                                      helperText={""}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="2px"
                              width="100%"
                            >
                              <Typography
                                component="label"
                                sx={{
                                  paddingLeft: "4px",
                                  fontSize: "12px",
                                  color: "#1E6B7F",
                                  fontWeight: 400,
                                }}
                              >
                                {t("settings.users.education_form.location_couse_or_empresa")}
                              </Typography>
                              <TextField
                                  name="company"
                                  value={item?.company}                                  
                                  placeholder={t("settings.users.education_form.location_couse_or_empresa_placeholder")}
                                  error={false}
                                  helperText={""}
                                  onChange={(e) =>
                                    handleCoursesChange(item.id, e)
                                  }
                                  sx={{
                                    width: "100%",
                                    height: "45px",
                                    fieldset: {
                                      border: "1px solid #DEE3E9CC",
                                      height: "45px",
                                    },
                                    div: { color: "#4F7091", height: "45px" },
                                    label: { top: "-4px" },
                                  }}
                                />
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <Box display={"flex"} gap={"6px"}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                gap="2px"
                                width="100%"
                              >
                                <Typography
                                  component="label"
                                  sx={{
                                    paddingLeft: "4px",
                                    fontSize: "12px",
                                    color: "#1E6B7F",
                                    fontWeight: 400,
                                  }}
                                >
                                  {t("settings.users.education_form.attachment")}
                                </Typography>
                                <div
                                  className={styles.boxInputFile}
                                  style={{
                                    fontFamily: "sans-serif",
                                    textAlign: "left",
                                    display: "flex",
                                  }}
                                >
                                  <label className={styles.customFileUpload}>
                                    <input
                                      name="attachment"
                                      type="file"
                                      onChange={(e) => handleCoursesChange(item.id, e)}
                                      accept=".pdf, image/*"
                                    />
                                    <AttachFile fontSize="small" style={{color: "#1E6B7F", fill: "#1E6B7F"}} />
                                    {getAttachmentName(item)}
                                  </label>
                                  {/* <p className={styles.attachmentInfo}>
                                    {t('settings.users.documents_form.attach_info')}*
                                  </p> */}
                                </div>
                              </Box>
                              <Box sx={{ marginTop: "22px" }}>
                                <button
                                    className={styles.buttonRemove}
                                    onClick={() => removeItem(item.id)}
                                >
                                    <Delete style={{color:"#FC6662"}} fontSize='small' />
                                </button>
                              </Box>                            
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginTop={"14px"}
          marginBottom={"8px"}
        >
          <Typography
            component="label"
            sx={{
              paddingLeft: "4px",
              fontSize: "12px",
              color: "#1E6B7F",
              fontWeight: 400,
            }}
          >
            {t("settings.users.education_form.no_courses")}
          </Typography>
        </Box>
      )}

      <Grid container spacing={2} marginBottom={"4px"} marginTop={0}>
        <Grid item xs={12}>
          <button
            className={styles.buttonAddItem}
            onClick={() =>
              addNewItem({
                name: "",
                conclusion_year: null,
                company: "",
                attachment: null,
                is_new: true
              })
            }
          >
            {t("settings.users.professional_form.new_item")}
          </button>
        </Grid>
      </Grid>
    </Box>
  );
}
