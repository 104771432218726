import React, { useEffect, useState } from 'react'
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import { connect } from 'react-redux'
import { alertActions } from '../../_actions'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import { api } from '../../_helpers/api'
import { authHeader, history } from '../../_helpers'
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton'
import CreateSkillModal from '../../_components/performance_assessments/PerformanceAssessmentsSkills/CreateSkillModal'
import CardManageSkill from '../../_components/performance_assessments/PerformanceAssessmentsSkills/CardManageSkill'
import { Box, Popover } from "@material-ui/core";
import PopoverConfirm from '../../_components/teams_settings/PopoverConfirmDelete'
import ImportSkillsModal from '../../_components/performance_assessments/PerformanceAssessmentsSkills/ImportSkillsModal'
import NoData from './NoData'

function PerformanceAssessmentSkillsPage({
  alertActions,
  userDetails,
  filter
}) {
  
  const { t, i18n } = useTranslation('common');
  const [openModalCreateSkill, setOpenModalCreateSkill] = useState(false);
  const [openModalImportSkills, setOpenModalImportSkills] = useState(false);
  const [skills, setSkills] = useState([]);
  const [scales, setScales] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [skillTypes, setSkillTypes] = useState([]);
  const [fetchingSkillTypes, setFetchingSkillTypes] = useState(true);
  
  const [firstFetch, setFirstFetch] = useState(true);
  const [firstData, setFirstData] = useState([]);

  const [currentSkill, setCurrentSkill] = useState(null);
  const [lastSkillCreated, setLastSkillCreated] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "actions-popover" : undefined;

  const [typeAction, setTypeAction] = useState(null);

  const [skillToEdit, setSkillToEdit] = useState(null);

  const [typeModal, setTypeModal] = useState('create_skill');

  const handleClickCurrentSkill = (event, skill) => {
    setCurrentSkill(skill);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCurrentSkill = (isFetching=false) => {
    setAnchorEl(null);
    setTypeAction(null);
    setCurrentSkill(null);
    setLastSkillCreated(null);
    if(isFetching===true) {
      getSkills(false, null, null)
    }
  };

    
  const [anchorElConfirmDelete, setAnchorElConfirmDelete] = useState(null);
  const openConfirmDelete = Boolean(anchorElConfirmDelete);
  const idConfirmDelete = openConfirmDelete ? 'popover-confirm-delete-skill' : undefined;

  const handleOpenConfirmDelete = (event) => {
    setAnchorElConfirmDelete(event.currentTarget);
  };

  const handleCloseConfirmDelete = () => {
    setAnchorElConfirmDelete(null)
  };

  

  const handleConfirmDeleteSkill = async () => {
    try {
      if (!currentSkill.id) {
        throw new Error('Skill to delete not found')
      }
      const res = await api.delete(`/performance-assessment/skills/${currentSkill.id}`, { headers: authHeader() })
      if (res.status !== 200) {
        throw new Error('Erro to delete skill')
      }
      handleCloseConfirmDelete()
      handleCloseCurrentSkill()
      getSkills()
      alertActions.success(t('performance_assessment.messages.success_delete_skill'))
    } catch (error) {
      alertActions.error(error.response.data)
      console.error(error)
    }
  }


  const [anchorElConfirmClone, setAnchorElConfirmClone] = useState(null);
  const openConfirmClone = Boolean(anchorElConfirmClone);
  const idConfirmClone = openConfirmClone ? 'popover-confirm-clone-skill' : undefined;

  const handleOpenConfirmClone = (event) => {
    setAnchorElConfirmClone(event.currentTarget);
  };

  const handleCloseConfirmClone = () => {
    setAnchorElConfirmClone(null)
  };

  const handleConfirmCloneSkill = async () => {
    try {
      if (!currentSkill.id) {
        throw new Error('Skill off to clone not found')
      }

      const res = await api.post(`/performance-assessment/skills/${currentSkill.id}/clone`, {}, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro to clone skill')
      }
      handleCloseConfirmClone()
      handleCloseCurrentSkill()
      getSkills()
      alertActions.success(t('performance_assessment.messages.success_clone_skill'))
    } catch (error) {      
      alertActions.error(error.response.data)
      console.error(error)
    }
  }

  const getScales = async () => {
    try {
      const response = await api.get('performance-assessment/scales', { headers: authHeader() });
      setScales(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getSkills = async (isLoading=true, filterTypeSkill=null, lastCreatedSkill=null) => {
    try {
      if(isLoading) setFetching(true);
      const queryTypeSkill = (filterTypeSkill!== null && filterTypeSkill !== 'all') ? `?skills_type=${filterTypeSkill}` : '';
      const response = await api.get(`performance-assessment/skills${queryTypeSkill}`, { headers: authHeader() });
      const { data } = response;

      if(firstFetch){
        setFirstData(data)
        setFirstFetch(false)
      }

      setSkills(data);
      if(lastCreatedSkill && lastCreatedSkill.id){
        const skillFiltered = data.find(skill => skill.id === lastCreatedSkill.id);
        if(skillFiltered) {
          setCurrentSkill(skillFiltered);
          setTypeAction('edit_questions');
          setLastSkillCreated(true);
        }
      }
      setFetching(false);
    } catch (error) {
      console.log(error);
      setFetching(false);
    }
  }
  
  const getSkillTypes = async () => {
    try {
      setFetchingSkillTypes(true);
      const response = await api.get('performance-assessment/skill-types', { headers: authHeader() });
      setSkillTypes(response.data);
      setFetchingSkillTypes(false);
    } catch (error) {
      console.log(error);
      setFetchingSkillTypes(false);
    }
  }


  const handleOpenModalCreateSkill = () => {
    setTypeModal('create_skill');
    setAnchorEl(null);
    setOpenModalCreateSkill(true);
  }

  const handleCloseModalCreateSkill = () => {
    setOpenModalCreateSkill(false);
  }

  const handleOpenModalEditSkill = () => {
    setTypeModal('edit_skill');
    if(currentSkill){
      setAnchorEl(null);
      setSkillToEdit(currentSkill);
    }
    setOpenModalCreateSkill(true);
  }

  const handleCloseModalEditSkill = () => {
    setOpenModalCreateSkill(false);
    if(currentSkill){
      handleCloseCurrentSkill(false)
      setSkillToEdit(null);
    }
  }

  const handleOpenModalImportSkills = () => {
    setOpenModalImportSkills(true);
  }

  const handleCloseModalImportSkills = () => {
    setOpenModalImportSkills(false);
  }

  useEffect(() => {    
    document.title = `Befective | ${t('menu.performance_assessment.pre_settings')} | ${t('menu.performance_assessment.skills')} `;  
    getSkillTypes();
    getScales();
    getSkills();
  }, [])


  return (
    <section className="mainContent">
      
      <Toolbar />

      <Filterbar
        filterTypeSkills={'enabled'}
        filterSkills={getSkills}
        isLoadingSkillTypes={fetchingSkillTypes}
        skillTypes={skillTypes}
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhat={'disabled'}
        buttonCreate={{
          title: t("button_titles.create_skill"),
          handleClick: handleOpenModalCreateSkill,
        }}
        buttonImport={{
          handleClick:handleOpenModalImportSkills,
        }}
      />

      <main className='mainContentPage'>
        {
          !fetching
          ?
          (
            firstData.length > 0 ?
              (skills.length > 0 ?
                skills.map((skill, index) => {
                  return (
                    <CardManageSkill 
                      skill={skill} 
                      key={`skill-${index}`} 
                      index={index} 
                      currentSkill={currentSkill}
                      handleClickCurrentSkill={handleClickCurrentSkill}
                      handleCloseCurrentSkill={handleCloseCurrentSkill} 
                      getSkills={() => getSkills(false, null, null)}
                      typeAction={typeAction}
                      scales={scales}
                      alertActions={alertActions}
                      lastSkillCreated={lastSkillCreated}
                    />
                  )
                })
                :
                <div className="boxNone">
                  <p className="textNone">{t('performance_assessment.messages.no_skiill_found')}</p>
                </div>
            )
            :
            <NoData />
          )
          :
          <GlobalSkeleton
            totalRowsStats={0}
            totalTables={1}
            heightTable={600}          
            margin={'-10px 0px 0px 0px'}
          />
        }
      </main>

      {/* Start Popover Actions */}
      <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => handleCloseCurrentSkill(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              backgroundColor: "#1E6B7F",
              marginTop: "10px",
            },
          }}
        >
          <Box className={"menuOptionsActions"}>
            <button
              className={"menuOptionsActionButton"}
              onClick={handleOpenModalEditSkill}
            >
              {t("performance_assessment.actions.edit_skill")}
            </button>
            <button
              className={"menuOptionsActionButton"}
              onClick={handleOpenConfirmDelete}
            >
              {t("performance_assessment.actions.delete_skill")}
            </button>
            <button
              className={"menuOptionsActionButton"}
              onClick={handleOpenConfirmClone}
            >
              {t("performance_assessment.actions.clone_skill")}
            </button>
            <button
              className={"menuOptionsActionButton"}
              onClick={() => {
                setTypeAction("edit_questions")
                setAnchorEl(null);
              }}
            >
              {t("performance_assessment.actions.edit_questions")}
            </button>
          </Box>
      </Popover>
      {/* End Popover Actions */}


      {/* Start Confirm Dialog Delete Skill */}
      <PopoverConfirm
        idConfirm={idConfirmDelete}
        openConfirm={openConfirmDelete}
        anchorEl={anchorElConfirmDelete}
        handleClose={handleCloseConfirmDelete}
        handleConfirm={handleConfirmDeleteSkill}
        title={t('performance_assessment.messages.delete_skill')}
      />
      {/* End Confirm Dialog Delete Skill */}


      {/* Start Confirm Dialog Clone Skill */}
      <PopoverConfirm
        idConfirm={idConfirmClone}
        openConfirm={openConfirmClone}
        anchorEl={anchorElConfirmClone}
        handleClose={handleCloseConfirmClone}
        handleConfirm={handleConfirmCloneSkill}
        title={t('performance_assessment.messages.clone_skill')}
      />
      {/* End Confirm Dialog Clone Skill */}
      
      <CreateSkillModal
        currentSkill={currentSkill}
        openModal={openModalCreateSkill}
        closeModal={() => {
          if(typeModal === "edit_skill"){
            handleCloseModalEditSkill()
            return
          }
          handleCloseModalCreateSkill()
        }}
        getSkills={getSkills}
        skillTypes={skillTypes}
        skillToEdit={skillToEdit}
        alertActions={alertActions}
      />

      <ImportSkillsModal
        openModal={openModalImportSkills}
        closeModal={handleCloseModalImportSkills}
        getSkills={getSkills}
        alertActions={alertActions}
      />



    </section>
  )
}



function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceAssessmentSkillsPage)