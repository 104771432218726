import React, { useEffect, useMemo, useState } from "react";
import Toolbar from "../../_components/_shared/Toolbar";
import Filterbar from "../../_components/_shared/Filterbar";
import { connect } from "react-redux";
import { alertActions } from "../../_actions";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { authHeader, befectiveFormatDate, history } from "../../_helpers";
import GlobalSkeleton from "../../_components/_shared/GlobalSkeleton";
import EnhancedTable from "../../_components/_shared/EnhancedTable";
import moment from "moment";
import { api } from "../../_helpers/api";
import ButtonCreate from "../../_components/_shared/ButtonCreate";
import NoData from "./NoData";
import { IconButton } from "@material-ui/core";
import { Search } from "@material-ui/icons";

function PerformanceAssessmentsAssessPage({
  alertActions,
  userDetails,
  filter,
}) {
  const { t, i18n } = useTranslation("common");

  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);

  const [firstFetch, setFirstFetch] = useState(true);
  const [firstData, setFirstData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [dateRef, setDateRef] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [pageSize, setPageSize] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  async function getData(
    date_ref,
    pageIndex,
    pageSize,
    isLoading,
    teams = null,
    teammates = null
  ) {

    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&teams=${teams.map(t=>t.id).join(',')}` : ''
      const queryTeammates = teammates ? `&users=${teammates.map(t=>t.id).join(',')}` : ''
      const queryFilterYear = date_ref ? `&year=${moment(date_ref).year()}` : ''
      const response = await api.get(`/performance-assessment/to-answer?page=${pageIndex}&per_page=${pageSize}${queryFilterYear}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      if(firstFetch){
        setFirstData(response.data)
        setFirstFetch(false)
      }

      setData(response.data)
      setFetching(false);
      
    } catch (error) {
      console.log(error);
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t("performance_assessment.page_assessment_assess.table.col_evaluation"),
        accessor: "peformance_assessment_name",
      },
      {
        Header: t("performance_assessment.page_assessment_assess.table.col_evaluated"),
        accessor: "evaluated_name",
        Cell: ({ value, row }) => {
          const {self_assessment} = row.original
          return self_assessment ? t("performance_assessment.page_assessment_assess.self_assessment") : value
        },
      },
      {
        Header: t("performance_assessment.page_assessment_assess.table.col_start"),
        accessor: "date_start",
        Cell: ({ value }) => {
          return befectiveFormatDate(value, i18n.language);
        },
      },
      {
        Header: t(
          "performance_assessment.page_assessment_assess.table.col_completed_at"
        ),
        accessor: "date_end",
        Cell: ({ value }) => {
          return befectiveFormatDate(value, i18n.language);
        },
      },
      {
        Header: t(
          "performance_assessment.page_assessment_assess.table.col_answered_at"
        ),
        accessor: "answered_at",
        Cell: ({ value }) => {
          return befectiveFormatDate(value, i18n.language);
        },
      },
      {
        Header: "",
        accessor: "need_to_be_answered",
        style: { padding: "0px 12px", width: '50px', textAlign: 'right' },
        Cell: ({ value, row }) => {

          if(!value){
            return <IconButton
            className={`button-options`}
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/performance-assessment/details/participants/${row.original.peformance_assessment_id}`)
            }}
          >
            <Search style={{color: "#4F7091"}} fontSize="small" />
          </IconButton>
          };

          return (
            <ButtonCreate
                  minWidth={0}
                  title={t('performance_assessment.actions.reply')}
                  handleClick={(e) => {
                    e.stopPropagation()
                    history.push(`/performance-assessment/answer-form/${row.original.evaluated_id}`)
                  }}
                />
          );
        },
      }
    ];
  }, []);

  const getDataAssessments = ({pageIndex, pageSize, filter}) => {
    let date_ref = dateRef;

    if (filter.singleDate) {
      date_ref = filter.singleDate;
    }

    setDateRef(date_ref);

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getData(date_ref, pageIndex, pageSize, true, teams, teammates);
  };

  useEffect(() => {
    getData(
      dateRef,
      0,
      localStorage.getItem("pageSizeStorage") || 20,
      false,
      null,
      null
    );
    document.title = `Befective | ${t("menu.performance_assessment.pending")} `;
  }, []);

  return (
    <section className="mainContent">
      <Toolbar />

      <Filterbar
        filterYears={"single"}
        filterWho={"multi"}
        filterWhen={"disabled"}
        filterWhat={"disabled"}
        maxDate={new Date()}
      />

      <main className="mainContentPage">
        {!fetching ? (
          firstData.length > 0 
          ?
          <EnhancedTable
            columns={columns}
            data={data}
            pageCountRows={pageCountRows}
            count={pageCount}
            filter={filter}
            fetchData={getDataAssessments}
            title={t("performance_assessment.page_assessment_assess.table.title")}
          />
          :
          <NoData />
        ) : (
          <GlobalSkeleton
            totalRowsStats={0}
            totalTables={1}
            heightTable={600}
          />
        )}
      </main>
    </section>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceAssessmentsAssessPage);
