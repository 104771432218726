import React from "react";

export default function IconBee(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="89.059"
      height="63.144"
      viewBox="0 0 89.059 63.144"
    >
      <g
        id="Grupo_1110"
        data-name="Grupo 1110"
        transform="translate(-1448.079 -25.697)"
      >
        <g id="abelha" transform="translate(1505 25.697)">
          <path
            id="Caminho_146"
            data-name="Caminho 146"
            d="M227.94,223.261a8.557,8.557,0,0,0-.491,1.509l6.679,5.208a18.435,18.435,0,0,0,1.131-2.625C238.434,218.014,230.989,216.286,227.94,223.261Z"
            transform="translate(-220.301 -214.895)"
            fill="#12a696"
            fillRule="evenodd"
          />
          <path
            id="Caminho_7195"
            data-name="Caminho 7195"
            d="M2456.746-20140.059h0l-6.134-5.148.44-2.508,8.086,6.783-2.392.873Zm4.244-1.549v0l-9.594-8.051.44-2.508,11.547,9.689-2.392.869Zm3.565-2.119h0l-10.884-9.129a7.932,7.932,0,0,1-1.915.219,11.445,11.445,0,0,1-7.58-2.945,8.5,8.5,0,0,1-2.182-3.35,4.188,4.188,0,0,1,0-2.869,2.951,2.951,0,0,1,2.908-1.787c1.164,0,3.1.455,5.717,2.637a17.4,17.4,0,0,1,5.066,6.906l9.314,7.811-.444,2.508Zm.787-4.445v0l-8.167-6.854,2.392-.871,6.215,5.217-.44,2.508Zm2.669-4.2a5.1,5.1,0,0,1-3.858-1.66,4.19,4.19,0,0,1-.915-3.482,4.528,4.528,0,0,1,2.521-3.316c-1.061-4.066-.263-6.328.129-7.109a.646.646,0,0,1-.111-.355.649.649,0,0,1,.647-.65.647.647,0,0,1,.643.65.646.646,0,0,1-.643.645h-.022c-.357.7-1.083,2.734-.093,6.578a6.239,6.239,0,0,1,.792-.23c1.412-4.027,3.511-5.523,4.348-5.98a.653.653,0,0,1,.636-.547.648.648,0,0,1,.643.646.645.645,0,0,1-.643.646.644.644,0,0,1-.447-.182c-.724.412-2.552,1.752-3.871,5.313.183-.016.385-.025.6-.025h.194c2.683.1,5.005,1.7,5.287,3.639a4,4,0,0,1-.94,2.945,6.517,6.517,0,0,1-2.909,2.127A5.858,5.858,0,0,1,2468.011-20152.371Zm.917-7.148a.819.819,0,0,0-.817.818.817.817,0,0,0,.817.816.815.815,0,0,0,.813-.816A.817.817,0,0,0,2468.928-20159.52Z"
            transform="translate(-2441.741 20168.945)"
            fill="#22bead"
          />
          <path
            id="Caminho_662"
            data-name="Caminho 662"
            d="M-2817.106-25.644l-5.153-1.793,2.087-.783,2.368-.889,6.157,5.167-2.392.871Zm1.137-4.154,2.371-.89,6.2,5.2-2.392.871Zm4.208-1.579,2.37-.889,3.6,3.026-.442,2.507Zm4.207-1.579,2.37-.89.183.154-.442,2.508Zm-.052-6.89c1.378,2.667,4.956,3.849,8.186,2.679a8.149,8.149,0,0,0,1.638-.814,6.531,6.531,0,0,1-3,2.248,5.848,5.848,0,0,1-1.99.351C-2805.348-35.382-2807.665-37.11-2807.606-39.846Z"
            transform="translate(2829.042 51.956)"
            fill="#12a696"
          />
        </g>
        <path
          id="Caminho_7168"
          data-name="Caminho 7168"
          d="M46.851,61.072C50,54.5,47.228,42.707,41.258,35.8,30.494,18.615.335,27.608,0,39.041-.294,49.205,35.268,38.168,10.74,0"
          transform="translate(1509.818 40.141) rotate(90)"
          fill="none"
          stroke="#fed852"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          strokeDasharray="5"
        />
      </g>
    </svg>
  );
}
