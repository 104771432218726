import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { teammatesActions, teamsActions } from '../../../_actions';

import IconAdd from '../../_icons/IconAdd';
import IconBefective from '../../_icons/IconBefective';
import IconInfo from '../../_icons/IconInfo';
import IconMood from '../../_icons/IconMood';
import IconNotification from '../../_icons/IconNotification';
import IconPeriod from '../../_icons/IconPeriod';
import IconRemove from '../../_icons/IconRemove';
import IconTeam from '../../_icons/IconTeam';
import IconWeek from '../../_icons/IconWeek';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import IconTeammate from '../../_icons/IconTeammate';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { Typography } from '@mui/material';

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#1E6B7F', // Cor de fundo personalizada
        marginRight: 0, // Espaçamento à direita
        maxWidth: '300px'
    },
    arrow: {
        color: '#1E6B7F'
    },
}))(Tooltip);

function Teams(props) {

    const { t, i18n } = useTranslation('common');

    const { teamsActions, userDetails, teammates, teammatesActions } = props;

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    let permissions = props.permissions || {};
    let current = props.current || []

    let deleted = props.deleted || false
    let updated = props.updated || false
    let teams = props.teams?.filter(team => team.id !== current.id) || []
    let teamsList = []

    const [teamId, setTeamId] = useState(null)
    const [activitieSubmitted, setActivitieSubmitted] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [periodsHTML, setPeriodsHTML] = useState([])
    const [value, setValue] = React.useState(dayjs('2020-01-01 12:00'));
    const [showFlagRespectCheckin, setShowFlagRespectCheckin] = useState(true);

    const [form, setForm] = useState({
        name: '',
        parent: 0,
        showIcon: 1,
        askMood: 1,
        timeout: 5,
        activitieType: 'neutral',
        selective_collection: false,
        mon: '00:00',
        tue: '00:00',
        wed: '00:00',
        thu: '00:00',
        fri: '00:00',
        sat: '00:00',
        sun: '00:00',
        periods: [],
        respect_checkin_actions: false,
        responsible_id: 0,
        continuous_working_time: '00:00', // Tempo de trabalho interruptos
        alert_time_must_rest: '00:00', //frequencia
        rest_time: '00:00', // tempo de descanso
        shortage_time: '00:00', //carencia
        mandatory: "0", // obrigatorio ou não
        max_repeat_alert: 0, // limite de repetição de alerta
    });

    const [formError, setFormError] = useState({
        name: '',
        period: '',
        respect_checkin_actions: '',
        alert_time_must_rest: ''
    });

    const [formActivitie, setFormActivitie] = useState({
        activitieName: '',
        activitieType: 'neutral'
    })

    const [formActivitieError, setFormActivitieError] = useState({
        activitieName: ''
    })

    const [teamActivities, setTeamActivities] = useState([])


    const teammatesFormatted = teammates?.reduce((acc, item) => {
        return [...acc, { name: item.name, value: item.id }]
    }, [])?.sort((a, b) => a.name.localeCompare(b.name));

    function setPeriods() {

        let newHTML = []

        for (let period in form.periods) {


            newHTML.push(<div className={'periodRow'}>
                <div
                    className={Object.values(form.periods[period].work_days).includes("1") ? 'periodDay active' : 'periodDay'}>
                    <button type={'button'} value={'1'}
                        onClick={() => {
                            handleChangePeriodsWorkDay(period, 1)
                        }}
                    >{t("settings.teams.mon")}</button>
                </div>
                <div
                    className={Object.values(form.periods[period].work_days).includes("2") ? 'periodDay active' : 'periodDay'}>
                    <button type={'button'}
                        onClick={() => {
                            handleChangePeriodsWorkDay(period, 2)
                        }}
                    >{t("settings.teams.tue")}</button>
                </div>
                <div
                    className={Object.values(form.periods[period].work_days).includes("3") ? 'periodDay active' : 'periodDay'}>
                    <button type={'button'}
                        onClick={() => {
                            handleChangePeriodsWorkDay(period, 3)
                        }}
                    >{t("settings.teams.wed")}</button>
                </div>
                <div
                    className={Object.values(form.periods[period].work_days).includes("4") ? 'periodDay active' : 'periodDay'}>
                    <button type={'button'}
                        onClick={() => {
                            handleChangePeriodsWorkDay(period, 4)
                        }}
                    >{t("settings.teams.thu")}</button>
                </div>
                <div
                    className={Object.values(form.periods[period].work_days).includes("5") ? 'periodDay active' : 'periodDay'}>
                    <button type={'button'}
                        onClick={() => {
                            handleChangePeriodsWorkDay(period, 5)
                        }}
                    >{t("settings.teams.fri")}</button>
                </div>
                <div
                    className={Object.values(form.periods[period].work_days).includes("6") ? 'periodDay active' : 'periodDay'}>
                    <button type={'button'}
                        onClick={() => {
                            handleChangePeriodsWorkDay(period, 6)
                        }}
                    >{t("settings.teams.sat")}</button>
                </div>
                <div
                    className={Object.values(form.periods[period].work_days).includes("0") ? 'periodDay active' : 'periodDay'}>
                    <button type={'button'}
                        onClick={() => {
                            handleChangePeriodsWorkDay(period, 0)
                        }}
                    >{t("settings.teams.sun")}</button>
                </div>
                <div className={'periodTime'}>
                    <TimePicker
                        ampm={false}
                        views={['hours', 'minutes']}
                        inputFormat="HH:mm"
                        mask="__:__"
                        // label={t("settings.teams.mon")}
                        value={dayjs('2022-01-01 ' + form.periods[period].start_time)}
                        onChange={(newValue) => handleChangePeriodsTime(period, 'start', convertToTime(newValue) + ':00')}
                        renderInput={(params) => <TextField variant='filled' style={{ width: 100, marginRight: 4 }} {...params} />}
                    />

                    <span className='to'>{t('settings.teams.to')}</span>

                    <TimePicker
                        ampm={false}
                        views={['hours', 'minutes']}
                        inputFormat="HH:mm"
                        mask="__:__"
                        // label={t("settings.teams.mon")}
                        value={dayjs('2022-01-01 ' + form.periods[period].end_time)}
                        onChange={(newValue) => handleChangePeriodsTime(period, 'end', convertToTime(newValue) + ':00')}
                        renderInput={(params) => <TextField variant='filled' style={{ width: 100, marginRight: 4 }} {...params} />}
                    />
                    {/* <input type={'time'} onChange={(e) => {
                        handleChangePeriodsTime(period, 'start', e.target.value)
                    }} value={form.periods[period].start_time} />
                    <span>{t('settings.teams.to')}</span>
                    <input type={'time'} onChange={(e) => {
                        handleChangePeriodsTime(period, 'end', e.target.value)
                    }} value={form.periods[period].end_time} /> */}
                </div>
                <div className={'periodControls'}>
                    <button type="button" className={"remove"} disabled={form.periods.length > 1 ? false : true}
                        onClick={() => {
                            handleRemovePeriod(period)
                        }}><IconRemove /></button>
                    <button type="button" onClick={() => {
                        handleAddPeriod()
                    }} className="add"><IconAdd /></button>
                </div>
            </div>)
        }

        setPeriodsHTML(newHTML)
    }

    for (let t0 of teams) {
        if (!t0.ancestry) {

            teamsList.push(<option className='t0' value={t0.id}>{t0.name}</option>)
            let p0 = t0.id
            for (let t1 of teams) {
                if (t1.ancestry == p0) {
                    teamsList.push(<option className='t1' value={p0 + '/' + t1.id}>&nbsp;&nbsp;{t1.name}</option>)
                    let p1 = t1.id
                    for (let t2 of teams) {
                        if (t2.ancestry == p1) {
                            teamsList.push(<option className='t2'
                                value={p0 + '/' + p1 + '/' + t2.id}>&nbsp;&nbsp;&nbsp;&nbsp;{t2.name}</option>)
                            let p2 = t2.id
                            for (let t3 of teams) {
                                if (t3.ancestry == p2) {
                                    teamsList.push(<option className='t3'
                                        value={p0 + '/' + p1 + '/' + p2 + '/' + t3.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t3.name}</option>)
                                    let p3 = t3.id
                                    for (let t4 of teams) {
                                        if (t4.ancestry == p3) {
                                            teamsList.push(<option className='t4'
                                                value={p0 + '/' + p1 + '/' + p2 + '/' + p3 + '/' + t4.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t4.name}</option>)
                                            let p4 = t4.id
                                            for (let t5 of teams) {
                                                if (t5.ancestry == p4) {
                                                    teamsList.push(<option className='t5'
                                                        value={p0 + '/' + p1 + '/' + p2 + '/' + p3 + '/' + p4 + '/' + t5.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t5.name}</option>)
                                                    let p5 = t5.id
                                                    for (let t6 of teams) {
                                                        if (t6.ancestry == p5) {
                                                            teamsList.push(<option className='t6'
                                                                value={p0 + '/' + p1 + '/' + p2 + '/' + p3 + '/' + p4 + '/' + p5 + '/' + t6.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t6.name}</option>)
                                                            let p6 = t6.id
                                                            for (let t7 of teams) {
                                                                if (t7.ancestry == p6) {
                                                                    teamsList.push(<option className='t7'
                                                                        value={p0 + '/' + p1 + '/' + p2 + '/' + p3 + '/' + p4 + '/' + p5 + '/' + p6 + '/' + t7.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t7.name}</option>)
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    function toggleRemoveDialog() {

        let elem = document.getElementById("removeDialog")
        elem.classList.toggle("open")

    }

    function handleChangePeriodsWorkDay(period, work_day) {

        if (Object.values(form.periods[period].work_days).includes(work_day.toString())) {

            let removeIndex = form.periods[period].work_days.indexOf(work_day.toString());
            form.periods[period].work_days.splice(removeIndex, 1);
        } else {
            if (form.periods.find((p) => p.work_days.includes(String(work_day)))) {
                return
            }
            form.periods[period].work_days.push(work_day.toString())
        }

        setPeriods()

    }

    function handleAddPeriod(type = null) {

        if (type == 'default') {
            form.periods.push({
                start_time: "08:00:00",
                end_time: "17:00:00",
                work_days: ['1', '2', '3', '4', '5']
            })
        } else {
            form.periods.push({
                start_time: "00:00:00",
                end_time: "00:00:00",
                work_days: []
            })
        }


        setPeriods()
    }

    function handleRemovePeriod(period) {

        form.periods.splice(period, 1);

        setPeriods()

    }

    function handleChangePeriodsTime(period, type, value) {

        if (type == 'start') {
            form.periods[period].start_time = value;
        }

        if (type == 'end') {
            form.periods[period].end_time = value;
        }

        setPeriods()

    }

    function handleChangeTime(field, value) {
        setForm({
            ...form,
            [field]: value
        })
    }

    const handleChange = e => {
        //e.preventDefault();
        setSubmitted(false)

        if (e.target.name == 'is_a_clone') {
            setForm({
                ...form,
                [e.target.name]: !form.is_a_clone,
            })
        } else if (e.target.name == 'is_subteam') {
            setForm({
                ...form,
                [e.target.name]: !form.is_subteam,
            })
        } else if (e.target.name == 'selective_collection' || e.target.name == 'respect_checkin_actions') {
            let newValue = false;


            if (e.target.value == 1) {
                newValue = true
            }


            setForm({
                ...form,
                [e.target.name]: newValue,
            })
        } else if (e.target.name == 'responsible_id' || e.target.name == 'parent') {
            let newValue = null;
            if (e.target.value != 0) {
                newValue = e.target.value
            }
            setForm({
                ...form,
                [e.target.name]: newValue,
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            })
        }
    }

    const handleChangeActivitie = e => {
        //e.preventDefault();
        setSubmitted(false)
        setFormActivitie({
            ...formActivitie,
            [e.target.name]: e.target.value,
        })

    }

    function hasConflictPeriods(periods) {
        if (periods.length) {

            const periodsGrouped = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] }

            periods.forEach(period => {
                period.work_days.forEach(work_day => {
                    if (Object.keys(periodsGrouped).includes(work_day)) {
                        periodsGrouped[work_day].push({
                            start_time: period.start_time,
                            end_time: period.end_time
                        })
                    }
                })
            })

            const listConflicts = []

            Object.keys(periodsGrouped).forEach((day) => {
                if (periodsGrouped[day].length > 1) {
                    periodsGrouped[day].forEach((p1, index1) => {
                        if (index1 + 1 < periodsGrouped[day].length) {
                            periodsGrouped[day].slice(index1 + 1).forEach(p2 => {
                                if ((new Date('2023-01-01T' + p2.start_time).getTime() >= new Date('2023-01-01T' + p1.start_time).getTime() && new Date('2023-01-01T' + p2.start_time).getTime() <= new Date('2023-01-01T' + p1.end_time).getTime()) || (new Date('2023-01-01T' + p2.end_time).getTime() >= new Date('2023-01-01T' + p1.start_time).getTime() && new Date('2023-01-01T' + p2.end_time).getTime() <= new Date('2023-01-01T' + p1.end_time).getTime())) {
                                    listConflicts.push(day)
                                }
                            })
                        }
                    })
                }

            })
            return {
                listConflicts,
                hasConflicts: listConflicts.length > 0
            }
        }
    }


    function timeToMinutes(timeString) {
        const [hours, minutes] = timeString.split(':').map(Number);
        const totalMinutes = hours * 60 + minutes;
        return totalMinutes;
    }

    const handleSubmit = (e) => {


        setSubmitted(true)

        let formIsValid = true

        if (!form["name"]) {
            formIsValid = false
            setFormError({
                ...formError,
                name: t('settings.company.error.required'),
            })
        }

        if (form.selective_collection === true) {

            for (let p of form.periods) {

                let start = p.start_time.split(':')
                let end = p.end_time.split(':')

                if (parseInt(start[0]) >= parseInt(end[0])) {
                    formIsValid = false
                    setFormError({
                        ...formError,
                        period: t('settings.teams.invalid_period'),
                    })
                }

            }

            if (formIsValid && hasConflictPeriods(form.periods).hasConflicts) {
                formIsValid = false
                setFormError({
                    ...formError,
                    period: t('settings.teams.conflict_period'),
                })
            }


        }

        if (form.respect_checkin_actions === true && form.periods.length === 0) {
            formIsValid = false
            setFormError({
                ...formError,
                respect_checkin_actions: t('settings.teams.invalid_period'),
            })
        }


        if (Number(timeToMinutes(form.continuous_working_time)) > 0 && Number(timeToMinutes(form.alert_time_must_rest)) < Number(form.timeout)) {
            formIsValid = false
            setFormError({
                ...formError,
                alert_time_must_rest: t('settings.teams.alert_time_must_rest_error'),
            })
        }

        if (formIsValid) {

            setFormError({
                name: '',
                period: ''
            })


            teamsActions.patchTeams({
                ...form,
                mandatory: form.mandatory == "0" ? false : true

            }, current.id)
        } else {
            setSubmitted(false)
        }

    }

    function disableTeam(id) {
        toggleRemoveDialog()
        setSubmitted(true)

        teamsActions.disableTeam(id)

    }

    function addActivitie() {

        setActivitieSubmitted(true)

        let formIsValid = true

        if (!formActivitie["activitieName"]) {
            formIsValid = false
            setFormError({
                ...formError,
                activitieName: t('settings.company.error.required'),
            })
        }

        if (formIsValid) {

            setFormError({
                activitieName: ''
            })


            teamsActions.postActivitie(formActivitie, current.id)


        } else {
            setActivitieSubmitted(false)
        }


    }

    function removeActivitie(groupId, trackedId) {

        teamsActions.deleteActivitie(groupId, trackedId)

        document.getElementById("act-" + trackedId).remove();
    }

    useEffect(() => {

        if (current.name) {

            let askMood = 0
            let showIcon = 0


            if (current.mood) {
                askMood = 1
            }
            if (current.system_tray) {
                showIcon = 1
            }


            let newPeriods = []
            for (let p of current.periods) {
                newPeriods.push(p);
            }

            setForm({
                ...form,
                name: current.name,
                parent: current?.ancestry,
                showIcon,
                askMood,
                timeout: current.timeout,
                activitieType: 'neutral',
                selective_collection: current.selective_collection,
                mon: current.work_time[1],
                tue: current.work_time[2],
                wed: current.work_time[3],
                thu: current.work_time[4],
                fri: current.work_time[5],
                sat: current.work_time[6],
                sun: current.work_time[0],
                periods: newPeriods,
                responsible_id: current?.responsible_id,
                continuous_working_time: current.continuous_working_time || '00:00', // Tempo de trabalho interruptos
                alert_time_must_rest: current.alert_time_must_rest || '00:00', //frequencia
                rest_time: current.rest_time || '00:00', // tempo de descanso
                shortage_time: current.shortage_time || '00:00', //carencia
                mandatory: current.mandatory === true ? "1" : "0", // obrigatorio ou não
                max_repeat_alert: current.max_repeat_alert || 0, // limite de repetição de alerta
            });


        }


    }, [props.current])

    useEffect(() => {
        if (form.selective_collection == true && form.periods.length == 0) {
            handleAddPeriod('default')
        }


    }, [form.selective_collection])

    useEffect(() => {
        setPeriods()
    }, [form.periods])

    useEffect(() => {
        if (deleted == 'error') {
            setSubmitted(false)
        }

        if (deleted == 'success') {
            setSubmitted(false)
        }

    }, [deleted])

    useEffect(() => {
        if (updated == 'error') {
            setSubmitted(false)
        }

        if (updated == 'success') {
            setSubmitted(false)
        }

    }, [updated])


    useEffect(() => {

        if (props.activities) {

            let activities = []

            for (let act of props.activities) {
                activities.push(<li className={act.efficiency_name} id={'act-' + act.tracked_id}>
                    <div className="efficiency">{act.exhibition_name}</div>
                    <button type="button" className="remove" onClick={() => {
                        removeActivitie(act.group_id, act.tracked_id)
                    }}><IconRemove /></button>
                </li>)
            }

            setTeamActivities(activities)
        }


    }, [props.activities])

    useEffect(() => {


        if (props.updatedActivities && activitieSubmitted) {

            let activities = teamActivities;


            activities.push(<li className={formActivitie.activitieType} id={'act-' + props.updatedActivities}>
                <div className="efficiency">{formActivitie.activitieName}</div>
                <button type="button" className="remove" onClick={() => {
                    removeActivitie(props.current.id, props.updatedActivities)
                }}><IconRemove /></button>
            </li>)


            setTeamActivities(activities)

            setActivitieSubmitted(false)

            setFormActivitie({
                activitieName: '',
                activitieType: 'neutral'
            })
        }


    }, [props.updatedActivities])


    const convertToTime = (valueTime) => {
        const hour = dayjs(valueTime).hour()
        const minute = dayjs(valueTime).minute()
        let hourString = ''
        let minuteString = ''
        if (hour < 10) {
            hourString = '0' + String(hour)
        } else {
            hourString = String(hour)
        }
        if (minute < 10) {
            minuteString = '0' + String(minute)
        } else {
            minuteString = String(minute)
        }
        return hourString + ':' + minuteString
    }

    useEffect(() => {
        teammatesActions.getTeammates();
    }, [])
    return (
        <React.Fragment>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

                <header className="pageHeader teams">
                    <div className='team-container-others'>

                        <h3>{current.name}</h3>

                        <div className={submitted ? 'controls disabled' : 'controls'}>
                            {(submitted && !deleted) &&
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            }
                            {(userDetails.allowed_delete) &&
                                <React.Fragment>
                                    <button type="button" className="remove" onClick={() => {
                                        toggleRemoveDialog()
                                    }} disabled={submitted ? true : false}><IconRemove /></button>
                                    <div className="removeDialog" id={"removeDialog"}>
                                        <div className="content">
                                            <div className="message"><IconInfo />{t('settings.administrator.you_are_sure')}</div>
                                            <div className="options">
                                                <button onClick={() => {
                                                    toggleRemoveDialog()
                                                }}>{t('no')}</button>
                                                <NavLink to={urlParams.get('to_back') == 'teammates' ? `/global-settings/teams/${current.id}` : `/global-settings/teams`}>
                                                    <button onClick={() => {
                                                        disableTeam(current.id)
                                                    }}>{t('yes')}</button>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            <NavLink to={urlParams.get('to_back') == 'teammates' ? `/global-settings/teams/${current.id}` : `/global-settings/teams`}>
                                <button type="button" disabled={submitted ? true : false}>{t('settings.teams.cancel')}</button>
                            </NavLink>
                            {(permissions.UpdateTeams) &&
                                <button type="button" className="update" disabled={submitted ? true : false}
                                    onClick={(event) => {
                                        handleSubmit(event)
                                    }}>{t('settings.teams.update')}</button>
                            }
                        </div>
                    </div>

                </header>
                <div className="editPage">
                    <form onSubmit={(event) => {
                        handleSubmit(event)
                    }} className="form edit">

                        <section>
                            <div className='boxLabel'>
                                <div className="icon">
                                    <IconTeam />
                                </div>
                                <span>{t('settings.teams.team')}:</span>
                            </div>

                            <div className='boxInputs'>
                                <div className="field fixed">
                                    <input type="text" id="name" name="name" value={form.name} onChange={handleChange} />
                                </div>
                                <div className="field fixed">
                                    <select id="parent" name="parent" value={form.parent} onChange={handleChange} style={{ backgroundPositionX: '300px' }}>
                                        <option value="0">{t("settings.teams.select_ancestor")}</option>
                                        {teamsList}
                                    </select>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className='boxLabel'>
                                <div className="icon">
                                    <IconTeammate />
                                </div>
                                <span>{t('settings.teams.responsable')}:</span>
                            </div>
                            <div className='boxInputs'>
                                <div className="field fixed">
                                    <select id="responsible_id" name="responsible_id" value={form.responsible_id} onChange={handleChange} style={{ backgroundPositionX: '300px' }}>
                                        <option value="0">{t("settings.teams.select_responsable")}</option>
                                        {teammatesFormatted?.map(item => (
                                            <option key={item.value} value={item.value}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </section>
                        <section style={{ flexWrap: 'nowrap' }}>
                            <div className='boxLabel'>
                                <div className="icon">
                                    <IconWeek />
                                </div>
                                <span>{t('settings.teams.work_week')}:</span>
                            </div>
                            <div className='boxInputs'>
                                <div className="weekDay">
                                    <label htmlFor="mon">{t("settings.teams.mon")}</label>
                                    <TimePicker
                                        ampm={false}
                                        views={['hours', 'minutes']}
                                        inputFormat="HH:mm"
                                        mask="__:__"
                                        // label={t("settings.teams.mon")}
                                        value={dayjs('2022-01-01 ' + form.mon)}
                                        onChange={(newValue) => {
                                            setForm({
                                                ...form,
                                                mon: convertToTime(newValue)
                                            })
                                        }}
                                        renderInput={(params) => <TextField variant='filled' style={{ width: 100, marginRight: 0 }} {...params} />}
                                    />
                                </div>
                                <div className="weekDay">
                                    <label htmlFor="tue">{t("settings.teams.tue")}</label>
                                    <TimePicker
                                        ampm={false}
                                        views={['hours', 'minutes']}
                                        inputFormat="HH:mm"
                                        mask="__:__"
                                        // label={t("settings.teams.tue")}
                                        value={dayjs('2022-01-01 ' + form.tue)}
                                        onChange={(newValue) => {
                                            setForm({
                                                ...form,
                                                tue: convertToTime(newValue)
                                            })
                                        }}
                                        renderInput={(params) => <TextField variant='filled' style={{ width: 100, marginRight: 0 }} {...params} />}
                                    />
                                </div>
                                <div className="weekDay">
                                    <label htmlFor="wed">{t("settings.teams.wed")}</label>
                                    <TimePicker
                                        ampm={false}
                                        views={['hours', 'minutes']}
                                        inputFormat="HH:mm"
                                        mask="__:__"
                                        // label={t("settings.teams.wed")}
                                        value={dayjs('2022-01-01 ' + form.wed)}
                                        onChange={(newValue) => {
                                            setForm({
                                                ...form,
                                                wed: convertToTime(newValue)
                                            })
                                        }}
                                        renderInput={(params) => <TextField variant='filled' style={{ width: 100, marginRight: 0 }} {...params} />}
                                    />
                                </div>
                                <div className="weekDay">
                                    <label htmlFor="thu">{t("settings.teams.thu")}</label>
                                    <TimePicker
                                        ampm={false}
                                        views={['hours', 'minutes']}
                                        inputFormat="HH:mm"
                                        mask="__:__"
                                        // label={t("settings.teams.thu")}
                                        value={dayjs('2022-01-01 ' + form.thu)}
                                        onChange={(newValue) => {
                                            setForm({
                                                ...form,
                                                thu: convertToTime(newValue)
                                            })
                                        }}
                                        renderInput={(params) => <TextField variant='filled' style={{ width: 100, marginRight: 0 }} {...params} />}
                                    />
                                </div>
                                <div className="weekDay">
                                    <label htmlFor="fri">{t("settings.teams.fri")}</label>
                                    <TimePicker
                                        ampm={false}
                                        views={['hours', 'minutes']}
                                        inputFormat="HH:mm"
                                        mask="__:__"
                                        // label={t("settings.teams.fri")}
                                        value={dayjs('2022-01-01 ' + form.fri)}
                                        onChange={(newValue) => {
                                            setForm({
                                                ...form,
                                                fri: convertToTime(newValue)
                                            })
                                        }}
                                        renderInput={(params) => <TextField variant='filled' style={{ width: 100, marginRight: 0 }} {...params} />}
                                    />
                                </div>
                                <div className="weekDay">
                                    <label htmlFor="sat">{t("settings.teams.sat")}</label>
                                    <TimePicker
                                        ampm={false}
                                        views={['hours', 'minutes']}
                                        inputFormat="HH:mm"
                                        mask="__:__"
                                        // label={t("settings.teams.sat")}
                                        value={dayjs('2022-01-01 ' + form.sat)}
                                        onChange={(newValue) => {
                                            setForm({
                                                ...form,
                                                sat: convertToTime(newValue)
                                            })
                                        }}
                                        renderInput={(params) => <TextField variant='filled' style={{ width: 100, marginRight: 0 }} {...params} />}
                                    />
                                </div>
                                <div className="weekDay">
                                    <label htmlFor="sun">{t("settings.teams.sun")}</label>
                                    <TimePicker
                                        ampm={false}
                                        views={['hours', 'minutes']}
                                        inputFormat="HH:mm"
                                        mask="__:__"
                                        // label={t("settings.teams.sun")}
                                        value={dayjs('2022-01-01 ' + form.sun)}
                                        onChange={(newValue) => {
                                            setForm({
                                                ...form,
                                                sun: convertToTime(newValue)
                                            })
                                        }}
                                        renderInput={(params) => <TextField variant='filled' {...params} style={{ width: 100, marginRight: 0 }} />}
                                    />
                                </div>
                                {/* <div className="weekDay">
                                <label htmlFor="mon">{t("settings.teams.mon")}</label>
                                <input type="time" name="mon" id="mon" value={form.mon} onChange={handleChange} />
                            </div>
                            <div className="weekDay">
                                <label htmlFor="tue">{t("settings.teams.tue")}</label>
                                <input type="time" name="tue" id="tue" value={form.tue} onChange={handleChange} />
                            </div>
                            <div className="weekDay">
                                <label htmlFor="wed">{t("settings.teams.wed")}</label>
                                <input type="time" name="wed" id="wed" value={form.wed} onChange={handleChange} />
                            </div>
                            <div className="weekDay">
                                <label htmlFor="thu">{t("settings.teams.thu")}</label>
                                <input type="time" name="thu" id="thu" value={form.thu} onChange={handleChange} />
                            </div>
                            <div className="weekDay">
                                <label htmlFor="fri">{t("settings.teams.fri")}</label>
                                <input type="time" name="fri" id="fri" value={form.fri} onChange={handleChange} />
                            </div>
                            <div className="weekDay">
                                <label htmlFor="sat">{t("settings.teams.sat")}</label>
                                <input type="time" name="sat" id="sat" value={form.sat} onChange={handleChange} />
                            </div>
                            <div className="weekDay">
                                <label htmlFor="sun">{t("settings.teams.sun")}</label>
                                <input type="time" name="sun" id="sun" value={form.sun} onChange={handleChange} />
                            </div> */}
                            </div>
                        </section>
                        <section style={{ flexWrap: 'nowrap' }}>
                            <div className="boxLabel">
                                <div className="icon">
                                    <IconPeriod />
                                </div>
                                <span>{t('settings.teams.gather_info')}:</span>
                            </div>
                            {/* <div className="field">
                                <input type="radio" id="always" name="selective_collection"
                                    checked={form.selective_collection == false} value="0"
                                    onChange={(event) => {
                                        handleChange(event)
                                    }} /> <label
                                        htmlFor="always">{t('settings.teams.always')}</label>
                            </div> */}
                            {/* <div className="field">
                                <input type="radio" id="period" name="selective_collection"
                                    checked={form.selective_collection == true} value="1"
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                /> <label
                                    htmlFor="period">{t('settings.teams.period')}</label>
                            </div> */}
                            {form.selective_collection == true &&
                                <div className="periods" style={{ padding: '0px' }}>
                                    {periodsHTML}
                                    {formError.period &&
                                        <div className={'error'}>{formError.period}</div>
                                    }
                                </div>
                            }
                        </section>
                        {/* {
                            userDetails.modules.some(module => module.name === "check_in_remote") && (form.selective_collection == true || props.current?.periods?.length > 0) && (
                                <section>
                                    <div className="icon">
                                        <IconWeek />
                                    </div>
                                    <span>{t('settings.teams.respect_checkin_actions')}:</span>
                                    <div className="field">
                                        <input type="radio" id="respect" name="respect_checkin_actions" checked={form.respect_checkin_actions == 1} value="1"
                                            onChange={handleChange} style={{ cursor: "pointer" }} /> <label htmlFor="respect">{t('settings.teams.yes')}</label>
                                    </div>
                                    <div className="field">
                                        <input type="radio" id="no_respect" name="respect_checkin_actions" checked={form.respect_checkin_actions == 0} value="0"
                                            onChange={handleChange} style={{ cursor: "pointer" }} /> <label
                                                htmlFor="no_respect">{t('settings.teams.no')}</label>
                                    </div>
                                    {formError.respect_checkin_actions &&
                                        <div className={'error'}>{formError.respect_checkin_actions}</div>
                                    }
                                </section>
                            )
                        } */}

                        <section style={{ display: 'none' }}>
                            <div className="icon">
                                <IconBefective />
                            </div>
                            <span>{t('settings.teams.show_icon')}:</span>
                            <div className="field">
                                <input type="radio" id="show" name="showIcon" checked={form.showIcon == 1} value="1"
                                    onChange={handleChange} /> <label htmlFor="show">{t('settings.teams.show')}</label>
                            </div>
                            <div className="field">
                                <input type="radio" id="not_show" name="showIcon" checked={form.showIcon == 0} value="0"
                                    onChange={handleChange} /> <label
                                        htmlFor="not_show">{t('settings.teams.dot_not_show')}</label>
                            </div>
                        </section>

                        <section>
                            <div className="boxLabel">
                                <div className="icon">
                                    <IconNotification />
                                </div>
                                <span>{t('settings.teams.show_away')}:</span>
                            </div>
                            <div className="boxInputs">
                                <div className="field">
                                    <select id="timeout" className="away" name="timeout" value={form.timeout}
                                        onChange={handleChange}>
                                        <option value="3">3 {t("settings.teams.minutes")}</option>
                                        <option value="4">4 {t("settings.teams.minutes")}</option>
                                        <option value="5">5 {t("settings.teams.minutes")}</option>
                                        <option value="6">6 {t("settings.teams.minutes")}</option>
                                        <option value="7">7 {t("settings.teams.minutes")}</option>
                                        <option value="8">8 {t("settings.teams.minutes")}</option>
                                        <option value="9">9 {t("settings.teams.minutes")}</option>
                                        <option value="10">10 {t("settings.teams.minutes")}</option>
                                        <option value="15">15 {t("settings.teams.minutes")}</option>
                                        <option value="20">20 {t("settings.teams.minutes")}</option>
                                        <option value="25">25 {t("settings.teams.minutes")}</option>
                                        <option value="30">30 {t("settings.teams.minutes")}</option>
                                    </select>
                                    <label htmlFor="timeout">{t('settings.teams.without')}.</label>
                                </div>
                            </div>
                        </section>

                        <section className="activities">
                            <div className="sub">
                                <div className="icon">
                                    <IconTeam />

                                </div>
                                <span>{t('settings.teams.activities')}:</span>
                                <div className="field activitie">
                                    <input type="text" id="activitieName" name="activitieName"
                                        value={formActivitie.activitieName}
                                        onChange={handleChangeActivitie}
                                        placeholder={t("settings.teams.new_activitie_placeholder")} />
                                </div>
                                <div className="field activitie">
                                    <select
                                        style={{
                                            width:'160px',
                                            backgroundPositionX:'135px'
                                        }}
                                        id="activitieType"
                                        className={
                                            formActivitie.activitieType === "productive"
                                                ?
                                                "related"
                                                :
                                                (
                                                    formActivitie.activitieType === "neutral"
                                                        ?
                                                        "neutral"
                                                        :
                                                        "not_related"
                                                )
                                        }
                                        name="activitieType"
                                        value={formActivitie.activitieType}
                                        onChange={handleChangeActivitie}
                                    >
                                        <option value="productive" className="related">{t("settings.teams.related")}</option>
                                        <option value="neutral" className="neutral">{t("settings.teams.neutral")}</option>
                                        <option value="unproductive" className="not_related">{t("settings.teams.not_related")}</option>
                                    </select>
                                </div>
                                <button type="button" className="add" onClick={() => {
                                    addActivitie()
                                }}><IconAdd /></button>
                            </div>
                            <div className="sub">
                                <ul>
                                    {teamActivities}
                                </ul>
                            </div>
                        </section>

                        <section>
                            <div className="boxLabel">
                                <div className="icon">
                                    <IconMood />
                                </div>
                                <span>{t('settings.teams.mood')}:</span>
                            </div>
                            <div className="boxInputs">
                                <div className="field">
                                    <input type="radio" id="ask" name="askMood" checked={form.askMood == 1} value="1"
                                        onChange={handleChange} /> <label htmlFor="ask">{t('settings.teams.ask')}</label>
                                </div>
                                <div className="field">
                                    <input type="radio" id="dont_ask" name="askMood" checked={form.askMood == 0} value="0"
                                        onChange={handleChange} /> <label
                                            htmlFor="dont_ask">{t('settings.teams.dont_ask')}</label>
                                </div>
                            </div>
                        </section>

                        <section style={{ flexWrap: 'nowrap', alignItems: 'flex-start' }}>
                            <div className="boxLabel">
                                <div className="icon">
                                    <IconWeek />
                                </div>
                                <span>{t('settings.teams.rest_control')}:</span>
                            </div>
                            <div className="boxInputs boxInputsRestControl">
                                <div className="rowInputs">
                                    <div className="periods" style={{ paddingLeft: '0px' }}>
                                        <div className="periodRow">
                                            <div className='periodTime'>
                                                <TimePicker
                                                    ampm={false}
                                                    views={['hours', 'minutes']}
                                                    inputFormat="HH:mm"
                                                    mask="__:__"
                                                    value={dayjs('2022-01-01 ' + form.continuous_working_time)}
                                                    onChange={(newValue) => handleChangeTime('continuous_working_time', convertToTime(newValue))}
                                                    renderInput={(params) => <TextField id='continuous_working_time' variant='filled' style={{ width: 150, marginRight: 4 }} {...params} />}
                                                />
                                                <label className='customLabel' htmlFor="continuous_working_time">
                                                    <span>{t('settings.teams.continuous_working_time')}</span>
                                                    <CustomTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('settings.teams.continuous_working_time_tooltip')}</Typography>} placement="right-start" arrow interactive>
                                                        <span style={{ cursor: 'pointer' }}><IconInfo /></span>
                                                    </CustomTooltip>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rowInputs">
                                    <div className="periods" style={{ paddingLeft: '0px' }}>
                                        <div className="periodRow">
                                            <div className='periodTime'>
                                                <TimePicker
                                                    ampm={false}
                                                    views={['hours', 'minutes']}
                                                    inputFormat="HH:mm"
                                                    mask="__:__"
                                                    value={dayjs('2022-01-01 ' + form.rest_time)}
                                                    onChange={(newValue) => handleChangeTime('rest_time', convertToTime(newValue))}
                                                    renderInput={(params) => <TextField id='rest_time' variant='filled' style={{ width: 150, marginRight: 4 }} {...params} />}
                                                />
                                                <label className='customLabel' htmlFor="rest_time">
                                                    <span>{t('settings.teams.rest_time')}</span>
                                                    <CustomTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('settings.teams.rest_time_tooltip')}</Typography>} placement="right-start" arrow interactive>
                                                        <span style={{ cursor: 'pointer' }}><IconInfo /></span>
                                                    </CustomTooltip>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="rowInputs">
                                    <div className="periods" style={{ paddingLeft: '0px' }}>
                                        <div className="periodRow">
                                            <div className='periodTime'>
                                                <TimePicker
                                                    ampm={false}
                                                    views={['hours', 'minutes']}
                                                    inputFormat="HH:mm"
                                                    mask="__:__"
                                                    minTime={
                                                        Number(timeToMinutes(form.continuous_working_time)) > 0
                                                            ?
                                                            dayjs('2022-01-01 ' + (parseInt(form.timeout) < 10 ? `00:0${form.timeout}` : `00:${form.timeout}`))
                                                            :
                                                            null
                                                    }
                                                    value={dayjs('2022-01-01 ' + form.alert_time_must_rest)}
                                                    onChange={(newValue) => handleChangeTime('alert_time_must_rest', convertToTime(newValue))}
                                                    renderInput={(params) => <TextField id='alert_time_must_rest' variant='filled' style={{ width: 150, marginRight: 4, color: '#1E6B7F' }} {...params} />}
                                                />
                                                <label className='customLabel' htmlFor="alert_time_must_rest">
                                                    <span>{t('settings.teams.alert_time_must_rest')}</span>
                                                    <CustomTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('settings.teams.alert_time_must_rest_tooltip')}</Typography>} placement="right-start" arrow interactive>
                                                        <span style={{ cursor: 'pointer' }}><IconInfo /></span>
                                                    </CustomTooltip>
                                                </label>
                                            </div>
                                            {formError.alert_time_must_rest &&
                                                <div className={'error'} style={{ marginTop: '6px' }}>{formError.alert_time_must_rest}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="rowInputs">
                                    <div className="field">
                                        <input style={{ fontSize: '14px', width: '120px', textAlign: 'left' }} type="number" min={0} id="max_repeat_alert" name="max_repeat_alert" value={form.max_repeat_alert} onChange={handleChange} />
                                        <label className='customLabel' htmlFor="max_repeat_alert" style={{ cursor: 'default' }}>
                                            <span>{t('settings.teams.max_repeat_alert')}</span>
                                            <CustomTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('settings.teams.max_repeat_alert_tooltip')}</Typography>} placement="right-start" arrow interactive>
                                                <span style={{ cursor: 'pointer' }}><IconInfo /></span>
                                            </CustomTooltip>
                                        </label>
                                    </div>
                                </div>

                                <div className="rowInputs directionColumn">
                                    <label className='customLabel'>
                                        <span>{t('settings.teams.mandatory')}</span>
                                        <CustomTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('settings.teams.mandatory_tooltip')}</Typography>} placement="right-start" arrow interactive>
                                            <span style={{ cursor: 'pointer' }}><IconInfo /></span>
                                        </CustomTooltip>
                                    </label>
                                    <div className='boxQuestionMandatory'>
                                        <div className="field">
                                            <input style={{ cursor: 'pointer' }} type="radio" id="mandatory" name="mandatory" checked={form.mandatory == "1"} value="1"
                                                onChange={handleChange} /> <label htmlFor="mandatory">{t('settings.teams.yes')}</label>
                                        </div>
                                        <div className="field">
                                            <input style={{ cursor: 'pointer' }} type="radio" id="dont_mandatory" name="mandatory" checked={form.mandatory == "0"} value="0"
                                                onChange={handleChange} /> <label
                                                    htmlFor="dont_mandatory">{t('settings.teams.no')}</label>
                                        </div>
                                    </div>
                                </div>

                                {
                                    form.mandatory == "1" &&
                                    <>
                                        <div className="rowInputs">
                                            <div className="periods" style={{ paddingLeft: '0px' }}>
                                                <div className="periodRow">
                                                    <div className='periodTime'>
                                                        <TimePicker
                                                            ampm={false}
                                                            views={['hours', 'minutes']}
                                                            inputFormat="HH:mm"
                                                            mask="__:__"
                                                            value={dayjs('2022-01-01 ' + form.shortage_time)}
                                                            onChange={(newValue) => handleChangeTime('shortage_time', convertToTime(newValue))}
                                                            renderInput={(params) => <TextField id='shortage_time' variant='filled' style={{ width: 150, marginRight: 4 }} {...params} />}
                                                        />
                                                        <label className='customLabel' htmlFor="shortage_time">
                                                            <span>{t('settings.teams.shortage_time')}</span>
                                                            <CustomTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('settings.teams.shortage_time_tooltip')}</Typography>} placement="right-start" arrow interactive>
                                                                <span style={{ cursor: 'pointer' }}><IconInfo /></span>
                                                            </CustomTooltip>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                }
                            </div>

                        </section>

                    </form>
                </div>
            </LocalizationProvider>

        </React.Fragment>

    )
};

function mapStateToProps(state) {

    return {
        token: state.authentication.user.accessToken,
        permissions: state.authentication.permissions,
        userDetails: state.authentication.userDetails,
        current: state.teams.detail,
        teams: state.teams.payload,
        activities: state.teams.activities,
        updated: state.teams.updated,
        updatedActivities: state.teams.updatedActivities,
        deleted: state.teams.deleted,
        teammates: state.teammates.payload
    }
}

function mapDispatchToProps(dispatch) {
    return {
        teamsActions: bindActionCreators(teamsActions, dispatch),
        teammatesActions: bindActionCreators(teammatesActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Teams));
