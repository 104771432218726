import React, { useRef, useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import { bindActionCreators } from "redux";
import { VictoryStack, VictoryBar, VictoryTheme } from "victory";

import Moment from "moment";

import { activitiesActions } from "../../_actions";
import { statsActions } from "../../_actions";
import { historicalActions } from "../../_actions";

import { parseValue, secondsToHm } from "../../_helpers";

import Skeleton from "@material-ui/lab/Skeleton";

import IconInfo from "../_icons/IconInfo";
import IconOnline from "../_icons/IconOnline";
import IconOutdoor from "../_icons/IconOutdoor";
import IconHappy from "../_icons/IconHappy";
import IconOk from "../_icons/IconOk";
import IconSad from "../_icons/IconSad";
import IconDate from "../_icons/IconDate";
import IconArrow from "../_icons/IconArrow";
import { Typography } from "@material-ui/core";

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const BefectiveTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#1E6B7F', // Cor de fundo personalizada
    marginRight: 0, // Espaçamento à direita
    maxWidth: '380px'
  },
  arrow: {
    color: '#1E6B7F'
  },
}))(Tooltip);

function HistoricalSummary(props) {
  const { t, i18n } = useTranslation("common");

  const { historicalActions, userDetails } = props;

  let filter = props.filter || {};

  Moment.locale(t("date.locale"));

  const [selectedDay, setSelectedDay] = useState();

  let startDate = props.startDate;
  let endDate = props.endDate;

  const [DisplaySelectedDateHTML, setDisplaySelectedDateHTML] = useState([]);
  const [DisplayOutlineHTML, setDisplayOutlineHTML] = useState([]);
  const [DisplayDaysHTML, setDisplayDaysHTML] = useState([]);

  const [DisplayTotalWorkedTime, setDisplayTotalWorkedTime] = useState(0);
  const [DisplayTotalWorkedTimeOnline, setDisplayTotalWorkedTimeOnline] = useState(0);
  const [DisplayTotalWorkedTimeOutdoor, setDisplayTotalWorkedTimeOutdoor] = useState(0);
  const [DisplayTotalProductiveTime, setDisplayTotalProductiveTime] = useState(0);
  const [DisplayTotalNeutralTime, setDisplayTotalNeutralTime] = useState(0);
  const [DisplayTotalUnproductiveTime, setDisplayTotalUnproductiveTime] = useState(0);
  const [DisplayPercentWorkedTimeOnline, setDisplayPercentWorkedTimeOnline] = useState(0);
  const [DisplayPercentWorkedTimeOutdoor, setDisplayPercentWorkedTimeOutdoor] = useState(0);
  const [DisplayPercentProductiveTime, setDisplayPercentProductiveTime] = useState(0);
  const [DisplayPercentNeutralTime, setDisplayPercentNeutralTime] = useState(0);
  const [DisplayPercentUnproductiveTime, setDisplayPercentUnproductiveTime] = useState(0);
  const [DisplayTotalBarsDays, setDisplayTotalBarsDays] = useState(0);
  const [DisplayMachineUserName, setDisplayMachineUserName] = useState(0);
  const [data, setData] = useState(null)

  function toggleShowing(where, day = null) {
    props.openWho({
      now: Moment().unix(),
      day: day,
    });
  }

  function setHistorical(data) {
    
    setData(data)
    
    let SelectedDateHTML = [];
    let OutlineHTML = [];
    let DaysHTML = [];
    let HoursHTML = [];

    if (filter.date) {
      startDate = filter.date.startDate;
      endDate = filter.date.endDate;
      if (startDate == endDate) {
        SelectedDateHTML.push(
          <div className="selectedDate">
            {Moment(startDate).format(t("date.mm/dd/yyyy"))}
          </div>
        );
      } else {
        SelectedDateHTML.push(
          <div className="selectedDate">
            {Moment(startDate).format(t("date.mm/dd/yyyy"))} até{" "}
            {Moment(endDate).format(t("date.mm/dd/yyyy"))}
          </div>
        );
      }
    } else {
      if (startDate == endDate) {
        SelectedDateHTML.push(
          <div className="selectedDate">
            {Moment(startDate).format(t("date.mm/dd/yyyy"))}
          </div>
        );
      } else {
        SelectedDateHTML.push(
          <div className="selectedDate">
            {Moment(startDate).format(t("date.mm/dd/yyyy"))} até{" "}
            {Moment(endDate).format(t("date.mm/dd/yyyy"))}
          </div>
        );
      }
    }

    setDisplayTotalBarsDays(data.length || 0);

    let MaxHours = 0;
    let LastTotalWorkedTime = 0;
    let MaxTotalWorkedTime = 0;

    for (let item of data.days) {
      if (item.total_worked_time_avg > LastTotalWorkedTime) {
        LastTotalWorkedTime = item.total_worked_time_avg;
        MaxTotalWorkedTime = item.total_worked_time_avg;
        if(userDetails.calculate_based_productivity_target !== undefined && userDetails.calculate_based_productivity_target===true){
          MaxHours = Math.round(
                                (Number(item.total_productive_time_avg)
                                + (userDetails.allowed_productive.includes('neutral') ? item.total_neutral_time_avg : 0)
                                + (userDetails.allowed_productive.includes('unproductive') ? item.total_unproductive_time_avg : 0))
                                / 3600)

        }else{
          MaxHours = Math.round(item.total_worked_time_avg / 3600);
        }
      }
    }

    let Fraction = Math.round(MaxHours / 9);

    for (let item of data.days) {
      let RelativeRelated = 0;
      let RelativeNeutral = 0;
      let RelativeUnrelated = 0;
      //let Date = new Date(item.date).toLocaleDateString();
      let noBorder = "";

      let rows = MaxHours;

      if (MaxHours % 2 !== 0) {
        rows += 1;
      }

      if (MaxHours > 0 && item.total_productive_time_avg > 0) {
        RelativeRelated = Math.round(
          ((item.total_productive_time_avg / 3600) * 100) / rows
        );
      }
      if (MaxHours > 0 && item.total_neutral_time_avg > 0) {
        RelativeNeutral = Math.round(
          ((item.total_neutral_time_avg / 3600) * 100) / rows
        );
      }
      if (MaxHours > 0 && item.total_unproductive_time_avg > 0) {
        RelativeUnrelated = Math.round(
          ((item.total_unproductive_time_avg / 3600) * 100) / rows
        );
      }

      if (RelativeRelated + RelativeNeutral + RelativeUnrelated < 5) {
        noBorder = "no-border";
      }

      DaysHTML.push(
        <div className="day">
          <div className="labelBox">
            <div className="header">
              <div className="icon">
                <IconDate />
              </div>
              {Moment.utc(item.date).format("dddd")},{" "}
              {Moment.utc(item.date).format(t("date.mm/dd"))}
            </div>
            {userDetails.allowed_productive.includes("related") && (
              <div className="related">
                {t("historical.related")}:{" "}
                {secondsToHm(item.total_productive_time_avg)}
              </div>
            )}
            {userDetails.allowed_productive.includes("neutral") && (
              <div className="neutral">
                {t("historical.neutral")}: {secondsToHm(item.total_neutral_time_avg)}
              </div>
            )}
            {userDetails.allowed_productive.includes("not_related") && (
              <div className="unrelated">
                {t("historical.unrelated")}:{" "}
                {secondsToHm(item.total_unproductive_time_avg)}
              </div>
            )}
          </div>
          <div
            className="bar"
            onClick={() => {
              toggleShowing("day", Moment.utc(item.date).format("YYYY-MM-DD"));
            }}
          >
            {userDetails.allowed_productive.includes("not_related") && (
              <div
                className={"unrelated " + noBorder}
                style={{ height: RelativeUnrelated + "%" }}
              ></div>
            )}
            {userDetails.allowed_productive.includes("neutral") && (
              <div
                className={"neutral " + noBorder}
                style={{ height: RelativeNeutral + "%" }}
              ></div>
            )}
            {userDetails.allowed_productive.includes("related") && (
              <div
                className={"related " + noBorder}
                style={{ height: RelativeRelated + "%" }}
              ></div>
            )}
          </div>
          <div className="labelDay">
            {Moment.utc(item.date).format(t("date.mm/dd"))}
          </div>
        </div>
      );
    }

    setDisplayDaysHTML(DaysHTML);

    let TotalWorkedTime = data.TotalWorkedTime || 0;
    let TotalWorkedTimeOnline = data.TotalWorkedTime - data.TotalWorkedTimeOutdoor || 0;
    let TotalWorkedTimeOutdoor = data.TotalWorkedTimeOutdoor || 0;
    let TotalProductiveTime = data.TotalProductiveTime || 0;
    let TotalNeutralTime = data.TotalNeutralTime || 0;
    let TotalUnproductiveTime = data.TotalUnproductiveTime || 0;

    setDisplayTotalWorkedTime(TotalWorkedTime);
    setDisplayTotalWorkedTimeOnline(TotalWorkedTimeOnline);
    setDisplayTotalWorkedTimeOutdoor(TotalWorkedTimeOutdoor);
    setDisplayTotalProductiveTime(TotalProductiveTime);
    setDisplayTotalNeutralTime(TotalNeutralTime);
    setDisplayTotalUnproductiveTime(TotalUnproductiveTime);

    if (TotalWorkedTime > 0) {
      setDisplayPercentWorkedTimeOnline(
        Math.round(
          ((TotalWorkedTime - TotalWorkedTimeOutdoor) / TotalWorkedTime) * 100
        )
      );
    }
    if (TotalWorkedTime > 0) {
      setDisplayPercentWorkedTimeOutdoor(
        Math.round((TotalWorkedTimeOutdoor / TotalWorkedTime) * 100)
      );
    }
    if (TotalWorkedTime > 0 && TotalProductiveTime > 0) {
      setDisplayPercentProductiveTime(
        Math.round((TotalProductiveTime / TotalWorkedTime) * 100)
      );
    }
    if (TotalWorkedTime > 0 && TotalNeutralTime > 0) {
      setDisplayPercentNeutralTime(
        Math.round((TotalNeutralTime / TotalWorkedTime) * 100)
      );
    }
    if (TotalWorkedTime > 0 && TotalUnproductiveTime > 0) {
      setDisplayPercentUnproductiveTime(
        Math.round((TotalUnproductiveTime / TotalWorkedTime) * 100)
      );
    }

    // let Fraction = Math.round(MaxHours / 9);

    if (Fraction === 0) {
      Fraction = 1;
    }

    let rows = MaxHours / Fraction;

    if (MaxHours % 2 === 0) {
      rows = Math.ceil(MaxHours / Fraction);
    }
    for (let n = 0; n <= rows; n++) {
      let hour = Fraction * n;

      // if (n === 9) {
      //     OutlineHTML.push(<div className="hour"><span>{MaxHours}</span></div>)
      // } else {
      OutlineHTML.push(
        <div className="hour">
          <span>{hour}</span>
        </div>
      );
      // }
    }
    if (MaxHours % 2 !== 0) {
      OutlineHTML.push(
        <div className="hour">
          <span>{MaxHours + 1}</span>
        </div>
      );
    }

    setDisplaySelectedDateHTML(SelectedDateHTML);
    setDisplayOutlineHTML(OutlineHTML);
  }

  useEffect(() => {
    if (props.historical.summary && props.historical.summary.days.length > 0) {
      setHistorical(props.historical.summary);
    }
  }, [props.historical]);

  return (
    <div className="box historical noBg">
      <div className="pageHeader historical">
        <div className="multi">
          {userDetails.allowed_total_time === true && (
            <h3>
              {t("historical.total_time")}:{" "}
              <b> {secondsToHm(data?.total_expected_worked_time_avg)} / {secondsToHm(data?.total_worked_time_avg)}</b>&nbsp;
              <BefectiveTooltip title={<Typography component={'div'} variant='caption' marginY={'4px'} style={{fontSize:'12px', lineHeight:'16px'}}>{t("historical.tooltip1")}</Typography>} placement="bottom-center" arrow interactive>
                <div><IconInfo /></div>
              </BefectiveTooltip>	
            </h3>
          )}
          {
						userDetails.allowed_total_time_related === true && (
								<h3 className="work">
									{t('historical.total_time_related')}: <b> {secondsToHm(data?.total_expected_productive_time_avg)} / {secondsToHm(data?.total_productive_time_avg)}</b>&nbsp;
									<BefectiveTooltip title={<Typography component={'div'} variant='caption' marginY={'4px'} style={{fontSize:'12px', lineHeight:'16px'}}>{t('historical.tooltip2')}</Typography>} placement="bottom-center" arrow interactive>
										<div><IconInfo /></div>
									</BefectiveTooltip>	
								</h3>
						)
					}
        </div>

        <div className="internalFilters">

          {
						userDetails.allowed_productive.includes('related') && (
							(userDetails.calculate_based_productivity_target !== undefined && userDetails.calculate_based_productivity_target===true)
							?
              <div className="filter work active">
                <div className={"head"}>
                  <div className="icon">
                    <div className="dot related"></div>
                  </div>
                  {
                    userDetails.allowed_total_time_related === true && (
                    <div className="number">
                      {secondsToHm(data?.total_productive_time_avg)}
                    </div>
                    )
                  }
                </div>
                <div className="highlight">{`${parseValue(data?.productive_percent_from_total_productive)}%`}</div>
                <div className="name">{t("historical.work_related")}</div>
              </div>
              :
              <div className="filter work active">
                <div className={"head"}>
                  <div className="icon">
                    <div className="dot related"></div>
                  </div>
                  {
                    userDetails.allowed_total_time_related === true && (
                    <div className="number">
                      {secondsToHm(data?.total_productive_time_avg)}
                    </div>
                    )
                  }
                </div>
                <div className="highlight">{`${parseValue(data?.productive_percent_from_total_time)}%`}</div>
                <div className="name">{t("historical.work_related")}</div>
              </div>
            )
          }
          
          {
						userDetails.allowed_productive.includes('neutral') && (
							(userDetails.calculate_based_productivity_target !== undefined && userDetails.calculate_based_productivity_target===true)
							?
              <div className="filter neutral active">
                <div className={"head"}>
                  <div className="icon">
                    <div className="dot neutral"></div>
                  </div>
                  {
                    userDetails.allowed_total_time_related === true && (
                    <div className="number">
                      {secondsToHm(data?.total_neutral_time_avg)}
                    </div>
                    )
                  }
                </div>
                <div className="highlight">{`${parseValue(data?.percent_neutral_from_total_productive)}%`}</div>
                <div className="name">{t("historical.work_neutral")}</div>
              </div>
              :
              <div className="filter neutral active">
                <div className={"head"}>
                  <div className="icon">
                    <div className="dot neutral"></div>
                  </div>
                  {
                    userDetails.allowed_total_time_related === true && (
                    <div className="number">
                      {secondsToHm(data?.total_neutral_time_avg)}
                    </div>
                    )
                  }
                </div>
                <div className="highlight">{`${parseValue(data?.percent_neutral_from_total_time)}%`}</div>
                <div className="name">{t("historical.work_neutral")}</div>
              </div>
          )}
          
          {
						userDetails.allowed_productive.includes('not_related') && (
							(userDetails.calculate_based_productivity_target !== undefined && userDetails.calculate_based_productivity_target===true)
							?
              <div className="filter sad active">
                <div className={"head"}>
                  <div className="icon">
                    <div className="dot unrelated"></div>
                  </div>
                  {
                    userDetails.allowed_total_time_related === true && (
                    <div className="number">
                      {secondsToHm(data?.total_unproductive_time_avg)}
                    </div>
                    )
                  }
                </div>
                <div className="highlight">{`${parseValue(data?.percent_unproductive_from_total_productive)}%`}</div>
                <div className="name">{t("historical.work_unrelated")}</div>
              </div>
              :
              <div className="filter sad active">
                <div className={"head"}>
                  <div className="icon">
                    <div className="dot unrelated"></div>
                  </div>
                  {
                    userDetails.allowed_total_time_related === true && (
                    <div className="number">
                      {secondsToHm(data?.total_unproductive_time_avg)}
                    </div>
                    )
                  }                    
                </div>
                <div className="highlight">{`${parseValue(data?.percent_unproductive_from_total_time)}%`}</div>
                <div className="name">{t("historical.work_unrelated")}</div>
              </div>
          )}

          {
						(userDetails.calculate_based_productivity_target !== undefined && userDetails.calculate_based_productivity_target===true)
						?
						<>
                <div className="filter time active">
                  <div className={"head"}>
                    <div className="icon">
                      <IconOnline />
                    </div>
                    {userDetails.allowed_total_time === true && (
                      <div className="number">
                        {secondsToHm(data?.total_productive_online_time_avg)}
                      </div>
                    )}
                  </div>
                  <div className="highlight">{`${parseValue(data?.productive_percent_online_from_total_productive)}%`}</div>
                  <div className="name">{t("historical.online")}</div>
                </div>
                
                <div className="filter active">
                  <div className={"head"}>
                    <div className="icon">
                      <IconOutdoor />
                    </div>
                    {userDetails.allowed_total_time === true && (
                      <div className="number">
                        {secondsToHm(data?.total_productive_offline_time_avg)}
                      </div>
                    )}
                  </div>
                  <div className="highlight">{`${parseValue(data?.productive_percent_offline_from_total_productive)}%`}</div>
                  <div className="name">{t("historical.outdoor")}</div>
                </div>
            </>
            :
            <>
              <div className="filter time active">
                <div className={"head"}>
                  <div className="icon">
                    <IconOnline />
                  </div>
                  {userDetails.allowed_total_time === true && (
                    <div className="number">
                      {secondsToHm(data?.total_online_time_avg)}
                    </div>
                  )}
                </div>
                <div className="highlight">{`${parseValue(data?.productive_percent_online_from_total_time)}%`}</div>
                <div className="name">{t("historical.online")}</div>
              </div>
              
              <div className="filter active">
                <div className={"head"}>
                  <div className="icon">
                    <IconOutdoor />
                  </div>
                  {userDetails.allowed_total_time === true && (
                    <div className="number">
                      {secondsToHm(data?.total_offline_time_avg)}
                    </div>
                  )}
                </div>
                <div className="highlight">{`${parseValue(data?.productive_percent_offline_from_total_time)}%`}</div>
                <div className="name">{t("historical.outdoor")}</div>
              </div>
            </>
          }
        </div>
      </div>

      <div className="chartWrapper">
        <div className="internalBreadcrub">{DisplaySelectedDateHTML}</div>
        <div className={"historicalChart days"}>
          <div className="outline">
            {DisplayOutlineHTML}

            <div className="timeLabel">{t("historical.hour")}</div>
          </div>
          <div className={DisplayTotalBarsDays > 10 ? "days" : "days few"}>
            {DisplayDaysHTML}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    historicalActions: bindActionCreators(historicalActions, dispatch),
    statsActions: bindActionCreators(statsActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    historical: state.historical,
    filter: state.filter,
    userDetails: state.authentication.userDetails,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalSummary);
