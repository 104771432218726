import { Container, Grid, Skeleton, Box } from '@mui/material'
import React, { useState } from 'react'

export default function SkeletonProductivityPerformanceFormPage(props) {

  return (
    <Box width={'680px'}>
      <Grid container spacing={0} mt={'20px'} mb={'0px'}>
        <Grid item xs={12} >
          <Skeleton width={'100%'} height={600} variant='rounded' />
        </Grid>
      </Grid>
    </Box>
  )
}