// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TVBh1PZ-0e1RkQ3Jtr5ceg\\=\\= {\n  background-color: #ffffff;\n}\n\n.QV-3aSt5u5-ng\\+acpSp6tw\\=\\= {\n  text-transform: uppercase;\n  font-size: 11px;\n  color: #4F7091;\n}\n\n._1C4lulI77VS8ra\\+Ngilvkg\\=\\= {\n  background-color: #ffffff;\n}\n\n.g18gT7DyBWyFnm66HgpZ2A\\=\\= {\n  font-weight: 500;\n  border-top: 6px solid #F5F6F9;\n}\n\n.tOFAr0bgUp4-LHaUQooVxA\\=\\= {\n  cursor: pointer;\n}\n\n.tOFAr0bgUp4-LHaUQooVxA\\=\\=:hover {\n  transition: 0.2s all ease-in-out;\n  background-color: rgba(228, 234, 239, 0.5215686275);\n}\n\n.\\+zzlwNljBmTrxzPEVKkSTw\\=\\= {\n  text-align: center;\n}\n\n.nhQE1IfN3qBOm4GrHUakZg\\=\\= {\n  width: 120px;\n}\n\n.AIAKkMIHKZgdACHVnd\\+37A\\=\\= {\n  color: #187DFF;\n}\n\n.uxWLoyEAZY7YzVgz\\+01Ljw\\=\\= {\n  cursor: pointer;\n}\n\n.uxWLoyEAZY7YzVgz\\+01Ljw\\=\\=:hover {\n  color: #187DFF;\n}", "",{"version":3,"sources":["webpack://./src/_components/performance_assessments/PerformanceAssessmentsDetails/ParticipantsTable.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;EACA,eAAA;EACA,cAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,gBAAA;EACA,6BAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,gCAAA;EACA,mDAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".tableHead {\n  background-color: #ffffff;\n}\n\n.tableColumn{\n  text-transform: uppercase;\n  font-size: 11px;\n  color: #4F7091;\n}\n\n.tableRow{\n  background-color: #ffffff;\n}\n\n.tableRowAvaleated{\n  font-weight: 500;\n  border-top: 6px solid #F5F6F9;\n}\n\n.tableRowHover{\n  cursor: pointer;\n}\n\n.tableRowHover:hover{\n  transition: .2s all ease-in-out;\n  background-color: #E4EAEF85;\n}\n\n.alignCenter{\n  text-align: center;\n}\n\n.widthMinimal{\n  width: 120px;\n}\n\n.autoAssessment{\n  color: #187DFF;\n}\n\n.avgAssessment{\n  cursor: pointer;  \n}\n\n.avgAssessment:hover{\n  color: #187DFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHead": "TVBh1PZ-0e1RkQ3Jtr5ceg==",
	"tableColumn": "QV-3aSt5u5-ng+acpSp6tw==",
	"tableRow": "_1C4lulI77VS8ra+Ngilvkg==",
	"tableRowAvaleated": "g18gT7DyBWyFnm66HgpZ2A==",
	"tableRowHover": "tOFAr0bgUp4-LHaUQooVxA==",
	"alignCenter": "+zzlwNljBmTrxzPEVKkSTw==",
	"widthMinimal": "nhQE1IfN3qBOm4GrHUakZg==",
	"autoAssessment": "AIAKkMIHKZgdACHVnd+37A==",
	"avgAssessment": "uxWLoyEAZY7YzVgz+01Ljw=="
};
export default ___CSS_LOADER_EXPORT___;
