import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"
import { FormControl, FormControlLabel, IconButton, Input, InputAdornment, OutlinedInput, Radio, RadioGroup, makeStyles } from '@material-ui/core'
import Select from 'react-select'
import { useState } from 'react'
import { api } from '../../../_helpers/api'
import { authHeader } from '../../../_helpers'
import './ParticipantsDrawer.scss'
import IconClose from '../../_icons/IconClose'
import BefectiveCheckbox from '../../_shared/BefectiveCheckbox'
import ParticipantsTablePagination from './ParticipantsTablePagination'
import { Search } from '@material-ui/icons'
import { CircularProgress } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  root: {
    '&$checked': {
      color: '#187DFF',
    },
  },
  checked: {},
}));

function ParticipantsDrawer({ openDrawer, closeDrawer, teams, addParticipants, coverage, autoEvaluation, participants, offices = [], alertActions }) {

	const { t } = useTranslation('common')
  const classes = useStyles();
	const [isSubmitting, setIsSubmitting] = useState(false);
  const [typeFilter, setTypeFilter] = useState('colab');
  const [teamSelected, setTeamSelected] = useState(null);
  const [positionSelected, setPositionSelected] = useState(null);  
  const [colabName, setColabName] = useState('');  
  const [participantsFiltered, setParticipantsFiltered] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const isAllSelected = participantsFiltered.length > 0 && selectedRows.length === participantsFiltered.length;
  const isSelected = id => selectedRows.some(row => row.id === id);
  const [fetchingData, setFetchingData] = useState(true);
  const [totalParticipants, setTotalParticipants] = useState(0);

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      setSelectedRows(participantsFiltered);
    }
  };

  const handleSelectRow = participant => {
    if (isSelected(participant.id)) {
      setSelectedRows(selectedRows.filter(row => row.id !== participant.id));
    } else {
      setSelectedRows([...selectedRows, {...participant, selected: true}]);
    }
  };

  const getUsersByTeamOrOffice = async ({teamId = null, officeLabel = null}) => {
    try {
      setFetchingData(true)
      const queryTeam = teamId ? `?team_id=${teamId}` : ''
      const queryOffice = officeLabel ? `?office=${officeLabel}` : ''
      const {data} = await api.get(`/performance-assessment/get-users${queryTeam}${queryOffice}`, {
        headers: authHeader()
      })
      setParticipantsFiltered(data.filter(user => !participants.some(participant => participant.machine_users_id === user.id)))
      setTotalParticipants(data.length)
      setSelectedRows([])    
      setFetchingData(false)  
    } catch (error) {
      console.log(error)
      setFetchingData(false)
    }
  }

  const handleChangeTeam = (option) => {
    setTeamSelected(option);
    if(!option || !option.value){
      getUsersByTeamOrOffice({teamId: null, officeLabel: null})
      return
    }
    getUsersByTeamOrOffice({teamId: option.value, officeLabel: null})
  };

  const handleChangePosition = (option) => {
    setPositionSelected(option);
    if(!option || !option.label){
      getUsersByTeamOrOffice({teamId: null, officeLabel: null})
      return
    }
    if(option.label){
      getUsersByTeamOrOffice({teamId: null, officeLabel: option.label})
    }
  };

  const handleChangeColabName = (e) => {
    const {value} = e.target

    setColabName(value)

    const _value = value.toLowerCase().trim()

    if(!_value){
      getUsersByTeamOrOffice({teamId: null, officeLabel: null})
      return
    }

    handleFilterColab(_value)
  };

  const handleFilterColab = (value = null) => { 
    const valueFilter = value || colabName.toLowerCase().trim()

    if(!valueFilter){
      return
    }

    const newParticipants = participantsFiltered.filter(participant => participant.username.toLowerCase().includes(valueFilter))

    setParticipantsFiltered(newParticipants)
  }

  const handleSaveParticipants = async () => {
    try {
      setIsSubmitting(true)

      if(selectedRows.length === 0 && typeFilter !== 'company'){
        alertActions.error(t('performance_assessment.messages.error_no_selected_colab'))
        setIsSubmitting(false)
        return
      }

      const queryTeamId = teamSelected?.value ? `&team_id=${teamSelected?.value}` : ''
      const queryOffice = positionSelected?.label ? `&office=${positionSelected?.label}` : ''
      const users = typeFilter === 'company' ? null : selectedRows.map(item => item.id).join(',')
      const queryUsers = users ? `&users=${users}` : ''
      const queryAllUsers = typeFilter === 'company' ? `&all_users=true` : ''
      const {data} = await api.get(`/performance-assessment/user-structure?scope=${coverage}&self_assessment=${autoEvaluation}${queryUsers}${queryTeamId}${queryOffice}${queryAllUsers}`, {
        headers: authHeader()
      })
      addParticipants(data)
      closeDrawer();
      setIsSubmitting(false)   
    } catch (error) {
      console.log(error) 
      setIsSubmitting(false)   
    }
  }
  const handleChangeRadio = (e) => {
    const { value } = e.target
    setTypeFilter(value)
    if(value === 'team' && teamSelected?.value){
      getUsersByTeamOrOffice({teamId: teamSelected?.value, officeLabel: null})
    }else if(value === 'position' && positionSelected?.label){
      getUsersByTeamOrOffice({teamId: null, officeLabel: positionSelected?.label})
    }else{
      getUsersByTeamOrOffice({teamId: null, officeLabel: null})
      setTeamSelected(null)
      setPositionSelected(null)
    }
  }

  useEffect(() => {
    if(openDrawer){
      getUsersByTeamOrOffice({teamId: null, officeLabel: null})
    }
  }, [openDrawer])

	return (
    <>
      <div
        className={"overlay" + (openDrawer ? " open" : "")}
        onClick={() => !isSubmitting && closeDrawer()}
      ></div>
      <section
        className={
          "drawer drawer540 drawerParticipants" + (openDrawer ? " open" : "")
        }
      >
        <button onClick={() => !isSubmitting && closeDrawer()} className="close">
          <IconClose />
        </button>

        <div className="containerParticipantsDrawer">
          
            <header>
              <h3>{t('performance_assessment.tab_participants_form.participants_drawer.title')}</h3>
            </header>

            <main>
              <div className="row">
                <div className="col">
                  <FormControl component="fieldset">
                    <RadioGroup row aria-label="type-filter" name="type_filter" value={typeFilter} onChange={handleChangeRadio}>
                      <FormControlLabel value="colab" control={<Radio classes={{ root: classes.root, checked: classes.checked }} />} label={<span className="labelTypeRadio">{t('performance_assessment.tab_participants_form.participants_drawer.label_colab')}</span>} />
                      <FormControlLabel value="position" control={<Radio classes={{ root: classes.root, checked: classes.checked }} />} label={<span className="labelTypeRadio">{t('performance_assessment.tab_participants_form.participants_drawer.label_position')}</span>} />
                      <FormControlLabel value="team" control={<Radio classes={{ root: classes.root, checked: classes.checked }} />} label={<span className="labelTypeRadio">{t('performance_assessment.tab_participants_form.participants_drawer.label_team')}</span>} />
                      <FormControlLabel value="company" control={<Radio classes={{ root: classes.root, checked: classes.checked }} />} label={<span className="labelTypeRadio">{t('performance_assessment.tab_participants_form.participants_drawer.label_all_company')}</span>} />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              <div className="row">
                {
                  typeFilter === 'team' &&
                  <div className="col">
                    <label htmlFor='teamSelect'>{t('performance_assessment.tab_participants_form.participants_drawer.label_team')}</label>
                    <Select
                      id='teamSelect'
                      value={teamSelected}
                      name="teamSelect"
                      options={teams.length > 0 ? teams.map(team => ({value: team.id, label: team.herac})) : []}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleChangeTeam}
                      placeholder={t('performance_assessment.tab_participants_form.select')}
                      isClearable
                    />
                  </div>
                }
                {
                  typeFilter === 'position' &&
                  <div className="col">
                    <label htmlFor='positionSelect'>{t('performance_assessment.tab_participants_form.participants_drawer.label_position')}</label>
                    <Select
                      id='positionSelect'
                      value={positionSelected}
                      name="positionSelect"
                      options={offices.length > 0 ? offices : []}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleChangePosition}
                      placeholder={t('performance_assessment.tab_participants_form.select')}
                      isClearable
                      
                    />
                  </div> 
                }

                {
                  typeFilter === 'colab' &&
                  <div className="col">
                    <label htmlFor='colabSelect'>{t('performance_assessment.tab_participants_form.participants_drawer.label_colab')}</label>
                    <FormControl variant="outlined">
                      <OutlinedInput
                        id="colabSelect"
                        placeholder={t('performance_assessment.tab_participants_form.participants_drawer.placeholder_colab')}
                        type={'text'}
                        value={colabName}
                        onChange={handleChangeColabName}
                        onKeyDown={(e) => e.key === 'Enter' && handleFilterColab()}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleFilterColab}
                              onMouseDown={handleFilterColab}
                              
                            >
                              <Search />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                }

              </div>
              {/* {
                (typeFilter === 'position' || typeFilter === 'team') &&
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '0px' }}>
                        <BefectiveCheckbox checked={isAllSelected} onChange={handleSelectAll} name="checkboxAll" />
                      </th>
                      <th>{t('performance_assessment.tab_participants_form.table.col_name')}</th>
                      <th>{t('performance_assessment.tab_participants_form.table.col_position')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      participantsFiltered.length > 0 ? 
                      participantsFiltered.map((participant, index) => (
                        <tr key={`participant-${index}`} className='rowHover' onClick={() => handleSelectRow(participant)}>
                          <td style={{ width: '0px' }}>
                            <BefectiveCheckbox 
                              checked={isSelected(participant.id)} 
                              onChange={(e) => {
                                e.stopPropagation(); 
                                handleSelectRow(participant)
                              }}/>
                          </td>
                          <td>{participant.username}</td>
                          <td>{participant?.office || "----"}</td>
                        </tr>
                      ))
                      :
                      <tr>
                        <td colSpan={3} style={{padding: '12px 24px'}}>{t('performance_assessment.messages.none_options')}</td>
                      </tr>
                    }
                  </tbody>
                </table>
              } */}

              {
                (typeFilter === 'position' || typeFilter === 'team' || typeFilter === 'colab') &&
                <ParticipantsTablePagination data={participantsFiltered} isAllSelected={isAllSelected} handleSelectAll={handleSelectAll} isSelected={isSelected} handleSelectRow={handleSelectRow} count={participantsFiltered.length / 20} pageCountRows={participantsFiltered.length} fetchingData={fetchingData}/>
              }

              {
                (typeFilter === 'company') &&
                <div className="boxInfoSelectAllCompany">
                  <p>{t('performance_assessment.tab_participants_form.participants_drawer.info_all_company')}</p>
                  <p><strong>{`${totalParticipants} ${t('performance_assessment.tab_participants_form.participants_drawer.colabs_selected')}`}</strong></p>
                </div>
              }
            </main>
        </div>
        <footer className='footerDrawerParticipants'>
          <button className='buttonCancel' onClick={closeDrawer} disabled={isSubmitting}>
            {t('performance_assessment.actions.cancel')}
          </button>
          <button className='buttonSave' onClick={handleSaveParticipants} disabled={isSubmitting}>
            {
              isSubmitting
              ?
              <div className={"containerLoadingButtonSave"}>
                <CircularProgress size={'16px'} />
                {isAllSelected || selectedRows.length > 100 || typeFilter === 'company' ? t('performance_assessment.actions.waiting') : t('performance_assessment.actions.saving')}
              </div>
              :
              t('performance_assessment.actions.save')
            }
          </button>
        </footer>
      </section>
    </>
	)
}

function mapStateToProps(state) {

	return {
		userDetails: state.authentication.userDetails
	}

}


function mapDispatchToProps(dispatch) {

	return {
	}
}



export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsDrawer)





