import React from 'react'
import style from './styles.module.scss';
import { Box, Skeleton } from '@mui/material';

export default function Widget32Skeleton() {

  return (
    <div className={style.container}>
      <Box display={'flex'} flexDirection={'column'} gap={'6px'}>
        <Skeleton variant='h6' width={'40%'} />
        <Skeleton variant="rectangular" width={'30%'} height={40} />
      </Box>

      <Box display={'flex'} flexDirection={'column'} gap={'6px'}>
        <Skeleton variant="rectangular" width={'100%'} height={180} />
      </Box>
    </div>
  )
}
