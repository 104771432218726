import React from 'react'
import styles from './ResultsStatsHeader.module.scss'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import { befectiveFormatDate, numberToPercentString } from '../../../_helpers'
import { useTranslation } from 'react-i18next';
import IconSearchLight from '../../_icons/IconSearchLight';
import IconInfoFlat from '../../_icons/IconInfoFlat';
import IconInfo from '../../_icons/IconInfo';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { Typography } from '@material-ui/core';
import { FileArrowUp, Printer } from 'phosphor-react';

export default function ResultsStatsHeader({
  infoAssessment,
  resultIndicators,
  handlePrint,
  handleOpenModalExport
}) {
  
  const { t, i18n } = useTranslation("common");

  return (

    <header>
      <div className={styles.statsHeader}>
        <div className={styles.boxResume}>
          <div>
            <h4 className={styles.title}>{t("performance_assessment.page_assessment_details.results.stats.reports")}: {infoAssessment?.title || "-----"}</h4>
            <div className={styles.gridStates}>

              <div className={styles.colState}>
                <div className={styles.labelStats}>{t("performance_assessment.page_assessment_details.results.stats.finished_at")}:</div>
                <div className={styles.valueStats}>{befectiveFormatDate(infoAssessment?.date_end, i18n.language)}</div>
              </div>

              
             {/*  <div className={styles.colState}>
                <div className={styles.labelStats}>{t("performance_assessment.page_assessment_details.results.stats.team")}:</div>
                <div className={styles.valueStats}>{infoAssessment?.team_name}</div>
              </div> */}

              <div className={styles.colState}>              
                <div className={styles.labelStats}>{t("performance_assessment.page_assessment_details.results.stats.model")}:</div>
                <div className={styles.valueStats}>
                  {`${t("performance_assessment.page_assessment_details.results.stats.assessment")} ${infoAssessment?.label_pt ? infoAssessment['label_'+i18n.language] : '-----'}`}
                  {
                    (infoAssessment?.help_text_pt && infoAssessment?.help_text_es && infoAssessment?.help_text_en) &&
                    <BefectiveTooltip
                      title={<Typography component={'div'} variant='caption'>{infoAssessment[`help_text_${i18n.language}`]}</Typography>}
                      placement="right-start"
                      arrow
                      interactive>
                      <span style={{ cursor: 'pointer' }}>&nbsp;&nbsp;<IconInfo /></span>
                    </BefectiveTooltip>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.boxButtonHeader}>
          <button type='button' onClick={handlePrint}>
            <Printer size={18} color='#98ACC1' weight="fill" />
          </button>
          <button type='button' onClick={handleOpenModalExport}>
            <FileArrowUp size={18} color='#98ACC1' weight="fill" />
          </button>
        </div>
      </div>
      {
        (resultIndicators && resultIndicators?.length > 0) && (
          <div className={styles.containerBarClassification}>
            <div className={styles.barClassification}>
              {
                resultIndicators?.filter(item => item?.percent > 0)?.map((item, index) => (
                  <div key={`classification-${index}`} className={styles.barClassificationParts} style={{width: `${item?.percent}%`, backgroundColor:item?.bg_color}}>
                    {item?.total} ({item?.percent}%)
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    </header>
  )
}
