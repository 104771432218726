import React, { useState } from 'react';
import styles from './ReportTodoList.module.scss';
import { useTranslation } from 'react-i18next';
import IconRemove from '../../_icons/IconRemove';
import BefectiveCheckbox from '../../_shared/BefectiveCheckbox';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';
import PopoverConfirm from '../../teams_settings/PopoverConfirmDelete';

const ReportTodoList = ({ type, title, data, alertActions }) => {

    const { t, i18n } = useTranslation("common");
    const [currentData, setCurrentData] = useState(data);
    const [submitted, setSubmitted] = useState(false); 
    const [itemToDelete, setItemToDelete] = useState(null); 
    const [anchorElConfirmDeleteItem, setAnchorElConfirmDeleteItem] = useState(null);
    const openConfirmDeleteItem = Boolean(anchorElConfirmDeleteItem);
    const idConfirmDeleteItem = openConfirmDeleteItem ? 'popover-confirm-delete-item' : undefined;
  
    const handleClickConfirmDeleteItem = (event, item) => {
        setItemToDelete(item)
      setAnchorElConfirmDeleteItem(event.currentTarget);
    };
  
    const handleCloseConfirmDeleteItem = () => {
      setAnchorElConfirmDeleteItem(null)
      setItemToDelete(null)
    };
    
    const updateItem = async (item) => {
        try {
            if(!item.id){
                throw new Error('Item not found');
            }

            setSubmitted(true);

            const response = await api.put(`performance-assessment/evaluator/todo`, {
                ...item
            }, {
                headers: authHeader()
            });

            if (response.status !== 200) {
                throw new Error(t('generic_messages.errors.update'));
            }

            setSubmitted(false);

            // alertActions.success(t('generic_messages.success.update'));            

        } catch (error) {
            console.log(error);
            setSubmitted(false);
            // alertActions.error(error.response.data)
        }
    }

    const deleteItem = async ()  => {
        try {
            if(!itemToDelete.id){
                throw new Error('Item not found');
            }

            setSubmitted(true);

            const response = await api.delete(`performance-assessment/evaluator/todo/${itemToDelete.id}`, {
                headers: authHeader()
            });

            if (response.status !== 200) {
                throw new Error(t('generic_messages.errors.delete'));
            }

            setSubmitted(false);

            alertActions.success(t('generic_messages.success.delete'));

            handleCloseConfirmDeleteItem();
            
            const newData = currentData.filter((item) => item.id !== itemToDelete.id);
            setCurrentData(newData);

        } catch (error) {
            console.log(error);
            setSubmitted(false);
            alertActions.error(error.response.data)
        }
    }

    const updateItemActive = (e, item) => {
        const { name, value, type, checked } = e.target;
        
        const newItem = {
            ...item,
            [name]: checked
        }
        
        const newData = currentData.map((item) => {
            if(item.id === newItem.id){
                return newItem
            }
            return item
        })

        setCurrentData(newData);

        updateItem(newItem);
    }

    return (
        <div className={styles.cardContainer}>
            <header className={styles.cardHeader}>
                <h6 className={styles.title}>{title}</h6>
            </header>
            
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>{t("performance_assessment.page_feedback.report_tab.item")}</th>
                        <th>{t("performance_assessment.page_feedback.report_tab.suggester")}</th>
                        <th style={{ width: '10px'}}/>
                    </tr>
                </thead>

                <tbody>
                    {
                        currentData && currentData.length > 0 ? (
                            currentData.map((item) => (
                                <tr key={`${title}-${item.id}`}>
                                    <td>
                                        <label htmlFor={`${type}_active_item_${item.id}`} className={styles.boxItemCheck}>
                                            {/* <BefectiveCheckbox 
                                                id={`${type}_active_item_${item.id}`}
                                                name='done'
                                                checked={item?.done} 
                                                onChange={(e) => updateItemActive(e, item)}
                                                disabled={submitted}
                                            /> */}
                                            <p>{item.content}</p>
                                        </label>
                                    </td>
                                    <td>{!item.self_evaluation ? item.evaluator_name : t("performance_assessment.page_feedback.report_tab.self_evaluation")}</td>
                                    <td style={{ width: '10px'}}>
                                        <button 
                                            type="button" 
                                            className={styles.buttonRemove}
                                            disabled={submitted}
                                            onClick={(e) => handleClickConfirmDeleteItem(e, item)} 
                                            >
                                                <IconRemove />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={3}>{t("performance_assessment.messages.none_items")}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>

            {/* Start Confirm Dialog Delete Item */}
            <PopoverConfirm
                idConfirm={idConfirmDeleteItem}
                openConfirm={openConfirmDeleteItem}
                anchorEl={anchorElConfirmDeleteItem}
                handleClose={handleCloseConfirmDeleteItem}
                handleConfirm={deleteItem}
                title={t('performance_assessment.messages.delete_item')}
            />
            {/* End Confirm Dialog Delete Item */}

        </div>
    )
}

export default ReportTodoList;