import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { filterActions, teammatesActions, teamsActions } from '../../../_actions'
import { Box, Button, Paper, Popover, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { authHeader } from '../../../_helpers'
import { api } from '../../../_helpers/api'
import IconInfo from '../../_icons/IconInfo'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'


function DrawerEdit(props) {
    const { t, i18n } = useTranslation('common')

    const [anchorElConfirmIntegration, setAnchorElConfirmIntegration] = useState(null);
    const openConfirmIntegration = Boolean(anchorElConfirmIntegration);
    const idConfirmIntegration = openConfirmIntegration ? 'popover-confirm-integration' : undefined;
    const handleCloseConfirmIntegration = () => {        
        setAnchorElConfirmIntegration(null);
    };
    const { teammatesActions, filterActions, setInactiveTeammates, inactiveTeammates, isTeamsSelected, value, handleChangeValue, getCompanyInformation, userDetails } = props
    const { teamsActions } = props
    const { drawerData } = props

    let current = props.current
    let permissions = props.permissions || {};
    let updated = props.updated || false
    let deleted = props.deleted || false
    let teams = props.teams || []
    let teammateDetail = props.teammateDetail || []

    let teamsList = []
    const [agentActionsTeammate, setAgentActionsTeammate] = useState([]);

    const [submitted, setSubmitted] = useState(false)
    const [form, setForm] = useState({
        alias: teammateDetail.name,
        team: teammateDetail.team_id,
        email: teammateDetail.email,
        username: teammateDetail.email,
        is_active: teammateDetail.active,
        send_invite: false
    })

    function formatDatetime(datetime) {
        if (!datetime) return datetime
        const dateTimeSplit = String(datetime).split('T')
        const date = new Date(dateTimeSplit[0] + 'T00:00:00.000').toLocaleDateString()
        const time = dateTimeSplit[1].slice(0, 5)
        return date + ' ' + time
    }

    const [formError, setFormError] = useState({
        alias: '',
        team: '',
        username: ''
    })

    console.log(form)


    const handleChange = e => {
        setSubmitted(false)
        
        if (e.target.name == 'send_invite') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked
            })
            return
        }

        if(e.target.name === 'team' && Number(teammateDetail.team_id) !== Number(e.target.value)){
            setAnchorElConfirmIntegration(e.currentTarget);
        }

        if (e.target.name == 'is_active') {
            setForm({
                ...form,
                [e.target.name]: !form.is_active,
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)

        let formIsValid = true

        if (!form["alias"]) {
            formIsValid = false
            setFormError({
                ...formError,
                alias: t('settings.company.error.required'),
            })
        }

        if (!form["team"]) {
            formIsValid = false
            setFormError({
                ...formError,
                team: t('settings.company.error.required'),
            })
        }
        
        if (!form["username"]) {
            formIsValid = false
            setFormError({
                ...formError,
                username: t('settings.company.error.required'),
            })
        }

        if (formIsValid) {

            setFormError({
                alias: '',
                team: '',
                username: ''
            })
            
            teammatesActions.updateTeammate({ ...form}, current.id, current.isInactive)
            
            filterActions.clear("who");

            let isUserExist = inactiveTeammates.filter((user) => user.id === current.id)
            let usersInactiveFiltered = inactiveTeammates.filter((user) => user.id !== current.id)

            if (teammateDetail.active !== form.is_active) {

                if (isUserExist.length > 0) {
                    setInactiveTeammates(usersInactiveFiltered)
                } else {
                    setInactiveTeammates(
                        [
                            ...inactiveTeammates,
                            {
                                id: current?.id,
                                alias: form?.alias,
                                email: form?.email,
                                name: form?.alias,
                                username: form?.username,
                                group_name: teams.find((team) => team?.id == form?.team).name
                            }
                        ]
                    )
                }
            } else {
                if (isUserExist.length > 0) {
                    setInactiveTeammates([
                        ...usersInactiveFiltered,
                        {
                            id: current?.id,
                            alias: form?.alias,
                            email: form?.email,
                            name: form?.alias,
                            username: form?.username,
                            group_name: teams.find((team) => team?.id == form?.team).name
                        }
                    ])
                }
            }
        }
        else {
            setSubmitted(false)
        }

    }

    function closeDrawer() {

        setForm({
            alias: '',
            team: '',
            email: '',
            username: '',
            is_active: false
        })

        setFormError({
            alias: '',
            team: '',
            username: ''
        })

        props.closeDrawer()
    }

    function toggleRemoveDialog() {

        let elem = document.getElementById("removeDialog")
        elem.classList.toggle("open")

    }

    function disableUser(id) {
        teammatesActions.deleteTeammate(id)
    }

    useEffect(() => {

        if (teammateDetail.name) {
            setForm({
                alias: teammateDetail.name,
                team: teammateDetail.team_id,
                email: teammateDetail.email,
                is_active: teammateDetail.active,
                username: teammateDetail.username,
                send_invite: false
            })
        }

    }, [teammateDetail])

    useEffect(() => {

        if (updated == 'error') {
            setSubmitted(false)
        }

        if (updated == 'success') {
            setSubmitted(false)
            teammatesActions.getTeammates()
            closeDrawer()
            getCompanyInformation()
        }

    }, [updated])

    useEffect(() => {

        if (deleted == 'error') {
            setSubmitted(false)
        }

        if (deleted == 'success') {
            setSubmitted(false)
            teammatesActions.getTeammates()
            closeDrawer()
        }

    }, [deleted])

    if (form?.team === null) {
        teamsList.push(<option value="" disabled selected hidden>{t('goals.modal_goal.select')}</option>)
    }

    for (let t0 of teams) {
        if (!t0.ancestry) {
            teamsList.push(<option className='t0' value={t0.id}>{t0.name}</option>)
            let p0 = t0.id
            for (let t1 of teams) {
                if (t1.ancestry == p0) {
                    teamsList.push(<option className='t1' value={t1.id}>&nbsp;&nbsp;{t1.name}</option>)
                    let p1 = t1.id
                    for (let t2 of teams) {
                        if (t2.ancestry == p1) {
                            teamsList.push(<option className='t2' value={t2.id}>&nbsp;&nbsp;&nbsp;&nbsp;{t2.name}</option>)
                            let p2 = t2.id
                            for (let t3 of teams) {
                                if (t3.ancestry == p2) {
                                    teamsList.push(<option className='t3' value={t3.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t3.name}</option>)
                                    let p3 = t3.id
                                    for (let t4 of teams) {
                                        if (t4.ancestry == p3) {
                                            teamsList.push(<option className='t4' value={t4.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t4.name}</option>)
                                            let p4 = t4.id
                                            for (let t5 of teams) {
                                                if (t5.ancestry == p4) {
                                                    teamsList.push(<option className='t5' value={t5.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t5.name}</option>)
                                                    let p5 = t5.id
                                                    for (let t6 of teams) {
                                                        if (t6.ancestry == p5) {
                                                            teamsList.push(<option className='t6' value={t6.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t6.name}</option>)
                                                            let p6 = t6.id
                                                            for (let t7 of teams) {
                                                                if (t7.ancestry == p6) {
                                                                    teamsList.push(<option className='t7' value={t7.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t7.name}</option>)
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    const getActionsAgent = async () => {
        try {
            const response = await api.get(
                `/teammates/agent-actions/${current?.id}`,
                {
                    headers: authHeader()
                }
            )
            setAgentActionsTeammate(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleActionRestart = async () => {

        try {
            const response = await api.post(
                `/teammates/agent-actions`,
                {
                    "group_id": teammateDetail?.team_id,
                    "machine_user_id": teammateDetail?.id,
                    "action": "RESTART"
                },
                {
                    headers: authHeader()
                }
            )

            if (response.status === 200) {
                setAgentActionsTeammate(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getActionsAgent()
    }, [current])

    return (
        <React.Fragment>

            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeValue} aria-label="tabs list">
                            <Tab label={t('settings.teams.tabs.info')} value="1" style={{padding:'0px 10px'}}/>
                            {!current?.isInactive && <Tab label={t('settings.teams.tabs.agent')} value="2" onClick={() => getActionsAgent()} />}
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <div className="internal invite create" style={{ paddingTop: 0 }}>

                            <header>
                                <h3>{current.name}</h3>
                            </header>

                            <form onSubmit={handleSubmit} className="form">

                                <div className="fieldset text">
                                    <div className="field">
                                        <label htmlFor="alias">{t('settings.teams.alias')}*</label>
                                        <input type="text" id="alias" name="alias" value={form.alias} onChange={handleChange} />
                                    </div>
                                </div>

                                <div className="fieldset text">
                                    <div className="field">
                                        <label htmlFor="username">{t('settings.teams.username')}</label>
                                        <input type="text" id="username" name="username" value={form.username} onChange={handleChange} disabled={(userDetails.roleName !== 'admin' &&  userDetails.roleName !== 'magic')} />
                                        {
                                            formError.username && <span className='error'>{formError.username}</span>
                                        }
                                    </div>
                                </div>

                                <div className="fieldset text">
                                    <div className="field">
                                        <label htmlFor="email">{t('settings.teams.temmate_email')}</label>
                                        <input type="text" id="email" name="email" value={form.email} disabled="disabled" />
                                    </div>
                                </div>


                                <div className="fieldset text">
                                    <div className="field">
                                        <label htmlFor="team">{t('settings.teams.team')}</label>
                                        <select id="team" value={form.team} name="team" onChange={handleChange}>
                                            {teamsList}
                                        </select>
                                    </div>
                                </div>

                                <div className="fieldset radio extra">
                                    <div className="field" style={{ justifyContent: 'flex-start' }}>
                                        <input type="checkbox" id="is_active" name="is_active" checked={form.is_active} value={form.is_active} onChange={handleChange} /> <label htmlFor="is_active">{t('settings.teams.is_active')}</label>
                                    </div>
                                </div>

                                <div className="fieldset radio extra">
                                    <div className="field" style={{ justifyContent: 'flex-start' }}>
                                        <input type="checkbox" id="send_invite" name="send_invite" checked={form.send_invite} value={form.send_invite} onChange={handleChange} /> <label htmlFor="send_invite">{t('settings.teams.send_invite_one')}</label>
                                    </div>
                                </div>

                                <div className="formReturn">
                                    {(updated == 'error') &&
                                        <div className="error">{t('settings.teams.edit_error')}</div>
                                    }

                                    {(formError.name || formError.team || formError.username) &&
                                        <div className="error">{t('settings.administrator.required')}</div>
                                    }
                                </div>

                                <div className="controls">
                                    {(submitted && (!updated || !deleted)) &&
                                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                    }
                                    {(permissions.DisableTeams) &&
                                        <React.Fragment>
                                            {/* <button type="button" className="remove" onClick={() => { toggleRemoveDialog() }} disabled={submitted ? true : false}><IconRemove /></button> */}
                                            <div className="removeDialog" id={"removeDialog"}>
                                                <div className="content">
                                                    <div className="message"><IconInfo />{t('settings.administrator.you_are_sure')}</div>
                                                    <div className="options">
                                                        <button type="button" onClick={() => { toggleRemoveDialog() }}>{t('no')}</button>
                                                        <NavLink to={"/teams"}>
                                                            <button type="button" onClick={() => { disableUser(current.id) }}>{t('yes')}</button>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    <button type="button" disabled={submitted ? true : false} onClick={() => { closeDrawer() }}>{t('settings.teams.cancel')}</button>
                                    <button type="submit" disabled={submitted ? true : false}>{t('settings.teams.update')}</button>
                                </div>
                            </form>

                        </div>
                        <Popover
                            id={idConfirmIntegration}
                            open={openConfirmIntegration}
                            anchorEl={anchorElConfirmIntegration}
                            onClose={handleCloseConfirmIntegration}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            PaperProps={{
                            style: {
                                backgroundColor: "#FFE3E5",
                                boxShadow: "none",
                                padding: '18px 14px',
                                borderRadius: '6px',
                            }
                            }}
                        >
                            <Box display="flex" flexDirection="column" gap="4px"  maxWidth={'320px'}>

                            <Typography fontSize="12px" fontWeight={500} color="#FF737D" componente="span">{t('teams.alerts.warning_01')}</Typography>
                            <Typography fontSize="11px" variant="caption" color="#FF737D" componente="span">{t('teams.alerts.warning_02')}</Typography>
                            <Typography fontSize="11px" variant="caption" color="#FF737D" componente="span">{t('teams.alerts.warning_03')}</Typography>

                            <Box display="flex" gap="4px" alignItems="center" justifyContent="flex-end">
                                <Button  style={{ width: '42px', minWidth: '0', height: '25px', backgroundColor: '#FF737D', borderRadius: '2px' }} onClick={handleCloseConfirmIntegration}>
                                <Typography component="span" fontSize={10} color="#fff">{t('teams.alerts.okay')}</Typography>
                                </Button>
                            </Box>
                            </Box>
                        </Popover>
                    </TabPanel>
                    {
                        !current?.isInactive &&
                        <TabPanel value="2">
                            <Box paddingBottom={'20px'}>
                                <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} spacing={1}>
                                    <Typography
                                        sx={{ flex: '1 1 100%', fontWeight: 500, fontSize: '16px' }}
                                        variant="subtitle1"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        {t('settings.teams.actions')}
                                    </Typography>
                                    {
                                        !agentActionsTeammate?.hasPending
                                            ?
                                            <Tooltip title={t('settings.teams.tooltip_time')}>
                                                <Button variant="contained" endIcon={<SettingsBackupRestoreIcon />} disabled={false}
                                                    onClick={handleActionRestart}
                                                >
                                                    {t('settings.teams.restart')}
                                                </Button>
                                            </Tooltip>
                                            :
                                            <Tooltip title={t('settings.teams.tooltip_not_restart')}>
                                                <Button variant="contained" endIcon={<SettingsBackupRestoreIcon color='disabled' />} style={{ backgroundColor: '#E0E0E0', color: '#A6A6AB', boxShadow: 'none' }}>
                                                    {t('settings.teams.restart')}
                                                </Button>
                                            </Tooltip>

                                    }

                                </Stack>
                            </Box>
                            <Box>
                                {agentActionsTeammate?.actions?.length > 0

                                    ?
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">{t("settings.teams.col_created_at")}</TableCell>
                                                    <TableCell align="center" width={'10%'}>Status</TableCell>
                                                    {/* <TableCell align="center" width={'50px'}>Ações</TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {agentActionsTeammate?.actions.map((row, index) => (
                                                    <TableRow
                                                        key={row.created_at}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="left">{formatDatetime(row.created_at)}</TableCell>
                                                        <TableCell align="center" style={{ color: row.status ? '#22BEAD' : '#FDA02B' }} >{row.status ? t("settings.teams.status_restarted") : t("settings.teams.status_pending")}</TableCell>
                                                        {/* <TableCell align="center">{row.carbs}</TableCell> */}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    <Typography
                                        sx={{ flex: '1 1 100%' }}
                                        align="center"
                                        variant="subtitle2"
                                        component="div"
                                    >
                                        {t('settings.teams.msg_no_actions')}
                                    </Typography>
                                }

                            </Box>
                        </TabPanel>
                    }

                </TabContext>
            </Box>
            
        </React.Fragment>
    )
}

function mapStateToProps(state) {

    return {
        teams: state.teams.payload,
        teammateDetail: state.teammates.detail,
        updated: state.teammates.updated,
        deleted: state.teammates.deleted,
        permissions: state.authentication.permissions,
    }

}


function mapDispatchToProps(dispatch) {

    return {
        teammatesActions: bindActionCreators(teammatesActions, dispatch),
        teamsActions: bindActionCreators(teamsActions, dispatch),
        filterActions: bindActionCreators(filterActions, dispatch)
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(DrawerEdit)



