import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import ButtonCreate from "../_shared/ButtonCreate";

export default function ScalesTabComponent({ currentActive, buttonCreate=null, userDetails }) {

  const { t, i18n } = useTranslation("common");

  return (
    <div className="mainFilterbar">
        <div className="filters">
            <NavLink
            to={`/performance-assessment/models/scales/answers`}
            className={currentActive === "answers" ? "active" : ""}
            >
            <button className={"settingsNav"}>
                {t("performance_assessment.page_scales_answers.tab_title")}
            </button>
            </NavLink>
            <NavLink
            to={`/performance-assessment/models/scales/result-indicator`}
            className={currentActive === "result_indicator" ? "active" : ""}
            >
            <button className={"settingsNav"}>
                {t("performance_assessment.page_scales_result_indicator.tab_title")}
            </button>
            </NavLink>
        </div>
        {
            (buttonCreate && userDetails.allowed_create) && (
                <ButtonCreate
                    minWidth={buttonCreate.minWidth || "100px"}
                    title={buttonCreate.title}
                    handleClick={buttonCreate.handleClick}
                />
            )
        }
    </div>
  );
}
