import { Box, Grid, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./BenefitsManageList.module.scss";
import { v4 as uuidv4 } from "uuid";
import { Delete, DragIndicator } from "@material-ui/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { prepareOptionsData } from "../../../_helpers";
import moment from "moment";

export default function BenefitsManageList({
  benefits = [],
  setValue = () => {},
  lang = "en",
  optionsData = [],
}) {
  const { t, i18n } = useTranslation("common");

  const addNewItem = (newItem) => {
    if (!newItem.is_new) return;
    setValue("benefits", [...benefits, { ...newItem, id: uuidv4(), index: benefits.length }]);
  };

  const removeItem = (itemId) => {
    setValue(
      "benefits",
      benefits.filter((item) => item.id !== itemId)
    );
  };

  const editItem = (item) => {
    setValue(
      "benefits",
      benefits.map((i) => (i.id === item.id ? item : i))
    );
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { destination, source } = result;

    if (destination.index === source.index) return;

    const newBenefits = Array.from(benefits);
    const [removed] = newBenefits.splice(source.index, 1);
    newBenefits.splice(destination.index, 0, removed);

    setValue("benefits", newBenefits.map((b, index) => ({ ...b, index })));
  };

  const handleBenefitsChange = (benefitId, event) => {
    const { name, value } = event.target;
    const benefitsNew = benefits.map((benefit) => {
      if (benefit.id === benefitId) {
        return { ...benefit, [name]: value };
      }
      return benefit;
    });
    setValue("benefits", benefitsNew);
  };

  return (
    <Box
      display="flex"
      bgcolor="#fff"
      flexDirection="column"
      paddingX="16px"
      paddingBottom="16px"
      borderRadius="4px"
      marginBottom="2px"
      flexGrow={1}
    >
      <Grid container spacing={2} marginBottom={"4px"} marginTop={0}>
        <Grid item xs={12}>
          <Typography
            component="h3"
            sx={{ fontSize: "18px", color: "#1E6B7F", fontWeight: 500 }}
          >
            {t("settings.users.finance_form.benefits")}
          </Typography>
        </Grid>
      </Grid>

      {benefits && benefits.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {benefits?.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={String(item.id)}
                      index={index}
                    >
                      {(provided2) => (
                        <Grid
                          component={"div"}
                          container
                          spacing={2}
                          marginBottom={"4px"}
                          marginTop={0}
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}
                          key={`benefit-${index}`}
                        >
                          <Grid item xs={8}>
                            <Box display="flex" gap="6px">
                              <Box sx={{ marginTop: "32px" }}>
                                <DragIndicator
                                  style={{ cursor: "grab", color: "#1E6B7F" }}
                                />
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                gap="2px"
                                width="100%"
                              >
                                <Typography
                                  component="label"
                                  sx={{
                                    paddingLeft: "4px",
                                    fontSize: "12px",
                                    color: "#1E6B7F",
                                    fontWeight: 400,
                                  }}
                                >
                                  {t(
                                    "settings.users.finance_form.benefit"
                                  )}
                                </Typography>

                                <select 
                                  className={styles.customSelect}
                                  name="benefit_id" 
                                  id={`benefit-${index}`}
                                  onChange={(event) => handleBenefitsChange(item.id, event)}
                                  placeholder={t(`placeholders.select`)}
                                  value={item?.benefit_id}
                                  >
                                  <option value="" disabled>{t("placeholders.select")}</option>
                                  {
                                    prepareOptionsData(
                                      optionsData?.company_benefits,
                                      lang
                                    ).map(option => (
                                      <option value={option.value}>{option.label}</option>
                                    ))
                                  }
                                </select>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={4}>
                            <Box display={"flex"} gap={"6px"}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                gap="2px"
                                width="100%"
                              >
                                <Typography
                                  component="label"
                                  sx={{
                                    paddingLeft: "4px",
                                    fontSize: "12px",
                                    color: "#1E6B7F",
                                    fontWeight: 400,
                                  }}
                                >
                                  {t("settings.users.finance_form.since")}
                                </Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  locale={lang}
                                >
                                  <DatePicker
                                    value={item?.since}
                                    inputFormat={
                                      lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY"
                                    }
                                    onChange={(value) =>
                                      handleBenefitsChange(item.id, {
                                        target: { name: "since", value: moment(value.$d).format("YYYY-MM-DD") },
                                      })
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        variant="outlined"
                                        fullWidth
                                        {...params}
                                        sx={{
                                          width: "100%",
                                          height: "45px",
                                          fieldset: {
                                            border: "1px solid #DEE3E9CC",
                                            height: "45px",
                                          },
                                          input: {
                                            color: "#4F7091",
                                            paddingY: "11.5px",
                                          },
                                          label: { top: "-4px" },
                                        }}
                                        name="since"
                                        error={false}
                                        helperText={""}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Box>
                              <Box sx={{ marginTop: "22px" }}>
                                <button
                                    className={styles.buttonRemove}
                                    onClick={() => removeItem(item.id)}
                                >
                                    <Delete style={{color:"#FC6662"}} fontSize='small' />
                                </button>
                              </Box>                            
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginTop={"14px"}
          marginBottom={"8px"}
        >
          <Typography
            component="label"
            sx={{
              paddingLeft: "4px",
              fontSize: "12px",
              color: "#1E6B7F",
              fontWeight: 400,
            }}
          >
            {t("settings.users.finance_form.no_benefits")}
          </Typography>
        </Box>
      )}

      <Grid container spacing={2} marginBottom={"4px"} marginTop={0}>
        <Grid item xs={12}>
          <button
            className={styles.buttonAddItem}
            onClick={() =>
              addNewItem({
                benefit_id: "",
                since: null,
                is_new: true
              })
            }
          >
            {t("settings.users.professional_form.new_item")}
          </button>
        </Grid>
      </Grid>
    </Box>
  );
}
