import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter, useRowSelect } from 'react-table';
import styles from './UsersTable.module.scss';
import { Pagination } from '@material-ui/lab';
import { api } from '../../_helpers/api';
import { ArrowDown, ArrowRight, ArrowUp, User } from 'phosphor-react';
import { authHeader, history } from '../../_helpers';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import IconEdit2 from '../_icons/IconEdit2';
import IconRemove from '../_icons/IconRemove';
import PopoverConfirm from '../teams_settings/PopoverConfirmDelete';
import BefectiveTooltip from '../_shared/BefectiveTooltip';
import { Typography } from '@mui/material';
import { Mail } from '@material-ui/icons';

const UsersTable = ({ columns, data, options, selectedRows, setSelectedRows, count, getUsersData, openDrawer, pageCountRows, alertActions, activeFilter, userDetails }) => {

  const { t } = useTranslation('common');

  const [anchorElConfirmDelete, setAnchorElConfirmDelete] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const openConfirmDelete = Boolean(anchorElConfirmDelete);
  const idConfirmDelete = openConfirmDelete ? 'simple-popover-confirm-deactive-user' : undefined;

  const handleClickConfirmDelete = (event, user) => {
    setUserToDelete(user)
    setAnchorElConfirmDelete(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElConfirmDelete(null)
    setUserToDelete(null)
  };

  const handleConfirmDelete = async () => {
    try {
      if (!userToDelete) {
        throw new Error('Days off to delete not found')
      }

      const res = await api.put(`/users/v2/deactive/${userToDelete.id}`, {}, { headers: authHeader() })
      if (res.status !== 200) {
        throw new Error('Erro to delete user')
      }

      getUsersData({ pageIndex: 0, pageSize: 20, isLoading: false })
      handleClose()
      alertActions.success(t('settings.users.success.delete_user'))
    } catch (error) {
      console.error(error)
    }
  }

  const [copied, setCopied] = useState({
    text: t('settings.teams.tooltip_copy_token'),
    value: false
  });

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: count,
      autoResetPage: false,
      initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20 },
      pageOptions: Array.from({ length: count }, () => 1).map((_, index) => index)
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const myPageOptions = [
    {
      value: 20,
      label: '20'
    },
    {
      value: 50,
      label: '50'
    },
    {
      value: 100,
      label: '100'
    },
    {
      value: 200,
      label: '200'
    },
    {
      value: 300,
      label: '300'
    },
    {
      value: 400,
      label: '400'
    },
    {
      value: 500,
      label: '500'
    },
    {
      value: pageCountRows,
      label: 'all'
    }
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, sortBy, sortOrder, globalFilter }
  } = tableInstance;

  const toggleAllRowsSelection = (e) => {
    const { checked } = e.target
    const allRows = page.map((row) => row.original.id);
    if (checked) {
      setSelectedRows(allRows);
    } else {
      setSelectedRows([])
    }

  };

  const toggleRowSelection = (id) => {
    setSelectedRows((prevState) =>
      prevState.includes(id) ? prevState.filter((rowId) => rowId !== id) : [...prevState, id]
    );
  };

  const fetchData = async ({ pageIndex, pageSize, sortBy, sortOrder, globalFilter }) => {
    try {
      getUsersData({ pageIndex, pageSize, isLoading: false, activeFilter: activeFilter })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const onCopyLink = () => {
    setCopied({ text: t('download.copied'), value: true })
    setTimeout(function () {
      setCopied({ text: t('settings.teams.tooltip_copy_token'), value: false })
    }, 1000);
  }

  useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy, sortOrder, globalFilter });
  }, [pageIndex, pageSize]);


  return (
    <div className={styles.tableContainer}>

      {/* <div className={styles.search}>
        <input
          type="text"
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </div> */}

      <table {...getTableProps()}>
        <thead>

          <tr>
            {/* <th style={{ width: '10px' }} className={styles.headColTable}>
              <label className={styles.customCheckbox}>
                <input type="checkbox" onChange={toggleAllRowsSelection} />
                <span className={`${styles.checkmark} ${styles.checkmarkGreen}`}></span>
              </label>
            </th> */}
            {headerGroups.map((headerGroup) => (
              <React.Fragment key={headerGroup.id}>
                {headerGroup.headers.map((column) => {
                  return <th {...column.getHeaderProps(column.getSortByToggleProps())} title={t('title_sort')} className={styles.headColTable} style={{ textAlign: `${['active', 'access_profile', 'token', 'email'].includes(column.id) ? 'center' : 'left'}` }}>
                    {column.render('Header')}
                    {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                  </th>
                })}
              </React.Fragment>
            ))}

            {
              (userDetails.allowed_update || userDetails.allowed_delete) && (
                <th style={{ width: '0px' }} className={styles.headColTable} />
              )
            }
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={`${userDetails.allowed_update ? styles.rowHover : ''}`}>
                {/* <td>
                  <label className={styles.customCheckbox}>
                    <input type="checkbox"
                      onChange={() => toggleRowSelection(row.original.id)}
                      checked={selectedRows.includes(row.original.id)}
                    />
                    <span className={styles.checkmark}></span>
                  </label>
                </td> */}
                {row.cells.map((cell) => {
                  switch (cell.column.id) {
                    case 'active':
                      return (
                        <td {...cell.getCellProps()} className={row.original.active ? styles.statusActive : styles.statusInactive} style={{ fontSize: '12px', width: '80px' }}>{cell.render("Cell")}</td>
                      );
                    case 'email':
                      return (
                        <td {...cell.getCellProps()} style={{ textAlign: 'center', width: '80px' }}>
                          <BefectiveTooltip arrow interactive title={<Typography variant='subtitle2'>{row.original.email}</Typography>}>
                            <Mail fontSize="medium" style={{ color: "#187dff" }} />
                          </BefectiveTooltip>
                        </td>
                      );
                    case 'token':
                      return (
                        <td {...cell.getCellProps()} style={{ width: '80px' }}>
                          <div className={styles.boxToken}>
                            <Tooltip className={styles.buttonClipboard} title={copied.text} arrow>
                              <CopyToClipboard text={String((row.original.token)).trim()} onCopy={onCopyLink}>
                                <button>
                                  {t('settings.teams.copy')}
                                </button>
                              </CopyToClipboard>
                            </Tooltip>
                          </div>
                        </td>
                      );
                    default:
                      return (
                        <td {...cell.getCellProps()} onClick={() => userDetails.allowed_update && history.push(`/global-settings/users/edit/${row.original.id}`)} style={{ textAlign: `${['access_profile'].includes(cell.column.id) ? 'center' : 'left'}`, fontSize: '12px', width: `${['last_sign_in_at', 'access_profile'].includes(cell.column.id) ? '100px' : 'auto'}` }} >{cell.render("Cell")}</td>
                      );
                  }
                })}
                {
                  (userDetails.allowed_update || userDetails.allowed_delete) && (
                    <td>
                      <div className={styles.boxActions}>
                        {
                          userDetails.allowed_update &&
                          <button className={`${styles.buttonAction} ${styles.permissions}`} onClick={(e) => history.push(`/global-settings/users/edit/${row.original.id}`)}>
                            <IconEdit2 />
                          </button>
                        }
                        {
                          userDetails.allowed_delete &&
                          <button className={`${styles.buttonAction} ${styles.remove}`} onClick={(e) => handleClickConfirmDelete(e, row.original)}>
                            <IconRemove />
                          </button>
                        }

                      </div>
                    </td>
                  )
                }
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.containerPagination}>
        <Pagination
          count={pageCount}
          page={pageIndex + 1}
          onChange={(event, page) => {
            gotoPage(page - 1)
          }}
          variant="outlined"
          shape="rounded"
          siblingCount={1}
          boundaryCount={1}
        />
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            localStorage.setItem('pageSizeStorage', Number(e.target.value));
          }}
          className={styles.selectOptions}
        >
          {myPageOptions.map((pageSize) => (
            <option key={pageSize.value} value={pageSize.value}>
              {pageSize.label === 'all' ? t('settings.teams.show_all') : `${t('settings.teams.show')} ${pageSize.label}`}
            </option>
          ))}
        </select>
      </div>

      <PopoverConfirm
        idConfirm={idConfirmDelete}
        openConfirm={openConfirmDelete}
        anchorEl={anchorElConfirmDelete}
        handleClose={handleClose}
        handleConfirm={handleConfirmDelete}
        title={t('settings.users.delete_message')}
      />
    </div>
  );
};

export default UsersTable;
