import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from './Global.module.scss'
import { authHeader, befectiveFormatDate } from "../../_helpers";
import { Select, MenuItem, FormControl, OutlinedInput } from "@material-ui/core";
import { api } from "../../_helpers/api";
import moment from "moment";

const MenuProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left"
      },
      getContentAnchorEl: null,
      PaperProps: {
        style: {
          boxShadow: "none",
        },
      },
}

const HistoricalAssessmentTable = ({
    data,
    setHistorical,
    scopes = [],
}) => {

    const { t, i18n } = useTranslation('common');
    const [yearSelected, setYearSelected] = useState(2024);
    const [scopeSelected, setScopeSelected] = useState("all");
    const [fetching, setFetching] = useState(false);

    const generateArrayYears = () => {
        const listYears = [];
        const yearMax = moment().add(5, 'year').year();
        const yearMin = moment().subtract(5, 'year').year();
        for (let i = yearMax; i >= 2020; i--) {
            listYears.push({
                value: i,
                label: i
            })
        }
        return listYears
    }
    
    const handleChangeYear = (event) => {
        const { value } = event.target;
        setYearSelected(value);
        console.log(value)
    }

    const handleChangeAssessment = (event) => {
        const { value } = event.target;
        setScopeSelected(value);
        console.log(value)
    }


    async function getData() {
        try {
            setFetching(true)

            const queryScope = scopeSelected !== "all" ? `&scope_id=${scopeSelected}` : '';

            const {data} = await api.get(`/performance-assessment/user/historic?year=${yearSelected}${queryScope}`, {
                headers: authHeader()
            })
            setHistorical(data);
            setFetching(false)
        } catch (error) {
            console.log(error)
            setFetching(false)
        }
    }

    useEffect(() => {
        getData();
    }, [yearSelected, scopeSelected])

    return (
        <div className={styles.cardSecondary}>
            <header className={styles.headerCard}>
                <h6>{t('colab_page.performance_assessment.historical.title')}</h6>
                <div className={styles.filtersHeader}>
                    <FormControl style={{marginRight:"10px", minWidth: 140}} size="small">
                        <Select
                            displayEmpty
                            className={"customSelectMUI"}
                            id="year-select-outlined"
                            value={yearSelected}
                            onChange={handleChangeYear}
                            variant="outlined"
                            name="year"
                            style={{color:'#1E6B7F', cursor: fetching ? 'progress' : 'pointer', fieldset: { border: 'transparent', height: '45px' }, div: { color: '#4F7091' }, label: { top: '-4px' } }}
                            disabled={fetching}
                            MenuProps={MenuProps}
                        >
                            {
                                generateArrayYears().map((year, index) => (
                                    <MenuItem className={styles.menuItem} key={index} value={year.value}>{year.label}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl size="small" style={{minWidth: 140}}>
                        <Select
                            displayEmpty
                            className={"customSelectMUI"}
                            id="assessment-select-outlined"
                            value={scopeSelected}
                            onChange={handleChangeAssessment}
                            variant="outlined"
                            name="assessment"
                            style={{color:'#1E6B7F', cursor: fetching ? 'progress' : 'pointer'}}
                            disabled={fetching}
                            MenuProps={MenuProps}
                            >
                            <MenuItem className={styles.menuItem} value={"all"}>{t('colab_page.performance_assessment.all')}</MenuItem>
                            {
                                scopes.map((scope, index) => (
                                    <MenuItem className={styles.menuItem} key={index} value={scope.id}>{scope[`label_${i18n.language}`]}</MenuItem>
                                ))
                                
                            }
                        </Select>
                    </FormControl>
                </div>
            </header>
            <table className={styles.tableHistorical}>
                <thead>
                    <tr>
                        <th>{t('colab_page.performance_assessment.historical.col_received_at')}</th>
                        <th>{t('colab_page.performance_assessment.historical.col_evaluated')}</th>
                        <th>{t('colab_page.performance_assessment.historical.col_position')}</th>
                        <th>{t('colab_page.performance_assessment.historical.col_scope')}</th>
                        <th>{t('colab_page.performance_assessment.historical.col_evaluated_at')}</th>
                        <th>{t('colab_page.performance_assessment.historical.col_status')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data?.length > 0
                            ? data.map((item, index) => (
                                <tr key={`historical-${index}`}>
                                    <td>{befectiveFormatDate(item?.received_at, i18n.language)}</td>
                                    <td>{item?.evaluated_username || '-----'}</td>
                                    <td>{item?.office || '-----'}</td>
                                    <td>
                                        {
                                        item?.self_assessment
                                        ?
                                         t('colab_page.performance_assessment.historical.self_assessment')
                                        :
                                        `${t('colab_page.performance_assessment.assessment')} ${item['label_' + i18n.language]}`
                                        
                                        }
                                    </td>
                                    <td>{befectiveFormatDate(item?.assessment_date_end, i18n.language)}</td>
                                    <td>
                                        <div className={styles.statusBox}>
                                            <div className={styles.circleStatus} style={{ backgroundColor: item?.status?.text_color || "transparent" }}></div>
                                            <span style={{ color: item?.status?.text_color || "" }}>{item?.status[`title_${i18n.language}`] || "-----"}</span>
                                        </div>
                                    </td>
                                </tr>
                            ))
                            : <tr>
                                <td colSpan={6}>
                                    <span>{t('colab_page.performance_assessment.historical.none')}</span>
                                </td>
                            </tr>
                            
                    }
                </tbody>
            </table>
        </div>
    )

}

export default HistoricalAssessmentTable;