import React, { useEffect, useMemo, useRef, useState } from "react";
import Toolbar from "../../_components/_shared/Toolbar";
import { connect } from "react-redux";
import { alertActions } from "../../_actions";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { authHeader } from "../../_helpers";
import GlobalSkeleton from "../../_components/_shared/GlobalSkeleton";
import EnhancedTable from "../../_components/_shared/EnhancedTable";
import { api } from "../../_helpers/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ResultsStatsCards } from "../../_components/performance_assessments/PerformanceAssessmentsDetails/ResultsStatsCards";
import ResultsStatsHeader from "../../_components/performance_assessments/PerformanceAssessmentsDetails/ResultsStatsHeader";
import StatusAssessment from "../../_components/performance_assessments/PerformanceAssessmentsCreated/StatusAssessment";
import { useReactToPrint } from "react-to-print";
import ModalExportReports from "../../_components/goals/ModalExportReports";
import HistoryAssessmentsDrawer from "../../_components/performance_assessments/PerformanceAssessmentsDetails/HistoryAssessmentsDrawer";
import Filterbar from "../../_components/_shared/Filterbar";

function PerformanceAssessmentReportsAssessmentsDetailsPage({
  alertActions,
  userDetails,
  filter,
}) {
  const { id } = useParams();

  const { t, i18n } = useTranslation("common");
  const [fetching, setFetching] = useState(true);
	const [openModalExportReports, setOpenModalExportReports] = useState(false);
  const [openDrawerHistoryAssessment, setOpenDrawerHistoryAssessment] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const contentDocument = useRef();
  
  const handlePrint = useReactToPrint({
    content: () => contentDocument.current,
  })
  
  const [data, setData] = useState({
    stats: null,
    data: null,
    evaluated: []
  });

  const handleOpenModalExport = () => {
		setOpenModalExportReports(true);
	};

  const handleCloseModalExport = () => {
		setOpenModalExportReports(false);
	};

  const columns = useMemo(() => {

    const fixedColumnsFirst = [
      {
        Header: t("performance_assessment.page_assessment_details.results.table.col_evaluated"),
        accessor: 'username',
      },
      {
        Header: t("performance_assessment.page_assessment_details.results.table.col_position"),
        accessor: 'office',
        Cell: ({ value }) => {
          return value || "-----";
        }
      }
    ];

    const skillColumns = data?.evaluated[0]?.skills?.map((skill, index) => ({
      Header: skill.name,
      accessor: `skills[${index}]`,
      Cell: ({ value, row }) => {
        return <span style={{color: row.original?.classification?.text_color || '#4F7091'}}>{value.skill_avg || "---"}</span>;
      }
    })) || [];

    const fixedColumnsLast = [
      {
        Header: t("performance_assessment.page_assessment_details.results.table.col_manager_avg"),
        accessor: 'manager_avg',
        Cell: ({ value, row }) => {
          return  <span style={{color: row.original?.classification?.text_color|| '#4F7091'}}>{value || "0"}</span>;
        }
      },
      {
        Header: t("performance_assessment.page_assessment_details.results.table.col_score_avg"),
        accessor: 'evaluated_avg',
        style: { padding: "0px 2px" },
        Cell: ({ value, row }) => {
          return (
            <StatusAssessment
              title={value}
              textColor={value && value !== "0" ? "#FFFFFF" : "#98ACC1"}
              bgColor={row.original?.classification?.bg_color }
              height="48px"
            />
          );
        }
      },
      {
        Header: t("performance_assessment.page_assessment_details.results.table.col_classification"),
        accessor: 'classification',
        style: { padding: "0px 2px"},
        Cell: ({ value, row }) => {
          return (
            <StatusAssessment
              title={value?.label || "-----"}
              textColor={value?.label ? "#FFFFFF" : "#98ACC1"}
              bgColor={value?.bg_color}
              height="48px"
            />
          );
        }
      }
    ];

    return [...fixedColumnsFirst, ...skillColumns, ...fixedColumnsLast];
  }, [data.evaluated]);

  async function getData(isLoading=true, teams=null, teammates=null) {
    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&teams=${teams.map(t=>t.id).join(',')}` : ''
      const queryTeammates = teammates ? `&users=${teammates.map(t=>t.id).join(',')}` : ''

      const response = await api.get(`/performance-assessment/results?performance_assessment_id=${id}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setData(response.data)
      setFetching(false);

    } catch (error) {
      console.log(error);
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }
  }

  const handleOpenDrawerHistory = (row) => {   
    setSelectedRow(row)
    setOpenDrawerHistoryAssessment(true);
  }

  const handleCloseDrawerHistory = () => {   
    setOpenDrawerHistoryAssessment(false);
    setSelectedRow(null)
  }

  const filterData = ({pageIndex, pageSize, filter}) => {
    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getData(true, teams, teammates);
  };

  useEffect(() => {
    document.title = `Befective | ${t('menu.performance_assessment.reports')} | ${t('menu.performance_assessment.assessments')} `;
    getData();
  }, []);

  return (
    <section className="mainContent">
      <Toolbar />

      <Filterbar
        filterWho={"multi"}
        filterWhen={"disabled"}
        filterWhat={"disabled"}
        routeBackToPage={"/performance-assessment/reports/assessments"}
      />

      <main className="mainContentPage" ref={contentDocument}>
        {!fetching ? (
          <>
            <ResultsStatsCards stats={data.stats}/>
            <ResultsStatsHeader               
              infoAssessment={data.performance_assessment} 
              resultIndicators={data.result_indicators}
              handlePrint={handlePrint}
              handleOpenModalExport={handleOpenModalExport}
            />
            <EnhancedTable
              columns={columns}
              data={data.evaluated}
              filter={filter}
              fetchData={filterData}
              onRowClick={(row) => handleOpenDrawerHistory(row)}
            />
          </>
        ) : (
          <GlobalSkeleton
            totalRowsStats={0}
            totalTables={1}
            heightTable={600}
          />
        )}
      </main>

      {
        (openDrawerHistoryAssessment && selectedRow) && (
          <HistoryAssessmentsDrawer 
            openDrawer={openDrawerHistoryAssessment} 
            closeDrawer={handleCloseDrawerHistory} 
            currentData={selectedRow}
          />
        )
      }

      <ModalExportReports 
        openModalExport={openModalExportReports} 
        handleCloseModalExport={handleCloseModalExport} 
        viewExport='performanceAssessmentsReport' 
        filter={{ ...filter, performance_assessment_id: id }} 
        disabledExport={{
          excel: true,
          pdf: false
        }}
      />

    </section>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceAssessmentReportsAssessmentsDetailsPage);
