import React, { useEffect, useMemo, useState } from 'react'
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import { connect } from 'react-redux'
import { alertActions } from '../../_actions'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { authHeader, befectiveFormatDate, history } from '../../_helpers'
import EnhancedTable from '../../_components/_shared/EnhancedTable'
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton'
import { api } from '../../_helpers/api'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { IconButton } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import NoData from './NoData'
import BefectiveTooltip from '../../_components/_shared/BefectiveTooltip'
import FeedbacksTable from '../../_components/performance_assessments/PerformanceAssessmentFeedback/FeedbacksTable'

function PerformanceAssessmentFeedbackPage({
  alertActions,
  userDetails,
  filter
}) {
  
  const { t, i18n } = useTranslation("common");
  const [firstFetch, setFirstFetch] = useState(true);
  const [fetching, setFetching] = useState(true);

  const [data, setData] = useState([]);
  const [firstData, setFirstData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [dateRef, setDateRef] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [isUpdating, setIsUpdating] = useState(false);

  let lang = i18n.language || "en"

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  }

  function getDataFormat(lang) {
    if (lang === 'en') {
      return "MM/DD/YYYY"
    } else {
      return "DD/MM/YYYY"
    }
  }


  async function getFeedbacks(
    date_ref,
    pageIndex,
    pageSize,
    isLoading,
    teams = null,
    teammates = null
  ) {

    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&teams=${teams.map(t=>t.id).join(',')}` : ''
      const queryTeammates = teammates ? `&users=${teammates.map(t=>t.id).join(',')}` : ''
      const queryFilterYear = date_ref ? `&year=${moment(date_ref).year()}` : ''
      const response = await api.get(`/performance-assessment/feedback?page=${pageIndex}&per_page=${pageSize}${queryFilterYear}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setData(response.data)

      if(firstFetch){
        setFirstData(response.data)
        setFirstFetch(false)
      }

      setFetching(false);
      
    } catch (error) {
      console.log(error);
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t("performance_assessment.page_feedback.table.col_colab"),
        accessor: "evaluated_name",
      },
      // {
      //   Header: t("performance_assessment.page_feedback.table.col_position"),
      //   accessor: "office",
      //   Cell: ({ value }) => {
      //     return value || "-----"
      //   },
      // },
      {
        Header: t("performance_assessment.page_feedback.table.col_responsible"),
        accessor: "manager",
        Cell: ({ value }) => {
          return value?.username || "-----"
        }
      },
      {
        Header: t("performance_assessment.page_feedback.table.col_assessment"),
        accessor: "title",
      },
      {
        Header: t("performance_assessment.page_feedback.table.col_score"),
        accessor: "general_avg",
        Cell: ({ value, row }) => {
          return (
            <BefectiveTooltip
              title={`${t("performance_assessment.page_feedback.table.col_classification")}: ${row.original?.classification?.label || "-----"}`}
              placement="bottom-center" 
              arrow 
              interactive
            >
              <span style={{color: row.original?.general_avg?.text_color || '#4F7091', fontWeight: 500}}>{value || "-----"}</span> 
            </BefectiveTooltip>
          )
        },
      },
      // {
      //   Header: t("performance_assessment.page_feedback.table.col_classification"),
      //   accessor: "classification",
      //   Cell: ({ value, row}) => {
      //     return <span style={{color: row.original?.classification?.text_color || '#4F7091', fontWeight: 500}}>{value?.label  || "-----"}</span>
      //   },
      // },      
      {
        Header: t("performance_assessment.page_feedback.table.col_completed_at"),
        accessor: "date_end",
        Cell: ({ value }) => {
          return befectiveFormatDate(value, i18n.language);
        },
      },
      {
        Header: t("performance_assessment.page_feedback.table.col_date"),
        accessor: "feedback_date",
        Cell: ({ value, row }) => {
          const [date, setDate] = useState(value)
          return (
            <div
              style={{width: '150px'}}
              onClick={(e) => e.stopPropagation()}
            >
               <LocalizationProvider dateAdapter={AdapterDayjs} locale={lang}>
                  <DatePicker
                    value={date}
                    inputFormat={getDataFormat(lang)}
                    disabled={isUpdating}
                    onChange={(newValue) => {
                      setDate(newValue)
                      handleChangeDateFeedback(newValue, row.original)
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant='outlined'
                        fullWidth
                        {...params}
                        sx={{ height: '40px', fieldset: { border: '1px solid #DEE3E9CC' }, input: { color: '#4F7091', padding: '10px' } }}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
            </div>
          )
        },
      },
      {
        Header: t("performance_assessment.page_feedback.table.col_action_plan"),
        accessor: "has_action_plan",
        Cell: ({ value }) => {
          return value ? <span style={{color: '#22BEAD'}}>{t("performance_assessment.page_feedback.table.has_action_plan")}</span> : <span style={{color: '#FC6662'}}>{t("performance_assessment.page_feedback.table.none_action_plan")}</span>
        },
      }
    ];
  }, []);

  const handleChangeDateFeedback = async (date, feedback) => {
    try {
      setIsUpdating(true)
      await api.put(`/performance-assessment/feedback`, {date: moment(date.$d).format('YYYY-MM-DD'), evaluated_id: feedback.evaluated_id}, {headers: authHeader()}) 
      setIsUpdating(false)
      alertActions.success(t('performance_assessment.messages.feedback_scheduled_success'))
      getFeedbacks(dateRef, 0, localStorage.getItem("pageSizeStorage") || 20, false, null, null)        
    } catch (error) {
      console.log(error)
      setIsUpdating(false)
      alertActions.error(t('performance_assessment.messages.feedback_scheduled_error'))     
    }
  }

  const getFeedbackData = ({pageIndex, pageSize, filter}) => {

    let date_ref = dateRef;

    if (filter.singleDate) {
      date_ref = filter.singleDate;
    }

    setDateRef(date_ref);

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getFeedbacks(date_ref, pageIndex, pageSize, true, teams, teammates);
  };

  const handleRowClick = (row) => {
    return history.push(`/performance-assessment/feedback/report/${row.evaluated_id}`);
  };

  useEffect(() => {
    getFeedbackData({
      pageIndex: 0,
      pageSize: localStorage.getItem("pageSizeStorage") || 20,
      filter: {...filter}
    })
  }, [filter.singleDate, filter.teams, filter.teammates]);

  useEffect(() => {    
    document.title = `Befective | ${t('menu.performance_assessment.feedback')} `;  
    // getFeedbacks(
    //   dateRef,
    //   0,
    //   localStorage.getItem("pageSizeStorage") || 20,
    //   false,
    //   null,
    //   null
    // )
  }, [])

  return (
    <section className="mainContent">
    <Toolbar />

    <Filterbar
      filterYears={"single"}
      filterWho={"multi"}
      filterWhen={"disabled"}
      filterWhat={"disabled"}
      maxDate={new Date()}
    />

    <main className="mainContentPage">
      {!fetching ? (
        firstData.length > 0 
        ?
          <FeedbacksTable
            title={t("performance_assessment.page_feedback.title_table")}
            lang={lang}
            data={data}
            handleChangeDateFeedback={handleChangeDateFeedback}
            isUpdating={isUpdating}
            actionColumn={(row) => (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <IconButton
                  className={`button-options`}
                  aria-label="expand row"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRowClick(row);
                  }}
                >
                  <Search style={{color: "#4F7091"}} fontSize="small" />
                </IconButton>
              </div>
            )}
            getDataFormat={getDataFormat}
          />
        :
        <NoData />

        // data && data.length > 0 ?
        //  <EnhancedTable
        //   columns={columns}
        //   data={data}
        //   pageCountRows={pageCountRows}
        //   count={pageCount}
        //   filter={filter}
        //   fetchData={getFeedbackData}
        //   title={t("performance_assessment.page_feedback.title_table")}
        //   onRowClick={handleRowClick}
        //   actionColumn={(row) => (
        //     <div>
        //       <IconButton
        //         className={`button-options`}
        //         aria-label="expand row"
        //         size="small"
        //         onClick={(e) => {
        //           e.stopPropagation();
        //           handleRowClick(row);
        //         }}
        //       >
        //         <Search style={{color: "#4F7091"}} fontSize="small" />
        //       </IconButton>
        //     </div>
        //   )}
        // /> 
        // <FeedbacksTable
        //   title={t("performance_assessment.page_feedback.title_table")}
        //   lang={lang}
        //   data={data}
        //   handleChangeDateFeedback={handleChangeDateFeedback}
        //   isUpdating={isUpdating}
        //   actionColumn={(row) => (
        //     <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        //       <IconButton
        //         className={`button-options`}
        //         aria-label="expand row"
        //         size="small"
        //         onClick={(e) => {
        //           e.stopPropagation();
        //           handleRowClick(row);
        //         }}
        //       >
        //         <Search style={{color: "#4F7091"}} fontSize="small" />
        //       </IconButton>
        //     </div>
        //   )}
        //   getDataFormat={getDataFormat}
        // />
        // :
        // <NoData />
      ) : (
        <GlobalSkeleton
          totalRowsStats={0}
          totalTables={1}
          heightTable={600}
        />
      )}
    </main>
  </section>
  )
}



function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceAssessmentFeedbackPage)