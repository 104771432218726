import React from "react";
import styles from "./StatusAssessment.module.scss";
export default function StatusAssessment({
  title = "-",
  textColor = "#98ACC1",
  bgColor = "#E4EAEF",
  height = "62px",
  borderTop = "none",
  borderBottom = "none",
  fontWeight = "400"
}) {
  return (
    <div
      className={styles.statusAssessment}
      style={{
        color: textColor,
        backgroundColor: bgColor,
        height: height,
        borderTop: borderTop,
        borderBottom: borderBottom,
        fontWeight: fontWeight
      }}
    >
      {title}
    </div>
  );
}
