import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"
import { bindActionCreators } from 'redux'
import { teamsActions } from '../../../_actions'
import { CircularProgress } from '@material-ui/core'
import IconSearch from '../../_icons/IconSearch'
import { useState } from 'react'
import './PerformanceAssessmentsFilterDrawer.scss'
import IconClose from '../../_icons/IconClose'

function PerformanceAssessmentsFilterDrawer({ 
    openDrawer, 
    closeDrawer, 
    getParticipantsData 
  }) {

	const { t } = useTranslation('common');

	const [isSubmitting, setIsSubmitting] = useState(false)
	
  const [form, setForm] = useState({
		searchField: ''
	})

	const handleChange = (event) => {
		setForm({ ...form, [event.target.name]: event.target.value });
	};

  const handleFilter = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await getParticipantsData({searchText: form.searchField});
    setIsSubmitting(false);
  }

	async function clearFilter(e) {
		setForm({
			searchField: ''
		})
		getParticipantsData({searchText: ''})
	}

	return (
    <>
      <div
        className={"overlay" + (openDrawer ? " open" : "")}
        onClick={closeDrawer}
      ></div>
      <section
        className={
          "drawer drawer400" + (openDrawer ? " open" : "")
        }
      >
        <button onClick={closeDrawer} className="close">
          <IconClose />
        </button>

        <div className="containerPerformanceAssessmentsFilterDrawer">
          
            <header>
              <h3>{t('performance_assessment.page_assessment_details.participants.drawer_filters.title')}</h3>
            </header>

            <main>
                <div className="row">
                  <div className="col">
                    <div className='inputSearch'>
                      <label htmlFor='inputSearch'>
                        <IconSearch width="13" height="13" />
                      </label>
                      <input 
                        id='inputSearch' 
                        type="text" 
                        name='searchField' 
                        placeholder={t('performance_assessment.page_assessment_details.participants.drawer_filters.placeholder_search')} 
                        onChange={handleChange} 
                        value={form.searchField} 
                        autoFocus
                        autoComplete='off'
                      />
                    </div>
                  </div>
                </div>
              
                <div className="row">
                  <div className="col">
                    <button 
                      className='search-button'
                      onClick={handleFilter}
                      disabled={isSubmitting || !form.searchField}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={14} />
                      ) : t('performance_assessment.page_assessment_details.participants.drawer_filters.search')}
                    </button>
                    <button
                      className='clear-button'
                      onClick={clearFilter}
                    >
                      {t('performance_assessment.page_assessment_details.participants.drawer_filters.clear')}
                    </button>
                  </div>
                </div>

          </main>
        </div>

      </section>
    </>
	)
}

function mapStateToProps(state) {

	return {
		userDetails: state.authentication.userDetails,
		teams: state.teams.payload,
		created: state.teams.created
	}

}


function mapDispatchToProps(dispatch) {

	return {
		teamsActions: bindActionCreators(teamsActions, dispatch)
	}
}



export default connect(mapStateToProps, mapDispatchToProps)(PerformanceAssessmentsFilterDrawer)



