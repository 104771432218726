import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export default function TabComponent({ currentActive, assessmentId }) {

  const { t, i18n } = useTranslation("common");

  return (
    <div className="mainFilterbar">
      <div className="filters">
        <NavLink
          to={`/performance-assessment/details/participants/${assessmentId}`}
          className={currentActive === "participants" ? "active" : ""}
        >
          <button className={"settingsNav"}>
            {t("performance_assessment.page_assessment_details.participants.tab_title")}
          </button>
        </NavLink>
        <NavLink
          to={`/performance-assessment/details/replies/${assessmentId}`}
          className={currentActive === "replies" ? "active" : ""}
        >
          <button className={"settingsNav"}>
            {t("performance_assessment.page_assessment_details.replies.tab_title")}
          </button>
        </NavLink>
        <NavLink
          to={`/performance-assessment/details/results/${assessmentId}`}
          activeClassName={currentActive === "results" ? "active" : ""}
        >
          <button className={"settingsNav"}>
            {t("performance_assessment.page_assessment_details.results.tab_title")}
          </button>
        </NavLink>
      </div>
    </div>
  );
}
