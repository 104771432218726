import React, { useEffect, useState } from 'react'
import Toolbar from '../../_components/_shared/Toolbar'
import { connect } from 'react-redux'
import { alertActions } from '../../_actions'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import { api } from '../../_helpers/api'
import { authHeader } from '../../_helpers'
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton'
import { Box, Popover } from "@material-ui/core";
import PopoverConfirm from '../../_components/teams_settings/PopoverConfirmDelete'
import ScalesTabComponent from '../../_components/performance_assessments/ScalesTabsComponent'
import CreateResultIndicatorModal from '../../_components/performance_assessments/PerformanceAssessmentsScalesResultIndicators/CreateResultIndicatorModal'
import CardManageResultIndicators from '../../_components/performance_assessments/PerformanceAssessmentsScalesResultIndicators/CardManageResultIndicators'
import Filterbar from '../../_components/_shared/Filterbar'
import NoData from './NoData'
import ImportIndicatorsModal from '../../_components/performance_assessments/PerformanceAssessmentsScalesResultIndicators/ImportIndicatorsModal'

function PerformanceAssessmentScalesResultIndicatorsPage({
  alertActions,
  userDetails,
  filter
}) {
  
  const { t, i18n } = useTranslation('common');
  const [resultIndicators, setResultIndicators] = useState([]);
  const [fetching, setFetching] = useState(true);

  const [openModalCreateResultIndicator, setOpenModalCreateResultIndicator] = useState(false);
  const [openModalIndicatorsImport, setOpenModalIndicatorsImport] = useState(false);
  const [currentResultIndicator, setCurrentResultIndicator] = useState(null);
  
  const [lastResultIndicatorCreated, setLastResultIndicatorCreated] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "actions-popover" : undefined;

  const [typeAction, setTypeAction] = useState(null);

  const [resultIndicatorToEdit, setResultIndicatorToEdit] = useState(null);

  const [typeModal, setTypeModal] = useState('create_result_indicator');

  const handleClickCurrentResultIndicator = (event, scale) => {
    setCurrentResultIndicator(scale);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCurrentResultIndicator = (isFetching=false) => {
    setAnchorEl(null);
    setTypeAction(null);
    setCurrentResultIndicator(null);
    setLastResultIndicatorCreated(null);
    if(isFetching===true) {
      getResultIndicators(false, null)
    }
  };

    
  const [anchorElConfirmDelete, setAnchorElConfirmDelete] = useState(null);
  const openConfirmDelete = Boolean(anchorElConfirmDelete);
  const idConfirmDelete = openConfirmDelete ? 'popover-confirm-delete-result-indicator' : undefined;

  const handleOpenConfirmDelete = (event) => {
    setAnchorElConfirmDelete(event.currentTarget);
  };

  const handleCloseConfirmDelete = () => {
    setAnchorElConfirmDelete(null)
  };  

  const handleConfirmDeleteResultIndicator = async () => {
    try {
      if (!currentResultIndicator.id) {
        throw new Error('Result indicator to delete not found')
      }
      const res = await api.delete(`/performance-assessment/result-indicators/${currentResultIndicator.id}`, { headers: authHeader() })
      if (res.status !== 200) {
        throw new Error('Erro to delete Result indicator')
      }
      handleCloseConfirmDelete()
      handleCloseCurrentResultIndicator()
      getResultIndicators()
      alertActions.success(t('performance_assessment.messages.success_delete_result_indicator'))
    } catch (error) {
      alertActions.error(error.response.data)
      console.error(error)
    }
  }


  const [anchorElConfirmClone, setAnchorElConfirmClone] = useState(null);
  const openConfirmClone = Boolean(anchorElConfirmClone);
  const idConfirmClone = openConfirmClone ? 'popover-confirm-clone-result-indicator' : undefined;

  const handleOpenConfirmClone = (event) => {
    setAnchorElConfirmClone(event.currentTarget);
  };

  const handleCloseConfirmClone = () => {
    setAnchorElConfirmClone(null)
  };

  const handleConfirmCloneResultIndicator = async () => {
    try {
      if (!currentResultIndicator.id) {
        throw new Error('Result indicator off to clone not found')
      }

      const res = await api.post(`/performance-assessment/result-indicators/${currentResultIndicator.id}/clone`, {}, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro to clone Result indicator')
      }
      handleCloseConfirmClone()
      handleCloseCurrentResultIndicator()
      getResultIndicators()
      alertActions.success(t('performance_assessment.messages.success_clone_result_indicator'))
    } catch (error) {      
      alertActions.error(error.response.data)
      console.error(error)
    }
  }

  const getResultIndicators = async (isLoading=true, lastCreatedResultIndicator=null) => {
    try {
      if(isLoading) setFetching(true);
      const response = await api.get('performance-assessment/result-indicators', { headers: authHeader() });
      const { data } = response;
      setResultIndicators(data);
      if(lastCreatedResultIndicator && lastCreatedResultIndicator.id){
        const resultIndicatorFiltered = data.find(resultIndicator => resultIndicator.id === lastCreatedResultIndicator.id);
        if(resultIndicatorFiltered) {
          setCurrentResultIndicator(resultIndicatorFiltered);
          setTypeAction('edit_options');
          setLastResultIndicatorCreated(true);
        }
      }
      setFetching(false);
    } catch (error) {
      console.log(error);
      setFetching(false);
    }
  }

  const handleOpenModalCreateResultIndicator = () => {
    setTypeModal('create_result_indicator');
    setAnchorEl(null);
    setOpenModalCreateResultIndicator(true);
  }

  const handleCloseModalCreateResultIndicator = () => {
    setOpenModalCreateResultIndicator(false);
  }

  const handleOpenModalEditResultIndicator = () => {
    setTypeModal('edit_result_indicator');
    if(currentResultIndicator){
      setAnchorEl(null);
      setResultIndicatorToEdit(currentResultIndicator);
    }
    setOpenModalCreateResultIndicator(true);
  }

  const handleCloseModalEditResultIndicator = () => {
    setOpenModalCreateResultIndicator(false);
    if(currentResultIndicator){
      handleCloseCurrentResultIndicator(false);
      setResultIndicatorToEdit(null);
    }
  }

  
  const handleOpenModalIndicatorsImport = () => {
    setOpenModalIndicatorsImport(true);
  }

  const handleCloseModalModalIndicatorsImport = () => {
    setOpenModalIndicatorsImport(false);
  }

  useEffect(() => {    
    document.title = `Befective | ${t('menu.performance_assessment.pre_settings')} | ${t('menu.performance_assessment.scales')} `;  
    getResultIndicators();
  }, [])


  return (
    <section className="mainContent">
      
      <Toolbar />

      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhat={'disabled'}    
        buttonCreate={{
          title: t('performance_assessment.page_scales_result_indicator.button_create'),
          handleClick: handleOpenModalCreateResultIndicator,
        }}
        buttonImport={{
          handleClick:handleOpenModalIndicatorsImport,
        }}
      />

      <main className='mainContentPage'>
        {
          !fetching
          ?
          (
            resultIndicators && resultIndicators.length > 0
            ?
            resultIndicators.map((resultIndicator, index) => {
              return (
                <CardManageResultIndicators 
                    key={`scale-result-indicator-${index}`} 
                    index={index} 
                    resultIndicator={resultIndicator} 
                    currentResultIndicator={currentResultIndicator}
                    handleClickCurrentResultIndicator={handleClickCurrentResultIndicator}
                    handleCloseCurrentResultIndicator={handleCloseCurrentResultIndicator} 
                    getResultIndicators={() => getResultIndicators(false, null)}
                    typeAction={typeAction}
                    alertActions={alertActions}
                    lastResultIndicatorCreated={lastResultIndicatorCreated}
                />
              )
            })
            :
            <NoData />
          )
          :
          <GlobalSkeleton
            totalRowsStats={0}
            totalTables={1}
            heightTable={600}          
            margin={'-10px 0px 0px 0px'}
          />
        }
      </main>

      {/* Start Popover Actions */}
      <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => handleCloseCurrentResultIndicator(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              backgroundColor: "#1E6B7F",
              marginTop: "10px",
            },
          }}
        >
          <Box className={"menuOptionsActions"}>
            <button
              className={"menuOptionsActionButton"}
              onClick={handleOpenModalEditResultIndicator}
            >
              {t("performance_assessment.actions.edit_result_indicator")}
            </button>
            <button
              className={"menuOptionsActionButton"}
              onClick={handleOpenConfirmDelete}
            >
              {t("performance_assessment.actions.delete_result_indicator")}
            </button>
            <button
              className={"menuOptionsActionButton"}
              onClick={handleOpenConfirmClone}
            >
              {t("performance_assessment.actions.clone_result_indicator")}
            </button>
            <button
              className={"menuOptionsActionButton"}
              onClick={() => {
                setTypeAction("edit_options")
                setAnchorEl(null);
              }}
            >
              {t("performance_assessment.actions.edit_options")}
            </button>
          </Box>
      </Popover>
      {/* End Popover Actions */}


      {/* Start Confirm Dialog Delete Result Indicator */}
      <PopoverConfirm
        idConfirm={idConfirmDelete}
        openConfirm={openConfirmDelete}
        anchorEl={anchorElConfirmDelete}
        handleClose={handleCloseConfirmDelete}
        handleConfirm={handleConfirmDeleteResultIndicator}
        title={t('performance_assessment.messages.delete_result_indicator')}
      />
      {/* End Confirm Dialog Delete Result Indicator */}


      {/* Start Confirm Dialog Clone Result Indicator */}
      <PopoverConfirm
        idConfirm={idConfirmClone}
        openConfirm={openConfirmClone}
        anchorEl={anchorElConfirmClone}
        handleClose={handleCloseConfirmClone}
        handleConfirm={handleConfirmCloneResultIndicator}
        title={t('performance_assessment.messages.clone_result_indicator')}
      />
      {/* End Confirm Dialog Clone Result Indicator */}
      
      <CreateResultIndicatorModal
        openModal={openModalCreateResultIndicator}
        closeModal={() => {
          if(typeModal === "edit_result_indicator"){
            handleCloseModalEditResultIndicator()
            return
          }
          handleCloseModalCreateResultIndicator()
        }}
        getResultIndicators={getResultIndicators}
        resultIndicatorToEdit={resultIndicatorToEdit}
        alertActions={alertActions}
      />

      <ImportIndicatorsModal
        openModal={openModalIndicatorsImport}
        closeModal={handleCloseModalModalIndicatorsImport}
        getResultIndicators={getResultIndicators}
        alertActions={alertActions}
      />
    </section>
  )
}



function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceAssessmentScalesResultIndicatorsPage)