import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";
import en from "date-fns/locale/en-US";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import useOutsideClick from "../../../_helpers/useOutsideClick";
import { filterActions } from "../../../_actions";
import { statsActions } from "../../../_actions";

import { useTranslation, initReactI18next } from "react-i18next";

import IconClear from "../../_icons/IconClear";

function WhenSingleYears(props) {
  const { t, i18n } = useTranslation("common");

  registerLocale("es", es);
  registerLocale("pt", pt);
  registerLocale("en", en);

  const { filterActions, userDetails, maxDate } = props;

  let calendarLanguage = "pt";

  if (userDetails && userDetails.language) {
    calendarLanguage = userDetails.language;
  } else {
    calendarLanguage = window.navigator.userLanguage;
  }

  const [ManualWhenValue, setManualWhenValue] = useState("");

  const refFilter = useRef();

  const [filterWhen, setWhen] = useState(false);
  const [dateSelected, setDateSelected] = useState(new Date());

  useOutsideClick(refFilter, (target) => {
    if (filterWhen) {
      if (
        refFilter &&
        !target.includes("react-datepicker") &&
        !target.includes("column") &&
        !target.includes("who") &&
        !target.includes("input")
      ) {
        if (filterWhen) {
          setWhen(false);
        }
      }
    }
  });

  const renderDayContents = (day) => {
    return <span>{day}</span>;
  };

  function applyWhenFilter(date) {
    setDateSelected(date);

    let path = window.location.pathname.split("/");

    let date1 = moment(date).format("YYYY-MM-DD");

    filterActions.replace(date1, "singleWhen");

    setWhen(false);
  }

  function quickWhenFilter(date1) {
    applyWhenFilter(date1);
  }

  function clearWhen() {
    let date1 = new Date();
    applyWhenFilter(date1);
  }

  function manualWhenFilter(e) {
    if (e.target.value.length >= 10) {
      if (e.keyCode == 13) {
        let toDateStart = ManualWhenValue.split("/");

        let NewStartDate = "";

        let date1 = "";

        let valid = true;

        if (toDateStart[2] > 2040) {
          setManualWhenValue("");
          return;
        }

        if (i18n.language == "en") {
          date1 = toDateStart[2] + "-" + toDateStart[0] + "-" + toDateStart[1];
        } else {
          date1 = toDateStart[2] + "-" + toDateStart[1] + "-" + toDateStart[0];
        }

        NewStartDate = new Date(date1 + "T12:00:00");

        if (new Date(date1) > new Date()) {
          valid = false;
        }

        if (valid) {
          applyWhenFilter(NewStartDate);
        } else {
          setManualWhenValue("");
        }
      }
      if (e.keyCode == 8 || e.keyCode == 46) {
        setManualWhenValue(e.target.value.slice(0, -1));
      }

      return;
    }

    if (e.keyCode != 8 && e.keyCode != 46) {
      if (e.keyCode >= 48 && e.keyCode <= 57) {
        let newValue = "";

        if (
          e.target.value.length == 2 ||
          e.target.value.length == 5 ||
          e.target.value.length == 15 ||
          e.target.value.length == 18
        ) {
          newValue = e.target.value + "/" + e.key;
        } else if (e.target.value.length == 10) {
          newValue = e.target.value + " > " + e.key;
        } else {
          newValue = e.target.value + e.key;
        }

        setManualWhenValue(newValue);
      } else {
      }
    } else {
      setManualWhenValue(e.target.value.slice(0, -1));
    }
  }

  useEffect(() => {
    if (props.filter.singleDate) {
      let newDate = new Date(props.filter.singleDate + "T12:00:00");
      setDateSelected(newDate);
    }
  }, [props.filter.singleDate]);

  return (
    <div
      style={{minWidth:"115px"}}
      className={filterWhen ? "filter when open" : "when filter"}
      ref={refFilter}
    >
      <div className="holder">
        <span className="label">{t("filter.when")}:</span>
        <span
          className="value"
          onClick={() => {
            setWhen(!filterWhen);
            setManualWhenValue("");
          }}
          style={{width: "80px"}}
        >
          {moment(dateSelected).format("yyyy")}
        </span>
        <input
          style={{ backgroundColor: "#fff", width: "80px" }}
          className="input"
          value={ManualWhenValue}
          disabled
          onKeyUp={(data) => {
            manualWhenFilter(data);
          }}
          placeholder={moment(dateSelected).format("yyyy")}
        />
        <div
          className="clear"
          onClick={() => {
            clearWhen();
          }}
        >
          <IconClear />
        </div>
      </div>
      <div className="comp date">
        <div className="column date">
          <DatePicker
            selected={dateSelected}
            onChange={(date) => applyWhenFilter(date)}
            maxDate={maxDate}
            disabledKeyboardNavigation // <-- active-day bug is still open
            inline
            renderDayContents={renderDayContents}
            locale={calendarLanguage}
            dateFormat="yyyy"
            showYearPicker
          />
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    statsActions: bindActionCreators(statsActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    filter: state.filter,
    userDetails: state.authentication.userDetails,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WhenSingleYears);
