import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Modal,
  Slide,
  makeStyles,
} from "@material-ui/core";
import styles from "./CreateResultIndicatorModal.module.scss";
import { useTranslation } from "react-i18next";
import { api } from "../../../_helpers/api";
import { authHeader } from "../../../_helpers";
import IconClose from "../../_icons/IconClose";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function CreateResultIndicatorModal({ openModal, closeModal, getResultIndicators, resultIndicatorToEdit = null, alertActions}) {

  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const [isSubmit, setIsSubmit] = useState(false);

  const [form, setForm] = useState({
    name: "",
    description: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: ""
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  }

  const handleClose = () => {
    closeModal();
    setForm({ name: "", description: ""});
    setFormErrors({ name: "" });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (form.name === "") {
      setFormErrors({
        ...formErrors,
        name: t("validation.required"),
      });
    }

    if (form.name !== "") {
      setIsSubmit(true);

      if (resultIndicatorToEdit) {
        api
          .put(`/performance-assessment/result-indicators/${resultIndicatorToEdit.id}`, form, {
            headers: authHeader(),
          })
          .then((res) => {
            getResultIndicators();
            setIsSubmit(false);
            handleClose();
            alertActions.success(t("performance_assessment.messages.success_update_result_indicator"));
          })
          .catch((err) => {
            console.log(err);
            setIsSubmit(false);
            alertActions.success(t("performance_assessment.messages.error_update_result_indicator"));
          });
          return
      }
      
      api
        .post(`/performance-assessment/result-indicators`, form, {
          headers: authHeader(),
        })
        .then((res) => {
          getResultIndicators(true, res.data);
          setIsSubmit(false);
          handleClose();
          alertActions.success(t("performance_assessment.messages.success_create_result_indicator"));
        })
        .catch((err) => {
          console.log(err);
          setIsSubmit(false);
          alertActions.success(t("performance_assessment.messages.error_create_result_indicator"));
        });

    }
  }

  useEffect(() => {
    if (resultIndicatorToEdit) {
      setForm({
        name: resultIndicatorToEdit.name,
        description: resultIndicatorToEdit.description
      });
    }else{
      setForm({ name: "", description: ""});
    }
  }, [resultIndicatorToEdit]);


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${styles.modal}`}
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: "#205B6F", opacity: 0.8 },
      }}
      sx={{ border: "none" }}
      style={{ outline: 0 }}
    >
      <Slide
        direction="down"
        in={openModal}
        style={{ outline: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.containerModal}>

          <button
              onClick={handleClose}
              className={styles.close}
          >
            <IconClose />
          </button>


          <header>
            <h4>
              {resultIndicatorToEdit ? t("performance_assessment.page_scales_result_indicator.modal_create_and_update.title_edit") : t("performance_assessment.page_scales_result_indicator.modal_create_and_update.title_new")}
            </h4>
          </header>

          <form
            onSubmit={handleSubmit}
          >
            <div className={styles.fieldBox}>
              <label htmlFor="name">{t("performance_assessment.page_scales_result_indicator.modal_create_and_update.name_scale")}*</label>
              <input type="text" id="name" name="name" onChange={onChangeInput} value={form.name} placeholder={t("performance_assessment.page_scales_result_indicator.modal_create_and_update.placeholder_name")}/>
              {formErrors.name && <p className={styles.error}>{formErrors.name}</p>}
            </div>

            <div className={styles.fieldBox}>
              <label htmlFor="description">{t("performance_assessment.page_scales_result_indicator.modal_create_and_update.description_scale")}</label>
              <textarea id="description" name="description" onChange={onChangeInput} rows={3} value={form.description} placeholder={t("performance_assessment.page_scales_result_indicator.modal_create_and_update.placeholder_description")}></textarea>
            </div>            
          
            <footer>            
              <button className={styles.buttonCancel} type="button" onClick={handleClose}>
                {t("performance_assessment.actions.cancel")}
              </button>
              <button className={styles.buttonSave} disabled={isSubmit}>
                {t("performance_assessment.actions.save")}
              </button>
            </footer> 
          </form>
        </div>
      </Slide>
    </Modal>
  );
}
