import React from 'react'
import styles from './styles.module.scss'
import HeaderForm from "../personal/HeaderForm";
import { useTranslation } from 'react-i18next'
import { befectiveFormatDate } from '../../../_helpers';


export default function FormFinancePreviewPT({
  formData,
  toogleShowForm,
  isEditing,
  isExistUserId,
  userId
}) {

  const {t, i18n} = useTranslation('common')

  const formatListDeductions = (deductions) => {
    if(deductions?.length === 0) return '-----'

    if(deductions.length === 1) return deductions[0][`label_${i18n.language}`]

    const deductionsLessLast = deductions.slice(0, -1).map(language => language[`label_${i18n.language}`])

    const deductionsLast = deductions[deductions.length - 1][`label_${i18n.language}`]

    return `${deductionsLessLast.join(', ')} ${t('settings.users.personal_form.and')} ${deductionsLast}`
  }

  return (
    <div className={styles.containerPreview}>
      <div className={styles.card}>
        <HeaderForm formData={formData} toogleShowForm={toogleShowForm} isEditing={isEditing} titleCreate={t('settings.users.finance_form.title_create')} isExistUserId={isExistUserId} userId={userId}/>
        <div className={styles.cardContent}>
            <div className={styles.col50}>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.finance_form.bank')}</span>
                <h6 className={styles.value}>{formData?.bank ? formData?.bank?.label : '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.finance_form.account_corrent')}</span>
                <h6 className={styles.value}>{formData?.current_account || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.finance_form.agency')}</span>
                <h6 className={styles.value}>{formData?.agency || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.finance_form.account_type')}</span>
                <h6 className={styles.value}>{formData?.account_type ? formData?.account_type[`label_${i18n.language}`] : '-----'}</h6>
              </div>
            </div>

            <div className={styles.col50}>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.finance_form.base_salary')}</span>
                <h6 className={styles.value}>{formData?.base_salary || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.finance_form.payroll_deduction')}</span>
                <h6 className={styles.value}>{formatListDeductions(formData?.payroll_deductions)}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.finance_form.form_payment')}</span>
                <h6 className={styles.value}>{formData?.payment_form ? formData?.payment_form[`label_${i18n.language}`] : '-----'}</h6>
              </div>
              {
                formData?.payment_form && formData?.payment_form.value === 1 && (
                  <div className={styles.field}>
                    <span className={styles.label}>{t('settings.users.finance_form.pix_key')}</span>
                    <h6 className={styles.value}>{formData?.pix_key || '-----'}</h6>
                  </div>
                )
              }
            </div>
        </div>
      </div>

      <div className={styles.card}>
        <header className={styles.cardHeader}>
          <h6>{t('settings.users.finance_form.benefits')}</h6>
        </header>
        <div className={styles.cardContent} style={{padding:'0px 20px 20px', display:'flex', flexDirection:'column'}}>
          {
            formData?.benefits?.length > 0 
            ?
            formData?.benefits?.map((benefit, index) => (
            <div className={styles.rowBenefit} key={`benefit-${index}`}>
              <div className={styles.boxTimeline}>
                <div className={styles.circle}></div>
                {index !== formData?.benefits?.length - 1 && <div className={styles.line}></div>}
              </div>
              <div className={styles.boxInfoBenefit}>
                <h6>{benefit?.label || "-----"}</h6>
                <span>{t('settings.users.finance_form.since')} {benefit?.since ? befectiveFormatDate(benefit?.since, i18n.language) : '-----'}</span>
              </div>
            </div>
            ))
            :
            <div className={styles.rowBenefit}>
              <p className={styles.noneBenefit}>{t('settings.users.finance_form.none_benefits_available')}</p>
            </div>
          }          
        </div>
      </div>

    </div>
  )
}
