import React from 'react';
import { Checkbox, withStyles } from '@material-ui/core'

const BefectiveCheckbox = withStyles({
    root: {
      color: '#22BEAD',
      '&$checked': {
        color: '#22BEAD',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&$disabled': {
        color: '#22BEAD',
      },
    },
    checked: {},
    disabled: {}
  })((props) => <Checkbox color="default" {...props} />);

export default BefectiveCheckbox;