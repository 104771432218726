import React from 'react'
import { useTranslation } from 'react-i18next';

export default function NoData() {
  const { t } = useTranslation('common');
  return (
    <div className="containerNoData" >
      <h6 className="titleNoData">{t("messages_no_data.skills.title")}</h6>
      <p className="simpleTextNoData lineHeight">{t("messages_no_data.skills.description")}</p>
      <p className="simpleTextNoData" dangerouslySetInnerHTML={{ __html: t("messages_no_data.skills.step1")}}></p>
      <p className="simpleTextNoData">{t("messages_no_data.skills.step2")}</p>
      <p className="simpleTextNoData">{t("messages_no_data.skills.step3")}</p>
      <p className="simpleTextNoData">{t("messages_no_data.skills.step4")}</p>
    </div>
  )
}
