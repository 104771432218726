// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ODDCkWy2xXGBSPbMlAlZWw\\=\\= {\n  border: none;\n  cursor: pointer;\n  color: #98ACC1;\n  background: transparent;\n}\n\n.ODDCkWy2xXGBSPbMlAlZWw\\=\\=:hover {\n  transition: all ease 0.2s;\n  color: #187DFF;\n}\n\n._3gbL0SzEL\\+dTyX7H-UzWQQ\\=\\= {\n  height: 38px;\n  background-color: #FFE0DF;\n  border-radius: 2px;\n  border: none;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n._3gbL0SzEL\\+dTyX7H-UzWQQ\\=\\=:disabled {\n  cursor: progress;\n}\n\n.IhNvBskPtPoIzCxDfaf4hw\\=\\= {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/_components/users_settings/professional/ExperiencesManageList.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,cAAA;EACA,uBAAA;AACF;;AAGA;EACE,yBAAA;EACA,cAAA;AAAF;;AAGA;EACE,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AAAF;AAEE;EACI,gBAAA;AAAN;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAFF","sourcesContent":[".buttonAddItem{\n  border: none;\n  cursor: pointer;\n  color: #98ACC1;\n  background: transparent;\n\n}\n\n.buttonAddItem:hover{\n  transition: all ease 0.2s; \n  color: #187DFF;\n}\n\n.buttonRemove{\n  height: 38px;\n  background-color: #FFE0DF;\n  border-radius: 2px;\n  border: none;\n  cursor: pointer;  \n  display: flex;\n  align-items: center;\n\n  &:disabled {\n      cursor: progress;\n  }\n\n}\n\n.boxItemCheck{\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonAddItem": "ODDCkWy2xXGBSPbMlAlZWw==",
	"buttonRemove": "_3gbL0SzEL+dTyX7H-UzWQQ==",
	"boxItemCheck": "IhNvBskPtPoIzCxDfaf4hw=="
};
export default ___CSS_LOADER_EXPORT___;
