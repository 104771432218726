import { Backdrop, FormControl, MenuItem, Modal, Popover, Select, Slide, Typography, makeStyles } from "@material-ui/core";
import { AddOutlined, ArrowDropDown, AttachFile, CheckBox, Close, Edit, EventAvailable, GetApp, LocalOffer, PeopleOutline } from "@material-ui/icons";
import { Box, Button, Checkbox, Grid, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import { DateTimePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from "moment";
import { Archive, Cards, LinkSimple, LinkSimpleBreak, List, Paperclip } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import AvatarGroup from "react-avatar-group";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CirclePicker } from "react-color";
import EasyEdit from 'react-easy-edit';
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RichTextEditor from "react-rte";
import { bindActionCreators } from "redux";
import { alertActions } from "../../_actions";
import { befectiveFormatDate, befectiveMaskFormatDateMUI } from "../../_helpers";
import { api } from "../../_helpers/api";
import { authHeader } from "../../_helpers/auth-header";
import IconClose from "../_icons/IconClose";
import style from './CardDetailsModal.module.scss';
import ChildrenBlur from "./ChildrenBlur";
import { KanbanCardChecklist } from "./KanbanBoard/KanbanCardChecklist";
import MembersCardModal from "./MembersCardModal";
import PopoverConfirm from "../teams_settings/PopoverConfirmDelete";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkbox: {
    'padding': '4px !important',
    'color': '#22BEAD !important',
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      width: 'auto',
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      // backgroundColor: "#1E6B7F",
      marginTop: '6px'
    },
  },

};

const BodyTextEditor = (props) => {
  const [value, setValue] = useState(!_.isEmpty(props.valueDefault) ? RichTextEditor.createValueFromString(props.valueDefault, 'html') : RichTextEditor.createEmptyValue());
  const onChange = (value) => {
    setValue(value);
    if (props.onChange) {
      props.onChange(value.toString('html'));
    }
  };

  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ]
  };

  return <RichTextEditor value={value} onChange={onChange} toolbarConfig={toolbarConfig} autoFocus />;
};

function CardDetailsModal({
  openModalDetailsCard,
  setOpenModalDetailsCard,
  userDetails,
  colabsOptions = [],
  alertActions,
  updateInfo,
  currentCard,
  setCurrentCard
}) {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles();
  const [colabs, setColabs] = useState([])
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [valueRTE, setValueRTE] = useState("");
  const [showRTE, setShowRTE] = useState(false)
  const [cardInfo, setCardInfo] = useState(null)
  const [openModalMembersCard, setOpenModalMembersCard] = useState(false)
  const [newChecklistTitle, setNewChecklistTitle] = useState('');
  const [errors, setErrors] = useState({ newChecklistTitle: false })
  const [anchorElNewChecklist, setAnchorElNewChecklist] = useState(null);
  const openPopoverNewChecklist = Boolean(anchorElNewChecklist);
  const idPopoverNewChecklist = openPopoverNewChecklist ? 'new-checklist-popover' : undefined;
  const [openDates, setOpenDates] = useState(false);
  const anchorRefDates = useRef(null);

  const handleClickNewChecklist = (event) => {
    setAnchorElNewChecklist(event.currentTarget);
  };

  const handleCloseNewChecklist = () => {
    setAnchorElNewChecklist(null);
    setNewChecklistTitle('')
  };

  const [viewTag, setViewTag] = useState(0);

  const [tags, setTags] = useState([])

  const [formTag, setFormTag] = useState({
    title: '',
    color: '#dcdfe4'
  });

  const [formTagError, setFormTagError] = useState({
    title: '',
    color: ''
  })

  const [anchorElTags, setAnchorElTags] = useState(null);
  const openPopoverTags = Boolean(anchorElTags);
  const idPopoverTags = openPopoverTags ? 'tags-popover' : undefined;
  
  const [anchorElConfirmDeleteChecklist, setAnchorElConfirmDeleteChecklist] = useState(null);
  const [checklistToDelete, setChecklistToDelete] = useState(null);
  const openConfirmDeleteChecklist = Boolean(anchorElConfirmDeleteChecklist);
  const idConfirmDeleteChecklist = openConfirmDeleteChecklist ? 'simple-popover-confirm-checklist-delete' : undefined;

  const handleClickConfirmDeleteChecklist = (event, checklist) => {
    setChecklistToDelete(checklist)
    setAnchorElConfirmDeleteChecklist(event.currentTarget);
  };

  const handleCloseConfirmDeleteChecklist = () => {
    setAnchorElConfirmDeleteChecklist(null)
    setChecklistToDelete(null)
  };


  const handleChangeCheckTag = (event) => {
    const { checked, id } = event.target
    toogleTag(id, cardInfo.id)
  };

  const handleClickTags = (event) => {
    setAnchorElTags(event.currentTarget);
  };

  const handleCloseTags = () => {
    setAnchorElTags(null);
    setFormTag({
      title: '',
      color: '#dcdfe4'
    })
  };

  const [isChecking, setIsChecking] = useState(false);

  const [formDates, setFormDates] = useState({
    dateStart: dayjs(moment().format('YYYY-MM-DD')),
    dateEnd: dayjs(moment().format('YYYY-MM-DD HH:mm')),
    done: false
  })

  const [anchorElDates, setAnchorElDates] = useState(null);
  const openPopoverDates = Boolean(anchorElDates);
  const idPopoverDates = openPopoverDates ? 'dates-popover' : undefined;
  const [disableDate, setDisableDate] = useState(false)

  const handleClickDates = (event) => {
    setAnchorElDates(event.currentTarget);
  };

  const handleCloseDates = () => {
    setAnchorElDates(null);
  };

  const handleChangeCheckDate = async (event) => {
    try {
      setIsChecking(true)
      await api.put(`project-management/boards/lists/cards/${currentCard.id}/due_date`, { start: dayjs(formDates.dateStart).format('YYYY-MM-DD'), end: dayjs(formDates.dateEnd).format(), done: event.target.checked }, { headers: authHeader() })
      await getDetailsCard()
      setIsChecking(false)
    } catch (error) {
      console.log(error)
      setIsChecking(false)
    }
  };


  const [structureGoals, setStructureGoals] = useState(null)
  const [anchorElAssociate, setAnchorElAssociate] = useState(null);
  const openPopoverAssociate = Boolean(anchorElAssociate);
  const idPopoverAssociate = openPopoverAssociate ? 'associate-popover' : undefined;
  const [disableAssociate, setDisableAssociate] = useState(false)


  const [goalSelected, setGoalSelected] = useState('');
  const [krSelected, setKrSelected] = useState('');
  const [taskSelected, setTaskSelected] = useState('');

  const [krs, setKrs] = useState([]);
  const [tasks, setTasks] = useState([]);

  const [disassociating, setDisassociating] = useState(false)
  const [anchorElDisassociateCard, setAnchorElDisassociateCard] = useState(null);
  const openDisassociateCard = Boolean(anchorElDisassociateCard);
  const idPopoverDisassociateCard = openDisassociateCard ? 'disassociate-card' : undefined;

  const handleClickDisassociateCard = (event) => {
    setAnchorElDisassociateCard(event.currentTarget);
  };

  function handleCloseDisassociateCardPopUp() {
    setDisassociating(false)
    setAnchorElDisassociateCard(null)
  }

  const handleChangeGoalSeleted = (event) => {
    const { value } = event.target
    if (!value) {
      setGoalSelected('');
      setKrSelected('')
      setTaskSelected('')
    } else {
      setGoalSelected(value);
      const { key_results } = structureGoals.find(goal => goal.id === value)
      setKrs(key_results)
      setTasks([])
    }
  };

  const handleChangeKrSeleted = (event) => {
    const { value } = event.target
    if (!value) {
      setGoalSelected('');
      setKrSelected('')
      setTaskSelected('')
    } else {
      setKrSelected(value);
      const { tasks } = krs.find(kr => kr.id === value)
      setTasks(tasks)
    }
  };

  const handleClickAssociate = (event) => {
    getStructureGoals()
    setAnchorElAssociate(event.currentTarget);

  };

  const handleCloseAssociate = () => {
    setAnchorElAssociate(null);
    setStructureGoals(null)
  };

  const associateCard = async () => {
    try {

      if ((!goalSelected || !krSelected || !taskSelected)) return

      setDisableAssociate(true)

      const result = await api.put(`project-management/boards/lists/cards/${currentCard.id}/associate`, { task_id: taskSelected }, { headers: authHeader() })

      if (result.status === 200) {
        await getDetailsCard()
        await getStructureGoals()
        setGoalSelected('');
        setKrSelected('')
        setTaskSelected('')
        setDisableAssociate(false)
        handleCloseAssociate()
        alertActions.success(t('project_management.success.associate_card'))

      }
    } catch (error) {
      console.log('Response Error -> ', error);
      setDisableAssociate(false)
      //alertActions.error(t('project_management.errors.associate_card'))
    }
  }

  const disassociateCard = async () => {
    try {

      setDisassociating(true)

      const result = await api.put(`project-management/boards/lists/cards/${currentCard.id}/associate`, {}, { headers: authHeader() })

      if (result.status === 200) {
        await getDetailsCard()
        await getStructureGoals()
        setGoalSelected('');
        setKrSelected('')
        setTaskSelected('')
        handleCloseDisassociateCardPopUp()
        alertActions.success(t('project_management.success.disassociate_card'))
      }
    } catch (error) {
      console.log('Response Error -> ', error);
      handleCloseDisassociateCardPopUp()
      //alertActions.error(t('project_management.errors.disassociate_card'))
    }
  }

  const getStructureGoals = async () => {
    try {
      const { data } = await api.get(`/goals/structure/?organization_id=${userDetails.organizationId}`, { headers: authHeader() })
      setStructureGoals(data)
    } catch (error) {
      console.log('Response Error -> ', error);
      //alertActions.error(t('project_management.errors.load'))
    }
  }

  const [archiving, setArchiving] = useState(false)
  const [anchorElArchivedCard, setAnchorElArchivedCard] = useState(null);
  const openArchivedCard = Boolean(anchorElArchivedCard);
  const idPopoverArchivedCard = openArchivedCard ? 'archived-card' : undefined;

  const handleClickArchiveCard = (event) => {
    setAnchorElArchivedCard(event.currentTarget);
  };

  function handleCloseArchivedCardPopUp() {
    setArchiving(false)
    setAnchorElArchivedCard(null)
  }

  const handleArchiveCard = async () => {
    try {
      setArchiving(true)
      await updateCard(null, true)
      setArchiving(false)
      closeModalDetailsCard()
    } catch (error) {
      alertActions.success(t('project_management.errors.deleted_list'))
      setArchiving(false)
    }
  }

  const [attachments, setAttachments] = useState([])
  const [attachmentsError, setAttachmentsError] = useState('')
  const [sendAttachments, setSendAttachments] = useState(false)
  const [deletedAttachments, setDeletedAttachments] = useState(false)
  const [anchorElAttachments, setAnchorElAttachments] = useState(null);
  const openPopoverAttachments = Boolean(anchorElAttachments);
  const idPopoverAttachments = openPopoverAttachments ? 'attachments-popover' : undefined;

  const handleClickAttachments = (event) => {
    setAnchorElAttachments(event.currentTarget);
  };

  const onChangeInputAttachments = (e) => {
    const filesInput = e.target.files;
    const filesArr = Array.prototype.slice.call(filesInput);
    setAttachments([...attachments, ...filesArr]);
    // setFormError({ ...formError, attachments: '' })
  }

  const removeAttachment = async (attachment) => {
    setAttachments(attachments.filter(f => f !== attachment));
    await deleteCardAttachment(attachment.id)
  }

  const deleteCardAttachment = async (idAttachment) => {
    try {
      if (!idAttachment)
        throw new Error('Id attachment not exists')
      setDeletedAttachments(true)
      await api.delete(`/project-management/boards/lists/cards/attachments/${idAttachment}`, {
        headers: authHeader()
      })
      await getDetailsCard()
      setDeletedAttachments(false)
    } catch (error) {
      console.log('Erro ao excluir o anexo. Error: ', error)
      setDeletedAttachments(false)
    }
  }

  const saveCardAttachments = async () => {
    try {
      setSendAttachments(true)
      if (attachments.length === 0) {
        return
      }

      attachments.forEach(attachment => {

        const acceptFiles = ['PNG', 'JPG', 'JPEG', 'GIF', 'WEBP', 'PDF']

        if ((attachment.size / 1000000) > 2) {
          setAttachmentsError(t('calls_page.error.file'))
          return
        }

        const fileNameSplitted = attachment.name.split('.')
        const fileExtension = fileNameSplitted[fileNameSplitted.length - 1]

        if (!acceptFiles.includes(fileExtension.toUpperCase())) {
          setAttachmentsError(t('calls_page.error.file_type'))
          return
        }
      })

      const formData = new FormData();

      for (let file of attachments) {
        formData.append('files', file)
      }

      await api.post(`/project-management/boards/lists/cards/${currentCard.id}/attachments`, formData, { headers: authHeader() })
      await getDetailsCard()
      handleCloseAttachments()
      setSendAttachments(false)
    } catch (error) {
      console.log(error)
      //alertActions.error(error)
      setSendAttachments(false)
    }
  }

  // Função para iniciar o download quando o botão é clicado
  const handleDownloadAttachment = (fileUrl) => {
    // Cria um elemento de link temporário
    const link = document.createElement('a');
    link.href = fileUrl;

    // Dispara um evento de clique no link
    link.click();

    // Remove o link temporário
    URL.revokeObjectURL(link.href);
  };

  const handleCloseAttachments = () => {
    setAttachments([])
    setAnchorElAttachments(null);
  };


  function handleChangeTextareaChecklistTitle(event) {
    setNewChecklistTitle(event.target.value)
  }

  const [formCard, setFormCard] = useState({});

  const [formCardError, setFormCardError] = useState({
    title: '',
    description: ''
  });

  const handleChange = e => {
    setFormCard({
      ...formCard,
      [e.target.name]: e.target.value,
    })
  }

  function closeModalDetailsCard() {
    setOpenModalDetailsCard(false)
    setFormCard({
      title: currentCard?.title || '',
      description: currentCard?.description || ''
    })
    setFormCardError({
      title: '',
      description: ''
    })
    setCurrentCard(null)
    updateInfo()
  }

  const validateForm = (formCard) => {
    const errors = {};

    if (!formCard.title) {
      errors.title = t('project_management.validations.required_field')
    }

    return errors
  }

  const updateCard = async (title = null, archive = false) => {

    try {
      const valueTitle = title || formCard.title
      const result = await api.put(`project-management/boards/lists/cards/${formCard.id}`,
        {
          list_id: formCard.lists_id,
          title: valueTitle,
          description: formCard.description,
          position: formCard.position,
          archive: archive
        },
        {
          headers: authHeader()
        })
      if (result.status === 200) {
        getDetailsCard()
        updateInfo()
        if (archive === true) {
          alertActions.success(t('project_management.success.archive_card'))
        }
      }
    } catch (error) {
      console.log('Response Error -> ', error);
      if (archive === true) {
        //alertActions.error(t('project_management.errors.archive_card'))
      } else {
        //alertActions.error(t('project_management.errors.updated_card'))
      }
    }
  }


  const save = async (value) => {
    updateCard(value)
  }

  const cancel = (e) => {
    console.log(e)
  }


  const updateItemIndex = async (checklist_id, item_id, origin, destination) => {
    try {
      const response = await api.patch(`/project-management/boards/lists/cards/checklists/items/update-index`, {
        checklist_id: checklist_id,
        item_id: item_id,
        source: origin,
        destination: destination,
      },
        {
          headers: authHeader()
        })
      if (response.status === 200) {
        await getDetailsCard()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDragChecklist = async (result) => {
    if (!result.destination) return; // Se o item não foi solto em uma posição válida

    const checklist_id = result.draggableId
    const source = result.source.index
    const destination = result.destination.index

    if (destination === source) return; // Se moveu para a mesma posição

    const newItems = Array.from(cardInfo.cards_checklists);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setCardInfo({
      ...cardInfo,
      cards_checklists: newItems
    })

    try {
      const response = await api.patch(`/project-management/boards/lists/cards/checklists/update-index`, {
        card_id: currentCard.id,
        checklist_id: checklist_id,
        source: source,
        destination: destination,
      },
        {
          headers: authHeader()
        })
      if (response.status === 200) {
        await getDetailsCard()
      }
    } catch (error) {
      console.log(error.response)
    }

  };

  const getDetailsCard = async () => {
    try {
      const { data } = await api.get(`/project-management/boards/lists/cards/${currentCard.id}`,
        {
          headers: authHeader()
        })
      setCardInfo(data)
      setFormCard(data)
      if (data.start && data.end) {
        setFormDates({
          dateStart: dayjs(moment(data.start).format('YYYY-MM-DD')),
          dateEnd: dayjs(moment(data.end).format('YYYY-MM-DD HH:mm')),
          done: data.done
        })
      }
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('project_management.errors.load'))
    }
  }

  const createChecklist = async () => {
    try {
      const response = await api.post(`/project-management/boards/lists/cards/${currentCard.id}/checklists`,
        {
          title: newChecklistTitle
        },
        {
          headers: authHeader()
        }
      )
      getDetailsCard()
      handleCloseNewChecklist()
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleSubmitFormCreateChecklist = (e) => {
    e.preventDefault()
    if (!newChecklistTitle) {
      setErrors({ ...errors, newChecklistTitle: true })
      return
    }
    createChecklist()
  }

  const createTag = async () => {
    try {
      const response = await api.post(`/tags`,
        formTag,
        {
          headers: authHeader()
        }
      )
      getDetailsCard()
      getTags()
      setViewTag(0)
      setFormTag({
        title: '',
        color: '#dcdfe4'
      })
    } catch (error) {
      console.log(error.response)
    }
  }

  const updateTag = async () => {
    try {
      const response = await api.put(`/tags/${formTag.id}`,
        formTag,
        {
          headers: authHeader()
        }
      )
      getDetailsCard()
      getTags()
      setViewTag(0)
      setFormTag({
        title: '',
        color: '#dcdfe4'
      })
    } catch (error) {
      console.log(error.response)
    }
  }

  const toogleTag = async (tagId, cardId) => {
    try {
      const response = await api.put(`/project-management/boards/lists/cards/${cardId}/tag`,
        {
          tag_id: Number(tagId)
        },
        {
          headers: authHeader()
        }
      )
      await getDetailsCard()
      await getTags()
      setViewTag(0)
      setFormTag({
        title: '',
        color: '#dcdfe4'
      })
    } catch (error) {
      console.log(error.response)
    }
  }

  const deleteTag = async () => {
    try {
      const response = await api.delete(`/tags/${formTag.id}`,
        {
          headers: authHeader()
        }
      )
      getDetailsCard()
      getTags()
      setViewTag(0)
      setFormTag({
        title: '',
        color: '#dcdfe4'
      })
    } catch (error) {
      console.log(error.response)
    }
  }

  const getTags = async () => {
    try {
      const { data } = await api.get(`/tags`,
        {
          headers: authHeader()
        }
      )
      setTags(data)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('project_management.errors.load'))
    }
  }

  const handleSubmitTags = (e) => {
    e.preventDefault()
    if (!formTag.title) {
      setFormCardError({ ...formCardError, title: 'Campo obrigatorio' })
      return
    }
    if (viewTag === 1) {
      createTag()
    } else {
      updateTag()
    }
  }

  function handleChangeColor(color) {
    setFormTag({
      ...formTag,
      color: color.hex
    })
  }


  const saveItem = async (body) => {

    try {

      const result = await api.post(`project-management/boards/lists/cards/checklists/${body.checklist_id}/items`,
        {
          id: body.checklist_id,
          title: body.title,
        },
        {
          headers: authHeader()
        })
      if (result.status === 200) {
        getDetailsCard()
      }
    } catch (error) {
      console.log('Response Error -> ', error);
      // //alertActions.error(error)
    }
  }

  const updateItem = async (body) => {

    try {
      const result = await api.put(`project-management/boards/lists/cards/checklists/items/${body.id}`,
        {
          title: body.title,
          is_checked: body.checked
        },
        {
          headers: authHeader()
        })
      if (result.status === 200) {
        getDetailsCard()
      }
    } catch (error) {
      console.log('Response Error -> ', error);
      // //alertActions.error(error)
    }
  }

  const deleteItem = async (id) => {
    if (id === undefined) {
      return { error: "bad request!" }
    }
    try {
      const result = await api.delete(`project-management/boards/lists/cards/checklists/items/${id}`,
        {
          headers: authHeader()
        })
      if (result.status === 200) {
        getDetailsCard()
      }
    } catch (error) {
      console.log('Response Error -> ', error);
      // //alertActions.error(error)
    }

  }
  const deleteChecklist = async () => {
    if (!checklistToDelete) {
      console.log("/bad request!")
      return { error: "bad request!" }
    }
    try {
      const result = await api.delete(`project-management/boards/lists/cards/checklists/${checklistToDelete}`,
        {
          headers: authHeader()
        })

      if (result.status !== 200) {
        throw new Error("Erro ao deletar a checklist")
      }
      getDetailsCard()
      handleCloseConfirmDeleteChecklist()
    } catch (error) {
      console.log('Response Error -> ', error);
      // //alertActions.error(error)
    }
  }

  const saveChecklist = async (body) => {

    try {
      const result = await api.put(`project-management/boards/lists/cards/checklists/${body.id}`,
        {
          title: body.title
        },
        {
          headers: authHeader()
        })
      if (result.status === 200) {
        getDetailsCard()
      }
    } catch (error) {
      console.log('Response Error -> ', error);
      // //alertActions.error(error)
    }

  }

  // Card Dates
  const saveCardDates = async (type) => {
    try {

      setDisableDate(true)
      const payload = type === 'remove' ? {} : {
        start: dayjs(formDates.dateStart).format('YYYY-MM-DD'),
        end: dayjs(formDates.dateEnd).format(),
        done: formDates.done
      }

      const result = await api.put(`project-management/boards/lists/cards/${currentCard.id}/due_date`, payload, { headers: authHeader() })

      if (result.status === 200) {
        getDetailsCard()
        setDisableDate(false)
        handleCloseDates()
      }
    } catch (error) {
      console.log('Response Error -> ', error);
      //alertActions.error(error)
      setDisableDate(false)
    }
  }

  useEffect(() => {
    if (currentCard) {
      getDetailsCard()
    } else {
      setCardInfo(null)
    }
  }, [currentCard])

  useEffect(() => {
    getTags()
  }, [])

  function isQuillEmpty(value) {
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
      return true;
    }
    return false;
  }




  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`${style.cardDetailsModal} ${classes.modal}`}
        open={openModalDetailsCard}
        onClose={closeModalDetailsCard}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
        style={{ outline: 0 }}
      >
        <Slide direction="down" in={openModalDetailsCard} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <Box className={style.containerCardDetailsModal}>

            <button onClick={() => { closeModalDetailsCard() }} className={style.close}><IconClose /></button>

            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Box paddingY={'4px'} maxWidth={'630px'} display={'flex'} alignItems={'center'} gap={'6px'}>
                  <Cards size={18} color="#1E6B7F" weight="fill" />
                  <EasyEdit
                    type={'text'}
                    onSave={(value) => save(value, formCard.id)}
                    onCancel={cancel}
                    attributes={{ name: "title", id: formCard.id }}
                    value={formCard.title}
                    placeholder={t('project_management.kanban.placeholder_title')}
                    hideSaveButton
                    hideCancelButton
                    cancelOnBlur
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={9} display={'flex'} flexDirection={'column'} gap={'14px'}>
                <Box
                  display={'flex'}
                  gap={'10px'}
                  flexWrap={'wrap'}
                >
                  {
                    cardInfo?.all_members?.length > 0 &&
                    <Box>
                      <Box width={'100%'} display={'flex'} gap={'6px'}>
                        <Typography variant="subtitle2" className={style.titleInfo}>{t('project_management.kanban.cardDetails.members')}</Typography>
                      </Box>

                      <Box width={'100%'} display={'flex'} gap={'2px'}>
                        <AvatarGroup
                          randomBackgroundColors={['#24677E']}
                          avatars={cardInfo.all_members.map(member => member.username)}
                          initialCharacters={2}
                          max={5}
                          size={30}
                          fontSize={0.4}
                          displayAllOnHover={false}
                          shadow={2}
                          style={{
                            marginLeft: '2px'
                          }}
                          avatarStyle={{
                            margin: '1px'
                          }}
                        />
                        <IconButton onClick={() => setOpenModalMembersCard(true)}>
                          <AddOutlined fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  }

                  {
                    (cardInfo?.start && cardInfo?.end) &&
                    <Box>
                      <Box width={'100%'} display={'flex'} gap={'6px'}>
                        <Typography variant="subtitle2" className={style.titleInfo}>{t('project_management.kanban.cardDetails.dates')}</Typography>
                      </Box>
                      <Box width={'100%'} display={'flex'}>
                        <Checkbox
                          inputProps={{ 'aria-label': 'Checkbox Date' }}
                          checked={formDates.done}
                          onChange={handleChangeCheckDate}
                          color="default"
                          disableRipple
                          classes={{ root: classes.checkbox }}
                          disabled={isChecking}
                        />
                        <Button
                          ref={anchorRefDates}
                          aria-controls={openDates ? 'menu-list-grow' : undefined}
                          aria-haspopup="true"
                          onClick={handleClickDates}
                          className={style.btnDates}
                          size="small"
                        >
                          <Box display={'flex'} width={'100%'} justifyContent={'space-evenly'}>
                            <Box component={'span'} style={{ textTransform: 'initial' }} color={'#106276'} fontWeight={400} fontSize={'12px'}>
                              {`${befectiveFormatDate(cardInfo.start, i18n.language)} - ${befectiveFormatDate(cardInfo.end, i18n.language, 'T')}`}
                            </Box>
                            <ArrowDropDown style={{ fill: '#106276' }} />
                          </Box>
                        </Button>
                      </Box>
                    </Box>
                  }
                </Box>

                {
                  cardInfo?.all_tags?.length > 0 &&
                  <Box>
                    <Box width={'100%'} display={'flex'} gap={'6px'}>
                      <Typography variant="subtitle2" className={style.titleInfo}>{t('project_management.kanban.cardDetails.tags')}</Typography>
                    </Box>
                    <Box width={'100%'} display={'flex'} gap={'6px'} flexWrap={'wrap'}>
                      {cardInfo?.all_tags.map(tag => (
                        <Tooltip title={tag.title}>
                          <button className={style.buttonTag} style={{ backgroundColor: tag.color }}>
                            {tag.title.length > 60 ? tag.title.substring(0, 60) + '...' : tag.title}
                          </button>
                        </Tooltip>
                      ))
                      }
                    </Box>
                  </Box>
                }

                {
                  cardInfo?.associate &&
                  <Box>
                    <Box width={'100%'} display={'flex'} gap={'6px'}>
                      <Typography variant="subtitle2" className={style.titleInfo}>{t('project_management.kanban.cardDetails.association')}</Typography>
                    </Box>
                    <Box width={'100%'} display={'flex'} gap={'6px'} flexWrap={'wrap'} sx={{ color: '#4F7091', fontWeight: 500, fontSize: '12px' }}>
                      {`${cardInfo.associated_task.goal_title} / ${cardInfo.associated_task.kr_title} / ${cardInfo.associated_task.task_title}`}
                    </Box>
                  </Box>
                }


                <Box>
                  <Box width={'100%'} display={'flex'} gap={'6px'} marginBottom={'6px'}>
                    <List size={18} color="#1E6B7F" weight="fill" />
                    <Typography variant="subtitle2" className={style.titleInfo}>{t('project_management.kanban.cardDetails.description')}</Typography>
                  </Box>
                  {
                    !formCard?.description && !showRTE &&
                    <Box height={'34px'} borderRadius={'4px'} style={{ backgroundColor: '#f1f5f7', cursor: 'pointer' }} padding={'10px'} onClick={() => setShowRTE(true)}>
                      <Typography style={{ color: '#4F7091' }} variant="caption">{t('project_management.kanban.cardDetails.placeholder_description')}</Typography>
                    </Box>
                  }
                  {
                    showRTE
                      ?
                      <ChildrenBlur
                        onBlur={() => setShowRTE(false)}
                      >
                        <Box
                          component={'form'}
                          onSubmit={(e) => {
                            e.preventDefault()
                            updateCard()
                            setShowRTE(false)
                          }}
                        >
                          <BodyTextEditor
                            onChange={(value) => {
                              if (!isQuillEmpty(value)) {
                                setFormCard({ ...formCard, description: value })
                              } else {
                                setFormCard({ ...formCard, description: '' })
                              }
                            }}
                            valueDefault={formCard.description}
                            close={() => setShowRTE(false)}
                          />
                          <Box display={'flex'} justifyContent={'flex-end'} gap={'4px'} marginTop={'6px'}>
                            <Button type="button" variant="contained" size="small" color="error" disableElevation onClick={() => setShowRTE(false)}>
                              {t('project_management.kanban.cardDetails.cancel')}
                            </Button>
                            <Button type="submit" variant="contained" size="small" disableElevation>
                              {t('project_management.kanban.cardDetails.save')}
                            </Button>
                          </Box>
                        </Box>
                      </ChildrenBlur>
                      :
                      <Box component={'p'} style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: formCard.description }} onClick={() => setShowRTE(true)}></Box>
                  }
                </Box>

                {
                  cardInfo?.cards_attachments.length > 0 &&
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                  >
                    <Box width={'100%'} display={'flex'} gap={'6px'} marginBottom={'6px'}>
                      <Paperclip size={18} color="#1E6B7F" />
                      <Typography variant="subtitle2" className={style.titleInfo}>{t('project_management.kanban.cardDetails.attachments')}</Typography>
                    </Box>
                    {
                      cardInfo?.cards_attachments.map((attachment, index) => (
                        <Box key={index} className={style.filePreview}>
                          <Box>{attachment.original_name}</Box>
                          <Box>
                            <IconButton
                              aria-label="download"
                              color="primary"
                              onClick={() => handleDownloadAttachment(attachment.url_attachment)}
                            >
                              <GetApp fontSize='small' />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              onClick={() => { removeAttachment(attachment) }}
                              disabled={deletedAttachments}
                            >
                              <Close fontSize='small' />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                }

                <Box>
                  {/* <ListDivider /> */}
                  <DragDropContext onDragEnd={handleDragChecklist}>
                    <Droppable droppableId='checklist-1'>
                      {(provided) => (
                        //{...provided.droppableProps} >
                        <div className='list-cards' ref={provided.innerRef}>

                          {cardInfo?.cards_checklists?.length > 0 && cardInfo?.cards_checklists.map((item, idx) => (
                            <Draggable
                              key={item.id}
                              draggableId={String(item.id)}
                              index={idx}
                            >

                              {(provided, snapshot) => (
                                <KanbanCardChecklist
                                  id={item.id}
                                  title={item.title}
                                  items={item?.items || []}
                                  saveChecklist={saveChecklist}
                                  deleteChecklist={deleteChecklist}
                                  saveItem={saveItem}
                                  updateItem={updateItem}
                                  getChecklist={getDetailsCard}
                                  innerRef={provided.innerRef}
                                  provided={provided}
                                  snapshot={snapshot}
                                  user={userDetails}
                                  updateItemIndex={updateItemIndex}
                                  deleteItem={deleteItem}
                                  openConfirmDeleteChecklist={handleClickConfirmDeleteChecklist}
                                />
                              )}

                            </Draggable>

                          ))
                          }
                          {provided.placeholder}
                        </div>
                      )

                      }

                    </Droppable>
                  </DragDropContext>

                </Box>

              </Grid>

              <Grid item xs={3}>
                <Box width={'100%'} display={'flex'} gap={1} flexDirection={'column'}>
                  <Typography variant="subtitle2" style={{ color: '#1E6B7F' }}>{t('project_management.kanban.cardDetails.add_to_card')}</Typography>
                  <Button
                    variant="outlined"
                    className={style.buttonActions}
                    startIcon={<PeopleOutline />}
                    onClick={() => {
                      setOpenModalMembersCard(true)
                    }}
                    fullWidth
                  >
                    {t('project_management.kanban.cardDetails.members')}
                  </Button>
                  <Button
                    variant="outlined"
                    className={style.buttonActions}
                    startIcon={<LocalOffer />}
                    aria-describedby={idPopoverTags}
                    onClick={handleClickTags}
                    fullWidth
                  >
                    {t('project_management.kanban.cardDetails.tags')}
                  </Button>
                  <Button
                    variant="outlined"
                    className={style.buttonActions}
                    startIcon={<CheckBox />}
                    aria-describedby={idPopoverNewChecklist}
                    onClick={handleClickNewChecklist}
                    fullWidth
                  >
                    {t('project_management.kanban.cardDetails.checklist')}
                  </Button>
                  <Button
                    variant="outlined"
                    className={style.buttonActions}
                    startIcon={<EventAvailable />}
                    onClick={handleClickDates}
                    fullWidth
                  >
                    {t('project_management.kanban.cardDetails.dates')}
                  </Button>
                  <Button
                    variant="outlined"
                    className={style.buttonActions}
                    startIcon={<AttachFile />}
                    onClick={handleClickAttachments}
                    fullWidth
                  >
                    {t('project_management.kanban.cardDetails.attachments')}
                  </Button>
                </Box>
                <Box width={'100%'} display={'flex'} gap={1} flexDirection={'column'} marginTop={'18px'}>
                  <Typography variant="subtitle2" style={{ color: '#1E6B7F' }}>{t('project_management.kanban.cardDetails.actions')}</Typography>
                  {
                    !cardInfo?.associate
                      ?
                      <Button
                        variant="outlined"
                        className={style.buttonActions}
                        startIcon={<LinkSimple size={16} color="#1E6B7F" weight="bold" />}
                        onClick={handleClickAssociate}
                        fullWidth
                      >
                        {t('project_management.kanban.cardDetails.associate')}
                      </Button>
                      :
                      <Button
                        variant="outlined"
                        className={style.buttonActions}
                        startIcon={<LinkSimpleBreak size={16} color="#1E6B7F" weight="bold" />}
                        onClick={handleClickDisassociateCard}
                        fullWidth
                      >
                        {t('project_management.kanban.cardDetails.disassociate')}
                      </Button>

                  }

                  <Button
                    variant="outlined"
                    className={style.buttonActions}
                    startIcon={<Archive />}
                    onClick={handleClickArchiveCard}
                    fullWidth
                  >
                    {t('project_management.kanban.cardDetails.archive')}
                  </Button>
                </Box>
              </Grid>
            </Grid>

          </Box>
        </Slide>
      </Modal>


      {/* Start Popover create Checklist */}
      <Popover
        id={idPopoverNewChecklist}
        open={openPopoverNewChecklist}
        anchorEl={anchorElNewChecklist}
        onClose={handleCloseNewChecklist}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        elevation={1}
      >
        <Box width={'300px'} padding={'8px'}>

          <Typography variant="subtitle2" align="center" style={{ padding: '6px 0px 0px', color: '#4F7091', fontSize: '14px' }}>{t('project_management.kanban.cardDetails.create_checklist')}</Typography>

          <Box
            display="flex"
            bgcolor="#fff"
            flexDirection="column"
            zIndex={2}
            p={'10px'}
            borderRadius="8px"
            gap="18px"
            component="form"
            autoComplete='off'
            onSubmit={handleSubmitFormCreateChecklist}
          >

            <Box display="flex" flexDirection="column">
              <TextField
                id="titleChecklist"
                label={t('project_management.kanban.cardDetails.label_title')}
                variant="outlined"
                placeholder={t('project_management.kanban.cardDetails.placeholder_checklist_title')}
                size="small"
                value={newChecklistTitle}
                onChange={handleChangeTextareaChecklistTitle}
                required
              />
            </Box>
            <Box display="flex" gap={'6px'} justifyContent={'flex-end'}>
              <Button type="button" size="small" variant="contained" color="inherit" onClick={handleCloseNewChecklist} disableElevation>
                {t('project_management.kanban.cardDetails.close')}
              </Button>
              <Button type="submit" size="small" variant="contained" color="primary" disableElevation>
                {t('project_management.kanban.cardDetails.save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
      {/* End Popover create Checklist */}

      {/* Start Popover create Tags */}
      <Popover
        id={idPopoverTags}
        open={openPopoverTags}
        anchorEl={anchorElTags}
        onClose={handleCloseTags}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        elevation={1}
      >
        {
          viewTag === 0 &&
          <Box width={'300px'} padding={'8px'}>
            <Typography variant="subtitle2" align="center" style={{ padding: '6px 0px 10px', color: '#4F7091', fontSize: '14px' }}>{t('project_management.kanban.cardDetails.tags')}</Typography>
            <Box
              display="flex"
              flexDirection="column"
              zIndex={2}
              marginBottom={'8px'}
            >
              {
                tags.length > 0
                  ?
                  tags.map((tag, index) => (
                    <Box key={tag.id} display={'flex'} alignItems={'center'}>
                      <Checkbox
                        id={tag.id}
                        checked={cardInfo?.all_tags?.find(tag2 => tag2.id === tag.id)}
                        onChange={handleChangeCheckTag}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <Box display={'flex'} width={'100%'} gap={'4px'} alignItems={'center'}>
                        <Box style={{ backgroundColor: tag.color, color: '#1E6B7F', fontSize: '12px', fontWeight: 500 }} padding={'8px'} borderRadius={'4px'} width={'85%'}>
                          {tag.title}
                        </Box>
                        <Box width={'15%'}>
                          <IconButton size="small" onClick={() => {
                            setFormTag(tag)
                            setViewTag(2)
                          }}>
                            <Edit fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  ))
                  :
                  <Typography>{t('project_management.kanban.cardDetails.no_tags')}</Typography>
              }
            </Box>
            <Button fullWidth type="button" size="small" variant="contained" color="inherit" onClick={() => setViewTag(1)} disableElevation>
              {t('project_management.kanban.cardDetails.create_tag')}
            </Button>
          </Box>
        }

        {
          (viewTag === 1 || viewTag === 2) &&
          <Box width={'300px'} padding={'8px'}>

            <Typography variant="subtitle2" align="center" style={{ padding: '6px 0px 0px', color: '#4F7091', fontSize: '14px' }}>{`${viewTag === 1 ? t('project_management.kanban.cardDetails.create_tag') : t('project_management.kanban.cardDetails.edit_tag')}`}</Typography>

            <Box
              display="flex"
              bgcolor="#fff"
              flexDirection="column"
              zIndex={2}
              p={'10px'}
              borderRadius="8px"
              gap="10px"
              component="form"
              autoComplete='off'
              onSubmit={handleSubmitTags}
            >
              <Box display="flex" flexDirection="column">
                <TextField
                  id="titleTag"
                  label={t('project_management.kanban.cardDetails.label_title_tag')}
                  variant="outlined"
                  placeholder={t('project_management.kanban.cardDetails.placeholder_tag_title')}
                  size="small"
                  name="title"
                  value={formTag.title}
                  onChange={(e) => setFormTag({ ...formTag, title: e.target.value })}
                  required
                />
              </Box>

              <Box display="flex" flexDirection="column">
                <CirclePicker color={formTag.color} onChangeComplete={handleChangeColor} className="colorPickerCircle"
                  colors={[
                    '#E6E6E6', // Cinza claro
                    '#F0E68C', // Amarelo claro
                    '#98FB98', // Verde claro
                    '#ADD8E6', // Azul claro
                    '#FFB6C1', // Rosa claro
                    '#D3D3D3', // Cinza claro
                    '#FFD700', // Amarelo claro
                    '#90EE90', // Verde claro
                    '#87CEEB', // Azul claro
                    '#FFA07A', // Salmão claro
                    '#FFC0CB', // Rosa claro
                    '#FFDAB9', // Pêssego claro
                    '#D8BFD8', // Lavanda
                    '#F5DEB3', // Trigo claro
                  ]}
                />
              </Box>
              <Box display="flex" gap={'6px'} justifyContent={'flex-end'}>
                {
                  viewTag === 2 &&
                  <Button type="button" size="small" variant="contained" color="error" onClick={() => deleteTag()} disableElevation>
                    {t('project_management.kanban.cardDetails.delete')}
                  </Button>
                }
                <Button type="button" size="small" variant="contained" color="inherit" onClick={() => setViewTag(0)} disableElevation>
                  {t('project_management.kanban.cardDetails.back')}
                </Button>
                <Button type="submit" size="small" variant="contained" color="primary" disableElevation>
                  {t('project_management.kanban.cardDetails.save')}
                </Button>
              </Box>
            </Box>
          </Box>
        }
      </Popover >
      {/* End Popover create Tags */}

      {/* Start Popover add Dates */}
      <Popover
        id={idPopoverDates}
        open={openPopoverDates}
        anchorEl={anchorElDates}
        onClose={handleCloseDates}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        elevation={1}
      >
        <Box
          width={'300px'}
          padding={'8px'}
        >
          <Typography variant="subtitle2" align="center" style={{ padding: '6px 0px 10px', color: '#4F7091', fontSize: '14px' }}>{t('project_management.kanban.cardDetails.dates')}</Typography>
          <Box
            display="flex"
            flexDirection="column"
            zIndex={2}
            marginBottom={'8px'}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label={t('project_management.kanban.cardDetails.date_start')}
                  value={formDates.dateStart || dayjs(moment().format('YYYY-MM-DD'))}
                  onChange={(newValue) => setFormDates({ ...formDates, dateStart: dayjs(newValue) })}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat={befectiveMaskFormatDateMUI(i18n.language)}
                />
                <DateTimePicker
                  ampm={false}
                  label={t('project_management.kanban.cardDetails.date_end')}
                  value={formDates.dateEnd}
                  onChange={(newValue) => setFormDates({ ...formDates, dateEnd: dayjs(newValue) })}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat={befectiveMaskFormatDateMUI(i18n.language, true)}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
          <Box display={'flex'} gap={'6px'} flexDirection={'column'}>
            <Button fullWidth type="button" disabled={disableDate} onClick={() => saveCardDates('create')} size="small" variant="contained" color="primary" disableElevation>
              {t('project_management.kanban.cardDetails.save')}
            </Button>
            <Button fullWidth type="button" disabled={disableDate} onClick={() => saveCardDates('remove')} size="small" variant="contained" color="inherit" disableElevation>
              {t('project_management.kanban.cardDetails.delete')}
            </Button>
          </Box>
        </Box>
      </Popover >
      {/* End Popover add Date */}

      {/* Start Popover Associate */}
      <Popover
        id={idPopoverAssociate}
        open={openPopoverAssociate}
        anchorEl={anchorElAssociate}
        onClose={handleCloseAssociate}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        elevation={1}
        disableEnforceFocus
      >
        <Box
          width={'300px'}
          padding={'8px'}
        >
          <Typography variant="subtitle2" align="center" style={{ padding: '6px 0px 10px', color: '#4F7091', fontSize: '14px' }}>{t('project_management.kanban.cardDetails.associate_card')}</Typography>
          {
            cardInfo &&
            <Box
              display="flex"
              flexDirection="column"
              zIndex={2}
              marginBottom={'8px'}
              gap={'8px'}
            >
              <Box display={'flex'} flexDirection={'column'}>
                <Typography variant="caption" component={'span'}>{t('project_management.kanban.cardDetails.goal')}</Typography>
                <FormControl>
                  <Select
                    id="goals"
                    value={goalSelected}
                    onChange={handleChangeGoalSeleted}
                    variant="outlined"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">
                      <em>{t('project_management.kanban.cardDetails.select_option')}</em>
                    </MenuItem>
                    {
                      structureGoals?.map((goal, index) => <MenuItem key={goal.id} value={goal.id}>{goal.title}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Typography variant="caption" component={'span'}>{t('project_management.kanban.cardDetails.keyresult')}</Typography>
                <FormControl>
                  <Select
                    id="krs"
                    value={krSelected}
                    onChange={handleChangeKrSeleted}
                    variant="outlined"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">
                      <em>{t('project_management.kanban.cardDetails.select_option')}</em>
                    </MenuItem>
                    {
                      krs?.map((kr, index) => <MenuItem key={kr.id} value={kr.id}>{kr.title}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                <Typography variant="caption" component={'span'}>{t('project_management.kanban.cardDetails.task')}</Typography>
                <FormControl>
                  <Select
                    id="task"
                    value={taskSelected}
                    onChange={(e) => setTaskSelected(e.target.value)}
                    variant="outlined"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">
                      <em>{t('project_management.kanban.cardDetails.select_option')}</em>
                    </MenuItem>
                    {
                      tasks?.map((task, index) => <MenuItem key={task.id} value={task.id}>{task.title}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Box>
            </Box>
          }
          <Box display={'flex'} gap={'6px'} flexDirection={'column'}>
            <Button fullWidth type="button" disabled={disableAssociate} onClick={associateCard} size="small" variant="contained" color="primary" disableElevation>
              {t('project_management.kanban.cardDetails.save')}
            </Button>
            <Button fullWidth type="button" disabled={disableAssociate} onClick={handleCloseAssociate} size="small" variant="contained" color="inherit" disableElevation>
              {t('project_management.kanban.cardDetails.close')}
            </Button>
          </Box>
        </Box>
      </Popover >
      {/* End Popover add Date */}

      {/* Start Modal Members Card */}
      {
        openModalMembersCard &&
        <MembersCardModal
          openModalMembers={openModalMembersCard}
          setOpenModalMembers={setOpenModalMembersCard}
          colabsOptions={colabsOptions}
          alertActions={alertActions}
          updateInfo={getDetailsCard}
          currentCard={currentCard}
          userDetails={userDetails}
          members={cardInfo.all_members}
        />
      }
      {/* End Modal Members Card */}

      {/* Start Popover Attachments */}
      <Popover
        id={idPopoverAttachments}
        open={openPopoverAttachments}
        anchorEl={anchorElAttachments}
        onClose={handleCloseAttachments}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        elevation={1}
      >
        <Box
          width={'400px'}
          padding={'8px'}
        >
          <Typography variant="subtitle2" align="center" style={{ padding: '6px 0px 10px', color: '#4F7091', fontSize: '14px' }}>{t('project_management.kanban.cardDetails.attachments')}</Typography>
          <Box
            display="flex"
            flexDirection="column"
            zIndex={2}
            marginBottom={'8px'}
          >
            {/* <label htmlFor="destinations">{t('messages.modal_new_message.attachments')}</label> */}
            <div className={style.boxInputFile} style={{
              fontFamily: 'sans-serif',
              textAlign: 'left',
              display: 'flex',
            }} >
              <label className={style.customFileUpload}>
                <input
                  type="file"
                  multiple
                  onChange={onChangeInputAttachments}
                  accept=".pdf, image/*"
                />
                <AttachFile fontSize='small' />
                <div>
                  {t('messages.modal_new_message.attachments_placeholder')}
                </div>
              </label>

              <p className={style.attachmentInfo}>
                {t('messages.modal_new_message.attach_info')}*
              </p>

              {attachmentsError &&
                <div className="error">{attachmentsError}</div>
              }
              {
                cardInfo?.cards_attachments.length > 0 && cardInfo?.cards_attachments.map((attachment, index) => (
                  <div key={index} className={style.filePreview}>
                    <div>{attachment.original_name}</div>
                    <div>
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() => { removeAttachment(attachment) }}
                        disabled={deletedAttachments}
                      >
                        <Close fontSize='small' />
                      </IconButton>
                    </div>
                  </div>
                ))
              }
              {attachments.length > 0 && attachments.map((attachment, index) => (
                <div key={index} className={style.filePreview}>
                  <div>{attachment.name}</div>
                  <div>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => { removeAttachment(attachment) }}
                      disabled={deletedAttachments}
                    >
                      <Close fontSize='small' />
                    </IconButton>
                  </div>
                </div>
              ))
              }
            </div>
          </Box>
          <Box display={'flex'} gap={'6px'} flexDirection={'column'}>
            <Button fullWidth type="button" disabled={attachments.length === 0 || sendAttachments} onClick={saveCardAttachments} size="small" variant="contained" color="primary" disableElevation>
              {t('project_management.kanban.cardDetails.save')}
            </Button>
            <Button fullWidth onClick={handleCloseAttachments} type="button" size="small" variant="contained" color="inherit" disableElevation>
              {t('project_management.kanban.cardDetails.close')}
            </Button>
          </Box>
        </Box>
      </Popover >
      {/* End Popover Attachments */}

      {/* Start Popover Archive Card */}
      <Popover
        id={idPopoverArchivedCard}
        open={openArchivedCard}
        anchorEl={anchorElArchivedCard}
        onClose={handleCloseArchivedCardPopUp}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableEnforceFocus
        elevation={0}
      >
        <Box
          sx={{
            backgroundColor: '#FFE0DF',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}
        >
          <Typography variant="subtitle2" style={{ color: '#FC6662', fontSize: '12px', fontWeight: '600' }}>{t('project_management.boards.title_dialog_archive_card')}</Typography>
          <Box display={'flex'} style={{ gap: '6px' }} justifyContent={'flex-end'}>
            <Button
              onClick={handleCloseArchivedCardPopUp}
              disabled={archiving}
              style={{
                width: '42px !important',
                height: '25px',
                backgroundColor: '#fff',
                color: '#FC6662',
                fontSize: '10px',
                textTransform: 'capitalize'
              }}
            >
              {t('confirmDialog.no')}
            </Button>
            <Button
              onClick={handleArchiveCard}
              disabled={archiving}
              style={{
                width: '42px !important',
                height: '25px',
                backgroundColor: '#FC6662',
                color: '#fff',
                fontSize: '10px',
                textTransform: 'capitalize'
              }}
            >
              {t('confirmDialog.yes')}
            </Button>
          </Box>
        </Box>
      </Popover>
      {/* End Popover Archive Card */}

      {/* Start Popover Disassociete Card */}
      <Popover
        id={idPopoverDisassociateCard}
        open={openDisassociateCard}
        anchorEl={anchorElDisassociateCard}
        onClose={handleCloseDisassociateCardPopUp}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableEnforceFocus
        elevation={0}
      >
        <Box
          sx={{
            backgroundColor: '#FFE0DF',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}
        >
          <Typography variant="subtitle2" style={{ color: '#FC6662', fontSize: '12px', fontWeight: '600' }}>{t('project_management.boards.title_dialog_disassociate_card')}</Typography>
          <Box display={'flex'} style={{ gap: '6px' }} justifyContent={'flex-end'}>
            <Button
              onClick={handleCloseDisassociateCardPopUp}
              disabled={disassociating}
              style={{
                width: '42px !important',
                height: '25px',
                backgroundColor: '#fff',
                color: '#FC6662',
                fontSize: '10px',
                textTransform: 'capitalize'
              }}
            >
              {t('confirmDialog.no')}
            </Button>
            <Button
              onClick={disassociateCard}
              disabled={disassociating}
              style={{
                width: '42px !important',
                height: '25px',
                backgroundColor: '#FC6662',
                color: '#fff',
                fontSize: '10px',
                textTransform: 'capitalize'
              }}
            >
              {t('confirmDialog.yes')}
            </Button>
          </Box>
        </Box>
      </Popover>
      {/* End Popover Disassociete Card */}

      <PopoverConfirm
        idConfirm={idConfirmDeleteChecklist}
        openConfirm={openConfirmDeleteChecklist}
        anchorEl={anchorElConfirmDeleteChecklist}
        handleClose={handleCloseConfirmDeleteChecklist}
        handleConfirm={deleteChecklist}
        title={t('project_management.boards.title_dialog_delete_checklist')}
      />

    </>

  )

};

function mapStateToProps(state) {

  return {
    share: state.share
  }
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardDetailsModal));
