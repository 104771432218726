import React from "react";

export default function IconModulePerformanceAssessment({ active }) {
  const bgColor = active ? "#fdbe2b" : "#dbf5f2";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
    >
      <g id="produtividade" transform="translate(-274 -379)">
        <rect
          id="Retângulo_1572"
          data-name="Retângulo 1572"
          width="38"
          height="38"
          rx="4"
          transform="translate(274 379)"
          fill={bgColor}
        />
        <g id="Layer_2" data-name="Layer 2" transform="translate(284 389)">
          <g id="invisible_box" data-name="invisible box">
            <rect
              id="Retângulo_1731"
              data-name="Retângulo 1731"
              width="18"
              height="18"
              fill="none"
            />
          </g>
          <g id="icons_Q2" data-name="icons Q2" transform="translate(-2.5 0)">
            <circle
              id="Elipse_157"
              data-name="Elipse 157"
              cx="3.5"
              cy="3.5"
              r="3.5"
              transform="translate(8.501 2.515)"
              fill="#24677e"
            />
            <path
              id="Caminho_781"
              data-name="Caminho 781"
              d="M24,9.985a8,8,0,1,0-12.5,6.6v6.35a1.05,1.05,0,0,0,1,1.05.75.75,0,0,0,.55-.2l2.95-2.3,2.95,2.3a.75.75,0,0,0,.55.2,1.05,1.05,0,0,0,1-1.05v-6.35a8,8,0,0,0,3.5-6.6Zm-14,0a6,6,0,1,1,6,6A6,6,0,0,1,10,9.985Zm8.5,10.9-2.2-1.7a.45.45,0,0,0-.6,0l-2.2,1.7v-3.3a8.012,8.012,0,0,0,5,0Z"
              transform="translate(-4 -4)"
              fill="#24677e"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
