import React, { useState } from 'react'
import style from './Dashboard.module.scss';
import { AvatarGroup, Box, Button, CardActionArea, CardActions, Grid, Menu, MenuItem, Popover, Tooltip } from '@mui/material';
import { authHeader, befectiveFormatDate, history, limitedText } from '../../_helpers';
import Avatar from '@mui/material/Avatar';
import { ExpandMore, Favorite, MoreVert, PeopleOutline, Share, Tune } from '@material-ui/icons';
import { Link, NavLink } from 'react-router-dom/cjs/react-router-dom';
import ModalEditBoard from './ModalEditBoard';
import { useEffect } from 'react';
import { api } from '../../_helpers/api';
import { useTranslation } from 'react-i18next';
import { Clock, Heart, HeartStraight, Kanban, Star, UsersThree } from 'phosphor-react';
import { IconButton, Typography } from '@material-ui/core';
import MembersWorkspaceModal from './MembersWorkspaceModal';
import ModalCreateBoard from './ModalCreateBoard';


export default function Dashboard({
  data,
  userDetails,
  alertActions,
  colabs,
  getData,
  workspaces = [],
  setOpenModalCreateBoard,
  openModalCreateBoard,
  setOpenModalCreateWorkspace
}) {
  const { t, i18n } = useTranslation('common');

  const [currentBoard, setCurrentBoard] = useState(null)
  const [currentWorkspace, setCurrentWorkspace] = useState(null)
  const [openModalEditBoard, setOpenModalEditBoard] = useState(false)
  const [openModalMembers, setOpenModalMembers] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleting, setDeleting] = useState(false)
  const [anchorElDeleteBoard, setAnchorElDeleteBoard] = useState(null);
  const handleClickDeleteBoard = (event) => {
    setAnchorElDeleteBoard(event.currentTarget);
  };
  const handleCloseDeleteBoard = () => {
    setAnchorElDeleteBoard(null);
  };

  const open = Boolean(anchorEl);
  const openDeleteBoard = Boolean(anchorElDeleteBoard);
  const id = openDeleteBoard ? 'popover-delete-board' : undefined;

  const handleClick = (event, board) => {
    setCurrentBoard(board)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentBoard(null)
  };

  const handleFavoriteBoard = async (board_id) => {
    try {
      const result = await api.post(`/project-management/boards/favorite`,
        {
          mu_id: userDetails.id,
          board_id: board_id
        },
        {
          headers: authHeader()
        }
      )
      getData(false)
    } catch (error) {
      setDeleting(false)
    }
  }

  const handleDeleteBoard = async () => {
    try {
      setDeleting(true)
      const result = await api.delete(`/project-management/boards/${currentBoard?.id}`,
        {
          headers: authHeader()
        }
      )
      handleCloseDeleteBoard()
      handleClose()
      getData()
      alertActions.success(t('project_management.success.deleted_board'))
      setDeleting(false)
    } catch (error) {
      //alertActions.error(t('project_management.errors.deleted_board'))
      setDeleting(false)
    }
  }

  const handleArchiveBoard = async () => {
    try {
      setDeleting(true)
      const result = await api.put(`/project-management/boards/${currentBoard?.id}`,
        {
          ...currentBoard,
          archive: true
        },
        {
          headers: authHeader()
        }
      )
      handleCloseDeleteBoard()
      handleClose()
      getData()
      alertActions.success(t('project_management.success.unarchived_board'))
      setDeleting(false)
    } catch (error) {
      //alertActions.error(t('project_management.errors.unarchived_board'))
      setDeleting(false)
    }
  }

  useEffect(() => {
    if (openModalEditBoard === false) {
      handleClose()
    }
  }, [openModalEditBoard])

  return (
    <Box display={`flex`} flexDirection={'column'} gap={'34px'}>
      {
        data.my_workspaces.length === 0 && data.favorite_boards.length === 0 && data.recently_viewed_boards.length === 0 && data.workspaces_invited.length === 0 && (
          <div className={"boxNone"}>
            <p className={"textNone"}>
              {t('project_management.no_workspaces')} <span className={"linkCreateWorkspace"} onClick={() => setOpenModalCreateWorkspace(true)}>{t('project_management.create_workspace')}</span>
            </p>
          </div>
        )
      }
      {/* Section Favorites Boards */}
      {
        data.favorite_boards.length > 0 &&
        <Box className="container">
          <Box display={'flex'} alignItems={'center'} marginBottom={'14px'} gap={'4px'}>
            <Star size={24} color="#1E6B7F" />
            <Typography variant='h6' style={{ color: '#1E6B7F' }}>{t('project_management.workspaces.favorite_boards')}</Typography>
          </Box>
          <Grid container spacing={2}>
            {
              data.favorite_boards.map((board, index) =>
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2} position={'relative'} height={'120px'}>
                  <Link
                    className={style.linkBoard}
                    to={`/project_management/home/boards/${board.id}`}
                  >
                    <Box
                      style={{ borderRadius: '8px', backgroundColor: !board.color || board.color === '#ffffff' ? '#ffffff' : board.color, height: '100%' }}
                      className={style.boardCard}
                    >
                      <Box style={{ padding: '10px 30px 0px 10px' }} zIndex={1}>
                        {
                          board.title.length > 40
                            ?
                            <Tooltip title={board.title} placement="bottom" style={{ maxWidth: '300px' }}
                              PopperProps={{
                                modifiers: [
                                  {
                                    name: "offset",
                                    options: {
                                      offset: [0, -10],
                                    },
                                  },
                                ],
                              }}
                            >
                              <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F', lineHeight: '18px' }}>{limitedText(board.title, 40)}</Typography>
                            </Tooltip>
                            :
                            <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F', lineHeight: '18px' }}>{board.title}</Typography>
                        }
                      </Box>
                      <Box display={'flex'} justifyContent={'space-between'} padding={'0px 10px'} zIndex={1}>
                        {
                          board.workspace_title.length > 30
                            ?
                            <Tooltip title={board.workspace_title} placement="bottom" style={{ maxWidth: '300px' }}
                              PopperProps={{
                                modifiers: [
                                  {
                                    name: "offset",
                                    options: {
                                      offset: [0, -10],
                                    },
                                  },
                                ],
                              }}
                            >
                              <Typography variant='subtitle2' style={{ fontSize: '13px', lineHeight: '18px', fontWeight: 400, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F' }}>{limitedText(board.workspace_title, 30)}</Typography>
                            </Tooltip>
                            :
                            <Typography variant='subtitle2' style={{ fontSize: '13px', lineHeight: '18px', fontWeight: 400, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F' }}>{board.workspace_title}</Typography>
                        }
                      </Box>
                      <Box display={'flex'} justifyContent={'space-between'} padding={'0px 30px 10px 10px'} zIndex={1}>
                        <Box>
                          <Typography variant='subtitle2' style={{ fontSize: '11px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F'}}>
                            {befectiveFormatDate(board.date_start, i18n.language) + " - " + befectiveFormatDate(board.date_end, i18n.language)}&nbsp;&nbsp;{`(${board.cards_stats?.total_done}/${board.cards_stats?.total_cards})`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {board.color !== '#ffffff' && <Box className={style.boxBright}></Box>}
                  </Link>
                  
                  <Box position={'absolute'} bottom={'4px'} right={'4px'} zIndex={2}>
                    <IconButton aria-label="settings" size='small' onClick={() => handleFavoriteBoard(board.id)}>
                      <Star size={20} color="#FDBE2B" weight="fill" />
                    </IconButton>
                  </Box>
                </Grid>
              )
            }
          </Grid>
        </Box>
      }

      {/* Section Recent Boards */}
      {
        data.recently_viewed_boards.filter(r => r.is_favorite === false).length > 0 &&
        <Box className="container">
          <Box display={'flex'} alignItems={'center'} marginBottom={'14px'} gap={'4px'}>
            <Clock size={24} color="#1E6B7F" />
            <Typography variant='h6' style={{ color: '#1E6B7F' }}>{t('project_management.workspaces.recent_boards')}</Typography>
          </Box>
          <Grid container spacing={2}>
            {
              data.recently_viewed_boards.filter(r => r.is_favorite === false).map((board, index) =>
                <Grid className={style.gridBoard} key={index} item xs={12} sm={6} md={4} lg={3} xl={2} position={'relative'} height={'100px'}>
                  <Link
                    className={style.linkBoard}
                    to={`/project_management/home/boards/${board.id}`}
                  >
                    <Box
                      style={{ borderRadius: '8px', backgroundColor: !board.color || board.color === '#ffffff' ? '#ffffff' : board.color, height: '100%' }}
                      className={style.boardCard}
                    >
                      <Box style={{ padding: '10px 30px 0px 10px' }} zIndex={1}>
                        {
                          board.title.length > 40
                            ?
                            <Tooltip title={board.title} placement="bottom" style={{ maxWidth: '300px' }}
                              PopperProps={{
                                modifiers: [
                                  {
                                    name: "offset",
                                    options: {
                                      offset: [0, -10],
                                    },
                                  },
                                ],
                              }}
                            >
                              <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F', lineHeight:'18px'}}>{limitedText(board.title, 40)}</Typography>
                            </Tooltip>
                            :
                            <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F', lineHeight: '18px' }}>{board.title}</Typography>
                        }
                      </Box>
                      <Box display={'flex'} justifyContent={'space-between'} padding={'0px 30px 10px 10px'} zIndex={1}>
                        <Box>
                          <Typography variant='subtitle2' style={{ fontSize: '11px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F'}}>
                            {befectiveFormatDate(board.date_start, i18n.language) + " - " + befectiveFormatDate(board.date_end, i18n.language)}&nbsp;&nbsp;{`(${board.cards_stats?.total_done}/${board.cards_stats?.total_cards})`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {board.color !== '#ffffff' && <Box className={style.boxBright}></Box>}
                  </Link>
                  <Box position={'absolute'} bottom={'4px'} right={'4px'} className={style.favorite} zIndex={2}>
                    <IconButton aria-label="settings" size='small' onClick={() => handleFavoriteBoard(board.id)}>
                      {
                        board.is_favorite
                          ?
                          <Star size={20} color="#FDBE2B" weight="fill" />
                          :
                          <Star size={20} color={board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F'} />
                      }
                    </IconButton>
                  </Box>
                </Grid>
              )
            }
          </Grid>
        </Box>
      }


      {/* Section My Workspaces */}
      {
        data.my_workspaces.length > 0 &&
        <Box className="container">
          <Box display={'flex'} alignItems={'center'} marginBottom={'14px'} gap={'4px'}>
            <UsersThree size={24} color="#1E6B7F" />
            <Typography variant='h6' style={{ color: '#1E6B7F' }}>{t('project_management.workspaces.my_workspaces')}</Typography>
          </Box>

          <Box display={"flex"} flexDirection={"column"} gap={'20px'}>
            {
              data.my_workspaces.map((workspace, index) => (
                <Box>
                  <Box component={'header'} marginBottom={'10px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                    <Typography variant='h6' style={{ fontSize: '14px', marginLeft: '4px', color: '#1E6B7F' }}>{workspace.title}</Typography>
                    <Box className={style.boxActionsWorkspace}>
                      <Button
                        variant="outlined"
                        size="small"
                        className={style.buttonActions}
                        startIcon={<Kanban size={18} color="#1E6B7F" />}
                        onClick={() => history.push(`/project_management/home/workspace/${workspace.id}`)}
                      >
                        {t('project_management.workspaces.boards') + ` (${workspace.boards.length})`}
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={style.buttonActions}
                        startIcon={<PeopleOutline />}
                        onClick={() => {
                          setCurrentWorkspace(workspace)
                          setOpenModalMembers(true)
                        }}
                      >
                        {t('project_management.workspaces.members') + ` (${workspace.total_members})`}
                      </Button>
                    </Box>
                  </Box>
                  {
                    workspace.boards.length > 0
                      ?
                      <Grid container spacing={2}>
                        {
                          workspace.boards.map((board, index) => (
                            <Grid className={style.gridBoard} key={index} item xs={12} sm={6} md={4} lg={3} xl={2} position={'relative'} height={'100px'}>
                              <Link
                                className={style.linkBoard}
                                to={`/project_management/home/boards/${board.id}`}
                              >
                                <Box
                                  style={{ borderRadius: '8px', backgroundColor: !board.color || board.color === '#ffffff' ? '#ffffff' : board.color, height: '100%' }}
                                  className={style.boardCard}
                                >
                                  <Box style={{ padding: '10px 30px 0px 10px' }} zIndex={1}>
                                    {
                                      board.title.length > 40
                                        ?
                                        <Tooltip title={board.title} placement="bottom" style={{ maxWidth: '300px' }}
                                          PopperProps={{
                                            modifiers: [
                                              {
                                                name: "offset",
                                                options: {
                                                  offset: [0, -10],
                                                },
                                              },
                                            ],
                                          }}
                                        >
                                          <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F', lineHeight:'18px' }} >{limitedText(board.title, 40)}</Typography>
                                        </Tooltip>
                                        :
                                        <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F', lineHeight: '18px' }}>{board.title}</Typography>
                                    }
                                  </Box>
                                  <Box display={'flex'} justifyContent={'space-between'} padding={'0px 30px 10px 10px'} zIndex={1}>
                                    <Box>
                                      <Typography variant='subtitle2' style={{ fontSize: '11px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F'}}>
                                        {befectiveFormatDate(board.date_start, i18n.language) + " - " + befectiveFormatDate(board.date_end, i18n.language)}&nbsp;&nbsp;{`(${board.cards_stats?.total_done}/${board.cards_stats?.total_cards})`}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                {board.color !== '#ffffff' && <Box className={style.boxBright}></Box>}
                              </Link>
                              <Box position={'absolute'} top={'20px'} right={'4px'} zIndex={2}>
                                <IconButton aria-label="settings" size='small' onClick={(event) => handleClick(event, board)}>
                                  <MoreVert fontSize='8px' style={{ fill: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F' }} />
                                </IconButton>
                              </Box>
                              <Box className={`${!board.is_favorite && style.favorite}`} position={'absolute'} bottom={'4px'} right={'4px'} zIndex={2}>
                                <IconButton aria-label="settings" size='small' onClick={() => handleFavoriteBoard(board.id)}>
                                  {
                                    board.is_favorite
                                      ?
                                      <Star size={20} color="#FDBE2B" weight="fill" />
                                      :
                                      <Star size={20} color={board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F'} />
                                  }
                                </IconButton>
                              </Box>
                            </Grid>
                          ))
                        }
                        <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2} position={'relative'} height={'100px'}>
                          <Box
                            style={{ borderRadius: '8px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            className={style.cardNew}
                            elevation={0}
                            onClick={() => {
                              setCurrentWorkspace(workspace)
                              setOpenModalCreateBoard(true)
                            }}
                          >
                            <Box style={{ padding: '8px 12px' }}>
                              <Typography variant='subtitle2' style={{ fontSize: '12px', fontWeight: 400, color: '#1E6B7F' }}>{t('project_management.workspaces.new_board')}</Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      :
                      <Grid container spacing={2}>
                        <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2} position={'relative'} height={'100px'}>
                          <Box
                            style={{ borderRadius: '8px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            className={style.cardNew}
                            elevation={0}
                            onClick={() => {
                              setCurrentWorkspace(workspace)
                              setOpenModalCreateBoard(true)
                            }}
                          >
                            <Box style={{ padding: '8px 12px' }}>
                              <Typography variant='subtitle2' style={{ fontSize: '12px', fontWeight: 400, color: '#1E6B7F' }}>{t('project_management.workspaces.new_board')}</Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                  }
                </Box>
              ))
            }

            <Popover
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              disableEnforceFocus
            >
              <Box className={style.menuOptionsBoard}>
                <button className={style.menuOptionsBoardButton} onClick={() => setOpenModalEditBoard(true)}>{t('project_management.btn_edit')}</button>
                <button className={style.menuOptionsBoardButton} aria-describedby={id} onClick={handleClickDeleteBoard}>{t('project_management.btn_archive')}</button>
              </Box>
            </Popover>

            <Popover
              id={id}
              open={openDeleteBoard}
              anchorEl={anchorElDeleteBoard}
              onClose={handleCloseDeleteBoard}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              disableEnforceFocus
            >
              <Box
                sx={{
                  backgroundColor: '#FFE0DF'
                }}
              >
                <Typography variant="subtitle2" style={{ padding: '12px', color: '#FC6662', fontSize: '12px', fontWeight: '600' }}>{t('project_management.boards.title_dialog_archive')}</Typography>
                <Box paddingBottom={2} paddingRight={2} display={'flex'} style={{ gap: '6px' }} justifyContent={'flex-end'}>
                  <Button
                    onClick={handleCloseDeleteBoard}
                    disabled={deleting}
                    style={{
                      width: '42px !important',
                      height: '25px',
                      backgroundColor: '#fff',
                      color: '#FC6662',
                      fontSize: '10px',
                      textTransform: 'capitalize'
                    }}
                  >
                    {t('confirmDialog.no')}
                  </Button>
                  <Button
                    onClick={handleArchiveBoard}
                    disabled={deleting}
                    style={{
                      width: '42px !important',
                      height: '25px',
                      backgroundColor: '#FC6662',
                      color: '#fff',
                      fontSize: '10px',
                      textTransform: 'capitalize'
                    }}
                  >
                    {t('confirmDialog.yes')}
                  </Button>
                </Box>
              </Box>
            </Popover>

            {
              currentWorkspace &&
              < ModalCreateBoard
                openModalCreateBoard={openModalCreateBoard}
                setOpenModalCreateBoard={setOpenModalCreateBoard}
                userDetails={userDetails}
                colabsOptions={colabs}
                alertActions={alertActions}
                updateInfo={getData}
                workspaces={workspaces}
                currentWorkspace={currentWorkspace}
                setCurrentWorkspace={setCurrentWorkspace}
              />
            }

            <ModalEditBoard
              openModalEditBoard={openModalEditBoard}
              setOpenModalEditBoard={setOpenModalEditBoard}
              userDetails={userDetails}
              colabsOptions={colabs}
              alertActions={alertActions}
              updateInfo={getData}
              currentBoard={currentBoard}
              closeMenu={handleClose}
              workspaces={workspaces}
            />


            <MembersWorkspaceModal
              openModalMembers={openModalMembers}
              setOpenModalMembers={setOpenModalMembers}
              userDetails={userDetails}
              colabsOptions={colabs}
              alertActions={alertActions}
              updateInfo={getData}
              currentWorkspace={currentWorkspace}
            />

          </Box>
        </Box>
      }

      {/* Section Workspaces Invited*/}
      {
        data.workspaces_invited.length > 0 &&
        <Box className="container">
          <Box display={'flex'} alignItems={'center'} marginBottom={'14px'} gap={'4px'}>
            <UsersThree size={24} color="#1E6B7F" />
            <Typography variant='h6' style={{ color: '#1E6B7F' }}>{t('project_management.workspaces.envited_boards')}</Typography>
          </Box>

          <Box display={"flex"} flexDirection={"column"} gap={'20px'}>
            {
              data.workspaces_invited.map((workspace, index) => (
                <Box>
                  <Box component={'header'} marginBottom={'10px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                    <Typography variant='h6' style={{ fontSize: '14px', marginLeft: '4px', color: '#1E6B7F' }}>{workspace.title}</Typography>
                  </Box>
                  {
                    workspace.boards.length > 0
                    &&
                    <Grid container spacing={2}>
                      {
                        workspace.boards.map((board, index) => (
                          <Grid className={style.gridBoard} key={index} item xs={12} sm={6} md={4} lg={3} xl={2} position={'relative'} height={'100px'}>
                            <Link
                              className={style.linkBoard}
                              to={`/project_management/home/boards/${board.id}`}
                            >
                              <Box
                                style={{ borderRadius: '8px', backgroundColor: !board.color || board.color === '#ffffff' ? '#ffffff' : board.color, height: '100%' }}
                                className={style.boardCard}
                              >
                                <Box style={{ padding: '10px 30px 0px 10px' }} zIndex={1}>
                                  {
                                    board.title.length > 40
                                      ?
                                      <Tooltip title={board.title} placement="bottom" style={{ maxWidth: '300px' }}
                                        PopperProps={{
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -10],
                                              },
                                            },
                                          ],
                                        }}
                                      >
                                        <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F', lineHeight:'18px'}}>{limitedText(board.title, 40)}</Typography>
                                      </Tooltip>
                                      :
                                      <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F' }}>{board.title}</Typography>
                                  }
                                </Box>
                                <Box display={'flex'} justifyContent={'space-between'} padding={'0px 30px 10px 10px'} zIndex={1}>
                                  <Box>
                                    <Typography variant='subtitle2' style={{ fontSize: '11px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F'}}>
                                      {befectiveFormatDate(board.date_start, i18n.language) + " - " + befectiveFormatDate(board.date_end, i18n.language)}&nbsp;&nbsp;{`(${board.cards_stats?.total_done}/${board.cards_stats?.total_cards})`}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              
                              {board.color !== '#ffffff' && <Box className={style.boxBright}></Box>}
                            </Link>
                            <Box className={`${!board.is_favorite && style.favorite}`} position={'absolute'} bottom={'4px'} right={'4px'} zIndex={2}>
                              <IconButton aria-label="settings" size='small' onClick={() => handleFavoriteBoard(board.id)}>
                                {
                                  board.is_favorite
                                    ?
                                    <Star size={20} color="#FDBE2B" weight="fill" />
                                    :
                                    <Star size={20} color={board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F'} />
                                }
                              </IconButton>
                            </Box>
                          </Grid>
                        ))
                      }
                    </Grid>
                  }
                </Box>
              ))
            }
          </Box>
        </Box>
      }

    </Box>
  )
}
