import React, { useState } from "react";
import styles from "./FeedbackTodoList.module.scss";
import { Add, Close, Delete, Edit, Save } from '@material-ui/icons';
import { useTranslation } from "react-i18next";

export default function FeedbackTodoList({ title, items, setItems }) {
    const [newItem, setNewItem] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingText, setEditingText] = useState('');
    const { t, i18n } = useTranslation('common');
  
    const handleAddItem = () => {
      if (newItem.trim() !== '') {
        setItems([...items, newItem]);
        setNewItem('');
      }
    };
  
    const handleDeleteItem = (index) => {
      const updatedItems = items.filter((item, i) => i !== index);
      setItems(updatedItems);
    };
  
    const handleEditItem = (index) => {
      setEditingIndex(index);
      setEditingText(items[index]);
    };
  
    const handleSaveEdit = (index) => {
      const updatedItems = items.map((item, i) => (i === index ? editingText : item));
      setItems(updatedItems);
      setEditingIndex(null);
      setEditingText('');
    };

    

    return (
        <div className={styles.todoContainer}>
            <div className={styles.todoTitle}>
                <h3>{title || "-----"}</h3>
            </div>
            <ul className={styles.todoList}>
                {items.map((item, index) => (
                <li key={index}>
                    {editingIndex === index ? (
                    <input
                        type="text"
                        value={editingText}
                        onChange={(e) => setEditingText(e.target.value)}
                        placeholder={t('colab_page.performance_assessment.form.placeholder_item')}
                        max={200}
                        onKeyDown={(e) => e.key === 'Enter' && handleSaveEdit(index)}
                    />
                    ) : (
                    <span>- {item}</span>
                    )}
                    {editingIndex === index ? (
                        <button
                            className={styles.buttonAddOption}
                            onClick={() => handleSaveEdit(index)}
                            type='button'
                        >
                            <Save style={{color:"#187DFF"}} fontSize='small'/>
                        </button>
                    ) : (
                        <button
                            className={styles.buttonAddOption}
                            onClick={() => handleEditItem(index)}
                            type='button'
                        >
                            <Edit style={{color:"#187DFF"}} fontSize='small'/>
                        </button>
                    )}
                    <button
                        className={styles.buttonRemoveOption}
                        onClick={() => handleDeleteItem(index)}
                        type='button'
                    >
                        <Delete style={{color:"#FC6662"}} fontSize='small' />
                    </button>
                </li>
                ))}
                <li>
                    <input
                        type="text"
                        value={newItem}
                        onChange={(e) => setNewItem(e.target.value)}
                        placeholder={t('colab_page.performance_assessment.form.placeholder_item')}
                        max={200}
                        onKeyDown={(e) => e.key === 'Enter' && handleAddItem()}
                    />
                    <button
                        className={styles.buttonAddOption}
                        onClick={handleAddItem}
                        type='button'
                    >
                        <Add style={{color:"#187DFF"}} fontSize='small' />
                    </button>
                </li>
            </ul>
        </div>
      );
};
