import React from 'react';

const GaugeChart = ({ score, label, fillColors }) => {
  const getRotation = (score) => {
    const minScore = 0;
    const maxScore = 5;
    const minRotation = -90;
    const maxRotation = 90;
    return (score - minScore) * (maxRotation - minRotation) / (maxScore - minScore) + minRotation;
  };

  const rotation = getRotation(score);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="221.248" height="130.131" viewBox="0 0 221.248 130.131">
      <g id="gauge" transform="translate(-8017.082 -8969)">
        <text id="score" transform="translate(8084 9066.131)" fill="#86b2b3" fontSize="44" fontFamily="Roboto-Regular, Roboto">
          <tspan x="0" y="0">{score}</tspan>
        </text>
        <text id="label" transform="translate(8127 9095.131)" fill="#1e6b7f" fontSize="15" fontFamily="Roboto-Regular, Roboto">
          <tspan x="-68.016" y="0">{label}</tspan>
        </text>
        <g id="paths" transform="translate(8017.082 8969)">
          <path id="path1" d="M20.183,41.105h0l-4.946-3.81A112.154,112.154,0,0,1,24.647,26.83,110.212,110.212,0,0,1,59.554,3.668C62.876,2.281,66.3,1.047,69.717,0l1.44,6.08c-3.266,1.014-6.523,2.205-9.683,3.543A104.852,104.852,0,0,0,20.184,41.1Z" transform="translate(8.508 26.632)" fill={fillColors[0]} />
          <path id="path2" d="M6.236,89.694H0A110.246,110.246,0,0,1,22.785,24.719l4.948,3.811c-1.275,1.661-2.518,3.385-3.694,5.126a103.82,103.82,0,0,0-17.8,56.036Z" transform="translate(0 40.436)" fill={fillColors[1]} />
          <path id="path3" d="M142.107,42.075h0A104.646,104.646,0,0,0,99.846,9.352c-2.539-1.075-5.153-2.06-7.769-2.929l1.9-5.939c2.616.866,5.237,1.846,7.791,2.912a110.2,110.2,0,0,1,34.908,23.162,111.993,111.993,0,0,1,10.379,11.7l-4.945,3.809Z" transform="translate(51.418 26.903)" fill={fillColors[2]} />
          <path id="path4" d="M151.566,89.244h-6.235a103.671,103.671,0,0,0-8.178-38.3,104.2,104.2,0,0,0-9.625-17.733c-.877-1.3-1.8-2.6-2.746-3.873l4.948-3.811a110.484,110.484,0,0,1,12.511,21.469,109.882,109.882,0,0,1,9.324,42.249Z" transform="translate(69.682 40.886)" fill={fillColors[3]} />
        </g>
        <g id="needle" transform={`translate(8017.082 8969) rotate(${rotation}, 111.248, 95)`}>
          <g id="needle-group" transform="translate(1536.036 2518.606)" fill="#86b2b3" strokeLinecap="round" strokeLinejoin="round">
            <path d="M -1393.960815429688 -2481.745849609375 L -1394.441528320312 -2481.906982421875 C -1404.403564453125 -2485.2451171875 -1414.8232421875 -2486.9375 -1425.411254882812 -2486.9375 C -1435.401977539062 -2486.9375 -1445.261474609375 -2485.42724609375 -1454.7158203125 -2482.448486328125 L -1455.228759765625 -2482.286865234375 L -1455.352661132812 -2482.81005859375 L -1458.237426757812 -2494.993408203125 L -1458.345458984375 -2495.44970703125 L -1457.89697265625 -2495.5869140625 C -1447.436279296875 -2498.7841796875 -1436.506469726562 -2500.4052734375 -1425.411254882812 -2500.4052734375 C -1413.385864257812 -2500.4052734375 -1401.579833984375 -2498.504150390625 -1390.3212890625 -2494.7548828125 L -1389.8525390625 -2494.598876953125 L -1390.0029296875 -2494.128173828125 L -1393.806396484375 -2482.228759765625 L -1393.960815429688 -2481.745849609375 Z" stroke="none"/>
            <path d="M -1394.28271484375 -2482.381103515625 L -1390.479248046875 -2494.280517578125 C -1401.695190429688 -2498.015625 -1413.409545898438 -2499.9052734375 -1425.411254882812 -2499.9052734375 C -1436.491455078125 -2499.9052734375 -1447.327026367188 -2498.294677734375 -1457.750854492188 -2495.108642578125 L -1454.866088867188 -2482.92529296875 C -1445.565063476562 -2485.855712890625 -1435.667846679688 -2487.4375 -1425.411254882812 -2487.4375 C -1414.537109375 -2487.4375 -1404.0673828125 -2485.65966796875 -1394.28271484375 -2482.381103515625 M -1394.28271484375 -2481.381103515625 C -1394.389770507812 -2481.381103515625 -1394.4970703125 -2481.398193359375 -1394.600463867188 -2481.432861328125 C -1404.511108398438 -2484.753662109375 -1414.87744140625 -2486.4375 -1425.411254882812 -2486.4375 C -1435.350952148438 -2486.4375 -1445.159912109375 -2484.934814453125 -1454.565551757812 -2481.971435546875 C -1454.830810546875 -2481.888671875 -1455.118408203125 -2481.9189453125 -1455.359985351562 -2482.0556640625 C -1455.6015625 -2482.19287109375 -1455.775146484375 -2482.424560546875 -1455.839233398438 -2482.69482421875 L -1458.723999023438 -2494.878173828125 C -1458.845458984375 -2495.391357421875 -1458.547485351562 -2495.910888671875 -1458.043090820312 -2496.06494140625 C -1447.535034179688 -2499.27685546875 -1436.556030273438 -2500.9052734375 -1425.411254882812 -2500.9052734375 C -1413.33203125 -2500.9052734375 -1401.472900390625 -2498.99560546875 -1390.163330078125 -2495.229248046875 C -1389.643798828125 -2495.056396484375 -1389.359985351562 -2494.49755859375 -1389.526733398438 -2493.97607421875 L -1393.330200195312 -2482.07666015625 C -1393.41162109375 -2481.82177734375 -1393.591918945312 -2481.610107421875 -1393.830688476562 -2481.489013671875 C -1393.97216796875 -2481.41748046875 -1394.127075195312 -2481.381103515625 -1394.28271484375 -2481.381103515625 Z" stroke="none" fill="#f2f5fa"/>
          </g>
          <path id="arrow" d="M9.351,0,18.7,10.909H0Z" transform="translate(111.533 50.909) rotate(180)" fill="#86b2b3" />
        </g>
      </g>
    </svg>
  );
};

export default GaugeChart;
