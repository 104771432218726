import React, { useEffect, useState } from 'react';
import { Card, CardContent, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './ResultsStatsCards.module.scss';
import { ClockCounterClockwise, ShieldWarning, Smiley,  WarningCircle, XCircle } from 'phosphor-react';
import { Typography } from '@material-ui/core';

export function ResultsStatsCards({ alertActions, userDetails, stats }) {
  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
          <WarningCircle size={18} color={'#22BEAD'} weight="bold" />
          <Typography className={style.textStats} style={{color: "#22BEAD"}}>
            {stats?.answer_evaluators || "0 / 0"}
          </Typography>
          <Typography className={style.textInfo} component={'span'}>
            {t("performance_assessment.page_assessment_details.results.stats.answers_evaluated")}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
          <ClockCounterClockwise size={18} color={'#22BEAD'} weight="bold" />
          <Typography className={style.textStats} style={{color: "#22BEAD"}}>
            {stats?.score_avg || "0"}
          </Typography>
          <Typography className={style.textInfo} component={'span'}>
            {t("performance_assessment.page_assessment_details.results.stats.score_avg_team")}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
          <XCircle size={18} color={'#22BEAD'} weight="bold" />
          <Typography className={style.textStats} style={{color: "#22BEAD"}}>
            {stats?.strong_point?.name  || "----"}
          </Typography>
          <Typography className={style.textInfo} component={'span'}>
            {t("performance_assessment.page_assessment_details.results.stats.strong_point")}: {stats?.strong_point?.skill_avg || "0"}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #FC6662' }}>
          <ShieldWarning size={18} color="#FC6662" weight="bold" />
          <Typography className={style.textStats} style={{color: "#FC6662"}}>
            {stats?.weak_point?.name || "----"}
          </Typography>
          <Typography className={style.textInfo} component="span">
            {t("performance_assessment.page_assessment_details.results.stats.weak_point")}: {stats?.weak_point?.skill_avg || "0"}
          </Typography>
        </CardContent>
      </Card>
      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
          <Smiley size={18} color={'#22BEAD'} weight="bold" />
          <Typography className={style.textStats} style={{color: "#22BEAD"}}>
            {stats?.best_avg_evaluated?.username || "----"}
          </Typography>
          <Typography className={style.textInfo} component={'span'}>
            {t('performance_assessment.page_assessment_details.results.stats.best_great')}: {stats?.best_avg_evaluated?.evaluated_avg || "0"}
          </Typography>
        </CardContent>
      </Card>

    </Stack>
  );
}
