import React from 'react'
import { Backdrop, Modal, Slide, makeStyles } from '@material-ui/core';
import styles from './AssessmentAnsweredModal.module.scss';
import { useTranslation } from 'react-i18next';
import IconBee from '../_icons/IconBee';
import { history } from '../../_helpers';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export default function AssessmentAnsweredModal({
  openModal,
  closeModal,
  isManager
}) {

  const classes = useStyles();  
  const { t, i18n } = useTranslation("common");

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${styles.modal}`}
      open={openModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <div className={styles.containerModal}>
          <main>
            <IconBee/>
            <h4>{t('colab_page.performance_assessment.title_assessment_answered')}</h4>
            <p>{t('colab_page.performance_assessment.subtitle_assessment_answered')}</p>
          </main>
          <footer>
            <button onClick={() => {
              if(isManager) {
                history.push('/performance-assessment/assess')
                return
              }
                history.push('/productive/productivity/performance-assessment')
            }}>              
                {t("performance_assessment.actions.return_painel")}
            </button>
          </footer>
        </div>
      </Slide>
    </Modal>
  )
}
