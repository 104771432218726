import { Box, FormControlLabel, Grid, Paper, Switch, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { alertActions, teammatesActions, usersActions } from '../../_actions'
import Filterbar from '../../_components/_shared/Filterbar'
import Toolbar from '../../_components/_shared/Toolbar'
import { authHeader, getModuleByPath } from '../../_helpers'
import { api } from '../../_helpers/api'
import './styles.scss'
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton'
import { Divider } from '@material-ui/core'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DragIndicator } from '@material-ui/icons'
import BefectiveTooltip from '../../_components/_shared/BefectiveTooltip'

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#22BEAD' : '#22BEAD',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#22BEAD',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function WidgetsSettings(props) {

  const { t, i18n } = useTranslation('common');

  const [isLoading, setIsLoading] = useState(false)
  const { userDetails, alertActions } = props
  const pathModule = window.location.pathname.split("/")[1]
	const currentModule = getModuleByPath(pathModule)

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const [widgets, setWidgets] = useState([])

  const getWidgetsSettings = async (fetching = true) => {
    try {

      if (fetching) setIsLoading(true)

      const response = await api.get(`/stats/executive/widgets?all=true&module=${currentModule}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get widgets!!!')
      }
      
      const widgetsAllowed = currentModule === 'productivity_management' ? response.data.filter(w => userDetails.allowed_widgets.includes(w.key)) : response.data
      setWidgets(widgetsAllowed)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      alertActions.error(t('generic_messages.errors.load'))
    }

  }

  const handleChange = async (event, widget) => {
    try {
      const { name, value, checked } = event.target

      if (!widget) {
        throw new Error('No widget pass!!!')
      }

      const updatedWidgets = widgets.map(w => {
        if (w.id === widget.id) {
          return { ...w, active: checked };
        }
        return w;
      });

      setWidgets(updatedWidgets)

      const res = await api.put('/stats/executive/widgets/user', { widget_id: widget.id }, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Error to save toggle widget!!!')
      }

      await getWidgetsSettings(false)

    } catch (error) {
      console.log(error)
      alertActions.error(error)
    }
  };


  const handleDragWidget = async (result) => {
    if (!result.destination) return; // Se o item não foi solto em uma posição válida

    const widgetId = result.draggableId
    const source = result.source.index
    const destination = result.destination.index

    if (destination === source) return; // Se moveu para a mesma posição

    const newItems = Array.from(widgets);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setWidgets(newItems)

    try {
      const response = await api.patch(`/stats/executive/widgets/update-index/${widgetId}`, {
        source: source,
        destination: destination
      },
        {
          headers: authHeader()
        })
      if (response.status === 200) {
        await getWidgetsSettings(false)
      }
    } catch (error) {
      console.log(error.response)
    }

  };


  useEffect(() => {
    getWidgetsSettings()
    document.title = 'Befective | ' + t('menu.settings') + ' | ' + 'Widgets';
  }, [])

  return (
    <section className="mainContent">
      <Toolbar />
      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhenMonths={'disabled'}
        filterWhat={'disabled'}
        routeBackToPage={`/${pathModule}`}
      />
      <Paper elevation={0} sx={{ marginX: 4, marginY: 3, padding: 3 }}>
        {
          !isLoading ?
            <Grid
              container
              spacing={2}
              component={'div'}
              id='formWidgetsSettings'
            >
              <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
                {
                  widgets.length > 0
                    ?
                    <Typography component={'h6'} variant='h6'>{`${t('widgets.form.title')} ( ${widgets.filter(w => w.active).length} / ${widgets.length} )`}</Typography>

                    :
                    <Typography component={'h6'} variant='h6'>{`${t('widgets.form.title')} ( 0 / 0 )`}</Typography>
                }
                {/* <Box className='btnContainer'>
                <button className={`btnCancel`} type='button'>{t('button.cancel')}</button>
                <button className={`btnSubmit ${isSubmitting && 'disabled'}`} type='button' disabled={isSubmitting} onClick={handleSubmit}>
                  {!isSubmitting ? t('button.save') : t('button.saving')}
                </button>
              </Box> */}
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              {
                widgets.length > 0
                  ?
                  <DragDropContext onDragEnd={handleDragWidget}>
                    <Droppable droppableId="widgets">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className='listSettingsWidgets'>
                          {
                            widgets.map((widget, index) => (
                              <Draggable draggableId={String(widget.id)} index={index} key={widget.key}>
                                {(provided) => (
                                  <Grid ref={provided.innerRef} {...provided.draggableProps} item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px solid #E4EAEF'} paddingY={'10px'}>
                                    <Box display={'flex'} flexDirection={'row'} gap={'16px'} alignItems={'center'}>
                                      <div {...provided.dragHandleProps}>
                                        {
                                          userDetails.organizationId === userDetails.origin_organization &&
                                          <DragIndicator style={{ cursor: 'grab' }} />
                                        }
                                      </div>
                                      <img src={widget.url_image} alt={widget.key} width={85} />
                                      <Box maxWidth={'500px'}>
                                        <Typography variant='subtitle1' fontWeight={500} fontSize={'16px'} sx={{ color: widget.active ? '#22BEAD' : '#1E6B7F' }}>{widget[`${lang}_title`]}</Typography>
                                        <Typography variant='caption' fontSize={'12px'} sx={{ color: '#1E6B7F' }}>{widget[`${lang}_description`]}</Typography>
                                      </Box>
                                    </Box>
                                    <Box className='field'>
                                      <Typography component={'span'} variant='subtitle1' fontWeight={500} fontSize={'14px'} sx={{ color: widget.active ? '#22BEAD' : '#E9E9EA' }}>{widget.index != null ? `${widget.index + 1}º` : '-'}</Typography>
                                      <BefectiveTooltip title={<Typography variant='subtitle2'>{t('widgets.form.width')}</Typography>} arrow>
                                        <Box className="boxIndicatorSize">
                                          <Box className="indicatorSize" width={`${widget.size || 100}%`} sx={{ backgroundColor: widget.active ? '#22BEAD' : '#E9E9EA' }}>
                                            <span>{`${widget.size || 100}%`}</span>
                                          </Box>
                                        </Box>
                                      </BefectiveTooltip>
                                      <Box display={'flex'} justifyContent={'flex-end'}>
                                        <FormControlLabel
                                          sx={{ margin: 0 }}
                                          control={<IOSSwitch sx={{ m: 0 }} checked={widget.active} onChange={(e) => handleChange(e, widget)} name={widget.key} />}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                )}
                              </Draggable>
                            ))
                          }
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  :

                  <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
                    <Typography textAlign={'center'} variant='caption' fontSize={'12px'}>{t('widgets.form.not_found')}</Typography>
                  </Grid>
              }


            </Grid>
            :
            <GlobalSkeleton totalRowsStats={0} totalTables={1} heightTable={650} />
        }
      </Paper>
    </section>

  )
}

function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetsSettings)