import { Box, Container, Grid } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { alertActions, usersActions } from '../../_actions'
import style from './ProductivityPerformanceFormPage.module.scss'
import { authHeader, history } from '../../_helpers'
import { api } from '../../_helpers/api'
import AlertBox from '../../_components/_shared/AlertBox'
import SkeletonProductivityPerformanceFormPage from './SkeletonProductivityPerformanceFormPage'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import SkillForm from '../../_components/performance_assessments_colab/SkillForm'
import HeaderProductivityColab from '../../_components/_shared/HeaderProductivityColab'
import AssessmentAnsweredModal from '../../_components/performance_assessments_colab/AssessmentAnsweredModal'


function ProductivityPerformanceFormPage({ userDetails, usersActions, alertActions, filter }) {
  const { t, i18n } = useTranslation('common');
  const { id } = useParams();
  const [fetching, setFetching] = useState(true);
  const [openModalAssessmentAnswered, setOpenModalAssessmentAnswered] = useState(false)
  const [data, setData] = useState(null)
  const isManager = window.location.pathname.split("/").includes("answer-form")
  
  const [ currentTab, setCurrentTab ] = useState(0);

  async function getSkills() {
    try {

      setFetching(true)

      const response = await api.get(`/performance-assessment/user/to-answer?evaluated_id=${id}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error('Erro get data ProductivityPerformancePage!!!')
      }

      const skills = response.data.skills?.length > 0 ? [...response.data.skills, {id: 3, name: t('colab_page.performance_assessment.form.conclusion_tab'), is_conclusion: true}] : []

      setData({
        ...response.data,
        skills: skills
      })


      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  const updateOptionQuestions = (skill, question, option) => {

    const skillsAssessmentNew = data.skills.map(skill2 => {
      if(skill2.id === skill.id && !skill2.is_conclusion){
       const questions = skill2.questions.map(question2 => {
         if(question2.questions_id === question.questions_id){

           if(question2?.is_objective===false){
             return {
               ...question2,
               discursive_content: option
             }
           }
           return {
             ...question2,
             options: question2.options.map(option2 => {
               if(option2.id === option.id){
                 return {
                   ...option2,
                   selected: !option2.selected
                 }
               }
               return {
                 ...option2,
                 selected: false
               }
             })
           }
         }
         return question2
       })

       return {
         ...skill2,
         questions: questions
       }
      }
      return skill2
    })
    setData({
      ...data,
      skills: skillsAssessmentNew
    })
  }

  useEffect(() => {
    document.title = `Befective | ${t('performance_assessment.title_page')}`;
    getSkills()
  }, [])


  return (
    <Box
      bgcolor="#F2F5FA"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <AlertBox />

      <HeaderProductivityColab
        tabsAssessments={true} 
        info={data?.title &&`${data?.title} / ${data?.evaluated_username}`}
        skills={data?.skills || []}
        currentTab={currentTab}
        toBackUrl={isManager ? "/performance-assessment/assess": "/productive/productivity/performance-assessment"}
      />

      {
        !fetching
          ?
            <div className={style.containerMain}>
                <SkillForm
                    data={data}
                    alertActions={alertActions}
                    evaluatedId={id}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    skills={data.skills}
                    updateOptionQuestions={updateOptionQuestions}
                    setOpenModalAssessmentAnswered={setOpenModalAssessmentAnswered}
                    isManager={isManager}
                />
            </div>
          :

          <div className={style.containerMain}>
            <SkeletonProductivityPerformanceFormPage />
          </div>
      }

      <AssessmentAnsweredModal
        openModal={openModalAssessmentAnswered}
        closeModal={() => null}
        isManager={isManager}
        />

    </Box >
  )
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter
  }
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductivityPerformanceFormPage));