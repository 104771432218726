import React from 'react'
import { Backdrop, CircularProgress, Modal, Slide, makeStyles } from '@material-ui/core';
import styles from './SubmitEvaluationModal.module.scss';
import { useTranslation } from 'react-i18next';
import { befectiveFormatDate, history } from '../../../_helpers';
import utf8 from 'utf8';
import IconBee from '../../_icons/IconBee';
import { Check, Error } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export default function SubmitEvaluationModal({
  openModal,
  closeModal,
  formAssessment,
  lang,
  userDetails,
  isSubmitting,
  submittingFail
}) {

  const classes = useStyles();  
  const { t, i18n } = useTranslation("common");

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${styles.modal}`}
      open={openModal}
      onClose={() => null}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <div className={styles.containerModal}>
          <header>
            <IconBee/>
            <h4>{t("performance_assessment.submiting_evaluation_modal.title")}</h4>
          </header>
          {
            isSubmitting
            ?
            <div className={styles.boxSendEmails}>
              <CircularProgress size={20}/>
              <span>{t("performance_assessment.submiting_evaluation_modal.label_send_emails")}</span>
            </div>
            :
            (
              !submittingFail
              ?
              <div className={styles.boxSendEmails}>
                <Check style={{color: '#22BEAD'}} fontSize='medium'/>
                <span>{t("performance_assessment.submiting_evaluation_modal.label_success_emails")}</span>
              </div>
              :
              <div className={styles.boxSendEmails}>
                <Error style={{color: '#FC6662'}} fontSize='medium'/>
                <span>{t("performance_assessment.submiting_evaluation_modal.label_error_emails")}</span>
              </div>
            )
          }
          <div className={styles.boxInfoEvaluation}>
            <div>
              {t("performance_assessment.submiting_evaluation_modal.start")}: <span>{befectiveFormatDate(formAssessment?.date_start, lang)}</span>
            </div>
            <div>
              {t("performance_assessment.submiting_evaluation_modal.end")}: <span>{befectiveFormatDate(formAssessment?.date_end, lang)}</span>
            </div>
            <div>
              {t("performance_assessment.submiting_evaluation_modal.send_by")}: <span>{utf8.decode(userDetails?.name)}</span>
            </div>
          </div>
          <footer>
            <button disabled={isSubmitting} onClick={() => {
              if(!submittingFail){
                history.push('/performance-assessment/created')
                return
              }
              closeModal()
            }}>
              {
                isSubmitting
                ?
                  <div className={styles.boxLoading}>
                    <CircularProgress size={16}/>
                    <span>{t("performance_assessment.actions.wait")}</span>
                  </div>
                :
                (!submittingFail ? t("performance_assessment.actions.monitor_evaluation") : t("performance_assessment.actions.close"))
              }
            </button>
          </footer>
        </div>
      </Slide>
    </Modal>
  )
}
