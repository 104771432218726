import { Box, Container, Grid } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { alertActions, usersActions } from '../../_actions'
import style from './ProductivityPerformancePage.module.scss'
import { authHeader, history } from '../../_helpers'
import { api } from '../../_helpers/api'
import AlertBox from '../../_components/_shared/AlertBox'
import moment from 'moment'
import SkeletonProductivityPerformancePage from './SkeletonProductivityPerformancePage'
import HeaderProductivityColab from '../../_components/_shared/HeaderProductivityColab'
import NewAssessmentCard from '../../_components/performance_assessments_colab/NewAssessmentCard'
import GaugeChartCard from '../../_components/performance_assessments_colab/GaugeChartCard'
import StrongAndWeakPointCard from '../../_components/performance_assessments_colab/StrongAndWeakPointCard'
import ResultLastAssessmentTable from '../../_components/performance_assessments_colab/ResultLastAssessmentTable'
import MyPerformanceCard from '../../_components/performance_assessments_colab/MyPerformanceCard'
import HistoricalAssessmentTable from '../../_components/performance_assessments_colab/HistoricalAssessmentTable'
import ApresentationDetailsModal from '../../_components/performance_assessments/PerformanceAssessmentsNew/ApresentationDetailsModal'


function ProductivityPerformancePage({ userDetails, usersActions, alertActions, filter }) {
  const { t, i18n } = useTranslation('common');
  const [fetching, setFetching] = useState(true);
  const [assessmentsPending, setAssessmentsPending] = useState([]);
  const [generalStats, setGeneralStats] = useState(null);
  const [historical, setHistorical] = useState([]);
  const [openModalIntro, setOpenModalIntro] = useState(false);
  const [newAssessmentSelected, setNewAssessmentSelected] = useState(null);
  const [selectData, setSelectData] = useState(null);

  async function getSelectData() {
    try {
      const {data} = await api.get('/performance-assessment/user/select-data', {
        headers: authHeader()
      })

      if(data?.performance_assessments?.length > 0) {
        getData({assessment_id: data?.performance_assessments[0]?.id})
      }else{
        setFetching(false)
      }

      setSelectData(data);
    } catch (error) {
      console.log(error)
    }    
  }

  async function getData({assessment_id=null}) {
    try {

      setFetching(true)

      const queryFilter = assessment_id ? `?performance_assessment_id=${assessment_id}` : '';

      const {data: pending} = await api.get(`/performance-assessment/user/pending${queryFilter}`, {
        headers: authHeader()
      })

      setAssessmentsPending(pending);

      const {data: generalStats} = await api.get(`/performance-assessment/user/general-stats${queryFilter}`, {
        headers: authHeader()
      })

      setGeneralStats(generalStats);

      setFetching(false)
    } catch (error) {
      console.log(error)
      // alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }



  const handleOpenModalIntro = (assessment) => {
    setNewAssessmentSelected(assessment)
    setOpenModalIntro(true)
  }

  const handleCloseModalIntro = () => {
    setOpenModalIntro(false)
    setNewAssessmentSelected(null)
  }

  useEffect(() => {
    document.title = `Befective | ${t('performance_assessment.title_page')}`;
    getSelectData();
  }, [])


  return (
    <Box
      bgcolor="#F2F5FA"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <AlertBox />

      <HeaderProductivityColab 
        activeMenu="performance-assessment"
        getDataAssessment={getData}
        assessmentsOptions={selectData?.performance_assessments}
      />

      {
        !fetching
          ?
          <Container component={'main'} maxWidth="xl" className={style.containerMain} sx={{ flexGrow: 1, marginTop:'-88px'}}>
            {
              assessmentsPending?.length > 0 && (
                <Grid container spacing={1} mb={'16px'} paddingX={'32px'}>
                  {
                    assessmentsPending?.map((assessment, index) => (
                      <Grid item xs={12} sm={4} key={index}>
                        <NewAssessmentCard
                          key={`card-new-${index}`}
                          assessment={assessment}
                          onClick={() => handleOpenModalIntro(assessment)}
                        />
                      </Grid>
                    ))
                  }
                </Grid>
              )
            }

            <Grid container spacing={1} mb={'16px'} paddingX={'32px'}>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <GaugeChartCard
                      data={generalStats}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StrongAndWeakPointCard
                      type='strong'
                      data={generalStats?.strong_point}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StrongAndWeakPointCard
                      type='weak'
                      data={generalStats?.weak_point}
                    />
                  </Grid>                  
                </Grid>

                <Grid container spacing={1} mt={'2px'}>
                  <Grid item xs={12}>
                    <ResultLastAssessmentTable
                      data={generalStats?.results_last_assessment}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MyPerformanceCard
                  data={generalStats?.my_performance}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} mb={'16px'} paddingX={'32px'}>
              <Grid item xs={12}>
                <HistoricalAssessmentTable
                  data={historical}
                  setHistorical={setHistorical}
                  scopes={selectData?.scopes}
                />
              </Grid>
            </Grid>

          </Container>

          :

          <SkeletonProductivityPerformancePage />
      }
      
      <ApresentationDetailsModal 
        openModal={openModalIntro} 
        closeModal={handleCloseModalIntro} 
        title={newAssessmentSelected?.title || "-----"}
        subtitle={newAssessmentSelected?.subtitle || "-----"}
        description={newAssessmentSelected?.introduction || "-----"}  
        handleClickStart={() => history.push(`/productive/productivity/performance-assessment/form/${newAssessmentSelected?.evaluated_id}`)}      
      />

    </Box >
  )
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter
  }
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductivityPerformancePage));