import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { api } from "../../../_helpers/api";
import IconClose from "../../_icons/IconClose";
import { Skeleton } from "@material-ui/lab";
import { authHeader, getAvatarUrl, getPeriod, numberToPercentString } from "../../../_helpers";
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import Avatar from '@material-ui/core/Avatar'
import { IconButton, Tooltip } from '@material-ui/core'
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import './PerformanceAssessmentsInfoDrawer.scss'
import CustomAvatarGroup from "../../_shared/CustomAvatarGroup";

export default function PerformanceAssessmentsInfoDrawer({
  openInfoDrawerAssessment,
  closeDrawerInfoAssessment,
}) {
  const { t, i18n } = useTranslation("common");
  const [toggleScale, setToggleScale] = useState(false);
  const [assessmentData, setAssessmentData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const getDetailsAssessments = async () => {
    try {
      setIsLoading(true);
      const result = await api.get(`/performance-assessment/participants/details?performance_assessment_id=${id}`, {
      	headers: authHeader()
      })
      setAssessmentData(result?.data);
      setIsLoading(false);
    } catch (error) {
      //alertActions.error(error)
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDetailsAssessments();
  }, []);

  return (
    <>
      <div
        className={"overlay" + (openInfoDrawerAssessment ? " open" : "")}
        onClick={closeDrawerInfoAssessment}
      ></div>
      <section
        className={
          "drawer drawer400" + (openInfoDrawerAssessment ? " open" : "")
        }
      >
        <button onClick={closeDrawerInfoAssessment} className="close">
          <IconClose />
        </button>
        {isLoading ? (
          <div className="containerDrawerAssessmentInfo">
            <header>
              <Skeleton variant="h4" width="100%" height={20} />
            </header>

            <main>
              <div className="headerDetail">
                <h3>{t('performance_assessment.page_assessment_details.participants.drawer_details_assessment.label')}</h3>
              </div>
              <div className="contentDetail">
                <div>
                  <div className="row">
                    <div className="col" style={{ width: "auto" }}>
                      <h6>
                        <Skeleton width="80px" />
                      </h6>
                      <div>
                        <Skeleton width="150px" />
                      </div>
                    </div>
                    <div className="col" style={{ width: "auto" }}>
                      <h6>
                        <Skeleton width="80px" />
                      </h6>
                      <div>
                        <Skeleton width="150px" />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <h6>
                        <Skeleton width="80px" />
                      </h6>
                      <div className="avatarTeam">
                        <Skeleton width="150px" />
                      </div>
                    </div>

                    <div className="col">
                      <h6>
                        <Skeleton width="80px" />
                      </h6>
                      <div className="avatarTeam">
                        <Skeleton width="150px" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <h6>
                        <Skeleton width="80px" />
                      </h6>
                      <div>
                        <Skeleton width="150px" />
                      </div>
                    </div>
                    <div className="col">
                      <h6>
                        <Skeleton width="80px" />
                      </h6>
                      <div>
                        <Skeleton width="150px" />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="row">
                    <div className="col">
                      <h6>
                        <Skeleton width="80px" />
                      </h6>
                      <div>
                        <Skeleton width="300px" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <h6>
                        <Skeleton width="80px" />
                      </h6>
                      <div>
                        <Skeleton width="300px" />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="row">
                    <div className="col">
                      <h6>
                        <Skeleton width="80px" />
                      </h6>
                      <div>
                        <Skeleton width="300px" />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <h6>
                        <Skeleton width="80px" />
                      </h6>
                      <span>
                        <Skeleton width="150px" />
                      </span>
                    </div>
                    <div className="col">
                      <h6>
                        <Skeleton width="80px" />
                      </h6>
                      <span>
                        <Skeleton width="150px" />
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <h6>
                        <Skeleton width="80px" />
                      </h6>
                      <div>
                        <Skeleton width="150px" />
                      </div>
                    </div>
                    <div className="col">
                      <h6>
                        <Skeleton width="80px" />
                      </h6>
                      <div>
                        <Skeleton width="150px" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        ) : (
          <div className="containerDrawerAssessmentInfo">
            <header>
              <h3>{assessmentData?.title || "-----"}</h3>
            </header>

            <main>
              <div className="headerDetail">
                <h3>{t('performance_assessment.page_assessment_details.participants.drawer_details_assessment.label')}</h3>
              </div>

              <div className="contentDetail">
                <div>
                  <div className="row">
                    <div className="col" style={{ width: "auto" }}>
                      <h6>{t('performance_assessment.page_assessment_details.participants.drawer_details_assessment.period')}</h6>
                      <div>
                        {getPeriod(
                          assessmentData?.date_start,
                          assessmentData?.date_end,
                          i18n.language,
                          t('performance_assessment.page_assessment_details.participants.drawer_details_assessment.to')
                        )}
                      </div>
                    </div>
                    <div className="col" style={{ width: "auto" }}>
                      <h6>{t('performance_assessment.page_assessment_details.participants.drawer_details_assessment.missing')}</h6>
                      <div>{`${assessmentData?.remaning_days || 0} ${t('performance_assessment.page_assessment_details.participants.drawer_details_assessment.days')}`}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col" style={{ width: "auto" }}>
                      <h6>{t('performance_assessment.page_assessment_details.participants.drawer_details_assessment.created_by')}</h6>
                      <div className="avatarTeam">
                        {
                          assessmentData?.creator_username ? (
                            <CustomAvatarGroup avatars={[{username: assessmentData?.creator_username}]} max={1}/>
                          )
                          : "-----"
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="row">
                    <div className="col">
                      <h6>{t('performance_assessment.page_assessment_details.participants.drawer_details_assessment.evaluators')}</h6>
                      {assessmentData?.evaluators.length > 0 ? (
                        <CustomAvatarGroup avatars={assessmentData?.evaluators} max={4}/>
                      ) : (
                        "-----"
                      )}
                    </div>
                  </div>
                 
                  <div className="row">
                    <div className="col">
                      <h6>{t('performance_assessment.page_assessment_details.participants.drawer_details_assessment.evaluated')}</h6>
                      {assessmentData?.evaluated.length > 0 ? (
                        <CustomAvatarGroup avatars={assessmentData?.evaluated} max={4}/>
                      ) : (
                        "-----"
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <h6>{t('performance_assessment.page_assessment_details.participants.drawer_details_assessment.status')}</h6>
                      <div>{assessmentData?.status ? assessmentData?.status[`title_${i18n.language}`] : '-----'}</div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="row">
                    <div className="col">
                      <h6>{t('performance_assessment.page_assessment_details.participants.drawer_details_assessment.answers')}</h6>
                      <div>{assessmentData?.general_progress}</div>
                    </div>
                    <div className="col">
                      <h6>{t("performance_assessment.page_assessment_details.participants.drawer_details_assessment.progress")}</h6>
                      <span style={{ color:  assessmentData?.status?.text_color || "#4F7091"}}>
                        {numberToPercentString(assessmentData?.general_progress_percent)}
                      </span>
                    </div>
                  </div>
                </div>

                {/* <div>
                  <div className="row">
                    <div className="col" style={{ width: "90%" }}>
                      <h6>{t('performance_assessment.page_assessment_details.participants.drawer_details_assessment.scale')}</h6>
                      <div>{assessmentData?.scale?.name}</div>
                    </div>
                    <div className="col" style={{ width: "10%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                      <IconButton aria-label="toggle" size="small" onClick={() => setToggleScale(!toggleScale)}>
                        {
                          !toggleScale
                          ? 
                            <ExpandMore fontSize="small"/>  
                          :
                            <ExpandLess fontSize="small"/>
                        }
                      </IconButton>
                    </div>
                  </div>
                  {
                    (toggleScale && assessmentData?.scale?.itens.length > 0) &&
                    <div className="row">
                      <div className="col">
                       {
                         assessmentData?.scale?.itens.map((item, index) => (
                           <div className="itemScale" key={index}>
                             {`${index+1} - ${item.title}`}
                           </div>
                         ))
                       }
                      </div>
                    </div>
                  }
                </div> */}
              </div>
            </main>
          </div>
        )}
      </section>
    </>
  );
}
