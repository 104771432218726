import React, { useEffect, useState } from "react";
import Toolbar from "../../_components/_shared/Toolbar";
import { connect } from "react-redux";
import { alertActions } from "../../_actions";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import GlobalSkeleton from "../../_components/_shared/GlobalSkeleton";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import TabComponent from "../../_components/performance_assessments/PerformanceAssessmentsDetails/TabComponent";
import ParticipantsStatsHeader from "../../_components/performance_assessments/PerformanceAssessmentsDetails/ParticipantsStatsHeader";
import ParticipantsTable from "../../_components/performance_assessments/PerformanceAssessmentsDetails/ParticipantsTable";
import PerformanceAssessmentsInfoDrawer from "../../_components/performance_assessments/PerformanceAssessmentsDetails/PerformanceAssessmentsInfoDrawer";
import PerformanceAssessmentsFilterDrawer from "../../_components/performance_assessments/PerformanceAssessmentsDetails/PerformanceAssessmentsFilterDrawer";
import { api } from "../../_helpers/api";
import { authHeader } from "../../_helpers";

function PerformanceAssessmentsDetailsParticipantsPage({
  alertActions,
  userDetails,
  filter,
}) {
  const { id } = useParams();
  const { t, i18n } = useTranslation("common");
  const [fetching, setFetching] = useState(true);
  const [openInfoDrawerAssessment, setOpenInfoDrawerAssessment] = useState(false);
  const [openDrawerFilter, setOpenDrawerFilter] = useState(false);
  const [participantsData, setParticipantsData] = useState(null);

  const handleCloseDrawerAssessment = () => {
    setOpenInfoDrawerAssessment(false)
  }
  const handleOpenDrawerAssessment = () => {
    setOpenInfoDrawerAssessment(true)
  }
  const handleCloseDrawerFilter = () => {
    setOpenDrawerFilter(false)
  }
  const handleOpenDrawerFilter = () => {
    setOpenDrawerFilter(true)
  }
  async function getParticipantsData({searchText = ''}) {

    try {

      if(!id){
        throw new Error(t('generic_messages.errors.load'))
      }

      setFetching(true);

      const querySearch = searchText ? `&search_text=${searchText}` : '';

      const response = await api.get(`/performance-assessment/participants?performance_assessment_id=${id}${querySearch}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setParticipantsData(response.data)
      setFetching(false);
    } catch (error) {
      console.log(error);
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }

  }


  useEffect(() => {
    document.title = `Befective | ${t("menu.performance_assessment.assessments")} | ${t("performance_assessment.page_assessment_details.participants.tab_title")}`;
    getParticipantsData({
      searchText: ''
    });
  }, []);

  return (
    <section className="mainContent">
      <Toolbar titleAssessment={participantsData?.title || ""}/>

      <TabComponent currentActive="participants" assessmentId={id}/>

      <main className="mainContentPage">
        {!fetching ? (
         <>        
          <ParticipantsStatsHeader 
            data={participantsData} 
            openDrawerAssessment={handleOpenDrawerAssessment} 
            openDrawerFilter={handleOpenDrawerFilter}
          />
          <ParticipantsTable 
            data={participantsData?.evaluated || []} 
            assessmentId={id}
          />
         </>
        ) : (
          <GlobalSkeleton
            totalRowsStats={0}
            totalTables={1}
            heightTable={600}
          />
        )}
      </main>
      {
        openInfoDrawerAssessment && (
          <PerformanceAssessmentsInfoDrawer 
            openInfoDrawerAssessment={openInfoDrawerAssessment} 
            closeDrawerInfoAssessment={handleCloseDrawerAssessment} 
          />
        )
      }

      {
        openDrawerFilter && (
          <PerformanceAssessmentsFilterDrawer 
            openDrawer={openDrawerFilter} 
            closeDrawer={handleCloseDrawerFilter} 
            getParticipantsData={getParticipantsData}
          />
        )
      }
    </section>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceAssessmentsDetailsParticipantsPage);
