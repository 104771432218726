import React, { useEffect, useState } from 'react';
import styles from './CardManageScalesAnswers.module.scss';
import { useTranslation } from 'react-i18next';
import { Add, Check, Close, Delete, DragIndicator, Save } from '@material-ui/icons';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';

const OptionEdit = ({
    provided,
    scaleAnswer,
    options,
    option,
    addOption,
    getScalesAnswers = () => {},
    setAddOption = () => {},
    editOption = () => {},
    removeOption = () => {},
    addNewOption = () => {}
}) => {

    const { t, i18n } = useTranslation('common');
    const [disableActions, setDisableActions] = useState(false);

    const [formOption, setFormOption] = useState({
        scales_id: scaleAnswer.id,
        options_id: option.options_id || null,
        options_label: option.options_label || "",
        options_help_text: option.options_help_text || "",
        options_value: option.options_value || ""
    })

    function findMaxOption(options) {
        return options.reduce((oldest, option) => {
          return (oldest.options_value || 0) > option.options_value ? oldest : option;
        }, {});
    }

    const scaleOptionsValue = [1,2,3,4,5,6].map((value) => {

        if(options.length === 0 || Object.keys(options).length === 0) return value

        if(!options.map(o => o.options_value).includes(value)) {
            return value
        }

        if(value === option.options_value) {
            return value
        }

        return null

    }).filter(value => value !== null)

    const [formErrors, setFormErrors] = useState({
        options_label: "",
        options_help_text: ""
    });

    const onChangeFormOption = (event) => {

        const { name, value } = event.target

        const newFormOption = {
            ...formOption,
            [name]: value
        }

        setFormOption(newFormOption)

        if(formOption.options_id) {
            editOption(newFormOption)
        }

        setFormErrors({
            ...formErrors,
            [event.target.name]: ""
        })
    }

    const validateForm = () => {

        const newFormErrors = {}

        if(!formOption.options_label) {
            newFormErrors.options_label = t('validation.required')
        }

        if(!formOption.options_help_text) {
            newFormErrors.options_help_text = t('validation.required')
        }

        setFormErrors(newFormErrors)

        if(Object.keys(newFormErrors).length > 0) {
            return false
        }

        return true
    }

    const resetForm = () => {
        setFormOption({
            scales_id: scaleAnswer.id,
            options_id: null,
            options_label: "",
            options_help_text: "",
            options_value: ""
        })
        setFormErrors({
            options_label: "",
            options_help_text: ""
        })
    }

    const getValue = (options_value, options) => {
        
        if(options_value){
            return options_value
        }

        if(!options || options.length === 0) return 1

        const max = findMaxOption(options)

        if(Object.keys(max).length === 0) return "--"

        return max.options_value + 1
    }

    return (
        <tr
        ref={provided && provided?.innerRef}
        {...provided?.draggableProps}
        className={styles.trCustomPadding}
        >
            {
                (formOption.options_id !== null)
                ?
                    <td style={{ cursor: 'grab', width: '0px' }} {...provided?.dragHandleProps}>
                        <DragIndicator style={{ cursor: 'grab' }} />
                    </td>
                :
                    <td></td>                    
            }
            <td style={{width: '20%'}}>
                <div className={styles.formGroup}>
                    <input max={30} maxLength={30} required autoComplete='off' disabled={disableActions} autoFocus={formOption.options_id === null} className={`${styles.inputEditable} ${formErrors.options_label && styles.inputError}`} placeholder={t('performance_assessment.page_scales_answers.placeholder_option')} type="text" value={formOption?.options_label || ""} onChange={onChangeFormOption} name="options_label"/>
                </div>
            </td>
            <td>
                <div className={styles.formGroup}>
                    <input maxLength={200} required disabled={disableActions}  className={`${styles.inputEditable} ${formErrors.options_help_text && styles.inputError}`} placeholder={t('performance_assessment.page_scales_answers.placeholder_text_help')} type="text" value={formOption?.options_help_text || ""} onChange={onChangeFormOption} name="options_help_text"/>
                </div>
            </td>
            <td style={{width: '8%', textAlign: 'center'}}>
                <div className={styles.cellValue}>
                    {getValue(formOption?.options_value, options)}
                </div>
            </td>
            {
                (formOption.options_id !== null)
                ?
                    <td style={{width: '0px'}}>
                        <div className={styles.boxButtonsOptions}>
                            <button
                                className={styles.buttonRemoveOption}
                                onClick={() => removeOption(formOption.options_id)}
                            >
                                <Delete style={{color:"#FC6662"}} fontSize='small' />
                            </button>
                        </div>
                    </td>

                :
                <td style={{width: '0px'}}>
                    <div className={styles.boxButtonsOptions}>
                        <button
                            className={styles.buttonRemoveOption}
                            onClick={() => setAddOption(false)}
                        >
                            <Close style={{color:"#FC6662"}} fontSize='small' />
                        </button>
                        <button
                            className={styles.buttonAddOption}
                            onClick={() => {
                                if(validateForm()){
                                    addNewOption({
                                        ...formOption,
                                        options_value: getValue(formOption?.options_value, options)
                                    })
                                    resetForm()
                                }
                            }}
                        >
                            <Check style={{color:"#187DFF"}} fontSize='small' />
                        </button>
                    </div>
                </td>
            }
        </tr>
    )
}   


export default OptionEdit;