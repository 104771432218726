import config from 'config';
import Moment from "moment";
import { authHeader } from '../_helpers';

export const shareService = {
    getFile

};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE);
}

function getFile({ format, report, filters, type='pdf', typeExportView, paginationFilters}) {

    
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/octet-stream' } }
    };

    let queryTeams = ''
    let queryTeammates = ''
    let startDate = Moment(new Date()).format('YYYY-MM-DD');
    let endDate = Moment(new Date()).format('YYYY-MM-DD');
    let querySeeArchived =  ''
    let queryUseArchived =  ''
    let typeExportViewQuery = ''
    let filterPaginationQuery = ''

    if(typeExportView){
        typeExportViewQuery = `&typeExport=${typeExportView}`
    }

    if(paginationFilters){
        filterPaginationQuery = `&page=${paginationFilters.pageIndex}&per_page=${paginationFilters.pageSize}`
    }

    if(filters.date){
        startDate = filters.date.startDate
        endDate = filters.date.endDate
    }

    if(!filters.date && (report ==='reportsRoomsColabs' || report === 'potentialProductiveReports' || report === 'fatigueReports')){
        startDate = Moment().startOf("month").format('YYYY-MM-DD')
        endDate = Moment().format('YYYY-MM-DD')
    }

    if((report === "realTime" || report === "checkinOvertime") && filters.singleDate){
        startDate = filters.singleDate
        endDate = filters.singleDate
    }
    if(report === "mood" && !filters.singleDate){
        startDate = Moment(endDate).subtract(6,'d').format('YYYY-MM-DD')
    }
    if(report === "mood" && filters.singleDate){
        startDate = Moment(filters.singleDate).subtract(6, 'd').format('YYYY-MM-DD')
        endDate = filters.singleDate
    }

    if (filters.teams && filters.teams.length > 0) {
        let nt = 0;
        queryTeams = "&teamIds=";

        for (let team of filters.teams) {
            if (nt == 0) {
                queryTeams += team.id;
            }
            else {
                queryTeams += "," + team.id;
            }
            nt++;
        }

    }

    if (filters.teammates && filters.teammates.length > 0) {
        let nt = 0;
        queryTeammates = "&machineUserIds=";

        for (let teammate of filters.teammates) {
            if (nt === 0) {
                queryTeammates += teammate.id;
            } else {
                queryTeammates += "," + teammate.id;
            }
            nt++;
        }

    }

    if(filters.seeArchived!==undefined){
        querySeeArchived = `&seeArchived=${filters.seeArchived}`
    }

    if(filters.useArchived!==undefined){
        queryUseArchived = `&use_not_active=${filters.useArchived}`
    }

    let queryFilterDate = `startDate=${startDate}&endDate=${endDate}`;

    
	if (startDate === endDate && report === "generalProgressGoal") {
        queryFilterDate = '';
    }

    const queryMuFilter = filters.mu_id ? `&mu_id=${filters.mu_id}` : ''
    
    const queryMachineUserFilter = filters.machine_user_id ? `&machine_user_id=${filters.machine_user_id}` : ''

    if (report === "historyPointsColab") {
        queryFilterDate = `date_ref=${Moment(filters.singleDate).format('YYYY-MM') || Moment(new Date()).format('YYYY-MM')}`;
    }

    if(report === 'checkinReportSignedPoints' || report === 'absenteeismReports'){
        queryFilterDate = `date_ref=${Moment(filters.singleDate).format('YYYY-MM-DD') || Moment(new Date()).format('YYYY-MM-DD')}`;
    }


    if(report === 'remoteCheckinReportsTeammates'){
        if(filters.date){
            queryFilterDate = `startDate=${Moment(filters.date.startDate).format('YYYY-MM-DD')}&endDate=${Moment(filters.date.endDate).format('YYYY-MM-DD')}`;
        }else{
            queryFilterDate = `startDate=${Moment(new Date()).startOf('month').format('YYYY-MM-DD')}&endDate=${Moment(new Date()).format('YYYY-MM-DD')}`;

        }
    }

    if(report === 'exportTeamStructure'){
        queryFilterDate = ''
        queryTeams = ''
        queryTeammates = ''
    }  

    const queryMachineUserID = filters.machine_users_id ? `&machine_users_id=${filters.machine_users_id}` : ''
    const queryPerformanceAssessmentID = filters.performance_assessment_id ? `&performance_assessment_id=${filters.performance_assessment_id}` : ''

    return fetch(`${config.apiNodeUrl}/${type}/${report}?`+ queryFilterDate + queryMachineUserID + queryPerformanceAssessmentID + queryTeams + queryTeammates + querySeeArchived + queryUseArchived + queryMuFilter + queryMachineUserFilter + typeExportViewQuery + filterPaginationQuery, requestOptions).then(response => {
        response.blob().then(blob => {
            if(response.status === 200){
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = report + "." + format;
                a.click();
            }
        }).catch(error => console.log(error));

    }).catch(err => console.log(err));

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {

                logout();
                location.reload(true);

            }

            const error = (data && data.Message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

