import React, { useEffect, useState } from "react";
import Toolbar from "../../_components/_shared/Toolbar";
import { connect } from "react-redux";
import { alertActions } from "../../_actions";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { authHeader} from "../../_helpers";
import GlobalSkeleton from "../../_components/_shared/GlobalSkeleton";
import { api } from "../../_helpers/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import TabComponent from "../../_components/performance_assessments/PerformanceAssessmentsDetails/TabComponent";
import RepliesCardList from "../../_components/performance_assessments/PerformanceAssessmentsDetails/RepliesCardList";
import RepliesFeedbackDrawer from "../../_components/performance_assessments/PerformanceAssessmentsDetails/RepliesFeedbackDrawer";
import PerformanceAssessmentsDetailsDrawer from "../../_components/performance_assessments/PerformanceAssessmentsDetails/PerformanceAssessmentsDetailsDrawer";

function PerformanceAssessmentsDetailsRepliesPage({
  alertActions,
  userDetails,
  filter,
}) {
  const { id } = useParams();
  const { t, i18n } = useTranslation("common");
  const [fetching, setFetching] = useState(true);
  const [openDrawerFeedbacks, setOpenDrawerFeedbacks] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);
  const [currentData, setCurrentData] = useState(true);
  const [data, setData] = useState([]);
  
  const handleOpenDrawerFeedbacks = (currentData) => {
    setCurrentData(currentData);
    setOpenDrawerFeedbacks(true);
  };

  const handleCloseDrawerFeedbacks = () => {
    setOpenDrawerFeedbacks(false);
    setCurrentData(null);
  };

  const handleOpenDrawerDetails = (currentData) => {
    setCurrentData(currentData);
    setOpenDrawerDetails(true);
  };

  const handleCloseDrawerDetails = () => {
    setOpenDrawerDetails(false);
    setCurrentData(null);
  };

  async function getData() {
    try {
      setFetching(true);

      const response = await api.get(`/performance-assessment/answers?performance_assessment_id=${id}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setData(response.data)
      
      setFetching(false);
    } catch (error) {
      console.log(error);
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }
  }


  useEffect(() => {
    document.title = `Befective | ${t("menu.performance_assessment.assessments")} | ${t("performance_assessment.page_assessment_details.replies.tab_title")}`;
    getData();
  }, []);

  return (
    <section className="mainContent">
      <Toolbar titleAssessment={data.length > 0 ? data[0]?.title : ""}/>

      <TabComponent currentActive="replies" assessmentId={id}/>

      <main className="mainContentPage">
        {!fetching ? (
         
          data.length > 0 
          ? 
          (
            data.map((item, index) => (
              <RepliesCardList 
                key={`card-list-${index}`} 
                data={item} 
                openDrawerFeedbacks={() => handleOpenDrawerFeedbacks(item)} 
                openDrawerDetails={() => handleOpenDrawerDetails(item)}/>
            ))
          )
          : 
          (
            <div className={"boxNone"}>
              <p className={"textNone"}>
                {t('performance_assessment.messages.none_data_replies')}
              </p>
            </div>
          )
         
        ) : (
          <GlobalSkeleton
            totalRowsStats={0}
            totalTables={1}
            heightTable={600}
          />
        )}
      </main>
      {
        (openDrawerFeedbacks && currentData) && (
          <RepliesFeedbackDrawer 
            openDrawer={openDrawerFeedbacks} 
            closeDrawer={handleCloseDrawerFeedbacks} 
            currentData={currentData}
          />
        )
      }
      {
        (openDrawerDetails && currentData) && (
          <PerformanceAssessmentsDetailsDrawer 
            openDrawer={openDrawerDetails} 
            closeDrawer={handleCloseDrawerDetails} 
            currentData={currentData}
          />
        )
      }
    </section>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceAssessmentsDetailsRepliesPage);
