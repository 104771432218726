import React from 'react'
import styles from './ReportSkillsCardList.module.scss'
import { Chat, Hexagon } from 'phosphor-react'
import BefectiveTooltip from '../../_shared/BefectiveTooltip'
import { Typography } from '@material-ui/core'
import IconInfo from '../../_icons/IconInfo'
import RepliesSkillsCollapsibleTable from '../PerformanceAssessmentsDetails/RepliesSkillsCollapsibleTable'
import { befectiveFormatDate, getAvatarUrl } from '../../../_helpers'
import { Avatar } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function ReportSkillsCardList({
  data,
  openDrawerDetails
}) {

  const { t, i18n } = useTranslation("common");

  return (
    <div className={styles.cardContainer}>
      <header className={styles.cardHeader}>
        <div className={styles.infoUser}>
          <div className={styles.avatarContainer}>
            <Avatar
              alt={data?.username}
              src={
                getAvatarUrl({
                  name: data?.username
                },
                  "187DFF"
                )
              }
              style={{ width: 72, height: 72 }}
            />
          </div>
          <div className={styles.infoUserContent}>
            <h6 className={styles.title}>{data?.username}: {data?.evaluated_avg} {data?.avg_label && `(${data?.avg_label})`}</h6>
            <div className={styles.listInfo}>
              <span>{t("performance_assessment.page_feedback.report_tab.position")}: {data?.office || "-----"}</span>
              <span>{t("performance_assessment.page_feedback.report_tab.assessment")}: {data?.performance_assessment_stats?.title || "-----"}</span>
              <span>{t("performance_assessment.page_feedback.report_tab.model")}: {data?.performance_assessment_stats[`label_${i18n.language}`]}</span>
              <span>{t("performance_assessment.page_feedback.report_tab.evaluated_at")}: {befectiveFormatDate(data?.performance_assessment_stats?.rated_at, i18n.language)}</span>
            </div>
          </div>
        </div>
        <div className={styles.boxButtonHeader}>
          {
            openDrawerDetails && 
            <button type='button' onClick={openDrawerDetails}>
              <Hexagon size={16} color='#98ACC1' weight='bold'/>
            </button>
          }
        </div>
      </header>

      <RepliesSkillsCollapsibleTable skills={data?.skills} evaluators={data?.evaluators}/>
    </div>
  )
}
