import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Modal,
  Slide,
  makeStyles,
} from "@material-ui/core";
import styles from "./CreateSkillModal.module.scss";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import { api } from "../../../_helpers/api";
import { authHeader } from "../../../_helpers";
import IconClose from "../../_icons/IconClose";
import { alertActions } from "../../../_actions";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function CreateSkillModal({ openModal, closeModal, skillTypes, getSkills, skillToEdit = null, alertActions}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const [isSubmit, setIsSubmit] = useState(false);
  const [form, setForm] = useState({
    name: "",
    description: "",
    type_id: 1
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    type_id: "",
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  }

  const onSelectType = (value) => {
    setForm({
      ...form,
      type_id: value.id
    });
    setFormErrors({
      ...formErrors,
      type_id: "",
    });
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    if (form.name === "") {
      setFormErrors({
        ...formErrors,
        name: t("validation.required"),
      });
    }

    if (form.type_id === "") {
      setFormErrors({
        ...formErrors,
        type_id: t("validation.required"),
      });
    }

    if (form.name !== "" && form.type_id !== "") {
      setIsSubmit(true);

      if (skillToEdit) {
        api
          .put(`/performance-assessment/skills/${skillToEdit.id}`, form, {
            headers: authHeader(),
          })
          .then((res) => {
            getSkills();
            handleClose();
            setIsSubmit(false);
            alertActions.success(t("performance_assessment.messages.success_update_skill"));
          })
          .catch((err) => {
            console.log(err);
            setIsSubmit(false);
            alertActions.error(t("performance_assessment.messages.error_update_skill"));
          });
          return
      }
      
      api
        .post(`/performance-assessment/skills`, form, {
          headers: authHeader(),
        })
        .then((res) => {
          getSkills(true, null, res.data);
          handleClose();
          setIsSubmit(false);
          alertActions.success(t("performance_assessment.messages.success_create_skill"));
        })
        .catch((err) => {
          console.log(err);
          setIsSubmit(false);
          alertActions.error(t("performance_assessment.messages.error_create_skill"));
        });

    }
  }

  const handleClose = () => {
    closeModal();
    setForm({ name: "", description: "", type_id: 1 });
    setFormErrors({ name: "", type_id: "" });
  }

  useEffect(() => {
    if (skillToEdit) {
      setForm({
        name: skillToEdit.name,
        description: skillToEdit.description,
        type_id: skillToEdit.performance_assessment_skills_type_id
      });
    }else{
      setForm({ name: "", description: "", type_id: 1 });
    }
  }, [skillToEdit]);


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${styles.modal}`}
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: "#205B6F", opacity: 0.8 },
      }}
      sx={{ border: "none" }}
      style={{ outline: 0 }}
    >
      <Slide
        direction="down"
        in={openModal}
        style={{ outline: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.containerModal}>

          <button
              onClick={handleClose}
              className={styles.close}
          >
            <IconClose />
          </button>


          <header>
            <h4>
              { skillToEdit ? t("performance_assessment.page_skills.modal_create_and_update.title_edit") : t("performance_assessment.page_skills.modal_create_and_update.title_new")}
            </h4>
          </header>

          <form
            onSubmit={handleSubmit}
          >
            {
              skillTypes.length > 0 &&
              <div className={styles.boxSkillTypes}>
                {
                  skillTypes.map((skillType) => (
                    <div key={skillType.id} style={{borderLeft: `4px solid ${skillType.color}`, borderTop: form.type_id === skillType.id ? `2px solid ${skillType.color}`: "1px solid #D6DEEB", borderRight: form.type_id === skillType.id ? `2px solid ${skillType.color}`: "1px solid #D6DEEB", borderBottom: form.type_id === skillType.id ? `2px solid ${skillType.color}`: "1px solid #D6DEEB"}} className={`${styles.cardSelectType}`} onClick={() => onSelectType(skillType)}>
                      <h6>{skillType[`label_${i18n.language}`]}</h6>
                      <p>{skillType[`help_text_${i18n.language}`]}</p>
                    </div>
                  ))
                }
                {formErrors.type_id && <p className={styles.error}>{formErrors.type_id}</p>}
                </div>
            }

            <div className={styles.fieldBox}>
              <label htmlFor="name">{t("performance_assessment.page_skills.modal_create_and_update.name_skill")}*</label>
              <input type="text" id="name" name="name" onChange={onChangeInput} value={form.name} placeholder={t("performance_assessment.page_skills.modal_create_and_update.placeholder_name_skill")}/>
              {formErrors.name && <p className={styles.error}>{formErrors.name}</p>}
            </div>

            <div className={styles.fieldBox}>
              <label htmlFor="description">{t("performance_assessment.page_skills.modal_create_and_update.description_skill")}</label>
              <textarea id="description" name="description" onChange={onChangeInput} rows={3} value={form.description} placeholder={t("performance_assessment.page_skills.modal_create_and_update.placeholder_description_skill")}></textarea>
            </div>            
          
            <footer>            
              <button className={styles.buttonCancel} type="button" onClick={handleClose}>
                {t("performance_assessment.actions.cancel")}
              </button>
              <button className={styles.buttonSave} disabled={isSubmit}>
                {t("performance_assessment.actions.save")}
              </button>
            </footer> 
          </form>
        </div>
      </Slide>
    </Modal>
  );
}
