import React from 'react'
import { useTranslation } from 'react-i18next';

export default function NoData() {
  const { t } = useTranslation('common');
  return (
    <div className="containerNoData" >
      <h6 className="titleNoData">{t("messages_no_data.assessment_created.title")}</h6>
      <p className="simpleTextNoData" dangerouslySetInnerHTML={{ __html: t("messages_no_data.assessment_created.step1")}}></p>
      <p className="simpleTextNoData">{t("messages_no_data.assessment_created.step2")}</p>
      <p className="simpleTextNoData">{t("messages_no_data.assessment_created.step3")}</p>
      <p className="simpleTextNoData">{t("messages_no_data.assessment_created.step4")}</p>
      <p className="simpleTextNoData" dangerouslySetInnerHTML={{ __html: t("messages_no_data.assessment_created.step5")}}></p>
      <p className="simpleTextNoData">{t("messages_no_data.assessment_created.finish")}</p>
    </div>
  )
}
