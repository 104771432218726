import React, { useEffect, useMemo, useState } from 'react'
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import { connect } from 'react-redux'
import { alertActions } from '../../_actions'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { api } from '../../_helpers/api'
import { authHeader, befectiveFormatDate, history } from '../../_helpers'
import EnhancedTable from '../../_components/_shared/EnhancedTable'
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton'
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import StatusAssessment from '../../_components/performance_assessments/PerformanceAssessmentsCreated/StatusAssessment'
import NoData from './NoData'

function PerformanceAssessmentActionPlanPage({
  alertActions,
  userDetails,
  filter
}) {

  
  const { t, i18n } = useTranslation("common");

  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);
  const [dateRef, setDateRef] = useState(moment(new Date()).format("YYYY-MM-DD"));  

  const [firstFetch, setFirstFetch] = useState(true);
  const [firstData, setFirstData] = useState([]);

  let lang = i18n.language || "en"

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  }


  async function getActionPlan(
    date_ref,
    pageIndex,
    pageSize,
    isLoading,
    teams = null,
    teammates = null
  ) {

    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&teams=${teams.map(t=>t.id).join(',')}` : ''
      const queryTeammates = teammates ? `&users=${teammates.map(t=>t.id).join(',')}` : ''
      const queryFilterYear = date_ref ? `&year=${moment(date_ref).year()}` : ''
      const response = await api.get(`/performance-assessment//action-plan?page=${pageIndex}&per_page=${pageSize}${queryFilterYear}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      if(firstFetch){
        setFirstData(response.data)
        setFirstFetch(false)
      }

      setData(response.data)

      setFetching(false);
      
    } catch (error) {
      console.log(error);
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t("performance_assessment.page_action_plan.table.col_plan"),
        accessor: "name"
      },  
      {
        Header: t("performance_assessment.page_action_plan.table.col_colab"),
        accessor: "evaluated_name"
      }, 
      {
        Header: t("performance_assessment.page_action_plan.table.col_responsible"),
        accessor: "responsible_name"
      }, 
      {
        Header: t("performance_assessment.page_action_plan.table.col_category"),
        accessor: "label_pt",
        Cell: ({ value, row }) => {
          const currentRow = row.original;
          return currentRow[`label_${lang}`];
        }
      },  
      {
        Header: t("performance_assessment.page_action_plan.table.col_start"),
        accessor: "date_start",
        Cell: ({ value }) => {
          return befectiveFormatDate(value, lang);
        },
      },
      {
        Header: t("performance_assessment.page_action_plan.table.col_expiration"),
        accessor: "date_end",
        Cell: ({ value }) => {
          return befectiveFormatDate(value, lang);
        },
      },
      {
        Header: t("performance_assessment.page_action_plan.table.col_steps"),
        accessor: "progress",
        Cell: ({ value, row }) => {
          const currentRow = row.original;
          return (
            <div className="boxCellCircularProgress">
              <div className={`containerCircularProgress`}>
                <CircularProgressbar
                  value={Number(value)}
                  strokeWidth={50}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: currentRow.text_color || "#4F7091",
                    trailColor: "#E4EAEF",
                  })}
                />
              </div>
              <div>{`${currentRow.total_done || 0} / ${currentRow.total_items || 0}`}</div>
            </div>
          );
        },
      },
      {
        Header: t("performance_assessment.page_action_plan.table.col_status"),
        accessor: "status_label_pt",
        style: { padding: "4px" },
        Cell: ({ value, row }) => {
          const currentRow = row.original;
          return (
            <StatusAssessment
              title={currentRow["status_label_" + lang]}
              textColor={currentRow.text_color}
              bgColor={currentRow.bg_color}
            />
          );
        },
      },
    ];
  }, []);

  const getActionPlanData = ({pageIndex, pageSize, filter}) => {

    let date_ref = dateRef;

    if (filter.singleDate) {
      date_ref = filter.singleDate;
    }

    setDateRef(date_ref);

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getActionPlan(date_ref, pageIndex, pageSize, true, teams, teammates);
  };

  const handleRowClick = (row) => {
    return history.push(`/performance-assessment/action-plan/details/${row.id}`);
  };

  const handleClickCreateSkill = () => {
    return history.push("/performance-assessment/action-plan/create/new_action_plan");
  }

  useEffect(() => {    
    document.title = `Befective | ${t('menu.performance_assessment.action_plan')} `;  
    getActionPlan(
      dateRef,
      0,
      localStorage.getItem("pageSizeStorage") || 20,
      false,
      null,
      null
    )
  }, [])

  return (
    <section className="mainContent">
      
      <Toolbar />
     
      <Filterbar
        filterYears={"single"}
        filterWho={"multi"}
        filterWhen={"disabled"}
        filterWhat={"disabled"}
        maxDate={new Date()}        
        buttonCreate={{
          title: t("button_titles.create_action_plan"),
          handleClick: handleClickCreateSkill,
        }}
      />

      <main className='mainContentPage'>
      {!fetching ? (
        firstData.length > 0 ?
        <EnhancedTable
          columns={columns}
          data={data}
          filter={filter}
          fetchData={getActionPlanData}
          title={t("performance_assessment.page_action_plan.title_table")}
          onRowClick={handleRowClick}
        />
        :
        <NoData />

      ) : (
        <GlobalSkeleton
          totalRowsStats={0}
          totalTables={1}
          heightTable={600}
        />
      )}
      </main>
    </section>
  )
}



function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceAssessmentActionPlanPage)