import React, { useEffect, useState } from 'react'
import styles from './FormSkillCard.module.scss'
import { useTranslation } from 'react-i18next';
import { CaretDown, CaretUp, Gear } from 'phosphor-react';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { Box } from '@mui/material';
import { FormControlLabel, IconButton, Typography } from '@material-ui/core';
import IconInfo from '../../_icons/IconInfo';
import CustomSwitch from '../../_shared/CustomSwitch';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import BefectiveCheckbox from '../../_shared/BefectiveCheckbox';
import { history } from '../../../_helpers';

export default function FormSkillCard({
  hasEdit,
  index,
  skill,
  formAssessment,
  setFormAssessment,
  updateSkillsAssessment
}) {

  const { t, i18n } = useTranslation("common");
  const [isFocused, setIsFocused] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const letters = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","U","V","W","X","Y","Z"]

  const toggleCollapse = () => {
    updateSkillsAssessment({
      ...skill,
      open: !skill.open
    })
  };
  
  const handleFocusSelect = (e) => {
    setIsFocused(true);
  };


  const handleBlurSelect = (e) => {
    setIsFocused(false);
  };

  const handleChangeSelect = (e) => {
    if(!skill.active) return

    const { name, value } = e.target;

    updateSkillsAssessment({
      ...skill,
      [name]: value 
    });
    setIsChanged(true);
  };

  const validateSkill = (skill) => {

    if(skill.questions?.length === 0){
      return false
    }

    if(skill?.questions?.find(question => (question.is_objective === true && question?.scales_options?.length === 0) || !question.scales_id)){
      return false
    }

    return true
    
  }

  return (
    <div className={styles.formSkillCard}>
      <header
        className={`${styles.headerSkill} ${
          skill.type === "result" ? styles.borderYellow : styles.borderBlue
        }`}
      >
        <div className={styles.boxSkillTitle}>
          <IconButton aria-label="toggle" size="small" onClick={toggleCollapse}>
            {!skill.open ? (
              <ExpandMore fontSize="small" />
            ) : (
              <ExpandLess fontSize="small" />
            )}
          </IconButton>
          <h6 className={skill.active ? styles.activeTitle : ""}>
            {`${index}. ${skill?.name}`}
            {skill?.description && (
              <BefectiveTooltip
                title={
                  <Typography component={"div"} variant="caption">
                    {skill?.description}
                  </Typography>
                }
                placement="right-start"
                arrow
                interactive
              >
                <span style={{ cursor: "pointer" }}>
                  &nbsp;&nbsp;
                  <IconInfo />
                </span>
              </BefectiveTooltip>
            )}
          </h6>
        </div>
        {!hasEdit && (
          <div className={styles.boxSkillActions}>
            <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} alignItems={"center"} gap={'4px'}>
              {validateSkill(skill) ? (
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={
                    <CustomSwitch
                      style={{ m: 0 }}
                      checked={skill?.active}
                      onChange={(e) =>
                        updateSkillsAssessment({
                          ...skill,
                          active: e.target.checked,
                        })
                      }
                      name="active_skill"
                    />
                  }
                />
              ) : (
                <>
                  <div className={styles.infoSkillInvalidation}>
                    <span>{t("performance_assessment.messages.info_invalidation_skill")}</span>
                    <button title={t("performance_assessment.messages.info_click_here_config_skill")} className={styles.infoClickHere} onClick={() => history.push("/performance-assessment/pre-settings/skills")}><Gear size={18} weight="bold" color='#FF7A90' /></button>
                  </div>
                  {/* <FormControlLabel
                    style={{ margin: 0, cursor: "not-allowed" }}
                    disabled={true}
                    control={
                      <CustomSwitch
                        style={{ m: 0 }}
                        checked={false}
                        onChange={(e) => null}
                        name="active_skill"
                      />
                    }
                  /> */}
                </>
              )}
            </Box>
          </div>
        )}
      </header>

      {skill?.open && (
        <>
          <table className={styles.tableQuestions}>
            <thead>
              <tr>
                <th>
                  {t(
                    "performance_assessment.tab_questions_form.table.col_question"
                  )}
                </th>
                <th>
                  {t(
                    "performance_assessment.tab_questions_form.table.col_type_reply"
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {skill?.questions && skill?.questions?.length > 0 ? (
                skill?.questions?.map((question, index2) => (
                  <tr key={`question-${index2}`}>
                    <td>{`${letters[index2]}.  ${question?.questions_content}`}</td>
                    <td>{question?.scales_name}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2}>
                    {t("performance_assessment.messages.none_questions")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* <div className={styles.rowFooter}>
            <label className={styles.labelText} htmlFor='allow_responses_after_deadline'>
              <BefectiveCheckbox 
                id='allow_responses_after_deadline'
                disabled={!skill.active}
                onChange={(e) => updateSkillsAssessment({...skill, allowed_comment: e.target.checked})} 
                checked={skill?.allowed_comment}
              />
              {t("performance_assessment.tab_apresentation_form.label_not_allowed_reply")}
            </label>
          </div>         */}
        </>
      )}
    </div>
  );
}
