import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { usePagination, useTable } from 'react-table';
import styles from './ParticipantsTablePagination.module.scss';
import { Pagination } from '@material-ui/lab';
import BefectiveCheckbox from '../../_shared/BefectiveCheckbox';
import { CircularProgress } from '@mui/material';

export default function ParticipantsTablePagination({
  data, 
  selectedRows, 
  setSelectedRows, 
  count,
  pageCountRows,
  isAllSelected,
  handleSelectAll,
  isSelected,
  handleSelectRow,
  fetchingData
}) {

  const { t } = useTranslation('common');

  const columns = React.useMemo(() => {
    return [
      {
        Header: t('performance_assessment.tab_participants_form.table.col_name'),
        accessor: 'username',
      },
      {
        Header: t('performance_assessment.tab_participants_form.table.col_position'),
        accessor: 'office',
        Cell: ({ value }) => {
          return value || "----";
        },
      }
    ];
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      pageCount: count,
      autoResetPage: false,
      initialState: { pageIndex: 0, pageSize:  20 },
      pageOptions: Array.from({ length: count }, () => 1).map((_, index) => index)
    },
    usePagination
  );

  const myPageOptions = [
    {
      value: 20,
      label: '20'
    },
    {
      value: 50,
      label: '50'
    },
    {
      value: 100,
      label: '100'
    },
    {
      value: 200,
      label: '200'
    },
    {
      value: 300,
      label: '300'
    },
    {
      value: 400,
      label: '400'
    },
    {
      value: 500,
      label: '500'
    },
    {
      value: pageCountRows,
      label: 'all'
    }
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = tableInstance;


  return (
    <div className={styles.tableContainer}>
       <table {...getTableProps()}>
        <thead>
          <tr>

            <th style={{ width: '10px' }} className={styles.headColTable}>
              <BefectiveCheckbox checked={isAllSelected} onChange={handleSelectAll} name="checkboxAll" />
            </th>

            {headerGroups.map((headerGroup) => (
              <React.Fragment key={`head-table-${headerGroup.id}`}>
                {headerGroup.headers.map((column, i2) => (
                  <th {...column.getHeaderProps()} key={`col-team-${column.id}-${i2}`} className={styles.headColTable} title={t('title_sort')}>
                    {column.render('Header')}
                    {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                  </th>
                ))}
              </React.Fragment>
            ))}

          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            !fetchingData 
            ?
            (
              page && page.length > 0 ?          
                page.map((row, i1) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className={styles.rowHover} key={`row-team-${row.original.id}`}>
                      <td>
                        <BefectiveCheckbox 
                          checked={isSelected(row.original.id)}
                          onChange={(e) => {
                            e.stopPropagation(); 
                            handleSelectRow(row.original)
                          }}/>
                      </td>
                      {row.cells.map((cell, i2) => (
                        <td {...cell.getCellProps()} key={`cell-${cell.column.id}-${i2}-${i1}`} onClick={() => handleSelectRow(row.original)}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
                :
                <td colSpan={3} style={{padding: '12px 24px'}}>{t('performance_assessment.messages.no_result_found')}</td>
            )
            :
            <td colSpan={3} style={{padding: '12px 24px'}}>
              <div className={styles.containerLoading}>
                <CircularProgress size={'20px'} />
              </div>
            </td>

        }
        </tbody>
      </table>
      {
        !fetchingData &&
        <div className={styles.containerPagination}>
          <Pagination
            count={pageCount}
            page={pageIndex + 1}
            onChange={(event, page) => {
              gotoPage(page - 1)
            }}
            variant="outlined"
            shape="rounded"
            siblingCount={1}
            boundaryCount={1}
          />
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className={styles.selectOptions}
          >
            {myPageOptions.map((pageSize) => (
              <option key={`page-${pageSize.value}`} value={pageSize.value}>
                {pageSize.label === 'all' ? t('settings.teams.show_all') : `${t('settings.teams.show')} ${pageSize.label}`}
              </option>
            ))}
          </select>
        </div>
      }
    </div>
  );
}
