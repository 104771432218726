import React from 'react'
import styles from './styles.module.scss'
import HeaderForm from "../personal/HeaderForm";
import { useTranslation } from 'react-i18next'
import { IconButton } from '@material-ui/core';
import { AttachFile, GetApp } from '@material-ui/icons';
import { befectiveFormatDate } from '../../../_helpers';
import { Tooltip } from '@mui/material';


export default function FormEducationPreviewPT({
  formData,
  toogleShowForm,
  isEditing,
  isExistUserId,
  userId
}) {

  const {t, i18n} = useTranslation('common')

  const formatList = (list) => {
    if(list.length === 0) return '-----'

    if(list.length === 1) return list[0]

    const listLessLast = list.slice(0, -1)

    const listLast = list[list.length - 1]

    return `${listLessLast.join(', ')} ${t('settings.users.personal_form.and')} ${listLast}`
  }

  const downloadFile = (file) => {
    const url = file?.file_url
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'file')
    document.body.appendChild(link)
    link.click()
  }

  const formatListLanguages = (languages) => {
    if(languages.length === 0) return '-----'

    if(languages.length === 1) return languages[0][`label_${i18n.language}`]

    const languagesLessLast = languages.slice(0, -1).map(language => language[`label_${i18n.language}`])

    const languagesLast = languages[languages.length - 1][`label_${i18n.language}`]

    return `${languagesLessLast.join(', ')} ${t('settings.users.personal_form.and')} ${languagesLast}`
  }
  
  return (
    <div className={styles.containerPreview}>
      <div className={styles.card}>
        <HeaderForm formData={formData} toogleShowForm={toogleShowForm} isEditing={isEditing}  titleCreate={t('settings.users.education_form.title_create')} isExistUserId={isExistUserId} userId={userId}/>
        <div className={styles.cardContent}>
            <div className={styles.col50}>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.education_form.teaching_degree')}</span>
                <h6 className={styles.value}>{formData?.level_education?.label || '-----'}</h6>
              </div>

              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.education_form.teaching_institution')}</span>
                <h6 className={styles.value}>{formData?.educational_institution || '-----'}</h6>
              </div>
            </div>
            <div className={styles.col50}>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.education_form.formation')}</span>
                <h6 className={styles.value}>{formData?.academic_training || '-----'}</h6>
              </div>
              <div className={styles.field}>
                <span className={styles.label}>{t('settings.users.education_form.year_formation')}</span>
                <h6 className={styles.value}>{formData?.conclusion_year || '-----'}</h6>
              </div>
            </div>
        </div>
      </div>
      
      <div className={styles.card}>
        <header className={styles.cardHeader}>
          <h6>{`${t('settings.users.education_form.languages_title')}`}</h6>
        </header>
        <div className={styles.cardContent} style={{paddingTop:"0px", marginTop:'-6px'}}>
          <div className={styles.field}>
            <span className={styles.label}>{t('settings.users.education_form.languages')}</span>
            <h6 className={styles.value}>{formatListLanguages(formData?.languages)}</h6>
          </div>
        </div>
      </div>
      <div className={styles.card}>
        <header className={styles.cardHeader}>
          <h6>{`${t('settings.users.education_form.title_courses_and_certificates')} (${formData?.courses?.length})`}</h6>
        </header>
        <div className={styles.cardContent} style={{padding:'0px 20px 20px', display:'flex', flexDirection:'column'}}>
          {
            formData?.courses?.map((course, index) => (
            <div className={styles.rowCourse} key={`course-${index}`}>
              <div className={styles.boxTimeline}>
                <div className={styles.circle}></div>
                {index !== formData?.courses?.length - 1 && <div className={styles.line}></div>}
              </div>
              <div className={styles.boxInfoCourse}>
                <h6>{course?.name || "-----"}</h6>
                <span>
                  {course?.conclusion_year || '-----'} - {course?.company || '-----'} 
                  {
                    (course?.file_original_name || course?.attachment?.name) &&
                    <Tooltip 
                      title={course?.file_original_name || course?.attachment?.name} 
                      placement="right"
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        },
                      }}
                      >
                      <IconButton style={{marginLeft:'2px', marginTop:'-5px'}} aria-label="attachment" color='inherit' size="small" onClick={() => downloadFile(course)}>
                        <AttachFile fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  }
                </span>
              </div>
            </div>
            ))
          }          
        </div>
      </div>

    </div>
  )
}
