import React from 'react'
import styles from './ParticipantsStatsHeader.module.scss'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import { befectiveFormatDate, numberToPercentString } from '../../../_helpers'
import { useTranslation } from 'react-i18next';
import IconSearchLight from '../../_icons/IconSearchLight';
import IconInfoFlat from '../../_icons/IconInfoFlat';
import IconInfo from '../../_icons/IconInfo';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { Typography } from '@material-ui/core';

export default function ParticipantsStatsHeader({
  data,
  openDrawerAssessment,
  openDrawerFilter
}) {
  
  const { t, i18n } = useTranslation("common");

  return (

    <header className={styles.header}>

      <div className={styles.boxResume}>

        <div className={styles.circularProgressGeneral}>
          <CircularProgressbarWithChildren
            value={data?.general_progress || 0}
            strokeWidth={10}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: data?.status?.text_color || "#4F7091",
              trailColor: "#eee",
            })}>
            <span style={{ fontSize: 14, fontWeight: 500, color: '#4F7091' }}>
              {`${numberToPercentString(data?.general_progress || 0)}`}
            </span>
          </CircularProgressbarWithChildren>
        </div>

        <div>
          <h4 className={styles.title}>{data?.title || "-----"}</h4>
          <div className={styles.gridStates}>
            <div className={styles.colState}>
              <div className={styles.labelStats}>{t("performance_assessment.page_assessment_details.participants.header_stats.created_at")}:</div>
              <div className={styles.valueStats}>{data?.date_start ?  befectiveFormatDate(data?.date_start, i18n.language) : "-----"}</div>
            </div>

            <div className={styles.colState}>
              <div className={styles.labelStats}>{t("performance_assessment.page_assessment_details.participants.header_stats.finished_at")}:</div>
              <div className={styles.valueStats}>{data?.date_end ?  befectiveFormatDate(data?.date_end, i18n.language) : "-----"}</div>
            </div>

            <div className={styles.colState}>              
              <div className={styles.labelStats}>{t("performance_assessment.page_assessment_details.participants.header_stats.model")}:</div>
              <div className={styles.valueStats}>
                {data?.scope_label_pt ? data[`scope_label_${i18n.language}`] : "-----"}
                {
                  (data?.scope_help_text_pt || data?.scope_help_text_es || data?.scope_help_text_en) &&
                  <BefectiveTooltip
                    title={<Typography component={'div'} variant='caption'>{data[`scope_help_text_${i18n.language}`]}</Typography>}
                    placement="right-start"
                    arrow
                    interactive>
                    <span style={{ cursor: 'pointer' }}>&nbsp;&nbsp;<IconInfo /></span>
                  </BefectiveTooltip>
                }
              </div>
            </div>
            <div className={styles.colState}>
              <div className={styles.circle} style={{ backgroundColor: data?.status ? data?.status?.text_color : "#4F7091" }}></div>
              <div className={styles.labelStats}>{ data?.status ? data?.status[`title_${i18n.language}`] : "-----" }</div>
            </div>
          </div>
        </div>
      </div>
         
        <div className={styles.boxButtonHeader}>
          <button type='button' onClick={openDrawerFilter}>
            <IconSearchLight />
          </button>
          <button type='button' onClick={openDrawerAssessment}>
            <IconInfoFlat />
          </button>
        </div>
      </header>
  )
}
