import { Container, Grid, Skeleton, Typography } from '@mui/material'
import React, { useState } from 'react'

export default function SkeletonProductivityPerformancePage(props) {

  return (

    <Container component={'main'} maxWidth="xl" sx={{ flexGrow: 1, padding: '16px 0px', marginTop:'-80px' }} >

      <Grid container spacing={1} mb={'16px'} paddingX={'32px'}>
        {
          [1, 2, 3]?.map((assessment, index) => (
            <Grid item xs={12} sm={4} key={`card-new-${index}`}>
              <Skeleton width={'100%'} height={140} variant='rounded' />
            </Grid>
          ))
        }
      </Grid>

      <Grid container spacing={1} mb={'16px'} paddingX={'32px'}>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={1}>
            {
              [1, 2, 3]?.map((assessment, index) => (
                <Grid item xs={12} sm={4} key={`card-stats-${index}`}>
                  <Skeleton width={'100%'} height={140} variant='rounded' />
                </Grid>
              ))
            }                
          </Grid>

          <Grid container spacing={1} mt={'2px'}>
            <Grid item xs={12}>
              <Skeleton width={'100%'} height={470} variant='rounded' />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Skeleton width={'100%'} height={620} variant='rounded' />
        </Grid>
      </Grid>

      <Grid container spacing={1} mb={'16px'} paddingX={'32px'}>
        <Grid item xs={12}>
          <Skeleton width={'100%'} height={470} variant='rounded' />
        </Grid>
      </Grid>
    </Container>
  )
}