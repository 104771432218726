import { IconButton, Typography } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Box, Button, Grid, Popover, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authHeader, befectiveFormatDate, limitedText } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './WorkspacesList.module.scss';


export default function BoardsArchivedList({
  data,
  userDetails,
  alertActions,
  getData
}) {

  const { t, i18n } = useTranslation('common');
  const [currentBoard, setCurrentBoard] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const [fetching, setFetching] = useState(false)
  const open = Boolean(anchorEl);
  const [anchorElDeleteBoard, setAnchorElDeleteBoard] = useState(null);
  const [deleting, setDeleting] = useState(false)
  const openDeleteBoard = Boolean(anchorElDeleteBoard);
  const id = openDeleteBoard ? 'popover-delete-board' : undefined;

  const handleClickDeleteBoard = (event) => {
    setAnchorElDeleteBoard(event.currentTarget);
  };
  const handleCloseDeleteBoard = () => {
    setAnchorElDeleteBoard(null);
  };

  const handleClick = (event, board) => {
    setCurrentBoard(board)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentBoard(null)
  };

  const handleUnarchiveBoard = async () => {
    try {
      setFetching(true)
      const result = await api.put(`/project-management/boards/${currentBoard?.id}`,
        {
          ...currentBoard,
          archive: false
        },
        {
          headers: authHeader()
        }
      )
      handleClose()
      getData()
      alertActions.success(t('project_management.success.unarchived_board'))
      setFetching(false)
    } catch (error) {
      //alertActions.error(t('project_management.errors.unarchived_board'))
      setFetching(false)
    }
  }

  const handleDeleteBoard = async () => {
    try {
      setDeleting(true)
      const result = await api.delete(`/project-management/boards/${currentBoard?.id}`,
        {
          headers: authHeader()
        }
      )
      handleCloseDeleteBoard()
      handleClose()
      getData()
      alertActions.success(t('project_management.success.deleted_board'))
      setDeleting(false)
    } catch (error) {
      //alertActions.error(t('project_management.errors.deleted_board'))
      setDeleting(false)
    }
  }


  return (
    <Box display={`flex`} flexDirection={'column'} gap={'34px'}>

      {/* Section My Workspaces */}
      {
        data.length > 0 &&
        <Box className="container">
          <Box display={"flex"} flexDirection={"column"} gap={'20px'}>
            {
              data.map((item, _) => (
                <Box key={item.year}>
                  <Box component={'header'} marginBottom={'10px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                    <Typography variant='h6' style={{ marginLeft: '4px', color: '#1E6B7F' }}>{item.year}</Typography>
                  </Box>
                  {
                    item.boards.length > 0
                    &&
                    <Grid container spacing={2}>
                      {
                        item.boards.map((board, index) =>
                          <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2} position={'relative'} minHeight={'120px'}>
                            <Box
                              style={{ cursor: 'auto', borderRadius: '8px', backgroundColor: !board.color || board.color === '#ffffff' ? '#ffffff' : board.color}}
                              className={style.boardCard}
                            >
                              <Box className={style.linkBoard}>
                                <Box style={{ padding: '10px 30px 0px 10px' }} position={'relative'} zIndex={1}>
                                  {
                                    board.title.length > 40
                                      ?
                                      <Tooltip title={board.title} placement="bottom" style={{ maxWidth: '300px' }}
                                        PopperProps={{
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -10],
                                              },
                                            },
                                          ],
                                        }}
                                      >
                                        <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F', lineHeight: '18px' }}>{limitedText(board.title, 40)}</Typography>
                                      </Tooltip>
                                      :
                                      <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F', lineHeight: '18x' }}>{board.title}</Typography>
                                  }
                                </Box>
                                <Box display={'flex'} justifyContent={'space-between'} padding={'0px 10px'} marginY={'10px'} position={'relative'} zIndex={1}>
                                  {
                                    board.work_space_name.length > 30
                                      ?
                                      <Tooltip title={board.work_space_name} placement="bottom" style={{ maxWidth: '300px' }}
                                        PopperProps={{
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -10],
                                              },
                                            },
                                          ],
                                        }}
                                      >
                                        <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F', lineHeight: '18px'}}>{limitedText(board.work_space_name, 30)}</Typography>
                                      </Tooltip>
                                      :
                                      <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F', lineHeight: '18px' }}>{board.work_space_name}</Typography>
                                  }
                                </Box>
                                <Box display={'flex'} justifyContent={'space-between'} padding={'0px 30px 10px 10px'} position={'relative'} zIndex={1}>
                                  <Box>
                                    <Typography variant='subtitle2' style={{ fontSize: '11px', fontWeight: 500, color: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F'}}>
                                      {befectiveFormatDate(board.date_start, i18n.language) + " - " + befectiveFormatDate(board.date_end, i18n.language)}&nbsp;&nbsp;{`(${board.cards_stats?.total_done}/${board.cards_stats?.total_cards})`}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              
                              {board.color !== '#ffffff' && <Box className={style.boxBright}></Box>}
                            </Box>

                            <Box position={'absolute'} top={'20px'} right={'4px'} zIndex={2}>
                              <IconButton aria-label="settings" size='small' onClick={(event) => handleClick(event, board)}>
                                <MoreVert fontSize='8px' style={{ fill: board.color && board.color !== '#ffffff' ? '#ffffff' : '#1E6B7F' }} />
                              </IconButton>
                            </Box>
                          </Grid>

                        )
                      }
                    </Grid>
                  }
                </Box>
              ))
            }

            <Popover
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              disableEnforceFocus
            >
              <Box className={style.menuOptionsBoard}>
                <button disabled={fetching} className={style.menuOptionsBoardButton} onClick={handleUnarchiveBoard}>{t('project_management.btn_unarchive')}</button>
                <button disabled={fetching} aria-describedby={id} className={style.menuOptionsBoardButton} onClick={handleClickDeleteBoard}>{t('project_management.btn_delete')}</button>
              </Box>
            </Popover>

            <Popover
              id={id}
              open={openDeleteBoard}
              anchorEl={anchorElDeleteBoard}
              onClose={handleCloseDeleteBoard}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              disableEnforceFocus
            >
              <Box
                sx={{
                  backgroundColor: '#FFE0DF'
                }}
              >
                <Typography variant="subtitle2" style={{ padding: '12px', color: '#FC6662', fontSize: '12px', fontWeight: '600' }}>{t('project_management.boards.title_dialog_delete')}</Typography>
                <Box paddingBottom={2} paddingRight={2} display={'flex'} style={{ gap: '6px' }} justifyContent={'flex-end'}>
                  <Button
                    onClick={handleCloseDeleteBoard}
                    disabled={deleting}
                    style={{
                      width: '42px !important',
                      height: '25px',
                      backgroundColor: '#fff',
                      color: '#FC6662',
                      fontSize: '10px',
                      textTransform: 'capitalize'
                    }}
                  >
                    {t('confirmDialog.no')}
                  </Button>
                  <Button
                    onClick={handleDeleteBoard}
                    disabled={deleting}
                    style={{
                      width: '42px !important',
                      height: '25px',
                      backgroundColor: '#FC6662',
                      color: '#fff',
                      fontSize: '10px',
                      textTransform: 'capitalize'
                    }}
                  >
                    {t('confirmDialog.yes')}
                  </Button>
                </Box>
              </Box>
            </Popover>

          </Box>
        </Box>
      }

    </Box>
  )
}
