import React, { useEffect } from 'react'
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import { connect } from 'react-redux'
import { alertActions } from '../../_actions'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'

function PerformanceAssessmentDashboardPage({
  alertActions,
  userDetails,
  filter
}) {

  const { t, i18n } = useTranslation('common');

  useEffect(() => {    
    document.title = `Befective | ${t('menu.performance_assessment.dashboard')} `;  
  }, [])

  return (
    <section className="mainContent">
      
      <Toolbar />

      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhat={'disabled'}
      />

      <main className='mainContentPage'>
        <div>PerformanceAssessmentDashboardPage</div>
      </main>
    </section>
  )
}



function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceAssessmentDashboardPage)