import React, { useEffect, useState } from 'react'
import style from './styles.module.scss';
import { authHeader, secondsToHm } from '../../../../_helpers';
import IconInfo from '../../../_icons/IconInfo';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Tooltip } from '@mui/material';
import Widget32Skeleton from './Widget32Skeleton';
import ReactApexChart from 'react-apexcharts';
import BefectiveTooltip from '../../../_shared/BefectiveTooltip';
import { api } from '../../../../_helpers/api';

export default function Widget32({
  alertActions,
  currentDate,
  width,
  filters
}) {
  const { t, i18n } = useTranslation('common');
  const [fetching, setFetching] = useState(true);
  const [filterSelected, setFilterSelected] = useState("best");
  
  const [data, setData] = useState([])


  const getData = async ({filterTeams, filterTeammates, filterAssessment}) => {
    try {
      setFetching(true)
      const filterOrder = `&order=${filterSelected}`
      const response = await api.get(`/performance-assessment/widgets/skills?current_day=${currentDate.format('YYYY-MM-DD')}${filterOrder}${filterTeams}${filterTeammates}${filterAssessment}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget32!!!')
      }
      const { data } = response
      setData(data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData({...filters})
  }, [filterSelected])

  return (
    !fetching
      ?

      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>
                <h6 className={style.title}>{t('widgets.widget32.title')}</h6>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('widgets.widget32.tooltip')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo/></div>
                </BefectiveTooltip>
              </div>
              <select className={style.select} name="filterSelect" id="filterSelect" value={filterSelected} onChange={(e) => setFilterSelected(e.target.value)}>
                <option value="best">{t('widgets.options.five_best')}</option>
                <option value="worst">{t('widgets.options.five_worst')}</option>
              </select>
            </div>
            {
              data && data.length > 0
              ?
              <Grid container spacing={2} sx={{borderBottom: '1px solid #E4EAEF'}}>
                <Grid item xs={6}>
                  <div className={style.containerBar}>
                    {
                      data.map((item, index) => (

                        <Tooltip title={`${index + 1}. ${item?.name} - ${item?.avg || 0}`} followCursor>
                          <div className={style.verticalBar} key={`bar-${item?.skills_id}`} style={{backgroundColor: item?.classification?.bg_color, height: `${item?.percent || 0}%`}}>
                            <div className={style.labelTop} style={{color: item?.classification?.bg_color}}>{item?.avg || 0}</div>
                          </div>
                        </Tooltip>
                      ))
                    }
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={style.containerList}>
                    {
                      data.map((item, index) => (
                        <div className={style.listItem} key={`list-${item?.skills_id}`}>
                          <div className={style.labelName} style={{color: item?.classification?.bg_color}}>{`${index + 1}. ${item?.name}`}</div>
                          <div className={style.labelScore} style={{color: item?.classification?.bg_color}}>{item?.avg || 0}</div>
                        </div>
                      ))
                    }
                  </div>
                </Grid>
              </Grid>
              :
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box component={'div'} sx={{ width: '100%', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography component={'span'} sx={{color:"#4F7091", fontSize:"14px"}}>{t('widgets.no_data_available')}</Typography>
                  </Box>
                </Grid>
              </Grid>
            }
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget32Skeleton />
      </Grid>

  )
}
