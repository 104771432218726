import React from "react";
import { useTranslation } from "react-i18next";
import SvgStep1 from "./SvgStep1";
import SvgStep2 from "./SvgStep2";
import SvgStep3 from "./SvgStep3";
import SvgStep4 from "./SvgStep4";

export default function TabsFormComponent({ currentTab, updateTabValue, hasEdit, formAssessment, skillsAssessment=[] }) {

  const { t, i18n } = useTranslation("common");


  const handleNextStep = (event, step) => {
    event.preventDefault();
    switch (step) {
      case "apresentation":
        updateTabValue("apresentation");
        break;
      case "form":
        if(hasEdit || formAssessment?.title !== ""){
          updateTabValue("form")
        }
        break;
      case "participants":
        if(hasEdit || (formAssessment?.title !== "" && skillsAssessment.filter(skill => skill.active).length > 0)){
          updateTabValue("participants")
        }
        break;
      case "settings":
        if(hasEdit || (formAssessment?.title !== "" && skillsAssessment.filter(skill => skill.active).length > 0 && formAssessment?.participants.length > 0)){
          updateTabValue("settings")
        }
        break;
      default:
        break;
    }
  }

  const validationStep = (step) => {
    switch (step) {
      case "apresentation":
        return true;
      case "form":
        if(hasEdit || formAssessment?.title !== ""){
          return true
        }
        return false
      case "participants":
        if(hasEdit || (formAssessment?.title !== "" && skillsAssessment.filter(skill => skill.active).length > 0)){
          return true
        }
        return false
      case "settings":
        if(hasEdit || (formAssessment?.title !== "" && skillsAssessment.filter(skill => skill.active).length > 0 && formAssessment?.participants.length > 0)){
          return true
        }
        return false
      default:
        break;
    }
  }

  return (
    <div className="mainFilterbar">
      <div className="filters">
        <a
          className={currentTab === "apresentation" ? "active" : ""}
          onClick={(e) => handleNextStep(e, "apresentation")}
          href=""
        >
          <button style={{cursor:'pointer'}} className={"settingsNav"}>
            1. {t("performance_assessment.tabs_form_new_assessments.apresentation")}
          </button>
        </a>
        <a
          className={currentTab === "form" ? "active" : ""}
          onClick={(e) => handleNextStep(e, "form")}
          href=""
        >
          <button style={{cursor: validationStep("form") ? 'pointer' : 'not-allowed'}} className={"settingsNav"}>
           2. {t("performance_assessment.tabs_form_new_assessments.form")}
          </button>
        </a>
        <a
          className={currentTab === "participants" ? "active" : ""}
          onClick={(e) => handleNextStep(e, "participants")}
          href=""
        >
          <button style={{cursor: validationStep("participants") ? 'pointer' : 'not-allowed'}} className={"settingsNav"}>
            3. {t("performance_assessment.tabs_form_new_assessments.participants")}
          </button>
        </a>
        <a
          className={currentTab === "settings" ? "active" : ""}
          onClick={(e) => handleNextStep(e, "settings")}
          href=""
        >
          <button style={{cursor: validationStep("settings") ? 'pointer' : 'not-allowed'}} className={"settingsNav"}>
            4. {t("performance_assessment.tabs_form_new_assessments.settings")}
          </button>
        </a>
      </div>
      <div>
        {
          currentTab === "apresentation" && (
           <SvgStep1 handleNextStep={handleNextStep} validationStep={validationStep}/>
          )
        }
        {
          currentTab === "form" && (
           <SvgStep2  handleNextStep={handleNextStep} validationStep={validationStep}/>
          )
        }
        {
          currentTab === "participants" && (
           <SvgStep3  handleNextStep={handleNextStep} validationStep={validationStep}/>
          )
        }
        {
          currentTab === "settings" && (
           <SvgStep4  handleNextStep={handleNextStep} validationStep={validationStep}/>
          )
        }
      </div>
    </div>
  );
}
