import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Modal,
  Slide,
  makeStyles,
} from "@material-ui/core";
import styles from "./CreateScaleAnswerModal.module.scss";
import { useTranslation } from "react-i18next";
import { api } from "../../../_helpers/api";
import { authHeader } from "../../../_helpers";
import IconClose from "../../_icons/IconClose";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function CreateScaleAnswerModal({ openModal, closeModal, getScalesAnswers, scaleAnswerToEdit = null, alertActions}) {

  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const [isSubmit, setIsSubmit] = useState(false);

  const [form, setForm] = useState({
    name: "",
    description: "",
    is_objective: "1"
  });

  const [formErrors, setFormErrors] = useState({
    name: ""
  });

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (form.name === "") {
      setFormErrors({
        ...formErrors,
        name: t("validation.required"),
      });
    }

    if (form.name !== "") {
      setIsSubmit(true);

      const payload = {
        ...form,
        is_objective: form.is_objective === "1" ? true : false
      };

      if (scaleAnswerToEdit) {
        api
          .put(`/performance-assessment/scales/${scaleAnswerToEdit.id}`, payload, {
            headers: authHeader(),
          })
          .then((res) => {
            getScalesAnswers();
            closeModal();
            setForm({ name: "", description: "", is_objective: "1" });
            setFormErrors({ name: "" });
            setIsSubmit(false);
            alertActions.success(t("performance_assessment.messages.success_update_scale"));
          })
          .catch((err) => {
            console.log(err);
            setIsSubmit(false);
            alertActions.error(t("performance_assessment.messages.error_update_scale"));
          });
          return
      }
      
      api
        .post(`/performance-assessment/scales`, payload, {
          headers: authHeader(),
        })
        .then((res) => {
          const { data } = res;

          if(data.is_objective === true){
            getScalesAnswers(true, res.data);
          }else{
            getScalesAnswers(true, null);
          }
          closeModal();
          setForm({ name: "", description: "", is_objective: "1" });
          setFormErrors({ name: "" });
          setIsSubmit(false);
          alertActions.success(t("performance_assessment.messages.success_create_scale"));
        })
        .catch((err) => {
          console.log(err);
          setIsSubmit(false);
          alertActions.error(t("performance_assessment.messages.error_create_scale"));
        });

    }
  }

  useEffect(() => {
    if (scaleAnswerToEdit) {
      setForm({
        name: scaleAnswerToEdit.name,
        description: scaleAnswerToEdit.description,
        is_objective: scaleAnswerToEdit.is_objective ? "1" : "0"
      });
    }else{
      setForm({ name: "", description: "", is_objective: "1" });
    }
  }, [scaleAnswerToEdit]);


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${styles.modal}`}
      open={openModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: "#205B6F", opacity: 0.8 },
      }}
      sx={{ border: "none" }}
      style={{ outline: 0 }}
    >
      <Slide
        direction="down"
        in={openModal}
        style={{ outline: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.containerModal}>

          <button
              onClick={closeModal}
              className={styles.close}
          >
            <IconClose />
          </button>


          <header>
            <h4>
              {scaleAnswerToEdit ? t("performance_assessment.page_scales_answers.modal_create_and_update.title_edit") : t("performance_assessment.page_scales_answers.modal_create_and_update.title_new")}
            </h4>
          </header>

          <form
            onSubmit={handleSubmit}
          >
            <div className={styles.fieldBox}>
              <label htmlFor="name">{t("performance_assessment.page_scales_answers.modal_create_and_update.name_scale")}*</label>
              <input type="text" id="name" name="name" onChange={onChangeInput} value={form.name} placeholder={t("performance_assessment.page_scales_answers.modal_create_and_update.placeholder_name")}/>
              {formErrors.name && <p className={styles.error}>{formErrors.name}</p>}
            </div>

            <div className={styles.fieldBox}>
              <label htmlFor="description">{t("performance_assessment.page_scales_answers.modal_create_and_update.description_scale")}</label>
              <textarea id="description" name="description" onChange={onChangeInput} rows={3} value={form.description} placeholder={t("performance_assessment.page_scales_answers.modal_create_and_update.placeholder_description")}></textarea>
            </div>  

             <div className={styles.fieldBox}>
              <label htmlFor="description">{t("performance_assessment.page_scales_answers.modal_create_and_update.type_scale")}</label>
              <select className={styles.customSelect} name="is_objective" id="is_objective" onChange={onChangeInput} value={form.is_objective}>
                <option value="1">{t("performance_assessment.page_scales_answers.modal_create_and_update.type_scale_objective")}</option>
                <option value="0">{t("performance_assessment.page_scales_answers.modal_create_and_update.type_scale_discursive")}</option>
              </select>
            </div>            
          
            <footer>            
              <button className={styles.buttonCancel} type="button" onClick={closeModal}>
                {t("performance_assessment.actions.cancel")}
              </button>
              <button className={styles.buttonSave} disabled={isSubmit}>
                {t("performance_assessment.actions.save")}
              </button>
            </footer> 
          </form>
        </div>
      </Slide>
    </Modal>
  );
}
