// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".-un5g3zwc0hw0m71oAq\\+fA\\=\\= {\n  border: none;\n  background-color: #FDBE2B;\n  color: #106276;\n  font-size: 14px;\n  font-weight: 500;\n  padding: 12px;\n  border-radius: 2px;\n  cursor: pointer;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 6px;\n}\n\n.-un5g3zwc0hw0m71oAq\\+fA\\=\\=:hover {\n  transition: 0.2s all ease-in-out;\n  background-color: #f9a41a;\n}", "",{"version":3,"sources":["webpack://./src/pages/PerformanceAssessmentFeedbackReportPage/styles.module.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EAEA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;AADF;;AAIA;EACE,gCAAA;EACA,yBAAA;AADF","sourcesContent":[".buttonCreateActionPlan{\n  // min-width: 120px;\n  border: none;\n  background-color: #FDBE2B;\n  color: #106276;\n  font-size: 14px;\n  font-weight: 500;\n  padding: 12px;\n  border-radius: 2px;\n  cursor: pointer;\n\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 6px;\n}\n\n.buttonCreateActionPlan:hover{\n  transition: .2s all ease-in-out;\n  background-color: #f9a41a;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonCreateActionPlan": "-un5g3zwc0hw0m71oAq+fA=="
};
export default ___CSS_LOADER_EXPORT___;
