import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { api } from "../../../_helpers/api";
import IconClose from "../../_icons/IconClose";
import { Skeleton } from "@material-ui/lab";
import './PerformanceAssessmentsDetailsDrawer.scss'
import ReactApexChart from "react-apexcharts";
import GaugeChart from "./GaugeChart";
import GaugeComponent from "react-gauge-component";
import { authHeader } from "../../../_helpers";

export default function PerformanceAssessmentsDetailsDrawer({
  openDrawer,
  closeDrawer,
  currentData
}) {
  const { t, i18n } = useTranslation("common");
  const [assessmentDetails, setAssessmentDetails] = useState(null);

  const [chartRadarData, setChartRadarData] = useState({
    series: [],
    options: {
      colors: [],
      chart: {
        height: 250,
        type: 'radar',
        toolbar: {
          show: false // Disable the export options
        }
      },
      yaxis: {
        stepSize: 2,
        labels: {
          style: {
            fontSize: '8px',
          }
        }
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            width: '80px',
            fontSize: '8px'
          }
        }
      },
      legend: {
        show: false // Disable the legend
      },
      plotOptions: {
        radar: {
          size: 80,
        }
      }
    }
  })
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {

    try {
      
      setIsLoading(true);

      const result = await api.get(`/performance-assessment/answers/hexagono?performance_assessment_id=${currentData.performance_assessment_id}&evaluated_id=${currentData.id}`, {
      	headers: authHeader()
      })
      
      setAssessmentDetails(result?.data);
      
      setChartRadarData({
        ...chartRadarData,
        series: result?.data?.series || [],
        options: {
          ...chartRadarData.options,
          xaxis: {
            ...chartRadarData.options.xaxis,
            categories: result?.data?.categories || []
          },
          colors: result?.data?.evaluators?.map((item) => item?.color) || []
        }
      })
      
      setIsLoading(false);

    } catch (error) {
      //alertActions.error(error)
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        className={"overlay" + (openDrawer ? " open" : "")}
        onClick={closeDrawer}
      ></div>
      <section
        className={
          "drawer drawer480" + (openDrawer ? " open" : "")
        }
      >
        
        <button onClick={closeDrawer} className="close">
          <IconClose />
        </button>
        {isLoading ? (
          <div className="containerDrawerAssessmentsDetails">
            <header>
              <Skeleton width="70%" height={20} />
            </header>

            <div className="container">
              <div className="header">
                <h3>
                  <Skeleton width="120px" />
                </h3>
              </div>
              <div className="content">
                <div className="row">
                  <div className="col">
                    <Skeleton variant="rect" width="100%" height={'200px'} />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h6 style={{margin:'10px 0px'}}>
                      <Skeleton width="80%" />
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h6 style={{margin:'10px 0px'}}>
                      <Skeleton width="80%" />
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Skeleton variant="rect" width="100%" height={'200px'} />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h6 style={{margin:'10px 0px'}}>
                      <Skeleton width="80%" />
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h6 style={{margin:'10px 0px'}}>
                      <Skeleton width="80%" />
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="containerDrawerAssessmentsDetails">
            <header>
              <h3>{assessmentDetails?.username}</h3>
            </header>
            <div className="container">
                <div className="header">
                  <h3>{t('performance_assessment.page_assessment_details.replies.details')}</h3>
                </div>                
                <div className="content">                    
                    <div id="chartGauge">
                      {/* <GaugeChart score={assessmentDetails?.score_general} label={`${t('performance_assessment.page_assessment_details.replies.score_general')}: ${assessmentDetails?.score_general_label}`} fillColors={assessmentDetails?.score_colors} /> */}
                      <GaugeComponent
                        type="semicircle"
                        arc={{
                          width: 0.05,
                          padding: 0.005,
                          cornerRadius: 1,
                          // gradient: true,
                          subArcs: [
                            {
                              limit: 2,
                              color: '#FF7D25'
                            },
                            {
                              limit: 3,
                              color: '#FDBE2B'
                            },
                            {
                              limit: 4,
                              color: '#86B2B3'
                            },
                            {
                              limit: 5,
                              color: '#22BEAD'
                            },
                            {
                              limit: 6,
                              color: '#1E6B7F'
                            }
                          ]
                        }}
                        pointer={{type: "arrow", elastic: true, color:assessmentDetails?.classification?.text_color || "#4F7091"}}
                        value={Number(assessmentDetails?.general_avg)}
                        labels={{
                          valueLabel: { 
                            style: {
                              fill: assessmentDetails?.classification?.text_color || "#4F7091", // Custom color for the main value
                              fontSize: '24px', // You can adjust the font size as needed
                              fontWeight: 'bold', // You can also set the font weight
                              textShadow: 'none'
                            },
                            formatTextValue: value => String(assessmentDetails?.general_avg),
                            hide: true
                          }
                          
                        }}
                        minValue={1}
                        maxValue={6}
                      />
                      <div className="labelScoreGeneral">
                        <h4 style={{color:assessmentDetails?.classification?.text_color || "#4F7091"}}>{assessmentDetails?.general_avg || 0}</h4>
                        <p style={{color:'#1E6B7F'}}>{t('performance_assessment.page_assessment_details.replies.score_general')}: {assessmentDetails?.classification?.label || "-----"}</p>
                      </div>
                    </div>
                    
                    <div className="row">
                        <div className="col">
                            <h6 >{`${t('performance_assessment.page_assessment_details.replies.score_assessment_previous')}`}</h6>
                            <div>{assessmentDetails?.last_avg_evaluated || 0}</div>
                        </div>
                        <div className="col">
                            <h6>{`${t('performance_assessment.page_assessment_details.replies.score_change')}`}</h6>
                            <div className={`${assessmentDetails?.score_change > 0 ? 'colorSuccess' : 'colorError'}`}>
                              {`${assessmentDetails?.score_change > 0 ? '+' : ( assessmentDetails?.score_change < 0 ? '-' : '')}${assessmentDetails?.score_change}`}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col" style={{ width: "auto" }}>
                            <h6 className="colorSuccess">{`${t('performance_assessment.page_assessment_details.replies.strong_point')}`}</h6>
                            <div>{assessmentDetails?.strong_point?.name || "-----"}: {assessmentDetails?.strong_point?.skill_avg || 0}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col" style={{ width: "auto" }}>
                            <h6 className="colorWarning">{`${t('performance_assessment.page_assessment_details.replies.weak_point')}`}</h6>
                            <div>{assessmentDetails?.weak_point?.name || "-----"}: {assessmentDetails?.weak_point?.skill_avg || 0}</div>
                        </div>
                    </div>
                </div>

                <div className="header">
                  <h3>{t('performance_assessment.page_assessment_details.replies.radar')}</h3>
                </div>     

                <div className="content">                    
                    <div id="chartRadar">
                      <ReactApexChart options={chartRadarData.options} series={chartRadarData.series} type="radar" height={250} />
                    </div>
                    {
                      assessmentDetails?.evaluators?.length > 0 && (
                        <div className="listEvaluators">
                          {
                            assessmentDetails?.evaluators?.map((evaluator, index) => (
                              <div className="itemEvaluator" key={`evaluator-${index}`}>
                                <div className="boxNameEvaluator">
                                  <div className="circle" style={{ backgroundColor: evaluator?.color }}></div>
                                  <div className="evaluatorName">{evaluator?.self_assessment === true ? t('performance_assessment.page_assessment_details.replies.table.col_auto_evaluated') : evaluator?.username}</div>
                                </div>
                                <div className="number">
                                  {Number(evaluator?.avg || 0).toFixed(2)}
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }
                    
                </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}
