import React, { useEffect } from 'react'
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import { connect } from 'react-redux'
import { alertActions } from '../../_actions'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import style from './styles.module.scss';
import Paper from '@material-ui/core/Paper'
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { ChartPieSlice } from 'phosphor-react'
import { history } from '../../_helpers'


function PerformanceAssessmentReportsPage({
  alertActions,
  userDetails,
  filter
}) {  
  
  const { t, i18n } = useTranslation('common');

  useEffect(() => {    
    document.title = `Befective | ${t('menu.performance_assessment.reports')} `;  
  }, [])


  return (
    <section className="mainContent">
      
      <Toolbar />

      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhat={'disabled'}
        showShareBtn={false}
      />

      <Box className={style.containerReportsPage}>
        <Paper className={style.paperContainer} elevation={0}>
          <Typography variant="h6" className={style.title}>{t('productive_reports.title_page_reports')}</Typography>
          <Grid container spacing={1}>
            
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
              <Card className={style.cardReport} variant="outlined" onClick={() => history.push('/performance-assessment/reports/assessments')}>
                <CardContent className={style.cardReportContent}>
                  <ChartPieSlice size={24} color="#22BEAD" weight="bold" />
                  <Typography variant="h6" className={style.cardReportTitle}>{t('performance_assessment_reports.card_reports.assessments')}</Typography>
                  <Typography variant="p" className={style.cardReportSubtitle}>{t('performance_assessment_reports.card_reports.subtitle_assessments')}</Typography>
                </CardContent>
              </Card>
            </Grid>
              
          </Grid>
             
        </Paper>
      </Box>
    </section>
  )
}



function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceAssessmentReportsPage)