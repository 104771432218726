import React from 'react'

export default function SvgStep2({handleNextStep = () => null, validationStep = () => false}) {
  return (    
    <svg xmlns="http://www.w3.org/2000/svg" width="300" height="30" viewBox="0 0 300 30">
      <g id="_2" transform="translate(2215 4968)">
        <rect id="Retângulo_1997" width="280" height="3" transform="translate(-2215 -4953)" fill="#ecf0f4"/>
        <rect id="Retângulo_2000" width="95" height="3" transform="translate(-2207 -4953)" fill="#afc5d6"/>
        <rect id="Retângulo_1996" width="30" height="30" rx="15" transform="translate(-2215 -4968)" fill="#afc5d6" style={{cursor: validationStep("apresentation") ? 'pointer' : 'not-allowed'}} onClick={(e) => handleNextStep(e, "apresentation")}/>
        <g id="Retângulo_1999" data-name="Retângulo 1999" transform="translate(-2035 -4968)" fill="#ecf0f4" stroke="#e4eaef" strokeWidth="1"  style={{cursor: validationStep("participants") ? 'pointer' : 'not-allowed'}} onClick={(e) => handleNextStep(e, "participants")}>
          <rect width="30" height="30" rx="15" stroke="none"/>
          <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="none"/>
        </g>
        <g id="Retângulo_2001" data-name="Retângulo 2001" transform="translate(-1945 -4968)" fill="#ecf0f4" stroke="#e4eaef" strokeWidth="1" style={{cursor: validationStep("settings") ? 'pointer' : 'not-allowed'}} onClick={(e) => handleNextStep(e, "settings")}>
          <rect width="30" height="30" rx="15" stroke="none"/>
          <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="none"/>
        </g>
        <rect id="Retângulo_1998" width="30" height="30" rx="15" transform="translate(-2125 -4968)" fill="#afc5d6" style={{cursor: validationStep("form") ? 'pointer' : 'not-allowed'}} onClick={(e) => handleNextStep(e, "form")}/>
        <text id="_1" transform="translate(-2200 -4948)" fill="#fff" fontSize="14" fontFamily="Roboto-Medium, Roboto" fontWeight="500" style={{cursor: validationStep("apresentation") ? 'pointer' : 'not-allowed'}} onClick={(e) => handleNextStep(e, "apresentation")}><tspan x="-3.979" y="0">1</tspan></text>
        <text id="_2-2" transform="translate(-2110 -4948)" fill="#fff" fontSize="14" fontFamily="Roboto-Medium, Roboto" fontWeight="500" style={{cursor: validationStep("form") ? 'pointer' : 'not-allowed'}} onClick={(e) => handleNextStep(e, "form")}><tspan x="-3.979" y="0">2</tspan></text>
        <text id="_3" transform="translate(-2020 -4948)" fill="#24677e" fontSize="14" fontFamily="Roboto-Regular, Roboto"><tspan x="-3.931" y="0" style={{cursor: validationStep("participants") ? 'pointer' : 'not-allowed'}} onClick={(e) => handleNextStep(e, "participants")}>3</tspan></text>
        <text id="_4" transform="translate(-1930 -4948)" fill="#24677e" fontSize="14" fontFamily="Roboto-Regular, Roboto" style={{cursor: validationStep("settings") ? 'pointer' : 'not-allowed'}} onClick={(e) => handleNextStep(e, "settings")}><tspan x="-3.931" y="0">4</tspan></text>
      </g>
    </svg>
  )
}
