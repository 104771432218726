import { Backdrop, Modal, Slide, makeStyles } from '@material-ui/core';
import { AttachFile, Close } from '@material-ui/icons';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';

import style from '../teams_settings/ModalImportTeamStructure.module.scss';


const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export function ModalImportUsers({
  openModalImportUsers,
  setOpenModalImportUsers,
  alertActions,
  setAnchorEl,
  getUsersData
}) {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false);
  const [errorImport, setErrorImport] = useState(null);
  const [form, setForm] = useState({
    file: null
  })
  const [formErrors, setFormErrors] = useState({
    file: null
  })

  const onChangeInputFile = (e) => {
    const file = e.target.files[0];
    setForm({ ...form, file: file });
    setFormErrors({ ...formErrors, file: null })
    e.target.value = '';

    if(errorImport) {
      setErrorImport(null)
    }
  }

  const removeFile = (file) => {
    setForm({ ...form, file: form.file.filter(f => f !== file) });
  }

  const validateForm = (form) => {
    const errors = {};
    if (!form.file) {
      errors.file = t('settings.users.modal_import_users.file_required')
    }

    if (form.file) {

      const acceptFiles = ['XLSX']

      if ((form.file.size / 1000000) > 2) {
        errors.file = t('settings.users.modal_import_users.size_invalid_file')
      }

      const fileNameSplitted = form.file.name.split('.')
      const fileExtension = fileNameSplitted[fileNameSplitted.length - 1]

      if (!acceptFiles.includes(fileExtension.toUpperCase())) {
        errors.file = t('settings.users.modal_import_users.type_invalid_file')
      }

    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(form))
    setSubmitted(true)
  }

  function resetForm() {
    setForm({
      file: null
    })

    setFormErrors({
      file: null
    })

    setErrorImport(null)
  }

  async function importUsers() {
    try {
      setIsSubmitting(true)

      const formData = new FormData();

      formData.append('files', form.file)

      const res = await api.post(
        `/users/import-user-full`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': authHeader().Authorization
          },
        }
      )

      handleCloseModalImportUsers()
      getUsersData()
      alertActions.success(t("settings.users.modal_import_users.success_import_users"))
    } catch (error) {
      console.log(error)
      setErrorImport(error.response?.data?.message || t("settings.users.modal_import_users.error_import_users"))
      setForm({ ...form, file: null })
      setFormErrors({ ...formErrors, file: null })
    }finally{
      setIsSubmitting(false)
    }
  }

  function handleCloseModalImportUsers() {
    setOpenModalImportUsers(false)
    setAnchorEl(null)
    resetForm()
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submitted) {
      importUsers()
    } else {
      setSubmitted(false)
    }
  }, [formErrors, submitted])

  // useEffect(() => {
  //   if (errorImport) {
  //     const timer = setTimeout(() => setErrorImport(null), 5000);
  //     return () => clearTimeout(timer); // Limpa o timeout quando o componente desmonta ou o estado muda
  //   }
  // }, [errorImport]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModalImportUsers}
      onClose={() => !isSubmitting && handleCloseModalImportUsers()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModalImportUsers} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          zIndex={2}
          py="32px"
          px="26px"
          borderRadius="8px"
          width={380}
          component="form"
          noValidate
          autoComplete='off'
        >
          <Typography component="h1" fontSize={18} color="#205B6F" fontWeight={500}>
            {t("settings.users.modal_import_users.title")}
          </Typography>

          <Box display="flex" flexDirection="column" gap={2}>

            <Box display="flex" flexDirection="column" mt={1}>


              <ul className={style.listInfo}>
                <li className={style.listItemInfo}>
                  {`${t("settings.users.modal_import_users.info_1")}`}
                  <a target='_blank' href="https://befective-staging.s3.amazonaws.com/plan_model_import_users.xlsx" download>link.</a>
                </li>
                <li className={style.listItemInfo}>
                  {t("settings.users.modal_import_users.info_2")}
                </li>
              </ul>
              <label className={style.inputFileBox}>
                <input
                  type="file"
                  onChange={onChangeInputFile}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <AttachFile fontSize='small' />
                <div style={{ color: '#205B6F' }}>
                  {
                    !form.file
                      ?
                      t("settings.users.modal_import_users.placeholder_file")
                      :
                      form.file.name.length > 40
                        ?
                        <span title={form.file.name}>
                          {`${form.file.name.slice(0, 20)} [...] ${form.file.name.slice(form.file.name.length - 20, form.file.name.length)}`}
                        </span>
                        :
                        form.file.name

                  }
                </div>
              </label>
              {formErrors.file &&
                <div
                  className={style.error}
                  style={{ marginTop: "2px", marginBottom: 0 }}
                >
                  {formErrors.file}
                </div>
              }
            </Box>
          </Box>
          {
            errorImport &&
            <div className={style.alertBox}>
              {errorImport}
            </div>
          }

          <Box display="flex" alignItems="center" justifyContent="flex-end" gap="4px" marginTop={'20px'}>
            <Button
              style={{
                backgroundColor: '#E3EFFF',
                paddingY: '12px',
                paddingX: '28px',
                borderRadius: '2px',
                width: '105px',
                height: '42px'
              }}
              className={style.cancelBtn}
              onClick={() => !isSubmitting && handleCloseModalImportUsers()}
              disabled={isSubmitting}
            >
              <Typography component="span" fontSize={12} color='#187DFF' textTransform="initial" fontWeight={500}>
                {t("settings.users.modal_import_users.cancel")}
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor: '#187DFF',
                paddingY: '12px',
                paddingX: '28px',
                borderRadius: '2px',
                width: '105px',
                height: '42px'
              }}
              className={style.save}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={14} />
              ) : (
                <Typography
                  component="span"
                  fontSize={12}
                  color='#fff'
                  textTransform="initial"
                  fontWeight={500}
                >
                  {t("settings.users.modal_import_users.import")}
                </Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
