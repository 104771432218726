import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { api } from "../../../_helpers/api";
import IconClose from "../../_icons/IconClose";
import { Skeleton } from "@material-ui/lab";
import './HistoryAssessmentsDrawer.scss'
import { authHeader, befectiveFormatDate } from "../../../_helpers";

export default function HistoryAssessmentsDrawer({
  openDrawer,
  closeDrawer,
  currentData
}) {
  const { id } = useParams();
  const { t, i18n } = useTranslation("common");
  const [historyAssessments, setHistoryAssessments] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getHistoryAssessments = async () => { 

    try {
      
      setIsLoading(true);

      const result = await api.get(`/performance-assessment/results/evaluated-historic?evaluated_id=${currentData.id}`, {
      	headers: authHeader()
      })

      console.log(currentData, result.data)
      
      setHistoryAssessments(result?.data);
      
      setIsLoading(false);

    } catch (error) {
      //alertActions.error(error)
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getHistoryAssessments();
  }, []);

  return (
    <>
      <div
        className={"overlay" + (openDrawer ? " open" : "")}
        onClick={closeDrawer}
      ></div>
      <section
        className={
          "drawer drawer400" + (openDrawer ? " open" : "")
        }
      >
        <button onClick={closeDrawer} className="close">
          <IconClose />
        </button>

        {isLoading ? (
          <div className="containerDrawerHistoryAssessments">
            <header>
              <Skeleton width="70%" height={20} />
            </header>
            <table>
              <thead>
                <tr>
                  <th>{t('performance_assessment.page_assessment_details.results.history_date_finished')}</th>
                  <th style={{textAlign: 'right'}}>{t('performance_assessment.page_assessment_details.results.history_score_total_avg')}</th>
                </tr>
              </thead>
              <tbody>
                {
                    [0,1,2].map((item, index) => (
                      <tr key={`history-fake-${index}`}>
                        <td><div><Skeleton width="120px" /></div></td>
                        <td><div style={{display:'flex', justifyContent: 'flex-end'}}><Skeleton width="100px" /></div></td>
                      </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        ) : (
          <div className="containerDrawerHistoryAssessments">
            <header>
              <h3>{historyAssessments?.username || "-----"}</h3>
            </header>
            <table>
              <thead>
                <tr>
                  <th>{t('performance_assessment.page_assessment_details.results.history_date_finished')}</th>
                  <th style={{textAlign: 'right'}}>{t('performance_assessment.page_assessment_details.results.history_score_total_avg')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  historyAssessments?.data.length > 0
                  ?
                    historyAssessments?.data.map((item, index) => (
                      <tr key={`history-${index}`}>
                        <td>{befectiveFormatDate(item?.date_end, i18n.language)}</td>
                        <td style={{color: item?.classification?.text_color || '#4F7091', textAlign: 'right', fontWeight: 500}}>{item?.score_avg || 0}</td>
                      </tr>
                  ))
                  :
                    <tr>
                      <td colSpan={2}>{t('performance_assessment.page_assessment_details.results.no_history_found')}</td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        )}
      </section>
    </>
  );
}
