import React from 'react'
import { useTranslation } from 'react-i18next';

export default function NoData() {
  const { t } = useTranslation('common');
  return (
    <div className="containerNoData" >
      <h6 className="titleNoData">{t("messages_no_data.assess.title")}</h6>
      <p className="simpleTextNoData lineHeight" dangerouslySetInnerHTML={{ __html: t("messages_no_data.assess.subtitle")}}></p>
    </div>
  )
}
