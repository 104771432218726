import React, { useEffect, useState } from 'react';
import styles from './CardManageResultIndicators.module.scss';
import { useTranslation } from 'react-i18next';
import { Add, Close, Delete, DragIndicator, Save } from '@material-ui/icons';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';

const RatingEdit = ({
    provided,
    resultIndicator,
    ratings,
    rating,
    addRating,
    getResultIndicators = () => {},
    setAddRating = () => {},
    addNewRating = () => {},
    editRating = () => {},
    removeRating = () => {}
}) => {

    const { t, i18n } = useTranslation('common');

    const [formRating, setFormRating] = useState({
        result_indicators_id: resultIndicator.id,
        ratings_id: rating.ratings_id || null,
        ratings_label: rating.ratings_label || "",
        ratings_help_text: rating.ratings_help_text || "",
        ratings_score: rating.ratings_score || ""
    })

    const [formErrors, setFormErrors] = useState({
        ratings_label: "",
        ratings_help_text: ""
    });

    const onChangeFormRating = (event) => {
        const { name, value } = event.target

        const newFormRating = {
            ...formRating,
            [name]: value
        }

        setFormRating(newFormRating)

        if(formRating.ratings_id) {
            editRating(newFormRating)
        }

        setFormErrors({
            ...formErrors,
            [event.target.name]: ""
        })
    }

    const validateForm = () => {

        const newFormErrors = {}

        if(!formRating.ratings_label) {
            newFormErrors.ratings_label = t('validation.required')
        }

        if(!formRating.ratings_help_text) {
            newFormErrors.ratings_help_text = t('validation.required')
        }

        setFormErrors(newFormErrors)

        if(Object.keys(newFormErrors).length > 0) {
            return false
        }

        return true
    }

    const resetForm = () => {
        setFormRating({
            result_indicators_id: resultIndicator.id,
            ratings_id: null,
            ratings_label: "",
            ratings_help_text: "",
            ratings_score: ""
        })
        setFormErrors({
            ratings_label: "",
            ratings_help_text: ""
        })
    }


    return (
        <tr
        ref={provided && provided?.innerRef}
        {...provided?.draggableProps}
        {...provided?.dragHandleProps}
        className={styles.trCustomPadding}
        >
            {
                formRating.ratings_id !== null
                ?
                <td style={{ cursor: 'grab', width: '0px' }}>
                    <DragIndicator style={{ cursor: 'grab' }} />
                </td>
                :
                <td/>
            }

            <td style={{width: '25%'}}>
                <div className={styles.formGroup}>
                    <input required autoComplete='off' autoFocus={formRating.ratings_id === null} className={`${styles.inputEditable} ${formErrors.ratings_label && styles.inputError}`} placeholder={t('performance_assessment.page_scales_result_indicator.placeholder_option')} type="text" value={formRating?.ratings_label || ""} onChange={onChangeFormRating} name="ratings_label"/>
                </div>
            </td>
            <td>
                <div className={styles.formGroup}>
                    <input required  className={`${styles.inputEditable} ${formErrors.ratings_help_text && styles.inputError}`} placeholder={t('performance_assessment.page_scales_result_indicator.placeholder_text_help')} type="text" value={formRating?.ratings_help_text || ""} onChange={onChangeFormRating} name="ratings_help_text"/>
                </div>
            </td>
            <td style={{width: '18%'}}>
                <div className={`${styles.formGroup} ${styles.formGroupCenter}`}>
                    {rating?.ratings_score ? `${rating?.ratings_score.split("/")[0]}% ${t('performance_assessment.page_scales_result_indicator.to')} ${rating?.ratings_score.split("/")[1]}%`: "-----"}
                </div>
            </td>
            {
                (formRating.id !== null && !addRating)
                ?
                    <td style={{width: '0px'}}>
                        <div className={styles.boxButtonsOptions}>
                            <button
                                className={styles.buttonRemoveOption}
                                onClick={() => removeRating(formRating.ratings_id)}
                            >
                                <Delete style={{color:"#FC6662"}} fontSize='small' />
                            </button>
                        </div>
                    </td>

                :
                <td style={{width: '0px'}}>
                    <div className={styles.boxButtonsOptions}>
                        <button
                            className={styles.buttonRemoveOption}
                            onClick={() => setAddRating(false)}
                        >
                            <Close style={{color:"#FC6662"}} fontSize='small' />
                        </button>
                        <button
                            className={styles.buttonAddOption}
                            onClick={() => {
                                if(validateForm()){
                                    addNewRating(formRating)
                                    resetForm()
                                }
                            }}
                        >
                            <Add style={{color:"#187DFF"}} fontSize='small' />
                        </button>
                    </div>
                </td>
            }
        </tr>
    )
}   


export default RatingEdit;