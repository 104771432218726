import React, { useEffect, useState } from "react";
import Toolbar from "../../_components/_shared/Toolbar";
import { connect } from "react-redux";
import { alertActions, teammatesActions } from "../../_actions";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { authHeader, history} from "../../_helpers";
import GlobalSkeleton from "../../_components/_shared/GlobalSkeleton";
import { api } from "../../_helpers/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import FeedbackTabsComponent from "../../_components/performance_assessments/PerformanceAssessmentFeedback/FeedbackTabsComponent";
import ActionPlanForm from "../../_components/performance_assessments/PerformanceAssessmentActionPlan/ActionPlanForm";
import Filterbar from "../../_components/_shared/Filterbar";
function PerformanceAssessmentFeedbackActionPlanPage({
  alertActions,
  userDetails,
  filter,
  teammates,
  teammatesActions
}) {
  const { evaluated_id, action_plan_id, new_action_plan } = useParams();
  const { t, i18n } = useTranslation("common");
  const [fetching, setFetching] = useState(true);
  const [selectData, setSelectData] = useState(null);
  const [fetchingSelectData, setFetchingSelectData] = useState(true);
  const [actionPlanData, setActionPlanData] = useState(null);
  const [selectDataPerformanceAssessment, setSelectDataPerformanceAssessment] = useState(null);
  const [selectDataEvaluateds, setSelectDataEvaluateds] = useState(null);

  async function getActionPlanSelectData() {
    try {

      setFetchingSelectData(true);

      const response = await api.get(`/performance-assessment/action-plan/select-data`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setSelectData(response.data);

      await teammatesActions.getTeammates();

      setFetchingSelectData(false);

    } catch (error) {
      console.log(error);
      //alertActions.error(t('generic_messages.errors.load'))
      setFetchingSelectData(false);
    }
  }

  const getDataActionPlanByEvaluatedID = async () => {
    try {
      setFetching(true);
      const response = await api.get(`/performance-assessment/action-plan/evaluated/${evaluated_id}`, {
        headers: authHeader()
      })
      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }
      setActionPlanData(response.data);
      setFetching(false);
    } catch (error) {
      console.log(error);
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }
  }
  
  const getDataActionPlanByID = async () => {
    try {
      setFetching(true);
      const response = await api.get(`/performance-assessment/action-plan/${action_plan_id}`, {
        headers: authHeader()
      })
      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }
      setActionPlanData(response.data);
      setFetching(false);
    } catch (error) {
      console.log(error);
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }
  }

  const getActionPlanCreateSelectData = async (performance_assessment_id) => {
    try {
      if(!performance_assessment_id){
        setFetching(true);
      }
      const queryPAID = performance_assessment_id ? `?performance_assessment_id=${performance_assessment_id}` : ''

      const response = await api.get(`/performance-assessment/action-plan/select-create${queryPAID}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      const {performance_assessments, evaluateds} = response.data
      if(!performance_assessment_id){
        setSelectDataPerformanceAssessment(performance_assessments)
      }
      setSelectDataEvaluateds(evaluateds)
      setFetching(false);
    } catch (error) {
      console.log(error);
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }
  }
  
  useEffect(() => {
    if (evaluated_id) {
      getDataActionPlanByEvaluatedID();
    }
  }, [evaluated_id]);


  useEffect(() => {
    if(action_plan_id) {
      getDataActionPlanByID();
    }
  }, [action_plan_id]);

  useEffect(() => {
    if(new_action_plan && new_action_plan === 'new_action_plan') {
      getActionPlanCreateSelectData(null)
    }
  },[new_action_plan])

  useEffect(() => {
    document.title = `Befective | ${t("menu.performance_assessment.feedback")} | ${t("performance_assessment.page_feedback.action_plain_tab.title")}`;
    getActionPlanSelectData();
  }, []);

  return (
    <section className="mainContent">
      <Toolbar />

      {
        evaluated_id && (
          <FeedbackTabsComponent 
            currentActive="action-plan" 
            evalutedId={evaluated_id}
          />
        )
      }

      {
        (action_plan_id || new_action_plan) && (
          <Filterbar
            routeBackToPage={`/performance-assessment/action-plan`}
            filterWho={'disabled'}
            filterWhen={'disabled'}
            filterWhat={'disabled'}
            showShareBtn={false}
          />
        )
      }

      <main className="mainContentPage">
        {(!fetchingSelectData && !fetching) ? (
          <ActionPlanForm
            actionPlanData={actionPlanData}
            selectData={selectData}
            teammates={teammates}
            alertActions={alertActions}
            evaluatedId={evaluated_id}
            actionPlanId={action_plan_id}
            getDataForm={() => {
              if(evaluated_id) {
                getDataActionPlanByEvaluatedID();
                return
              }
              getDataActionPlanByID();
              history.push("/performance-assessment/action-plan")
            }}
            new_action_plan={new_action_plan}
            assessments={selectDataPerformanceAssessment}
            evaluateds={selectDataEvaluateds}
            getActionPlanCreateSelectData={getActionPlanCreateSelectData}
          />         
        ) : (
          <GlobalSkeleton
            totalRowsStats={0}
            totalTables={1}
            heightTable={600}
          />
        )}
      </main>
    </section>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter,
    teammates: state.teammates.payload,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceAssessmentFeedbackActionPlanPage);
