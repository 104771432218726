import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Modal,
  Slide,
  Typography,
  makeStyles,
} from "@material-ui/core";
import styles from "./ImportScalesModal.module.scss";
import { useTranslation } from "react-i18next";
import { api } from "../../../_helpers/api";
import { authHeader } from "../../../_helpers";
import IconClose from "../../_icons/IconClose";
import BefectiveTooltip from "../../_shared/BefectiveTooltip";
import IconInfo from "../../_icons/IconInfo";
import { Add, Remove } from "@material-ui/icons";
import BefectiveCheckbox from "../../_shared/BefectiveCheckbox";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function ImportScalesModal({ openModal, closeModal, getScalesAnswers, alertActions}) {

  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const [loadingScalesModels, setLoadingScalesModels] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scalesModels, setScalesModels] = useState([]);

  const letters = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","U","V","W","X","Y","Z"]

  const getScalesModels = async () => {
    try {
      setLoadingScalesModels(true);
      const response = await api.get("/performance-assessment/scales/import-models", {
        headers: authHeader(),
      });
      const { data } = response;
      if(data.length > 0){
        setScalesModels(data.map((scale, index) => ({...scale, selected: false, show: true})));
      }
      setLoadingScalesModels(false);
    } catch (error) {
      console.log(error);
      setLoadingScalesModels(false);
    }
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const selectedScalesIds = scalesModels.filter(s => s.selected).map(s => s.id);

      if(selectedScalesIds.length === 0){
        alertActions.error(t("performance_assessment.page_scales_answers.modal_import.error_no_scale_selected"));
        return;
      }

      const response = await api.post("/performance-assessment/scales/import-models", { scales_id: selectedScalesIds }, {
        headers: authHeader(),
      });

      closeModal();
      await getScalesAnswers();
      alertActions.success(t("performance_assessment.page_scales_answers.modal_import.success_import"));
      setIsSubmitting(false);
      setScalesModels([]);
    } catch (error) {
      console.log(error);
      alertActions.error(t("performance_assessment.page_scales_answers.modal_import.error_import"));
      setIsSubmitting(false);
    }
  }
  

  const onChangeCheckedScale = (scale) => {
    setScalesModels(scalesModels.map(s => s.id === scale.id ? {...s, selected: !s.selected} : s));
  }

  useEffect(() => {
    if(openModal){
      getScalesModels();
    }
  }, [openModal]);


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${styles.modal}`}
      open={openModal}
      onClose={() => {
        if(!isSubmitting){
          closeModal();
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: "#205B6F", opacity: 0.8 },
      }}
      sx={{ border: "none" }}
      style={{ outline: 0 }}
    >
      <Slide
        direction="down"
        in={openModal}
        style={{ outline: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.containerModal}>

          <button
              onClick={() => {
                if(!isSubmitting){
                  closeModal();
                }
              }}
              className={styles.close}
          >
            <IconClose />
          </button>


          <header>
            <h4>
              {t("performance_assessment.page_scales_answers.modal_import.title")}
            </h4>
            <p>{t("performance_assessment.page_scales_answers.modal_import.subtitle")}</p>
          </header>

          <main>
            {
              !loadingScalesModels
              ?
              (
                scalesModels.length > 0
                ?
                scalesModels.map((scaleModel, index) => {

                  return (
                    <div className={styles.scaleContainer} key={scaleModel.id}>
                      <header className={styles.scaleHeader} onClick={() => onChangeCheckedScale(scaleModel)}>
                        <div className={styles.scaleBoxTitle}>
                          {
                            scaleModel.is_objective === true &&
                           ( 
                              scaleModel.show
                              ?
                              <IconButton aria-label="minus" size="small" onClick={(e) => {
                                e.stopPropagation();
                                setScalesModels(scalesModels.map(s => s.id === scaleModel.id ? {...s, show: false} : s))
                              }}>
                                <Remove fontSize="small" />
                              </IconButton>
                              :
                              <IconButton aria-label="plus" size="small" onClick={(e) => {
                                e.stopPropagation();
                                setScalesModels(scalesModels.map(s => s.id === scaleModel.id ? {...s, show: true} : s))
                              }}>
                                <Add fontSize="small" />
                              </IconButton>
                            )
                          }
                          <h6 style={{color: scaleModel.selected ? '#22BEAD' : '#1E6B7F'}}>{`${scaleModel[`name_${i18n.language}`]} ${scaleModel.is_objective === false ? '('+t('performance_assessment.page_scales_answers.discursive')+')' : ''}`}</h6>
                          {
                            scaleModel[`description_${i18n.language}`] &&
                            <BefectiveTooltip
                                title={<Typography component={'div'} variant='caption'>{scaleModel[`description_${i18n.language}`]}</Typography>}
                                placement="right-start"
                                arrow
                                interactive>
                                <span style={{ cursor: 'pointer' }}>&nbsp;&nbsp;<IconInfo /></span>
                            </BefectiveTooltip>
                          }
                        </div>
                        <div className={styles.scaleBoxCheckboxSelect}>
                           <BefectiveCheckbox 
                            checked={scaleModel.selected} 
                            onChange={(e) => {
                              e.stopPropagation(); 
                              onChangeCheckedScale(scaleModel)
                            }}/>
                        </div>
                      </header>

                      {
                        (scaleModel.show && scaleModel.is_objective === true) && (
                          <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th style={{width: '0px', paddingRight: '4px'}}/>
                                    <th style={{width: '30%'}}>{t("performance_assessment.page_scales_answers.table.col_options")}</th>
                                    <th>{t("performance_assessment.page_scales_answers.table.col_text_help")}</th>
                                    <th style={{width: '10px', textAlign: 'center'}}>{t("performance_assessment.page_scales_answers.table.col_value")}</th>
                                </tr>
                            </thead>
                            <tbody className={styles.tbodyGray}>
                              {
                              (scaleModel.options && scaleModel.options.length > 0) 
                                ?
                                scaleModel.options.map((option, index) => {
                                    return (
                                      <tr key={`option-${index}`}>
                                          <td style={{width: '0px', paddingRight: '4px'}}>{`${letters[index]}.`}</td>
                                          <td style={{width: '30%'}}>{option[`label_${i18n.language}`]}</td>
                                          <td>
                                            {
                                              option[`help_text_${i18n.language}`]
                                              ?
                                              ( 
                                                option[`help_text_${i18n.language}`].length > 40
                                                ?
                                                <BefectiveTooltip
                                                  title={<Typography component={'div'} variant='caption'>{option[`help_text_${i18n.language}`]}</Typography>}
                                                  placement="right-start"
                                                  arrow
                                                  interactive>
                                                  <span>{`${option[`help_text_${i18n.language}`].substring(0, 40)}...`}</span>
                                                </BefectiveTooltip>                                                
                                                :
                                                option[`help_text_${i18n.language}`]
                                              )
                                              :
                                              '------'
                                            }
                                          </td>
                                          <td style={{width: '10px', textAlign: 'center'}}>{option.value}</td>
                                      </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={5}>{t("performance_assessment.page_scales_answers.no_items")}</td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        )
                      }
                    </div>
                  )
                })
                :
                <p>{t("performance_assessment.page_scales_answers.modal_import.no_models_found")}</p>
              )
              :
              <div className={styles.boxLoading}>
                <CircularProgress />
              </div>

            }
          </main>

          <footer>            
            <button className={styles.buttonCancel} type="button" disabled={isSubmitting} onClick={closeModal}>
              {t("performance_assessment.actions.close")}
            </button>
            <button className={styles.buttonSave} style={{cursor: isSubmitting && 'progress'}} disabled={isSubmitting || !scalesModels.some(s => s.selected)} type="button" onClick={handleSubmit}>
              {isSubmitting ? <CircularProgress size={20} /> : t("performance_assessment.actions.import")}
            </button>
          </footer> 
        </div>
      </Slide>
    </Modal>
  );
}
